import axios from 'axios'
import { api } from 'services/api.service'
import { useCallback, useState } from 'react'
import { useAlert } from 'context/Alert/AlertContext'
import usePagination from 'hooks/usePagination'

export type Params = {
  id: number
  type: 'Brand' | 'Retailer' | 'Order'
}

type AuditLogHook = {
  log: DiffObj[]
  isLoading: boolean
  fetchData: () => () => void
  pagination: ReturnType<typeof usePagination>['pagination']
}

type DiffObj = {
  id: number
  event: string
  whodunnit: { id: number; email: string; fullName: string }
  changeset: { [key: string]: [string, string]; updatedAt: [string, string] }
}

const mapType: Record<Params['type'], string> = {
  Brand: 'brands',
  Retailer: 'retailers',
  Order: 'orders',
}

export default function useAuditLog({ id, type }: Params): AuditLogHook {
  const [log, setLog] = useState<DiffObj[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const { addAlert } = useAlert()
  const { pagination, paginationQueryParams, setPagination } = usePagination()

  const fetchData = useCallback(() => {
    const cancelSource = axios.CancelToken.source()
    setIsLoading(true)
    api
      .get(`${mapType[type]}/${id}/history`, {
        params: { ...paginationQueryParams },
        cancelToken: cancelSource.token,
      })
      .then((resp: any) => {
        setLog(resp.data.data)
        setPagination(resp.data.meta)
        setIsLoading(false)
      })
      .catch((err: any) => {
        if (!axios.isCancel(err)) {
          console.error(err)
          setIsLoading(false)
          addAlert(`Error fetching ${type} audit log for ${id}`)
        }
      })
    return () => cancelSource.cancel()
  }, [type, id, paginationQueryParams, setPagination, addAlert])

  return { fetchData, isLoading, log, pagination }
}
