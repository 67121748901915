import { Address as AddressType } from 'types'
import { Country, Countries } from 'types'

import PhoneNumber from 'components/PhoneNumber'

interface Props {
  address: AddressType
  country: Country
}

const Address: React.FC<Props> = ({ address, country }) => {
  if (!Object.values(address).some(Boolean)) return <>—</>

  return (
    <>
      <AddressLine>{address.name}</AddressLine>
      <AddressLine>{address.businessName}</AddressLine>
      <AddressLine>{address.address1}</AddressLine>
      <AddressLine>{address.address2}</AddressLine>
      {country === Countries['United States'] && (
        <AddressLine>{address.zipcode && `${address.city}, ${address.state} ${address.zipcode}`}</AddressLine>
      )}
      {country === Countries['United Kingdom'] && (
        <>
          <AddressLine>{address.city}</AddressLine>
          <AddressLine>{address.state}</AddressLine>
          <AddressLine>{address.zipcode}</AddressLine>
        </>
      )}
      <AddressLine>{address.country}</AddressLine>
      <AddressLine>
        <PhoneNumber phone={address.phone} country={country} />
      </AddressLine>
    </>
  )
}

const AddressLine = ({ children }: any) => children && <div>{children}</div>

export default Address
