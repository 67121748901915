import { useMemo } from 'react'
import { GridColDef } from '@material-ui/data-grid'

import DataGrid, { RelativeDateCell, UserCell } from 'components/DataGrid'
import useProductImportsQueue, { UseProductImportsQueueParams } from 'hooks/useProductImportsQueue'

import { PrioritizeButton } from './PrioritizeButton'
import { CancelButton } from './CancelButton'

export type ProductImportsQueueDataGridProps = UseProductImportsQueueParams

export default function ProductImportsQueueDataGrid(props: ProductImportsQueueDataGridProps) {
  const { items, isLoading, pagination, triggerRefresh } = useProductImportsQueue(props)

  const dataGridCols = useMemo(() => reviewColumns({ hideActions: props.queued !== true, triggerRefresh }), [
    props.queued,
    triggerRefresh,
  ])

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick
      rows={items ?? []}
      columns={dataGridCols}
      pagination
      paginationMode="server"
      page={pagination.currentPage - 1}
      pageSize={pagination.perPage}
      rowCount={pagination.count}
      onPageChange={(params) => {
        pagination.goToPage(params.page + 1)
      }}
      loading={isLoading}
    />
  )
}

function reviewColumns(props: { hideActions: boolean; triggerRefresh?: () => void }): GridColDef[] {
  return [
    {
      headerName: 'ID',
      field: 'id',
      flex: 0.3,
    },

    {
      headerName: 'Brand',
      field: 'brandProfile',
      renderCell: ({ value }: any) => (
        <UserCell user={null} linkTo={{ href: `/brands/${value?.id}`, text: value?.companyName }} />
      ),
      flex: 1,
    },

    {
      headerName: 'State',
      field: 'state',
      flex: 0.5,
    },

    {
      headerName: 'Started At',
      field: 'startedAt',
      renderCell: ({ value }: any) => RelativeDateCell({ isoDateTime: value }),
      flex: 1,
    },

    {
      headerName: 'Finished At',
      field: 'finishedAt',
      renderCell: ({ value }: any) => RelativeDateCell({ isoDateTime: value }),
      flex: 1,
    },

    {
      headerName: '# Products Validated',
      field: 'productsValidated',
      flex: 0.3,
    },

    {
      headerName: '# Products Saved',
      field: 'productsSaved',
      flex: 0.3,
    },

    {
      headerName: 'File',
      field: '__File',
      renderCell: (cell) => (
        <a target="_blank" rel="noreferrer" href={cell.row.fileUrl} download>
          {cell.row.fileName}
        </a>
      ),
    },

    {
      headerName: 'Type',
      field: 'csvType',
    },

    {
      headerName: 'Uploader',
      field: 'uploadedByEmail',
      flex: 1,
    },

    {
      headerName: 'Prioritize',
      field: '__Prioritize',
      renderCell: (cell) => (
        <PrioritizeButton
          id={cell.row.id}
          disabled={cell.row.state !== 'pending_persistence'}
          triggerRefresh={props.triggerRefresh}
        />
      ),
      flex: 1,
      hide: props.hideActions,
    },

    {
      headerName: 'Cancel',
      field: '__Cancel',
      renderCell: (cell) => (
        <CancelButton
          id={cell.row.id}
          disabled={cell.row.state !== 'pending_persistence'}
          triggerRefresh={props.triggerRefresh}
        />
      ),
      flex: 1,
      hide: props.hideActions,
    },
  ]
}
