export default function normalizeFacebookPage(facebookPage: string | null) {
  if (!facebookPage) {
    // nope
    return null
  } else if (/^https?:\/\/.*facebook\.com.+/.test(facebookPage)) {
    // probably a valid link
    return facebookPage
  } else if (/^(www\.)?.*facebook\.com.+/.test(facebookPage)) {
    // almost a valid link
    return 'https://' + facebookPage
  } else if (facebookPage?.startsWith('@') && !facebookPage?.includes(' ')) {
    // probably a valid handle prefixed with @
    return `http://www.facebook.com/${facebookPage.slice(1)}`
  } else if (!facebookPage?.includes(' ')) {
    // maybe a valid handle
    return `https://www.facebook.com/${facebookPage}`
  } else {
    // who the hell knows, search it
    return `https://www.facebook.com/search?q=${facebookPage}`
  }
}
