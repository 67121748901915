import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TextField, Container } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { api } from 'services/api.service'
import useToken from 'hooks/useToken'
import usePageTitle from 'hooks/usePageTitle'
import Button from 'components/Button'
import { useAbility } from 'hooks/useAbility'

interface Props {
  setToken: (token: string) => void
}

const Login: React.FC<Props> = () => {
  usePageTitle('Login')

  const css = useStyles()
  const { token, setToken } = useToken()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const history = useHistory()
  const ability = useAbility()

  useEffect(() => {
    if (token) {
      const initialPath = localStorage.getItem('initialPath')
      if (initialPath) {
        localStorage.removeItem('initialPath')
        history.replace(initialPath)
      } else {
        history.replace('/')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsBusy(true)
    api
      .post('/auth/sign_in', { email, password })
      .then((response: any) => {
        setToken(response.data)
        ability.update(response.data?.rules || [])
      })
      .catch((error: any) => {
        if (!error.response || error.response.status >= 500) {
          setError('Something went wrong on the backend. Please contact support.')
        } else {
          setError('Invalid Credentials. Please try again.')
        }
      })
      .finally(() => setIsBusy(false))
  }

  return (
    <Container maxWidth="xs" style={{ marginTop: 100 }}>
      <h1 className={css.pad}>Administry</h1>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          className={css.pad}
          fullWidth
          inputProps={{ required: true }}
          placeholder="Email"
          name="email"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          className={css.pad}
          fullWidth
          inputProps={{ required: true }}
          placeholder="Password"
          type="password"
          name="password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <Button
          primary
          type="submit"
          size="large"
          fullWidth
          className={css.pad}
          busy={isBusy}
          disabled={!email.length || !password.length}
        >
          Log In
        </Button>
        {error && (
          <Alert severity="error" className={css.pad}>
            {error}
          </Alert>
        )}
      </form>
    </Container>
  )
}

export default Login

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pad: {
      margin: theme.spacing(1),
    },
  })
)
