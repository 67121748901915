import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAlert } from 'context/Alert/AlertContext'

import { api } from 'services/api.service'
import usePagination from 'hooks/usePagination'

export type UseProductImportsQueueParams = {
  queued?: true
  processing?: true
  completed?: true
  rejected?: true
  succeeded?: true
  canceled?: true
}

export type ProductImportsQueueItem = {}

export default function useProductImportsQueue(props?: UseProductImportsQueueParams) {
  const snackbar = useAlert()
  const [refreshSignal, setRefreshSignal] = useState({})
  const [items, setItems] = useState<ProductImportsQueueItem[]>()
  const { pagination, setPagination, paginationQueryParams } = usePagination({ perPage: 15 })

  const triggerRefresh = useCallback(() => {
    setRefreshSignal({})
  }, [])

  const queryString = useMemo(() => {
    let params: Record<string, string> = paginationQueryParams
    if (props?.queued) params.queued = 'true'
    if (props?.processing) params.processing = 'true'
    if (props?.completed) params.completed = 'true'
    if (props?.rejected) params.rejected = 'true'
    if (props?.succeeded) params.succeeded = 'true'
    if (props?.canceled) params.canceled = 'true'
    return new URLSearchParams(params).toString()
  }, [
    paginationQueryParams,
    props?.queued,
    props?.processing,
    props?.completed,
    props?.rejected,
    props?.succeeded,
    props?.canceled,
  ])

  useEffect(() => {
    api
      .get(`/product_imports_queue?${queryString}`)
      .then(({ data }) => {
        setItems(data.data)
        setPagination(data.meta)
      })
      .catch((err) => {
        console.error(err)
        snackbar.error('Failed to fetch the imports queue')
      })
  }, [queryString, refreshSignal, setPagination, snackbar])

  return {
    items,
    isLoading: items === undefined,
    pagination,
    triggerRefresh,
  }
}
