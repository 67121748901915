import { api } from 'services/api.service'
import { useAlert } from 'context/Alert/AlertContext'
import useAction from 'hooks/useAction'
import { snakeCase } from 'lodash'

type Params<T> = {
  setResources?: React.Dispatch<React.SetStateAction<T[]>>
  resourceName: string
  url: string
}

export default function useCreateResource<T>({ setResources, url, resourceName }: Params<T>) {
  const snackbar = useAlert()

  const [createResource, isCreatingResource, createResourceError] = useAction({
    request: (resource) => api.post(url, { [snakeCase(resourceName)]: resource }),
    onSuccess: (resp) => {
      setResources && setResources((resources) => [...resources, resp.data])
      snackbar.success(`Created new ${resourceName}`)
    },
    onError: (err) => {
      console.error(err)
      snackbar.error(`Error creating new ${resourceName}`)
    },
  })

  return { createResource, isCreatingResource, createResourceError }
}
