import { Popper, Paper } from '@material-ui/core'
import clsx from 'clsx'

import BrandIndex from './BrandIndex'
import RetailerIndex from './RetailerIndex'
import OrderIndex from './OrderIndex'
import OrderReturnIndex from './OrderReturnIndex'
import useStyles from './styles'

const ResultsBox = ({ open, anchorEl, close }: any) => {
  const css = useStyles()

  return (
    <Popper open={open} anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: 1000000 }} transition>
      <Paper variant="outlined" className={clsx(css.root, css.results)} onClick={close}>
        <BrandIndex />
        <RetailerIndex />
        <OrderIndex />
        <OrderReturnIndex />
      </Paper>
    </Popper>
  )
}

export default ResultsBox
