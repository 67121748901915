import { Grid } from '@material-ui/core'
import * as yup from 'yup'
import Async from 'components/Async'
import EditableDefinition from 'components/EditableDefinition'
import MoneyInput from 'components/MoneyInput'
import Panel from 'components/Panel'
import usePaymentPlans from 'hooks/usePaymentPlans'
import centsToCurrency, { centsToUnit } from 'util/centsToCurrency'
import { useCurrencyContext } from 'context/Country/CountryContext'
import Button from 'components/Button'
import { useState } from 'react'
import PaymentPlanForm, { Frequents } from './PaymentPlanForm'
import Definition from 'components/Definition'
import { PaymentPlan } from 'types/paymentPlan'
import { currencyToCents } from 'util/currencyToCents'
import Payments from 'pages/Retailers/Retailer/Payments'

const PaymentPlanPanel = ({
  paymentPlan,
  updatePaymentPlan,
  updatePayment,
}: {
  paymentPlan: PaymentPlan
  updatePaymentPlan: (id: number, field: string, value: any) => any
  updatePayment: (id: number, field: string, value: any) => any
}) => {
  const [showPayments, setShowpayments] = useState(false)
  const currency = useCurrencyContext()

  return (
    <Panel>
      <Grid container spacing={2}>
        <Grid item xs={8}></Grid>

        <Grid item xs={4}>
          <Button
            small
            style={{ float: 'right' }}
            onClick={() => {
              setShowpayments(true)
            }}
          >
            Payments
          </Button>
        </Grid>
        <Grid item xs={6} lg={4}>
          <EditableDefinition
            label="Total Amount"
            textFieldProps={{
              InputProps: {
                inputComponent: MoneyInput,
              },
            }}
            value={centsToUnit(paymentPlan.totalAmountCents)}
            validator={yup.string().required("Amount can't be blank.")}
            onSave={(value: any) => updatePaymentPlan(paymentPlan.id, 'totalAmountCents', currencyToCents(value))}
          >
            {centsToCurrency(paymentPlan.totalAmountCents, currency)}
          </EditableDefinition>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Definition label="Paid Amount">{paymentPlan.paidAmounCents}</Definition>
        </Grid>
        <Grid item xs={6} lg={4}>
          <EditableDefinition
            label="Single Payment Amount"
            textFieldProps={{
              InputProps: {
                inputComponent: MoneyInput,
              },
            }}
            value={centsToUnit(paymentPlan.paymentAmountCents)}
            validator={yup.string().required("Amount can't be blank.")}
            onSave={(value: any) => updatePaymentPlan(paymentPlan.id, 'paymentAmountCents', currencyToCents(value))}
          >
            {centsToCurrency(paymentPlan.paymentAmountCents, currency)}
          </EditableDefinition>
        </Grid>
        <Grid item xs={6} lg={4}>
          <EditableDefinition
            label="Frequent"
            options={Frequents}
            value={paymentPlan.frequent}
            onSave={(value: any) => updatePaymentPlan(paymentPlan.id, 'frequent', value)}
          >
            {paymentPlan.frequent}
          </EditableDefinition>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Definition label="State">{paymentPlan.state}</Definition>
        </Grid>
      </Grid>
      <Payments
        open={showPayments}
        onClose={() => setShowpayments(false)}
        payments={paymentPlan.payments}
        updatePayment={updatePayment}
      />
    </Panel>
  )
}

type PaymentPlansProps = {
  id: number
}

export default function PaymentPlans({ id }: PaymentPlansProps) {
  const [createPaymentPlanFormOpened, setCreatePaymentPlanFormOpened] = useState(false)
  const { paymentPlans, createPaymentPlan, updatePaymentPlan, updatePayment, paymentPlansIsLoading } = usePaymentPlans({
    retailerId: id,
  })
  return (
    <>
      <Panel
        title="Payment Plans"
        collapsible
        startCollapsed
        contentRight={
          <Button
            small
            onClick={() => {
              setCreatePaymentPlanFormOpened(true)
            }}
          >
            ＋ Create Plan
          </Button>
        }
      >
        <Async loading={paymentPlansIsLoading}>
          {paymentPlans.map((paymentPlan) => (
            <PaymentPlanPanel
              paymentPlan={paymentPlan}
              updatePaymentPlan={updatePaymentPlan}
              updatePayment={updatePayment}
              key={paymentPlan.id}
            />
          ))}
        </Async>
      </Panel>
      <PaymentPlanForm
        open={createPaymentPlanFormOpened}
        onClose={() => setCreatePaymentPlanFormOpened(false)}
        onSubmit={createPaymentPlan}
      />
    </>
  )
}
