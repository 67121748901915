import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    lineItem: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr 1fr',
      gridGap: 16,
      alignItems: 'center',
    },
    conditionValue: {
      display: 'grid',
      gridTemplateColumns: '3fr 4fr',
      borderBottom: '1px solid #eee',
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '& h4': {
        margin: 0,
      },
    },
    lineItemImage: {
      borderRadius: theme.spacing(1),
      cursor: 'pointer',
      maxHeight: 125,
    },
    flex: {
      display: 'flex',
      marginBottom: -theme.spacing(2.5),
      '& > * + *': {
        marginLeft: theme.spacing(5),
      },
    },
    twoCols: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: 16,
      alignItems: 'stretch',
      margin: '16px 0',
      '& > *': {
        margin: '0 !important',
      },
    },
    threeCols: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: 16,
      alignItems: 'stretch',
      margin: '16px 0',
      '& > *': {
        margin: '0 !important',
      },
    },
    modal: {
      padding: theme.spacing(2, 4, 3),
      display: 'flex',
      alignItems: 'flex-start',
      '& > img': {
        maxWidth: 250,
        height: 'auto',
        maxHeight: 'auto',
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(4),
        borderRadius: theme.spacing(1),
      },
    },
    modalContent: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > div': {
        marginRight: theme.spacing(4),
      },
    },
  })
)
