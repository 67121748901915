import { useState } from 'react'
import { useFormik } from 'formik'
import { startCase } from 'lodash'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import useOrder from 'hooks/useOrder'

import Button from 'components/Button'

type Props = {
  open: boolean
  onClose: () => void
  chargedOnAcceptance: boolean
  reinstateOrder: ReturnType<typeof useOrder>['reinstateOrder']
}

const states = ['accepted', 'pending_acceptance'] as const

const warningMessage: Record<typeof states[number], string> = {
  accepted: `Will charge the retailer credit!`,
  pending_acceptance: 'Will hold amount from retailer credit!',
}

export default function ReinstateDialog({ open, onClose, chargedOnAcceptance, reinstateOrder }: Props) {
  const [error, setError] = useState<string | null>(null)

  const { values, handleSubmit, getFieldProps, isSubmitting } = useFormik<{
    state: typeof states[number]
  }>({
    initialValues: { state: states[0] },
    onSubmit: ({ state }) => {
      if (error !== null) {
        setError(null)
      }
      return reinstateOrder(state)
        .then(onClose)
        .catch((err) => {
          setError(err?.message)
        })
    },
  })

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Reinstate Order</DialogTitle>
        <Divider />

        <DialogContent>
          {error !== null && <Alert severity="error">{error}</Alert>}

          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>State</InputLabel>
            <Select label="State" variant="outlined" fullWidth {...getFieldProps('state')}>
              {states.map((state) => (
                <MenuItem key={state} value={state}>
                  {startCase(state)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {chargedOnAcceptance && (
            <Alert severity="warning">
              <strong>{warningMessage[values.state]}</strong>
            </Alert>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>

          <Button type="submit" primary autoFocus busy={isSubmitting}>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
