export type Currency = {
  code: string
  fraction: number
  grapheme: string
  thousand: string
}

const createCurrencies = <T extends Record<string, Currency>>(currencies: T) => currencies

export const Currencies = createCurrencies({
  USD: {
    code: 'USD',
    fraction: 2,
    grapheme: '$',
    thousand: ',',
  },
  GBP: {
    code: 'GBP',
    fraction: 2,
    grapheme: '£',
    thousand: ',',
  },
})

const supportedCurrency = (code: string): code is keyof typeof Currencies => {
  if (code in Currencies) {
    return true
  }
  return false
}

export const getCurrency = (code: string): Currency | null => {
  return supportedCurrency(code) ? Currencies[code] : null
}
