import axios from 'axios'
import { api } from 'services/api.service'
import { useCallback, useEffect, useState } from 'react'
import { useAlert } from 'context/Alert/AlertContext'
import { ShipEngineAddress } from 'types'

export default function useReturnsShippingAddress() {
  const [returnAddressLoading, setReturnAddressLoading]: [boolean, Function] = useState(false)
  const [returnsShippingAddress, setReturnShippingAddress] = useState<ShipEngineAddress>()

  const { addAlert } = useAlert()

  const fetchData = useCallback(() => {
    const cancelSource = axios.CancelToken.source()
    setReturnAddressLoading(true)
    api
      .get(`constants/abound_returns_shipping_address`, { cancelToken: cancelSource.token })
      .then((resp: any) => {
        setReturnShippingAddress(resp.data)
        setReturnAddressLoading(false)
      })
      .catch((err: any) => {
        if (!axios.isCancel(err)) {
          setReturnAddressLoading(false)
          addAlert('Error fetching returnsShippingAddress', 'error')
        }
      })
    return () => cancelSource.cancel()
  }, [addAlert])

  useEffect(() => {
    return fetchData() // cancel pending request on unmount
  }, [fetchData])

  return {
    fetchData,
    returnAddressLoading,
    returnsShippingAddress,
  }
}
