import { Index, Hits, connectStateResults } from 'react-instantsearch-dom'

import { Box, Divider } from '@material-ui/core'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'

import Link from 'components/Link'

interface OrderHitProps {
  hit: {
    objectID: number
    uid: string
    order_uid: string
  }
}

const OrderReturnHit = ({ hit }: OrderHitProps) => (
  <Link to={`/returns/${hit.objectID}`}>
    <Box display="flex" alignContent="center">
      <KeyboardReturnIcon style={{ marginRight: 10 }} />
      {hit.uid} (order #{hit.order_uid})
    </Box>
  </Link>
)

const OrderReturnIndexResult = connectStateResults<any>(
  ({ searchResults, children }) =>
    searchResults &&
    searchResults.nbHits !== 0 && (
      <div>
        {children}
        <Divider />
      </div>
    )
)

const OrderReturnIndex = () => {
  return (
    <Index indexName={`admin_${process.env.REACT_APP_ENV}_order_returns`}>
      <OrderReturnIndexResult />
      <Hits hitComponent={OrderReturnHit} />
    </Index>
  )
}

export default OrderReturnIndex
