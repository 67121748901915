import React, { useState, useRef, useMemo } from 'react'

import Confirmation from 'components/Confirmation'

const ConfirmContext = React.createContext<any>(undefined)

interface ConfirmInfo {
  title: string
  text: string
  submitText?: string
  danger?: boolean
}

const ConfirmProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false)
  const [info, setInfo] = useState<ConfirmInfo | null>(null)
  const promiseRef = useRef<any>()

  const confirm = (confirmInfo: ConfirmInfo) => {
    setInfo(confirmInfo)
    setOpen(true)

    return new Promise((resolve, reject) => {
      promiseRef.current = { resolve, reject }
    })
  }

  const handleCancel = () => {
    setOpen(false)
    setInfo(null)
    promiseRef.current && promiseRef.current.reject()
  }

  const handleSubmit = () => {
    promiseRef.current && promiseRef.current.resolve()
    setOpen(false)
    setInfo(null)
  }

  const value = useMemo(() => ({ confirm }), [])

  return (
    <ConfirmContext.Provider value={value}>
      {children}
      <Confirmation onCancel={handleCancel} onSubmit={handleSubmit} open={open} {...info} />
    </ConfirmContext.Provider>
  )
}

const useConfirm = () => {
  const context = React.useContext(ConfirmContext)

  return context
}

export { ConfirmProvider, useConfirm }
