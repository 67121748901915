import { useState } from 'react'
import { isEmpty, pick } from 'lodash'

import { Box, Card, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

import { Order, ShippingLabel, Address } from 'types'

import Button from 'components/Button'
import Definition from 'components/Definition'
import MiniForm from 'components/MiniForm'
import OrderShipmentFormFields from 'components/OrderShipmentFormFields'
import ExternalLink from 'components/ExternalLink'
import Panel from 'components/Panel'
import PageActionsMenu from 'components/PageActionsMenu'
import { useConfirm } from 'context/Confirm/ConfirmContext'
import OrderShippingLabelForm from './OrderShippingLabelForm'
import ShippingLabels from '../../../../pages/OrderReturns/ShippingLabels'
import centsToCurrency from 'util/centsToCurrency'
import { useCurrencyContext } from 'context/Country/CountryContext'

interface ShipmentsProps {
  order: Order
  shippingLabels: [ShippingLabel]
  shipments: any[]
  createShipment: (shipment: any) => Promise<any>
  updateShipment: (shipment: any) => Promise<any>
  updateShipmentState: (shipment: any, state: string) => Promise<any>
  deleteShipment: (shipment: any) => Promise<any>
  shipToAddress: Address
  shipFromAddress: Address
}

const shipmentFieldsToUpdate = [
  'id',
  'trackingId',
  'costInCents',
  'carrier',
  'deliveredAt',
  'method',
  'assumedDeliveredAt',
  'weight',
  'dimensions',
]

export default function Shipments({
  order,
  shippingLabels,
  shipments,
  createShipment,
  updateShipment,
  updateShipmentState,
  deleteShipment,
  shipToAddress,
  shipFromAddress,
}: ShipmentsProps) {
  const css = useStyles()
  const { confirm } = useConfirm()
  const currency = useCurrencyContext()
  const [showShippingLabelForm, setShowShippingLabelForm] = useState(false)

  const handleShippingFormClose = () => {
    setShowShippingLabelForm(false)
  }

  const newShipment = {
    trackingId: null,
    costInCents: null,
    carrier: null,
    deliveredAt: null,
    method: null,
    assumedDeliveredAt: null,
    weight: {},
    dimensions: {},
  }

  const handleDelete = (shipmentToDelete: any) => {
    confirm({
      title: 'Delete Shipment',
      text: `Are you sure you would like to delete this shipment with tracking ID '${shipmentToDelete.trackingId}'?`,
      submitText: 'Delete',
      danger: true,
    })
      .then(() => {
        deleteShipment(shipmentToDelete)
      })
      .catch((err: any) => {
        if (err !== undefined) {
          console.error(err)
        }
      })
  }

  return (
    <>
      <Panel>
        {shipments.map((shipment: any, idx: number) => {
          const shipmentCost = centsToCurrency(shipment.costInCents, currency)
          return (
            <Card key={idx} className={css.root} elevation={0}>
              <Box display="flex" justifyContent="space-between">
                <Typography component="div">
                  <Box fontWeight="fontWeightMedium" mb={2}>
                    Package {idx + 1}
                  </Box>
                </Typography>
                <Box>
                  <PageActionsMenu
                    disabled={shipment.availableAasmStateTransitions.length === 0}
                    items={shipment.availableAasmStateTransitions.map((transition: string) => ({
                      label: transition,
                      callback: () => updateShipmentState(shipment, transition),
                    }))}
                  />
                  <Button danger onClick={() => handleDelete(shipment)} style={{ marginLeft: 5 }}>
                    Delete
                  </Button>
                </Box>
              </Box>

              <MiniForm
                fields={shipment}
                onSave={(s) => updateShipment(pick(s, shipmentFieldsToUpdate))}
                style={{ marginBottom: 24 }}
                renderFields={(formik: any) => <OrderShipmentFormFields formik={formik} />}
              >
                <Definition label="Tracking ID">
                  <ExternalLink href={`https://www.google.com/search?q=${shipment.trackingId}`}>
                    {shipment.trackingId}
                  </ExternalLink>
                </Definition>
                <Definition label="Status">{shipment.aasmState}</Definition>
                <Definition label="Service">
                  {shipment.carrier}
                  {` (${shipmentCost})`}
                </Definition>
                <Definition label="Delivered at">{shipment.deliveredAt}</Definition>
                <Definition label="Assumed Delivered At">{shipment.assumedDeliveredAt}</Definition>
                <Definition label="Method">{shipment.method}</Definition>
                <Definition label="Weight">
                  {!isEmpty(shipment.weight) ? (
                    <>
                      {shipment.weight?.value} {shipment.weight?.unit}
                    </>
                  ) : (
                    ''
                  )}
                </Definition>
                <Definition label="Dimensions (length X width X height)">
                  {!isEmpty(shipment.weight) ? (
                    <>
                      {shipment.dimensions?.length} X {shipment.dimensions?.width}
                      {' X '}
                      {shipment.dimensions?.height} {shipment.dimensions?.unit}
                    </>
                  ) : (
                    ''
                  )}
                </Definition>
              </MiniForm>
            </Card>
          )
        })}
        <br />

        <MiniForm
          fields={newShipment}
          onSave={(s) => createShipment(pick(s, shipmentFieldsToUpdate))}
          style={{ marginBottom: 24 }}
          renderFields={(formik: any) => <OrderShipmentFormFields formik={formik} />}
          renderToggleEdit={(onClick) => (
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={onClick}>Add Package</Button>
            </Box>
          )}
        ></MiniForm>
      </Panel>
      <Panel
        title="Shipping Labels"
        contentRight={
          <Button onClick={() => setShowShippingLabelForm(true)} startIcon={<AddIcon />}>
            Create New Shipping Label
          </Button>
        }
      >
        {shippingLabels?.map((label: any) => (
          <ShippingLabels key={label.id} shippingLabel={label} />
        ))}
        <OrderShippingLabelForm
          orderId={order.id}
          shipFrom={shipFromAddress}
          shipTo={shipToAddress}
          open={showShippingLabelForm}
          onClose={handleShippingFormClose}
        />
      </Panel>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0),
      margin: theme.spacing(0, 1, 2, 1),
      bshipments: 0,
      borderRadius: 0,
      borderBottom: '1px solid rgba(0,0,0,.1)',
      '&:last-child': {
        border: 0,
      },
    },
  })
)
