import { api } from 'services/api.service'
import { useCallback, useEffect, useState } from 'react'
import { useAlert } from 'context/Alert/AlertContext'

interface Params {
  brandCountry: string
}

export default function useBrandValueOptions({ brandCountry }: Params) {
  const [brandValueOptions, setBrandValueOptions] = useState<string[]>()
  const snackbar = useAlert()

  const queryUrl = brandCountry === 'United Kingdom' ? 'uk_brand_values' : 'us_brand_values'

  const getBrandValueOptions = useCallback(() => {
    return api
      .get(`/constants/${queryUrl}`)
      .then((resp) => {
        setBrandValueOptions(resp.data)
      })
      .catch((err) => {
        snackbar.error('Error fetching brand value options.')
      })
  }, [snackbar, queryUrl])

  useEffect(() => {
    getBrandValueOptions()
  }, [getBrandValueOptions])

  return { brandValueOptions }
}
