import { useEffect, useState } from 'react'
import { api, HOSTNAME } from 'services/api.service'
import useAction from 'hooks/useAction'
import useSafeDispatch from 'hooks/useSafeDispatch'

const POLL_INTERVAL_MILLISECONDS = 10000

function isDisabled() {
  const enabled = window.localStorage.getItem('enableConnectionChecker')
  return !enabled
}

export default function useConnectionStatus() {
  const [connected, setConnected] = useState(true)
  const safeSetConnected = useSafeDispatch(setConnected)
  const [ping] = useAction({
    request: () =>
      api.get('/api/current_user', {
        baseURL: HOSTNAME,
      }),
    onSuccess: () => safeSetConnected(true),
    onError: () => safeSetConnected(false),
  })

  useEffect(() => {
    const id = setInterval(() => {
      if (isDisabled()) {
        safeSetConnected(true)
        return
      }
      ping().catch(() => safeSetConnected(false))
    }, POLL_INTERVAL_MILLISECONDS)
    return () => clearInterval(id)
  }, [ping, safeSetConnected])

  return {
    connected,
  }
}
