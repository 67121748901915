import { useCallback, useEffect, useState } from 'react'
import { GridPageChangeParams } from '@material-ui/data-grid'

import { api, cancelRequest } from 'services/api.service'
import { useAlert } from 'context/Alert/AlertContext'
import useAction from 'hooks/useAction'

interface RetailerPrams {
  query?: string
  pageSize?: number
  selectedCountry?: number
  verified?: number
  deleted?: number
  dropshipStatus?: string
}

const cancelKey = 'retailers'

export default function useRetailers({
  pageSize = 25,
  query,
  selectedCountry,
  verified,
  deleted,
  dropshipStatus,
}: RetailerPrams) {
  const [retailerProfiles, setRetailerProfiles] = useState([])
  const [retailerProfilesCount, setRetailerProfilesCount] = useState<number>(0)
  const snackbar = useAlert()
  const [page, setPage] = useState<number>(0)

  const handlePageChange = useCallback((params: GridPageChangeParams) => {
    setPage(params.page)
  }, [])

  const qp = new URLSearchParams({
    page: '' + (page + 1),
    per_page: '' + pageSize,
  })
  query && qp.set('query', query)
  if (selectedCountry !== undefined) qp.set('country', selectedCountry.toString())
  if (verified !== undefined) qp.set('verified', verified.toString())
  if (deleted !== undefined) qp.set('deleted', deleted.toString())
  if (dropshipStatus !== undefined) qp.set('dropship_status', dropshipStatus.toString())
  const queryString = qp.toString()

  const [fetchData, isLoading] = useAction({
    request: () => api.get(`retailers/?${queryString}`, { cancelKey }),
    onSuccess: (resp: any) => {
      setRetailerProfilesCount(resp.data.meta.total)
      setRetailerProfiles(resp.data.data)
    },
    onError: () => snackbar.error('Error fetching retailers'),
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  useEffect(() => () => cancelRequest(cancelKey), [])

  return {
    fetchData,
    isLoading,
    retailerProfiles,
    retailerProfilesCount,
    handlePageChange,
  }
}
