import { useMemo } from 'react'
import { Switch } from '@material-ui/core'
import { GridColDef } from '@material-ui/data-grid'
import { StarRounded, StarOutlineRounded } from '@material-ui/icons'

import { Review } from 'types'

import { useReviews, UseReviewsParams } from 'hooks/useReviews'

import Link from 'components/Link'
import DataGrid, { UserCell } from 'components/DataGrid'

type Props = UseReviewsParams & { hideRetailer?: boolean; hideBrand?: boolean }

export default function ReviewsDataGrid(props: Props) {
  const { reviews, isLoading, toggleFlag, pagination } = useReviews(props)

  const dataGridCols = useMemo(
    () => reviewColumns(toggleFlag, { hideBrand: props.hideBrand, hideRetailer: props.hideRetailer }),
    [toggleFlag, props.hideBrand, props.hideRetailer]
  )

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      disableSelectionOnClick
      rows={reviews !== undefined && pagination.count ? reviews : []}
      columns={dataGridCols}
      pagination
      paginationMode="server"
      page={pagination.currentPage - 1}
      pageSize={pagination.perPage}
      rowCount={pagination.count}
      onPageChange={(params) => {
        pagination.goToPage(params.page + 1)
      }}
      loading={isLoading}
    />
  )
}

function reviewColumns(
  toggleFlag: (x: Review) => Promise<void>,
  opts: Pick<Props, 'hideBrand' | 'hideRetailer'>
): GridColDef[] {
  return [
    {
      headerName: 'Order',
      field: 'order',
      renderCell: (params) => <Link to={`/orders/${params.row.order.id}`}>{params.row.order.uid}</Link>,
      width: 120,
    },

    {
      headerName: 'Brand',
      field: 'brandProfile',
      renderCell: ({ value }: any) => (
        <UserCell user={value?.user} linkTo={{ href: `/brands/${value?.id}`, text: value?.companyName }} />
      ),
      width: 240,
      hide: opts.hideBrand === true,
    },

    {
      headerName: 'Description',
      field: 'description',
      flex: 1,
    },

    {
      headerName: 'Score',
      field: 'score',
      renderCell: (params) => (
        <>
          {Array.from(Array(5), (_, i) =>
            params.row.score > i ? <StarRounded key={i} /> : <StarOutlineRounded key={i} />
          )}
        </>
      ),
      width: 160,
    },

    {
      headerName: 'Retailer',
      field: 'retailerProfile',
      renderCell: ({ value }: any) => (
        <UserCell user={value?.user} linkTo={{ href: `/retailers/${value?.id}`, text: value?.user?.fullName }} />
      ),
      width: 240,
      hide: opts.hideRetailer === true,
    },

    {
      headerName: 'Flagged',
      field: 'flagged',
      renderCell: (params) => (
        <Switch
          size="small"
          color="secondary"
          checked={params.value === true}
          onChange={() => toggleFlag(params.row as Review)}
        />
      ),
      width: 90,
    },
  ]
}
