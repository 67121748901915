import React from 'react'
// import useProduct from 'hooks/useProduct'
import { TextField } from '@material-ui/core'
import { Autocomplete, AutocompleteProps } from '@material-ui/lab'

import { SecondaryCategory } from 'types/category'

type Props = Omit<AutocompleteProps<SecondaryCategory, true, boolean | undefined, false>, 'options' | 'renderInput'> & {
  value: SecondaryCategory[]
  subCategories: SecondaryCategory[] // TODO: move useCategories to context and use here
  onChange: (evt: React.ChangeEvent<{}>, value: SecondaryCategory[]) => void
  TextFieldProps?: any
}

// TODO: This happens when pressing the delete key in the textfield: https://github.com/mui-org/material-ui/issues/24785
const ProductCategoryAutocomplete: React.FC<Props> = ({ subCategories, value, onChange, TextFieldProps, ...rest }) => {
  return (
    <Autocomplete
      multiple
      fullWidth
      options={subCategories}
      ChipProps={{
        size: 'small',
      }}
      getOptionLabel={(option) => option.displayName}
      value={value}
      onChange={onChange}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.id === val.id}
      renderInput={(params) => <TextField label="Categories" {...params} {...TextFieldProps} />}
      {...rest}
    />
  )
}

export default ProductCategoryAutocomplete
