import { Index, Hits, connectStateResults } from 'react-instantsearch-dom'

import { Box, Divider } from '@material-ui/core'
import LabelIcon from '@material-ui/icons/Label'

import Link from 'components/Link'

interface BrandHitProps {
  hit: {
    objectID: number
    company_name: string
    email: string
  }
}

const BrandHit = ({ hit }: BrandHitProps) => (
  <Link to={`/brands/${hit.objectID}`}>
    <Box display="flex" alignContent="center">
      <LabelIcon style={{ marginRight: 10 }} /> {hit.company_name} ({hit.email})
    </Box>
  </Link>
)

const BrandIndexResult = connectStateResults<any>(
  ({ searchResults, children }) =>
    searchResults &&
    searchResults.nbHits !== 0 && (
      <div>
        {children}
        <Divider />
      </div>
    )
)

const BrandIndex = () => {
  return (
    <Index indexName={`admin_${process.env.REACT_APP_ENV}_brand_profiles`}>
      <BrandIndexResult />
      <Hits hitComponent={BrandHit} />
    </Index>
  )
}

export default BrandIndex
