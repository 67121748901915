import { useState } from 'react'
import { Redirect } from 'react-router-dom'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Typography,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import { Category, isParentCategory } from 'types/category'

import Button from 'components/Button'
import AsyncButton from 'components/AsyncButton'

type Props = {
  category: Category
  open: boolean
  onClose: () => void
  isLoading: boolean
  deleteCategory: () => Promise<void>
}

export default function DeleteDialog({ category, open, onClose, deleteCategory, isLoading }: Props) {
  const [success, setSuccess] = useState(false)

  const handleDelete = () =>
    deleteCategory().then(() => {
      setSuccess(true)
      onClose()
    })

  if (success) {
    return <Redirect to="/categories" />
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Delete Category</DialogTitle>
      <Divider />

      <DialogContent style={{ marginTop: '10px' }}>
        <DialogContentText>
          <Typography component="span">
            Are you sure you would like to delete the{' '}
            <Typography component="span" color="primary" display="inline">
              {category.displayName}
            </Typography>{' '}
            category?
          </Typography>
        </DialogContentText>

        {isParentCategory(category) && (
          <Alert severity="warning">
            <strong>This will delete all subcategories under {category.name}!</strong>
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>

        <AsyncButton primary autoFocus busy={isLoading} onClick={handleDelete}>
          Delete
        </AsyncButton>
      </DialogActions>
    </Dialog>
  )
}
