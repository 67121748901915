import { api } from 'services/api.service'
import { useCallback } from 'react'

export default function useVerification() {
  const sendEmailVerificationCode = useCallback(() => api.post('/auth/send_verification_code', {}), [])

  const handleVerification = useCallback(
    ({ verificationMethod, verificationCode, remember }) =>
      api.post('/auth/verify_code', { method: verificationMethod, code: verificationCode, remember }),
    []
  )

  return {
    sendEmailVerificationCode,
    handleVerification,
  }
}
