import clsx from 'clsx'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import Definition, { DefinitionProps } from 'components/Definition'
import Editable, { EditableProps } from 'components/Editable'
import parseDateTime from 'util/parseDateTime'

type Props = EditableProps & DefinitionProps

export default function EditableDefinition({ label, value, format, className, children, fontLarge, ...rest }: Props) {
  const css = useStyles()

  if (rest.datePickerProps || format === 'date' || format === 'datetime') {
    value = parseDateTime(value)?.toISO()
  }

  return (
    <Editable label={label} value={value} {...rest} className={clsx(css.editableDefinition, className)}>
      <Definition format={format} label={label} fontLarge={fontLarge}>
        {children ?? value}
      </Definition>
    </Editable>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editableDefinition: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2.5),
      },
    },
  })
)
