import useToken from 'hooks/useToken'
import { useHistory, useLocation } from 'react-router-dom'

const AuthGate: React.FC = ({ children }) => {
  const { token } = useToken()
  const history = useHistory()
  const location = useLocation()

  if (!token) {
    localStorage.setItem('initialPath', location.pathname)
    history.replace('/login')
    return null
  } else {
    return <>{children}</>
  }
}

export default AuthGate
