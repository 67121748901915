import { Grid } from '@material-ui/core'
import Button from 'components/Button'
import Input from 'components/Input/input'
import { useConfirm } from 'context/Confirm/ConfirmContext'
import { Form, Formik } from 'formik'

export interface ProductValues {
  quantity: number | ''
  productId: number | ''
}

interface Props {
  onSubmit: (arg: ProductValues) => void | Promise<void>
}

const AddLineItemForm = ({ onSubmit }: Props) => {
  const { confirm } = useConfirm()

  return (
    <Formik<ProductValues> initialValues={{ quantity: '', productId: '' }} onSubmit={onSubmit}>
      {({ submitForm }) => {
        const handleAdd = async () => {
          try {
            await confirm({
              title: 'Add Product',
              text:
                'PLEASE NOTE: If the brand has already been paid you will need to manually administer an additional payment. If the retailer has already paid you will need to manually administer an additional charge.',
              submitText: 'Add',
            })

            submitForm()
          } catch {}
        }
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Input name="productId" label="Product Id" />
              </Grid>
              <Grid item xs={4}>
                <Input name="quantity" label="Quantity" />
              </Grid>
              <Grid item xs={4}>
                <Button onClick={handleAdd}>Add to order</Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddLineItemForm
