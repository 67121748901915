import React, { useMemo } from 'react'
import { set } from 'lodash'

import { api } from 'services/api.service'
import { Category } from 'types/category'
import { useAlert } from 'context/Alert/AlertContext'
import useAction from 'hooks/useAction'

type Params = {
  id: number
  categories: Category[]
  setCategories: React.Dispatch<React.SetStateAction<Category[]>>
}

export default function useCategory({ categories, setCategories, id }: Params) {
  const snackbar = useAlert()

  const category = useMemo(() => categories.find((x) => x.id === id) ?? null, [id, categories])

  const saveField = useAction({
    request: <T extends keyof Category>(key: T, value: Category[T]) =>
      api.patch(`categories/${id}`, { category: set({}, key, value) }),
    onSuccess: (resp) => {
      setCategories((xs) => xs.map((x) => (x.id !== id ? x : Object.assign(x, resp.data))))
    },
    onError: (err) => {
      console.error(err)
      snackbar.error('Error updating category')
    },
  })

  const deleteCategory = useAction({
    request: () => api.delete(`categories/${id}`),
    onSuccess: () => {
      snackbar.success('Category deleted')
    },
    onError: (err) => {
      console.error(err)
      snackbar.error('Failed to delete category')
    },
  })

  return { category, saveField, deleteCategory }
}
