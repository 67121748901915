import { api } from 'services/api.service'
import { useAlert } from 'context/Alert/AlertContext'
import useAction from 'hooks/useAction'

export default function useCreateShippingLabel() {
  const snackbar = useAlert()

  const [createShippingLabel, isCreatingShippingLabel, error] = useAction({
    request: (shippingAddressInfo, orderReturnId) =>
      api.post(`order_returns/${orderReturnId}/create_shipping_label`, {
        shippingLabel: shippingAddressInfo,
      }),
    onSuccess: () => snackbar.success('Successfully created new label'),
  })

  return { createShippingLabel, isCreatingShippingLabel, error }
}
