const COLORS: any = {
  powderBlue: '#cee0f0',
  lightSand: '#fcf7ee',
  cream1: '#f5eddd',
  cream2: '#e1dbcd',
  white: '#fff',
  offWhite: '#fefdfa',
  blueLight: '#0049a6',

  // Interaction palette
  // The interaction palette are accent tones, used for certain
  // interactive interfaces and elements
  yellow: '#faf437',
  darkBlue: '#08339d',
  midBlue2: '#578194',
  midBlue3: '#9abdca',
  pink: '#eb5757',

  // text primary
  darkGray: 'rgba(0, 0, 0, 0.7)',
  // text secondary
  // default radio/checkbox border
  // active (hover/focus) inputs
  // default border hover
  mediumDarkGray: 'rgba(0, 0, 0, 0.5)',
  // text tertiary
  // order return accordion  update to dark gray above?
  // status tag border
  lightGray: 'rgba(0, 0, 0, 0.1)',
  // Borders
  // small bg colors
  lightestGray: 'rgba(0, 0, 0, 0.04)',
  // default gray background for larger canvases
  // active bg state
  // Lighter border color
  darkGrayOpaque: '#5a5a5a',
  // used as bg color for hover state for primary buttons
  lightestGrayOpaque: '#f5f5f5',
  // Used as background color when it needs to be opaque

  disabledGrayText: 'rgba(0, 0, 0, 0.26)',
  disabledGray: 'rgba(0, 0, 0, 0.12)',

  green: '#a9fbd7',
  greenBright: '#03dfae',
  purple: '#cbb3bf',
  red: '#cc0c12',
  redMuted: '#eb5757',
  redLight: '#f3c4c4',
  orange: '#f3d4c4',

  colorFacebook: '#3b5998',
  colorLinkedin: '#0077b5',
  colorTwitter: '#1da1f2',
  colorShopify: '#5E8E3E',
}

export default COLORS
