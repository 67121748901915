import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { CircularProgress, CircularProgressProps, Fade } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

interface Props {
  loaderProps?: CircularProgressProps
  loading: boolean
  /** Display loader over dimmed content. */
  overlay?: boolean
  /** Error message to display if content failed to load. */
  error?: string
}

/**
 * A container for presenting a loader while asynchronous content loads.
 */
const Async: React.FC<Props> = ({ children, loaderProps, loading, overlay, error }) => {
  const css = useStyles()

  return (
    <div
      className={clsx(css.container, {
        [css.overlay]: overlay,
        [css.loading]: loading,
      })}
    >
      <Fade in={!error && (!loading || overlay)} unmountOnExit>
        <div className={css.contentContainer}>{children}</div>
      </Fade>
      {error && <Alert severity="error">{error}</Alert>}
      {loading && (
        <div className={css.loaderContainer}>
          <CircularProgress size={32} {...loaderProps} />
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
    },
    contentContainer: {},
    loading: {},
    loaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    overlay: {
      '& $loaderContainer': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      '& $contentContainer': {
        minHeight: 32,
      },
      '&$loading $contentContainer': {
        opacity: '0.5 !important', // override <Fade> styles
        pointerEvents: 'none',
      },
    },
  })
)

export default Async
