import React from 'react'
import { Paper, Popper, Fade, FadeProps } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { GridCellParams, isOverflown } from '@material-ui/data-grid'

interface GridCellPopoutProps {
  value: any
  width: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      lineHeight: '24px',
      width: '100%',
      height: '100%',
      position: 'relative',
      display: 'flex',
      '& .cellValue': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
      },
    },
  })
)

export const GridCellPopout = React.memo(function GridCellPopout(props: GridCellPopoutProps) {
  const { width, value } = props
  const wrapper = React.useRef<HTMLDivElement | null>(null)
  const cellDiv = React.useRef(null)
  const cellValue = React.useRef(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const css = useStyles()
  const [showFullCell, setShowFullCell] = React.useState(false)
  const [showPopper, setShowPopper] = React.useState(false)

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!)
    setShowPopper(isCurrentlyOverflown)
    setAnchorEl(cellDiv.current)
    setShowFullCell(true)
  }

  const handleMouseLeave = () => {
    setShowFullCell(false)
  }

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [setShowFullCell, showFullCell])

  return (
    <div ref={wrapper} className={css.root} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <div ref={cellValue} className="cellValue">
        {value}
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl != null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
          transition
        >
          {({ TransitionProps }: { TransitionProps: FadeProps }) => (
            <Fade {...TransitionProps} timeout={350} style={{ transitionDelay: `1300ms` }}>
              <Paper
                elevation={1}
                style={{
                  minHeight: wrapper.current!.offsetHeight - 3,
                  padding: 8,
                  wordBreak: 'break-word',
                }}
              >
                {value}
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </div>
  )
})

export function renderGridCellPopout(params: GridCellParams) {
  return <GridCellPopout value={params.value ? params.value : ''} width={params.colDef.width} />
}
