import { isEqual } from 'lodash'
import React, { useCallback } from 'react'
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField'
import { InputProps } from '@material-ui/core/Input'
import normalizeOptions, { Option } from 'util/normalizeOptions'
import { MenuItem } from '@material-ui/core'

export type SelectFieldProps = StandardTextFieldProps & {
  options: string[] | Option[]
  nullable?: boolean
  value?: any
  onBlur?: InputProps['onBlur']
  onChange?: InputProps['onChange']
  onFocus?: InputProps['onFocus']
}
/**
 * Select component that wraps TextField and accepts options
 * as an array of strings or Option objects.
 */
const SelectField: React.FC<SelectFieldProps> = ({
  options,
  nullable = false,
  value,
  onBlur,
  onChange,
  onFocus,
  ...rest
}) => {
  let normalizedOptions = normalizeOptions(options)
  if (nullable) {
    normalizedOptions = [{ label: '—', value: null }, ...normalizedOptions]
  }

  const selectedIndex = normalizedOptions.findIndex((opt) => isEqual(opt.value, value))

  if (selectedIndex === -1) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(`Invalid value '${value}' supplied to <Select> with options ${JSON.stringify(options)}.`)
    }
  }

  const getEventHandler = useCallback((handler: any) => (evt: any) => handleEvent(evt, normalizedOptions, handler), [
    normalizedOptions,
  ])

  return (
    <TextField
      select
      value={`${selectedIndex}`}
      onBlur={getEventHandler(onBlur)}
      onChange={getEventHandler(onChange)}
      onFocus={getEventHandler(onFocus)}
      {...rest}
    >
      {normalizedOptions.map((option, index) => (
        <MenuItem key={`${option.value}~~${option.label}`} value={`${index}`} disabled={option.disabled}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

function getOptionValue(options: Option[], index: number) {
  return options[index]?.value
}

function handleEvent(evt: any, options: Option[], callback: (evt: any, value: any) => any) {
  if (!callback) return null
  const value = getOptionValue(options, evt.target.value)
  return callback({ ...evt, target: { ...evt.target, value } }, value)
}

export default SelectField
