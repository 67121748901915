import { useEffect, useState } from 'react'
import { api } from 'services/api.service'

import useAction from './useAction'
import { useAlert } from 'context/Alert/AlertContext'

interface LSHParams {
  id: string
}

export default function useLiveShoppingHighlight({ id }: LSHParams) {
  const [liveShoppingHighlight, setLiveShoppingHighlight]: [any, Function] = useState({})

  const { error } = useAlert()

  const [fetchData, isLoading] = useAction({
    request: () => api.get(`live_shopping_highlights/${id}`),
    onSuccess: ({ data }) => {
      setLiveShoppingHighlight(data)
    },
    onError: () => error('Error fetching live shopping highlight'),
  })

  useEffect(() => {
    fetchData().catch((err) => {
      console.error(err)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    liveShoppingHighlight,
    isLoading,
    fetchData,
  }
}
