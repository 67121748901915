import { api, cancelRequest } from 'services/api.service'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAlert } from 'context/Alert/AlertContext'
import indexBy from 'util/indexBy'
import { GridPageChangeParams } from '@material-ui/data-grid'
import { BrandState } from 'util/getBrandStateMeta'

import useAction from 'hooks/useAction'

interface BrandsParams {
  query?: string
  pageSize?: number
  brandState?: BrandState
  dropshipStatus?: string
  brandCategoryId?: number
}

const cancelKey = 'brands'

export default function useBrands({ query, brandState, brandCategoryId, dropshipStatus, pageSize = 25 }: BrandsParams) {
  const [brandProfiles, setBrandProfiles] = useState([])
  const [brandProfilesCount, setBrandProfilesCount] = useState(0)
  const snackbar = useAlert()
  const brandProfilesById = useMemo(() => indexBy('id', brandProfiles), [brandProfiles])

  const [page, setPage] = useState(0)
  const handlePageChange = useCallback((params: GridPageChangeParams) => {
    setPage(params.page)
  }, [])

  const qp = new URLSearchParams({
    page: '' + (page + 1),
    per_page: '' + pageSize,
  })
  if (brandState != null) qp.set('aasmState', brandState)
  if (brandCategoryId != null) qp.set('primary_category_id', '' + brandCategoryId)
  if (dropshipStatus != null) qp.set('dropship_status', '' + dropshipStatus)
  if (query) qp.set('search', query)

  const [fetchData, isLoading] = useAction({
    request: () => api.get(`/brands?${qp.toString()}`, { cancelKey }),
    onSuccess: (resp: any) => {
      setBrandProfilesCount(resp.data.meta.total)
      setBrandProfiles(resp.data.data)
    },
    onError: () => snackbar.error('Error fetching brands'),
  })

  useEffect(() => {
    fetchData()
    return () => cancelRequest(cancelKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query, brandState, brandCategoryId, dropshipStatus])

  return {
    fetchData,
    isLoading,
    brandProfiles,
    brandProfilesCount,
    brandProfilesById,
    handlePageChange,
    pageSize,
  }
}
