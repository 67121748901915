export interface Address {
  address1?: string
  address2?: string
  businessName?: string
  city?: string
  country?: string
  id?: number
  name?: string
  phone?: string
  state?: string
  zipcode?: string
}

export interface ShipEngineAddress {
  addressLine1?: string
  addressLine2?: string
  companyName?: string
  cityLocality?: string
  country?: string
  id?: number
  name?: string
  phone?: string
  stateProvince?: string
  postalCode?: string
}

export function addressToShipEngineAddress(addr: Address): ShipEngineAddress {
  return {
    name: addr.name,
    phone: addr.phone,
    companyName: addr.businessName,
    addressLine1: addr.address1,
    addressLine2: addr.address2,
    cityLocality: addr.city,
    stateProvince: addr.state,
    postalCode: addr.zipcode,
  } as ShipEngineAddress
}

export function addressFromShipEngineAddress(addr: ShipEngineAddress): Address {
  return {
    name: addr.name,
    phone: addr.phone,
    businessName: addr.companyName,
    address1: addr.addressLine1,
    address2: addr.addressLine2,
    city: addr.cityLocality,
    state: addr.stateProvince,
    zipcode: addr.postalCode,
  } as Address
}
