import { useEffect, useState, useCallback } from 'react'

import { api } from 'services/api.service'

interface ProductImportParams {
  brandId?: number
  importId?: number
}

export type ProductImportData = {
  id: number
  state?: string
  startedAt?: string
  finishedAt?: string
  productsValidated?: number
  validationErrors?: Array<
    {
      startRow: number
      endRow: number
      type: string
      row?: number
    } & Record<string, unknown>
  >
  productsSaved?: number
  uploadedByEmail?: string
  inProgress?: boolean
}

export default function useProductImport({ brandId, importId }: ProductImportParams) {
  const POLL_INTERVAL_MS = 120000 // two minutes
  const [importData, setImportData] = useState<ProductImportData>()

  const apiCall = useCallback(() => {
    api.get<{ data: ProductImportData }>(`brands/${brandId}/product_imports/${importId}`).then(({ data }) => {
      setImportData(data)
    })
  }, [brandId, importId])

  useEffect(() => {
    if (!brandId || !importId) return
    apiCall()
  }, [brandId, importId, apiCall])

  useEffect(() => {
    if (importData?.inProgress !== true) return

    const intervalId = setInterval(apiCall, POLL_INTERVAL_MS)

    return () => {
      clearInterval(intervalId)
    }
  }, [importData, apiCall])

  return importData
}
