import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginLeft: -8,
      marginTop: -8,
    },
  })
)

export default useStyles
