export interface Option {
  label: string
  value: any
  disabled?: boolean
}

//
// Convenience function for converting options shorthands:
//   ['one', 'two']
// to
//   [{label: 'one', value: 'one'}, {label: 'two', value: 'two'}]
//
// These structures are currently supported by Select and RadioGroup inputs
//
export default function normalizeOptions(options: Array<string | Option>): Option[] {
  if (Array.isArray(options)) {
    if (typeof options[0] === 'string') {
      return options.map((option) => ({
        label: option,
        value: option,
      })) as Option[]
    } else {
      return options as Option[]
    }
  } else if (options != null && typeof options === 'object') {
    throw Error('Invalid options array.')
  }
  return []
}
