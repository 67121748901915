import COLORS from 'colors'

export type BrandState =
  | 'registration_completed'
  | 'non_responsive'
  | 'declined'
  | 'decline'
  | 'approve'
  | 'approved'
  | 'pending_approval'
  | 'manual_store_setup_in_progress'
  | 'more_details_needed'
  | 'pending_product_upload'
  | 'pending_brand_launch'
  | 'brand_launched'

export const brandStates: BrandState[] = [
  'registration_completed',
  'non_responsive',
  'declined',
  'approved',
  'pending_approval',
  'manual_store_setup_in_progress',
  'more_details_needed',
  'pending_product_upload',
  'pending_brand_launch',
  'brand_launched',
]

const stateMeta = {
  registration_completed: {
    color: COLORS.lightGray,
    label: 'Registration Completed',
  },
  non_responsive: {
    color: COLORS.lightGray,
    label: 'Non Responsive',
  },
  declined: {
    color: COLORS.redLight,
    label: 'Declined',
  },
  approved: {
    color: COLORS.midBlue3,
    label: 'Approved',
  },
  pending_approval: {
    color: COLORS.purple,
    label: 'Pending Approval',
  },
  manual_store_setup_in_progress: {
    color: COLORS.yellow,
    label: 'Manual Store Setup in Progress',
  },
  more_details_needed: {
    color: COLORS.yellow,
    label: 'More Details Needed',
  },
  pending_product_upload: {
    color: COLORS.yellow,
    label: 'Pending Product Upload',
  },
  pending_brand_launch: {
    color: COLORS.orange,
    label: 'Pending Brand Launch',
  },
  brand_launched: {
    color: COLORS.green,
    label: 'Launched',
  },
} as any

export default function getBrandStateMeta(state: string) {
  return (
    stateMeta[state] || {
      color: COLORS.lightGray,
      label: state,
    }
  )
}
