import React, { useMemo } from 'react'
import { RouteComponentProps, useParams } from 'react-router-dom'
import { get, omit, uniqBy } from 'lodash'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Chip, CircularProgress, Grid } from '@material-ui/core'

import useRetailer from 'hooks/useRetailer'
import useBecomeUser from 'hooks/useBecomeUser'
import usePageTitle from 'hooks/usePageTitle'
import Button from 'components/Button'
import ExternalLink from 'components/ExternalLink'
import Definition from 'components/Definition'
import PageHeader from 'components/PageHeader'
import Panel from 'components/Panel'
import Link from 'components/Link'
import PageActionsMenu from 'components/PageActionsMenu'
import COLORS from '../../../colors'
import EditableDefinition from 'components/EditableDefinition'
import { countryOptions } from 'util/geography'
import MiniForm from 'components/MiniForm'
import AddressFormFields from 'components/AddressFormFields'
import Address from 'components/Address'
import BrandAutocomplete from 'components/BrandAutocomplete'
import { EditableContext } from 'context/Editable/EditableContext'
import Credits from './Credits'
import PaymentPlans from './PaymentPlans'
import AuditLog from 'components/AuditLog'
import ReviewsDataGrid from 'components/ReviewsDataGrid'

import { useConfirm } from 'context/Confirm/ConfirmContext'
import { CurrencyProvider } from 'context/Country/CountryContext'
import { Countries, getCountry } from 'types/country'
import CategoryPreferencesDataGrid from 'pages/Retailers/Retailer/CategoryPreferencesDataGrid'
import HelloworksWorkflowInstances from 'pages/Retailers/Retailer/HelloworksWorkflowInstances'

const storeTypeOptions = ['Online Only', 'Physical Retail', 'Pop-up Only']
const dropshipStatusOptions = [
  { label: '--', value: null },
  { label: 'Pending', value: 'pending' },
  { label: 'Approved', value: 'approved' },
  { label: 'Live', value: 'live' },
  { label: 'Waitlisted', value: 'waitlisted' },
  { label: 'Declined', value: 'declined' },
]

const roleOptions = [
  { label: 'Basic', value: 0 },
  { label: 'Admin', value: 1 },
  { label: 'Warehouse Temp', value: 2 },
]

const booleanOptions = [
  { label: 'NO', value: false },
  { label: 'YES', value: true },
]

interface Params {
  id: string
}

const Retailer: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const css = useStyles()
  const { confirm } = useConfirm()

  const { id } = useParams<Params>()
  const {
    createCredit,
    credits,
    creditsPagination,
    deleteCredit,
    deleteRetailer,
    disconnectShopify,
    requireShopifyReconnect,
    isCreditsLoading,
    isLoading,
    refreshWorkflowInstance,
    removeBalance,
    retailer,
    saveField,
    saveFields,
    unverify,
    updateCredit,
    verify,
  } = useRetailer({ id })

  const user = retailer.user
  const shippingAddrs = retailer.shippingAddresses || []
  const countryName = user?.country?.name
  const uniqPurchasedFromBrands = uniqBy(retailer?.purchasedFromBrands, 'id')
  const isDeleted: boolean = retailer.deletedAt
  const isVerified: boolean = retailer.verifiedAt
  const hasShopify: boolean = retailer.shopifyReferrerToken
  const canRequireReconnectShopify: boolean =
    retailer.isShopifyDropshipConnected && !retailer.isShopifyDropshipReconnectedRequired
  const isPastDue: boolean = retailer.isPastDue
  const isDefaulted: boolean = retailer.isDefaulted
  const isOnPaymentPlan: boolean = retailer.isOnPaymentPlan

  usePageTitle(`Retailer: ${retailer?.storeName}`)

  const [country, currency] = useMemo(() => {
    const country = getCountry(retailer?.user?.country?.name) ?? Countries['United States']
    const currency = country.currency
    return [country, currency]
  }, [retailer])

  const { becomeUser, isLoading: isBecomingUser } = useBecomeUser({
    id: user?.id,
  })

  if (isLoading) {
    return <CircularProgress size={24} />
  }

  const handleDelete = () => {
    confirm({
      title: 'Delete Retailer',
      text: `Are you sure you would like to delete ${retailer.storeName}?`,
      submitText: 'Delete',
      danger: true,
    })
      .then(() => {
        return deleteRetailer(id)
      })
      .then(() => {
        history.push('/retailers')
      })
      .catch((err: any) => {
        console.error(err)
      })
  }

  const handleVerification = () => {
    const toggleVerification = () => {
      if (isVerified) {
        unverify(id)
      } else {
        verify(id)
      }
    }

    const verifyActionText = isVerified ? 'Unverify' : 'Verify'

    confirm({
      title: verifyActionText,
      text: `Are you sure you would like to ${verifyActionText} ${retailer.storeName}?`,
      submitText: verifyActionText,
    }).then(() => {
      toggleVerification()
    })
  }

  const handleShopifyDisconnect = () => {
    confirm({
      title: 'Disconnect Shopify',
      text: `Are you sure you would like to disconnect ${retailer.storeName} Shopify Store?`,
    }).then(() => {
      disconnectShopify(id)
    })
  }

  const handleRequireShopifyReconnect = () => {
    confirm({
      title: 'Shopify dropship store reconnect',
      text: `Are you want to enforce the retailer to reconnect their dropship Shopify store?`,
    }).then(() => {
      requireShopifyReconnect(id)
    })
  }

  const handleSaveAddress = (address: any, idx: number) => {
    shippingAddrs[idx] = address
    return saveFields({ shipping_addresses: shippingAddrs })
  }

  const handleRemoveBalance = () => {
    confirm({
      title: 'Remove Balance',
      text: `This will remove the Balance credentials from the retailer profile,
        remove their available Balance net terms credits, and delete their Balance
        net terms application. Are you sure?`,
      submitText: 'Remove',
      danger: true,
    })
      .then(() => {
        removeBalance()
      })
      .catch((err: any) => {
        console.error(err)
      })
  }

  const fieldsFor = (keyPath: string) => ({
    value: get(retailer, keyPath),
    onSave: (value: any) => saveField(keyPath, value),
  })

  return (
    <CurrencyProvider currency={currency}>
      <EditableContext.Provider value={{ disabled: isDeleted }}>
        <PageHeader
          backLink="/retailers"
          title={retailer.storeName}
          beside={countryName}
          contentRight={
            <>
              <PageActionsMenu
                disabled={isDeleted}
                items={[
                  { label: 'Disconnect shopify store', callback: handleShopifyDisconnect, disabled: !hasShopify },
                  {
                    label: 'Require Reconnect dropship shopify',
                    callback: handleRequireShopifyReconnect,
                    disabled: !canRequireReconnectShopify,
                  },
                  { label: isVerified ? 'Unverify retailer' : 'Verify retailer', callback: handleVerification },
                  { label: 'Become User', callback: becomeUser, disabled: !becomeUser || isBecomingUser },
                ]}
              />
              <Button style={{ marginLeft: '5px' }} primary danger onClick={handleDelete} disabled={isDeleted}>
                Delete
              </Button>
            </>
          }
        >
          {isPastDue && !isDefaulted && (
            <Chip
              label="Past Due"
              style={{ backgroundColor: COLORS.redMuted, marginLeft: '5px', color: COLORS.white }}
            />
          )}
          {isDefaulted && (
            <Chip
              label="Defaulted"
              style={{ backgroundColor: COLORS.redMuted, marginLeft: '5px', color: COLORS.white }}
            />
          )}

          {isOnPaymentPlan && (
            <Chip
              label="On Payment Plan"
              style={{ backgroundColor: COLORS.redMuted, marginLeft: '5px', color: COLORS.white }}
            />
          )}

          {isDeleted && <Chip label="Deleted" style={{ backgroundColor: COLORS.red }} />}
          {isVerified ? (
            <Chip label="Verified" style={{ backgroundColor: COLORS.green, marginLeft: '5px' }} />
          ) : (
            <Chip label="Pending Verification" style={{ backgroundColor: COLORS.yellow, marginLeft: '5px' }} />
          )}
          <Chip style={{ marginLeft: '5px' }} label={retailer.storeType} color="primary" />
          {hasShopify && (
            <Chip
              label="Shopify Linked"
              style={{
                color: 'white',
                backgroundColor: COLORS.colorShopify,
                marginLeft: '5px',
              }}
            />
          )}
        </PageHeader>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Panel title="General">
              <EditableDefinition label="Store Name" {...fieldsFor('storeName')} />
              <EditableDefinition label="Website" {...fieldsFor('website')}>
                <ExternalLink href={retailer.website}>{retailer.website}</ExternalLink>
              </EditableDefinition>

              <EditableDefinition label="Store Type" options={storeTypeOptions} {...fieldsFor('storeType')}>
                {retailer.storeType}
              </EditableDefinition>
              <EditableDefinition label="Location" {...fieldsFor('storeLocation')} />

              <EditableDefinition label="Country" options={countryOptions} {...fieldsFor('user.countryId')}>
                {countryName}
              </EditableDefinition>
              <EditableDefinition
                label="Dropship Status"
                options={dropshipStatusOptions}
                {...fieldsFor('dropshipStatus')}
              />
              <EditableDefinition
                label="VIP Retailer"
                {...fieldsFor('vip')}
                format="boolean"
                options={booleanOptions}
              />
              {!retailer?.droplyUser?.retailerAccount &&
                shippingAddrs.map((addr: object, idx: number) => (
                  <MiniForm
                    key={idx}
                    renderFields={(formik: any) => <AddressFormFields formik={formik} country={country} />}
                    fields={omit(addr, 'type')}
                    onSave={(address) => handleSaveAddress(address, idx)}
                    style={{ marginBottom: 24 }}
                  >
                    <Definition label={`Shipping Address ${idx + 1}`}>
                      <Address address={addr} country={country} />
                    </Definition>
                  </MiniForm>
                ))}

              <MiniForm
                fields={{ referredBy: retailer.referredBy?.brandProfile }}
                onSave={(fields) => saveField('referredById', fields.referredBy?.user.id || null)}
                renderFields={(formik) => (
                  <BrandAutocomplete
                    fullWidth
                    {...formik.getFieldProps('referredBy')}
                    TextFieldProps={{ label: 'Referred by' }}
                    onChange={(evt: any, value: any) => {
                      formik.setFieldValue('referredBy', value)
                    }}
                  />
                )}
              >
                <Definition label="Referred By" style={{ marginBottom: 24 }}>
                  {retailer.referredById ? (
                    <Link to={`/brands/${retailer.referredBy?.brandProfile.id}`}>{retailer.referredBy?.email}</Link>
                  ) : (
                    '—'
                  )}
                </Definition>
              </MiniForm>

              <EditableDefinition label="Reseller Id" {...fieldsFor('resellerId')} />
              <EditableDefinition label="Registration Number" {...fieldsFor('companyRegistrationNumber')} />
              {countryName !== 'United States' && <EditableDefinition label="VAT Number" {...fieldsFor('vatNumber')} />}
              <Definition label="Annual Sales">{retailer?.annualSales}</Definition>
              <Definition label="Discovery Medium">{retailer?.discoveryMedium}</Definition>
              <Definition label="Store Category">{retailer?.storeCategory}</Definition>
              <Definition label="Years In Business">{retailer?.yearsInBusiness}</Definition>
              <Definition label="External ID">{retailer?.user?.externalAttributionIdentifier}</Definition>
              <EditableDefinition label="Stripe Customer ID" {...fieldsFor('stripeCustomerId')} />
              {retailer.balanceBuyerId && (
                <div>
                  <hr style={{ marginBottom: 30 }} />
                  <EditableDefinition label="Balance Buyer ID" {...fieldsFor('balanceBuyerId')} />
                  <EditableDefinition
                    label="Balance Pilot"
                    format="boolean"
                    {...fieldsFor('balancePilot')}
                    options={booleanOptions}
                  />
                  <Button
                    small
                    onClick={() => {
                      handleRemoveBalance()
                    }}
                  >
                    Remove Balance
                  </Button>
                </div>
              )}
            </Panel>

            <Panel title="Order Details">
              <Definition label="Total Orders">
                <Link to={`/orders?query=${retailer.user?.email}`}>{retailer.orderCount}</Link>
              </Definition>
              <Definition label="Purchased From">
                {uniqPurchasedFromBrands.map((brand: any, idx: number) => (
                  <Link key={idx} to={`/brands/${brand.id}`} className={css.brandLink}>
                    {brand.companyName}
                  </Link>
                ))}
              </Definition>
            </Panel>

            <Panel title="Retailer Success">
              <EditableDefinition
                label="Personal Shopping Held"
                format="boolean"
                {...fieldsFor('personalSessionHeld')}
                options={booleanOptions}
              />
              <EditableDefinition
                label="Personal Shopping Date"
                format="date"
                asDatePicker
                {...fieldsFor('personalSessionDate')}
              />
            </Panel>

            <Panel title="Category Preferences" collapsible startCollapsed>
              <CategoryPreferencesDataGrid categories={retailer?.categoryPreferences} />
            </Panel>

            {hasShopify && (
              <Panel title="Shopify">
                <EditableDefinition label="Shopify Token" {...fieldsFor('shopifyReferrerToken')} />
                <EditableDefinition label="Shopify Token" {...fieldsFor('shopifyStore.shopifyDomain')}>
                  <ExternalLink href={`http://${retailer.shopifyStore?.shopifyDomain}`}>
                    {retailer.shopifyStore?.shopifyDomain}
                  </ExternalLink>
                </EditableDefinition>
              </Panel>
            )}
          </Grid>

          <Grid item xs={6}>
            <Panel title="Profile Details">
              <EditableDefinition label="Full Name" {...fieldsFor('user.fullName')} />
              <EditableDefinition label="Email" {...fieldsFor('user.email')} />
              <EditableDefinition label="Phone" {...fieldsFor('phoneNumber')} />
              <EditableDefinition
                label="Description"
                textFieldProps={{ multiline: true, rows: 4 }}
                {...fieldsFor('description')}
              />
              <EditableDefinition label="Role" {...fieldsFor('user.role')} options={roleOptions} />
            </Panel>

            {retailer?.droplyUser?.retailerAccount && (
              <Panel title="Droply Retailer Account">
                <Definition label="Account Status">{retailer.droplyUser.retailerAccount.accountStatus}</Definition>
                <Definition label="Annual Sales">{retailer.droplyUser.retailerAccount.annualSales}</Definition>
                <Definition label="Dropship percentage of sales">
                  {retailer.droplyUser.retailerAccount.dropshippingSalesPercentage}
                </Definition>
                <Definition label="Sales channel">{retailer.droplyUser.retailerAccount.salesChannel}</Definition>
                <Definition label="Store name">{retailer.droplyUser.retailerAccount.storeName}</Definition>
                <Definition label="Store type">{retailer.droplyUser.retailerAccount.storeType}</Definition>
                <Definition label="Website">{retailer.droplyUser.retailerAccount.websiteUrl}</Definition>
                <Definition label="Years in business">{retailer.droplyUser.retailerAccount.yearsInBusiness}</Definition>
              </Panel>
            )}

            {isVerified && (
              <Panel title="Verification Details">
                <Definition format="datetime" label="Date">
                  {retailer.verifiedAt}
                </Definition>
                <Definition label="Verified By">{retailer.verifiedBy?.email}</Definition>
                {/* TODO: include internal note & check box for resale certificate received */}
              </Panel>
            )}

            {retailer?.helloworksWorkflowInstances?.length > 0 && (
              <HelloworksWorkflowInstances
                workflowInstances={retailer.helloworksWorkflowInstances}
                startCollapsed={isVerified}
                refreshWorkflowInstance={refreshWorkflowInstance}
              />
            )}

            {retailer.id && <PaymentPlans id={retailer.id} />}

            <Credits
              credits={credits}
              countryName={countryName}
              createCredit={createCredit}
              updateCredit={updateCredit}
              deleteCredit={deleteCredit}
              isCreditsLoading={isCreditsLoading}
              isReferred={retailer.referredById !== null}
              pagination={creditsPagination}
            />

            <Panel title="Reviews" collapsible startCollapsed>
              <ReviewsDataGrid for="retailer" id={retailer.id} hideRetailer />
            </Panel>

            <AuditLog type="Retailer" id={retailer.id} collapsible startCollapsed />

            <Panel title="" style={{ background: '#E5E5E5' }}>
              <Definition format="datetime" label="Created At">
                {retailer.createdAt}
              </Definition>
              <Definition format="datetime" label="Last Update">
                {retailer.updatedAt}
              </Definition>
              <Definition format="datetime" label="Deleted At">
                {retailer.deletedAt}
              </Definition>
            </Panel>
          </Grid>
        </Grid>
      </EditableContext.Provider>
    </CurrencyProvider>
  )
}

export default Retailer

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    brandLink: {
      '&:not(:last-child)::after': {
        content: '", "',
      },
    },
  })
)
