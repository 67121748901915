import { api, cancelRequest } from 'services/api.service'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { GridPageChangeParams } from '@material-ui/data-grid'
import { Product } from 'types/product'

import { useAlert } from 'context/Alert/AlertContext'
import { SecondaryCategory } from 'types/category'
import useAction from 'hooks/useAction'

interface ProductRequestParams {
  pageSize: number
  tagging?: boolean
  visibility?: boolean
  brandId?: any
  sku?: string
  productName?: string
  excluded?: boolean
  dynamicCancelKey?: boolean
}

const cancelKey = 'products'

export default function useProducts({
  pageSize = 10,
  tagging,
  visibility,
  brandId,
  sku,
  productName,
  excluded,
  dynamicCancelKey,
}: ProductRequestParams) {
  const [products, setProducts] = useState<Product[]>([])
  const [productsCount, setProductsCount]: [number, Function] = useState(0)
  const [untaggedProductsCount, setUntaggedProductsCount] = useState(0)
  const [untaggedProductFetchTimeoutID, setUntaggedProductFetchTimeoutID] = useState<number | null>(null)
  const [page, setPage] = useState(0)
  const snackbar = useAlert()

  const handlePageChange = useCallback((params: GridPageChangeParams) => {
    setPage(params.page)
  }, [])

  const qp = new URLSearchParams({
    page: '' + (page + 1),
    per_page: '' + pageSize,
  })
  if (tagging !== undefined) qp.set('tagging', tagging.toString())
  if (visibility !== undefined) qp.set('visibility', visibility.toString())
  if (brandId !== undefined) qp.set('brand_profile', brandId)
  if (sku !== undefined) qp.set('sku', sku)
  if (productName) qp.set('query', productName)
  if (excluded) qp.set('excluded', excluded.toString())
  const queryString = qp.toString()

  const [fetchData, isLoading] = useAction({
    request: () =>
      api.get(`/products?${queryString}`, { cancelKey: dynamicCancelKey ? `${cancelKey}-${queryString}` : cancelKey }),
    onSuccess: (resp: any) => {
      setProductsCount(resp.data.meta.total)
      setProducts(
        resp.data.data.map((product: any) => ({
          id: product.id,
          name: product.name,
          brandProfile: product.brandProfile,
          description: product.description,
          categories: product.categories,
          excluded: product.excludeFromExternalCatalogs,
          dropship: product.dropship,
          internalSku: product.internalSku,
        }))
      )
    },
    onError: () => snackbar.error('Error fetching products'),
  })

  const [deleteProduct] = useAction({
    request: (product: Product) => api.delete(`products/${product.id}`),
    onSuccess: (resp, [targetProduct]) => {
      setProducts((products) => products.filter((product) => product.id !== targetProduct.id))
      snackbar.success('Product successfully deleted.')
    },
    onError: (err: unknown) => {
      console.error(err)
      snackbar.error('Failed to delete Product.')
    },
  })

  const [updateProductField] = useAction({
    request: (product: Product, field: string, value: any) => {
      console.log(product, field, value)
      return api.patch(`products/${product.id}`, { product: { [field]: value } })
    },
    onSuccess: (resp, [targetProduct]) => {
      setProducts((products) =>
        products.map((product: any) =>
          product.id !== targetProduct.id
            ? product
            : {
                id: product.id,
                name: resp.data.name,
                brandProfile: resp.data.brandProfile,
                description: resp.data.description,
                categories: resp.data.categories,
                excluded: resp.data.excludeFromExternalCatalogs,
                dropship: resp.data.dropship,
                internalSku: resp.data.internalSku,
              }
        )
      )
      snackbar.success('Updated!')
    },
    onError: (err: unknown) => {
      console.error(err)
      snackbar.error('Failed to toggle excluded.')
    },
  })

  const toggleExcluded = (product: Product) => {
    return updateProductField(product, 'exclude_from_external_catalogs', !product.excluded)
  }

  const untaggedProductsQP = new URLSearchParams({
    tagging: 'false',
    visibility: 'true',
  })
  const [fetchUntaggedProduct] = useAction({
    request: () => api.get(`/products?${untaggedProductsQP.toString()}`),
    onSuccess: (resp: any) => {
      setUntaggedProductsCount(resp.data.meta.total)
    },
    onError: () => snackbar.error('Error fetching untagged products count'),
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  useEffect(() => {
    fetchUntaggedProduct()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => () => {
      cancelRequest(cancelKey)
      untaggedProductFetchTimeoutID && clearTimeout(untaggedProductFetchTimeoutID)
    },
    [untaggedProductFetchTimeoutID]
  )

  /**
   * Sets and saves categories on the given product
   */
  const saveProductCategories = useCallback(
    (productId, categories: SecondaryCategory[]) => {
      const prevProducts = [...products]
      setProducts(products.map((product) => (product.id === productId ? { ...product, categories } : product)))
      api
        .patch(`products/${productId}`, {
          product: { categoryIds: categories.map((cat) => cat.id) },
        })
        .then(() => {
          // We fetch untagged product count after some delay to give time to algolia to index tagged product
          setUntaggedProductFetchTimeoutID(
            window.setTimeout(() => {
              fetchUntaggedProduct()
            }, 5000)
          )
        })
        .catch((err: AxiosError) => {
          setProducts(prevProducts)
          const { response } = err
          if (response) {
            snackbar.error(`Failed to update categories (${response.data}).`)
          } else {
            snackbar.error('Failed to update categories. Please contact support.')
          }
          console.error(err)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [products]
  )

  return {
    fetchData,
    toggleExcluded,
    updateProductField,
    deleteProduct,
    isLoading,
    products,
    productsCount,
    untaggedProductsCount,
    page,
    handlePageChange,
    saveProductCategories,
  }
}
