import { Paper } from '@material-ui/core'

import usePageTitle from 'hooks/usePageTitle'
import ReviewsDataGrid from 'components/ReviewsDataGrid'

export default function Reviews() {
  usePageTitle('Reviews')

  return (
    <div>
      <h1 style={{ marginTop: 0 }}>Reviews</h1>
      <Paper elevation={0}>
        <ReviewsDataGrid for="all" />
      </Paper>
    </div>
  )
}
