import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const drawerWidth = 200

export const CSS = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    listItem: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      width: '100%',
      padding: theme.spacing(0, 3),
    },
    toolbar: {
      padding: theme.spacing(15, 1, 1, 1),
      ...theme.mixins.toolbar,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    icon: {
      position: 'fixed',
      top: '90px',
      left: '185px',
      background: 'white',
      padding: '2px',
      zIndex: 1203,
      '&:hover': {
        background: 'white',
        boxShadow: '0px 1px 5px rgba(0, 0, 0, .5)',
      },
    },
    drawerClosedIcon: {
      left: '26px',
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerOpenIcon: {
      left: '185px',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.25)',
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  })
)
