import { useEffect, useState } from 'react'
import { set } from 'lodash'

import { api } from 'services/api.service'
import { useAlert } from 'context/Alert/AlertContext'
import useAction from 'hooks/useAction'
import usePagination from 'hooks/usePagination'

type Credit = {
  id: string
  type: string
  amountInCents: number
  expiresAt: string
  usedAt: string
  createdAt: string
  chargeId: string
}

interface RetailerParams {
  id: string
}

export default function useRetailer({ id }: RetailerParams) {
  const [retailer, setRetailer]: [any, Function] = useState({})
  const [credits, setCredits]: [Credit[], Function] = useState([])
  const snackbar = useAlert()

  const [fetchData, isLoading] = useAction({
    request: () => api.get(`/retailers/${id}`),
    onSuccess: (resp) => setRetailer(resp.data),
    onError: () => snackbar.error(`Error fetching retailer ${id}`),
  })

  const {
    pagination: creditsPagination,
    setPagination: setCreditsPagination,
    paginationQueryParams: creditPaginationQueryParams,
  } = usePagination({ perPage: 3 })

  const [fetchCredits, isCreditsLoading] = useAction({
    request: () =>
      api.get(`/retailers/${id}/credits`, { params: { order_by: 'createdAt', ...creditPaginationQueryParams } }),
    onSuccess: (resp) => {
      setCredits(resp.data.data)
      setCreditsPagination(resp.data.meta)
    },
    onError: () => snackbar.error('Error fetching retailer credits'),
  })

  const [verify] = useAction({
    request: (id: string) => api.patch(`retailers/${id}/verify`),
    onSuccess: (resp) => {
      snackbar.success('Retailer Verified')
      setRetailer(resp.data)
    },
    onError: () => snackbar.error('Error Verifying Retailer'),
  })

  const [unverify] = useAction({
    request: (id: string) => api.patch(`retailers/${id}/unverify`),
    onSuccess: (resp) => {
      snackbar.success('Retailer Unverified')
      setRetailer(resp.data)
    },
    onError: () => snackbar.error('Error Unverifying Retailer'),
  })

  const [disconnectShopify] = useAction({
    request: (id: string) => api.patch(`retailers/${id}/disconnect_shopify`),
    onSuccess: (resp) => {
      snackbar.success('Shopify Store Disconnected')
      setRetailer(resp.data)
    },
    onError: () => snackbar.error('Error Disconnecting Shopify'),
  })

  const [requireShopifyReconnect] = useAction({
    request: (id: string) => api.patch(`retailers/${id}/require_reconnect`),
    onSuccess: (resp) => {
      snackbar.success('Shopify Reconnect Required!')
      setRetailer(resp.data)
    },
    onError: () => snackbar.error('Error requesting Shopify Reconnect Required'),
  })

  const [saveField] = useAction({
    request: (keyPath: string, value: any) =>
      api.patch(`retailers/${id}`, { retailer_profile: set({}, keyPath, value) }),
    onSuccess: (resp) => setRetailer(resp.data),
  })

  const [saveFields] = useAction({
    request: (retailerFieldsToUpdate) => api.patch(`retailers/${id}`, { retailer_profile: retailerFieldsToUpdate }),
    onSuccess: (resp) => setRetailer(resp.data),
  })

  const [deleteRetailer] = useAction({
    request: (id: string) => api.delete(`retailers/${id}`),
    onSuccess: () => snackbar.success('Retailer deleted'),
    onError: () => snackbar.error('Error deleting Retailer.'),
  })

  const [createCredit, isCreatingCredit] = useAction({
    request: (credit: Credit) => api.post(`retailers/${id}/credits`, { credit }),
    onSuccess: (resp) => {
      creditsPagination.goToPage(1, { forceReload: true })
      snackbar.success('Credit added')
    },
    onError: () => snackbar.error('Error adding credit'),
  })

  const [updateCredit] = useAction({
    request: (creditId: string, field: string, value: any) =>
      api.patch(`retailers/${id}/credits/${creditId}`, { credit: set({}, field, value) }),
    onSuccess: (resp, reqArgs) => {
      const updatedCredits = credits.map((credit) => (credit.id === reqArgs[0] ? resp.data : credit))
      setCredits(updatedCredits)
      snackbar.success('Credit updated')
    },
  })

  const [deleteCredit] = useAction({
    request: (creditId: string) => api.delete(`retailers/${id}/credits/${creditId}`),
    onSuccess: (resp, reqArgs) => {
      let updatedCredits: any[] = credits.filter((credit) => credit.id !== reqArgs[0])
      const { currentPage } = creditsPagination
      creditsPagination.goToPage(updatedCredits.length > 0 ? currentPage : currentPage - 1, { forceReload: true })
      snackbar.success('Credit deleted')
    },
  })

  const [removeBalance] = useAction({
    request: () => api.delete(`retailers/${id}/remove_balance_terms`),
    onSuccess: (resp, reqArgs) => {
      snackbar.success('Balance terms will be removed shortly.')
    },
    onError: () => snackbar.error('Error removing Balance terms'),
  })

  const [refreshWorkflowInstance] = useAction({
    request: (workflowInstanceId) =>
      api.post(`/retailers/${id}/helloworks_workflow_instances/${workflowInstanceId}/refresh`),
    onSuccess: (resp, reqArgs) => {
      snackbar.success('The instance will be refetched shortly.')
    },
    onError: () => snackbar.error(`Error refreshing HelloWorks workflow instance`),
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    fetchCredits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, creditPaginationQueryParams])

  return {
    createCredit,
    credits,
    creditsPagination,
    deleteCredit,
    deleteRetailer,
    disconnectShopify,
    requireShopifyReconnect,
    fetchCredits,
    fetchData,
    isCreatingCredit,
    isCreditsLoading,
    isLoading,
    refreshWorkflowInstance,
    removeBalance,
    retailer,
    saveField,
    saveFields,
    unverify,
    updateCredit,
    verify,
  }
}
