import { useCallback, useState } from 'react'

import { useAlert } from 'context/Alert/AlertContext'

import { api } from 'services/api.service'

export default function useProductImportsQueuePrioritize(id: number) {
  const snackbar = useAlert()
  const [isLoading, setIsLoading] = useState<boolean>()

  const prioritize = useCallback(() => {
    setIsLoading(true)
    return api
      .patch(`/product_imports_queue/${id}/prioritize`)
      .catch((err) => {
        console.error(err)
        snackbar.error('Failed to prioritize import')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [id, snackbar])

  return { prioritize, isLoading }
}
