import { useMemo, useState } from 'react'
import { get, pick } from 'lodash'
import { Chip, Collapse, Grid, InputAdornment } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useHistory, RouteComponentProps } from 'react-router-dom'

import * as yup from 'yup'

import useBrand from 'hooks/useBrand'
import useBecomeUser from 'hooks/useBecomeUser'
import useCategories from 'hooks/useCategories'
import useBrandDeclineReason from 'hooks/useBrandDeclineReason'
import useBrandValueOptions from 'hooks/useBrandValueOptions'
import Async from 'components/Async'
import CheckGroup from 'components/CheckGroup'
import Definition from 'components/Definition'
import EditableDefinition from 'components/EditableDefinition'
import MiniForm from 'components/MiniForm'
import ExternalLink from 'components/ExternalLink'
import MoneyInput from 'components/MoneyInput'
import PageHeader from 'components/PageHeader'
import Panel from 'components/Panel'
import ReviewsDataGrid from 'components/ReviewsDataGrid'
import centsToCurrency, { centsToUnit } from 'util/centsToCurrency'
import { currencyToCents } from 'util/currencyToCents'
import { BrandState } from 'util/getBrandStateMeta'
import COLORS from 'colors'
import AddressFormFields from 'components/AddressFormFields'
import BrandStateChip from 'components/BrandStateChip'
import { hqRegionNamesByCountry } from 'util/geography'
import Address from 'components/Address'
import Link from 'components/Link'
import { countryOptions } from 'util/geography'
import BrandDeclineDialog from 'components/DeclineDialog'
import FindRetailerDialog from 'components/FindRetailerDialog'
import normalizeFacebookPage from 'util/normalizeFacebookPage'
import usePageTitle from 'hooks/usePageTitle'
import { EditableContext } from 'context/Editable/EditableContext'
import { useConfirm } from 'context/Confirm/ConfirmContext'
import PageActionsMenu from 'components/PageActionsMenu'
import AuditLog from 'components/AuditLog'
import Button from 'components/Button'
import ProductUploadDialog from 'components/UploadModal'
import DataGrid from 'components/DataGrid'
import useProductImport from 'hooks/useProductImports'
import { CurrencyProvider } from 'context/Country/CountryContext'
import { Address as AddressType } from 'types'
import { Countries, getCountry } from 'types/country'
import { Brand } from 'types'
import { ProductImportStatus } from 'components/ProductImportStatus'

const booleanOptions = [
  { label: 'NO', value: false },
  { label: 'YES', value: true },
]
const leadTimeOptions = ['1-3 days', '4-7 days', '8-15 days', '16-30 days', '30-45 days']

const avgShippingOptions = Array(15)
  .fill(1)
  .map((x, i) => ({
    value: i + 1,
    label: i + 1 + '%',
  }))

const emptyAddress = {
  address1: '',
  address2: '',
  businessName: '',
  city: '',
  country: '',
  id: undefined,
  name: '',
  phone: '',
  state: '',
  zipcode: '',
}
const addressKeys = Object.keys(emptyAddress)

type Props = { id: string }

export default function BrandDetail({ match }: RouteComponentProps<Props>) {
  const css = useStyles()
  const brandId = match.params.id
  const { parentCategories } = useCategories()
  const { confirm } = useConfirm()
  const history = useHistory()

  const {
    isLoading,
    brandProfile: brand,
    saveField,
    saveFields,
    saveAasmState,
    addExistingRelationship,
    deleteBrand,
    deleteAllProducts,
    launchDropship,
    unlaunchDropship,
    uploadProducts,
    exportProducts,
    resendApprovalEmail,
    waitlistDropship,
    approveDropship,
    declineDropship,
  } = useBrand({
    id: brandId,
  })

  const {
    productImports,
    pageSize,
    handlePageChange,
    productImportsCount,
    isLoading: isLoadingProductImports,
  } = useProductImport({ brandId })

  const { declineReason } = useBrandDeclineReason()

  const [showAddExistingRelationshipModal, setShowAddExistingRelationshipModal] = useState(false)

  usePageTitle(`Brand: ${brand?.companyName}`)

  const [showDeclineDialog, setShowDeclineDialog] = useState(false)

  const onCancel = () => {
    setShowDeclineDialog(false)
  }

  const [showProductUploadDialog, setShowProductUploadDialog] = useState(false)

  const onConfirmDecline = (declineReason: Brand['declineReason']) => {
    setShowDeclineDialog(false)
    saveAasmState({ state: 'decline', declineReason })
  }

  const user = brand?.user
  const addr = pick(brand?.address, addressKeys) || emptyAddress
  const shipFromAddr = pick(brand?.shipFromAddress, addressKeys) || emptyAddress
  const [country, currency] = useMemo(() => {
    const country = getCountry(brand?.user.country.name) ?? Countries['United States']
    const currency = country.currency
    return [country, currency]
  }, [brand])
  const isDeleted: boolean = Boolean(brand?.deletedAt)

  const { becomeUser, isLoading: isBecomingUser } = useBecomeUser({
    id: user?.id,
  })

  const brandCountry = country.name
  const { brandValueOptions } = useBrandValueOptions({ brandCountry })
  let brandDropshipStatus

  const renderAddress = (label: string, addr: AddressType) => (
    <Definition label={label}>
      <Address address={addr} country={country} />
    </Definition>
  )

  const fieldsFor = (keyPath: keyof Brand | `user.${keyof Brand['user']}`) => ({
    value: get(brand, keyPath),
    onSave: (value: any) => saveField(keyPath, value),
  })

  const handleSaveAddress = (address: any) => saveFields({ address: address })
  const handleSaveShipFromAddress = (address: any) => saveFields({ shipFromAddress: address })

  const handleSetAasmState = (state: BrandState) => {
    if (state === 'decline') {
      setShowDeclineDialog(true)
    } else {
      return confirm({
        title: 'Update brand state',
        text: `Are you sure you want to set this brand state to ${state + 'd'}?`,
        submitText: 'Update State',
      })
        .then(() => saveAasmState({ state }))
        .catch(() => Promise.resolve())
    }
    return Promise.resolve()
  }

  const handleAddExistingRelationship = () => {
    setShowAddExistingRelationshipModal(true)
  }

  const handleDeleteBrand = () => {
    confirm({
      title: 'Delete Brand',
      text: `Are you sure you would like to delete ${brand?.companyName}? This action can't be undone.`,
      submitText: 'Delete',
      danger: true,
    })
      .then(() => {
        return deleteBrand()
      })
      .then(() => {
        history.push('/brands')
      })
  }

  const handleDeleteProducts = () => {
    confirm({
      title: 'Delete Products',
      text: `Are you sure you would like to delete all this brands products?`,
      danger: true,
    })
      .then(() => deleteAllProducts())
      .catch(() => Promise.resolve())
  }

  const handleShowProductDialog = () => {
    setShowProductUploadDialog(true)
  }

  const handleProductUpload = (files: File[], csvType: string): Promise<any> =>
    new Promise((resolve, reject) => {
      files.forEach((file: any) => {
        let reader = new FileReader()
        reader.onloadend = () => {
          uploadProducts(reader.result, csvType, file.name).then(resolve).catch(reject)
        }
        reader.readAsDataURL(file)
      })
    })

  const handleResendApprovalEmail = () => {
    confirm({
      title: 'Resend Approval Email',
      text: `Are you sure you would like to resend ${brand?.companyName}'s approval email ?`,
      submitText: 'Send',
      danger: false,
    })
      .then(() => resendApprovalEmail())
      .catch(() => Promise.resolve())
  }

  const isPendingApproval = brand?.aasmState !== 'pending_approval'

  if (brand === undefined || isLoading) {
    return <Async loading={true} />
  }

  if (brand?.dropshipper) {
    brandDropshipStatus = 'LAUNCHED'
  } else if (brand?.waitlistedDropshipper) {
    brandDropshipStatus = 'WAITLISTED'
  } else if (brand?.approvedDropshipper) {
    brandDropshipStatus = 'APPROVED'
  } else if (brand?.declinedDropshipper) {
    brandDropshipStatus = 'DECLINED'
  } else if (brand?.appliedDropshipper) {
    brandDropshipStatus = 'APPLIED'
  } else {
    brandDropshipStatus = 'NO'
  }

  return (
    <CurrencyProvider currency={currency}>
      <EditableContext.Provider value={{ disabled: isDeleted }}>
        <PageHeader
          title={brand.companyName}
          backLink="/brands"
          beside={country.name}
          contentRight={
            <>
              <PageActionsMenu
                disabled={isDeleted}
                items={[
                  { label: 'Approve', callback: () => handleSetAasmState('approve'), hidden: isPendingApproval },
                  { label: 'Decline', callback: () => handleSetAasmState('decline'), hidden: isPendingApproval },
                  {
                    label: 'Approve dropship',
                    callback: approveDropship,
                    hidden: brand.approvedDropshipper || brand.dropshipper || !brand.appliedDropshipper,
                  },
                  {
                    label: 'Decline dropship',
                    callback: declineDropship,
                    hidden: brand.approvedDropshipper || !brand.appliedDropshipper || brand.declinedDropshipper,
                  },
                  {
                    label: 'Launch dropship',
                    callback: launchDropship,
                    hidden: !brand.appliedDropshipper || brand.dropshipper,
                  },
                  {
                    label: 'Unlaunch dropship',
                    callback: unlaunchDropship,
                    hidden: !brand.appliedDropshipper || !brand.dropshipper,
                  },
                  {
                    label: 'Waitlist dropship',
                    callback: waitlistDropship,
                    hidden: !brand.appliedDropshipper || brand.waitlistedDropshipper || brand.dropshipper,
                  },
                  { label: 'Upload Products', callback: handleShowProductDialog },
                  { label: 'Export Products', callback: exportProducts, disabled: !brand.productsCount },
                  { label: 'Become User', callback: becomeUser, disabled: !becomeUser || isBecomingUser },
                  {
                    label: 'Remove All Products',
                    callback: handleDeleteProducts,
                    disabled: !brand.productsCount || process.env.NODE_ENV === 'production',
                  },
                  {
                    label: 'Resend Approval',
                    callback: handleResendApprovalEmail,
                    disabled: brand.aasmState !== 'approved' || !handleResendApprovalEmail,
                  },
                ]}
              />
              <Button onClick={handleDeleteBrand} disabled={isDeleted} className={css.btnMargin} primary danger>
                Delete
              </Button>
            </>
          }
        >
          <div className={css.headerBeside}>
            {isDeleted && <Chip label="Deleted" style={{ backgroundColor: COLORS.red }} />}
            <BrandStateChip state={brand.aasmState} className={css.headerChip} />
            {brand.paused && (
              <Chip label="Paused" className={css.headerChip} style={{ backgroundColor: COLORS.yellow }} />
            )}
            {user?.hidden && (
              <Chip label="Hidden" className={css.headerChip} style={{ backgroundColor: COLORS.redLight }} />
            )}
          </div>
        </PageHeader>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Panel title="General">
              <EditableDefinition label="Company Name" {...fieldsFor('companyName')} />
              <EditableDefinition label="Full Name" {...fieldsFor('user.fullName')} />
              <EditableDefinition label="Email" {...fieldsFor('user.email')} />

              <EditableDefinition
                label="Slug"
                {...fieldsFor('slug')}
                validator={yup.string().required("Slug can't be blank")}
              >
                <ExternalLink href={`http://helloabound.com/${brand.slug}`}>{brand.slug}</ExternalLink>
              </EditableDefinition>

              <EditableDefinition label="Brand Website" {...fieldsFor('companyWebsite')}>
                <ExternalLink href={brand.companyWebsite}>{brand.companyWebsite}</ExternalLink>
              </EditableDefinition>

              <EditableDefinition
                label="Country"
                options={countryOptions}
                value={user?.country.id}
                onSave={(value) => saveField('user.countryId', value)}
              >
                {user?.country.name}
              </EditableDefinition>
              <Definition label="Dropshipper">{brandDropshipStatus}</Definition>
              <EditableDefinition label="VIP Brand" {...fieldsFor('vip')} format="boolean" options={booleanOptions} />
              <MiniForm
                fields={addr}
                onSave={handleSaveAddress}
                style={{ marginBottom: 24 }}
                renderFields={(formik: any) => <AddressFormFields formik={formik} country={country} />}
              >
                {renderAddress('Business Address', addr)}
              </MiniForm>

              <MiniForm
                fields={shipFromAddr}
                onSave={handleSaveShipFromAddress}
                renderFields={(formik: any) => <AddressFormFields formik={formik} country={country} />}
              >
                {renderAddress('Ship From Address', shipFromAddr)}
              </MiniForm>

              {/* <EditableDefinition label="Location" {...fieldsFor('location')} /> */}
              {country === Countries['United Kingdom'] && (
                <>
                  <EditableDefinition
                    label="VAT Registered"
                    format="boolean"
                    {...fieldsFor('vatRegistered')}
                    options={booleanOptions}
                  />
                  <EditableDefinition
                    label="VAT Number"
                    validator={yup.string().required('VAT# is required if VAT registered is true.')}
                    {...fieldsFor('vatNumber')}
                  />
                </>
              )}
              <EditableDefinition
                label="Internal Notes"
                textFieldProps={{
                  multiline: true,
                  rowsMin: 3,
                  rowsMax: 11,
                  inputProps: { maxLength: 1000 },
                }}
                {...fieldsFor('internalNotes')}
              />

              <EditableDefinition
                label="Custom Boost Credit Amount Enabled"
                format="boolean"
                {...fieldsFor('customBoostCreditAmountEnabled')}
                options={booleanOptions}
              />
              <EditableDefinition
                label="Custom Boost Credit Amount"
                {...fieldsFor('customBoostCreditAmountInCents')}
                textFieldProps={{
                  InputProps: {
                    inputComponent: MoneyInput,
                  },
                }}
                value={centsToUnit(brand.customBoostCreditAmountInCents)}
                onSave={(value: number) => saveField('customBoostCreditAmountInCents', currencyToCents(value))}
              />
              <EditableDefinition
                label=">$500 Free Shipping Enabled"
                format="boolean"
                {...fieldsFor('freeShippingThresholdEnabled')}
                options={booleanOptions}
              />
              <EditableDefinition
                label="Free Shipping Contribution Percentage"
                validator={yup.number().min(0).max(100)}
                {...fieldsFor('freeShippingContributionPercentage')}
              />
            </Panel>

            <Panel title="Shop details">
              <EditableDefinition
                label="Primary category"
                value={brand.primaryCategoryId ? `${brand.primaryCategoryId}` : null}
                onSave={(value: any) => saveField('primaryCategoryId', value)}
                options={parentCategories.map((category) => ({
                  value: category.id,
                  label: category.displayName,
                }))}
              >
                {brand.primaryCategory?.displayName}
              </EditableDefinition>

              <EditableDefinition
                label="Brand values"
                EditorComponent={CheckGroup}
                options={brandValueOptions ?? []}
                {...fieldsFor('values')}
              >
                <div className={css.categories}>
                  {brand.values?.map((tag: string) => (
                    <Chip size="small" key={tag} label={tag} />
                  ))}
                </div>
              </EditableDefinition>
              <EditableDefinition
                label="Tagline"
                textFieldProps={{
                  multiline: true,
                  rows: 3,
                  rowsMax: 12,
                  inputProps: { maxLength: 150 },
                }}
                {...fieldsFor('tagline')}
              />
              <EditableDefinition
                label="Description"
                textFieldProps={{
                  multiline: true,
                  rows: 3,
                  rowsMax: 12,
                  inputProps: { maxLength: 1000 },
                }}
                {...fieldsFor('description')}
              />
              <EditableDefinition
                label="Headquarters Location"
                options={hqRegionNamesByCountry[country.name]}
                {...fieldsFor('headquartersState')}
              />
              <EditableDefinition
                label="Date Established"
                format="date"
                textFieldProps={{
                  type: 'date',
                }}
                {...fieldsFor('establishedAt')}
              />
              <EditableDefinition
                label="Instagram username"
                {...fieldsFor('instagramUsername')}
                textFieldProps={{
                  InputProps: {
                    startAdornment: <InputAdornment position="start">@</InputAdornment>,
                  },
                }}
              >
                @
                <ExternalLink href={`https://www.instagram.com/${brand.instagramUsername ?? ''}`}>
                  {brand.instagramUsername}
                </ExternalLink>
              </EditableDefinition>
              <EditableDefinition label="Facebook URL" {...fieldsFor('facebookPage')}>
                {normalizeFacebookPage(brand.facebookPage) ? (
                  <>
                    {brand.facebookPage} (
                    <ExternalLink href={normalizeFacebookPage(brand.facebookPage)}>LINK</ExternalLink>)
                  </>
                ) : (
                  brand.facebookPage
                )}
              </EditableDefinition>

              <Definition label="Brand Products">
                <Link to={`/products?brand=${brand.id}`}>{brand.productsCount || 0}</Link>
              </Definition>
            </Panel>

            <Panel title="Brand Media" collapsible startCollapsed>
              <Definition label="Video">
                {brand.videoUrl ? (
                  <video controls>
                    <source src={brand.videoUrl} />
                  </video>
                ) : (
                  '—'
                )}
              </Definition>
              <Definition label="User image">
                {brand.brandUserImage?.originalUrl ? (
                  <img alt="brand user" src={brand.brandUserImage?.originalUrl} />
                ) : (
                  '—'
                )}
              </Definition>
              <Definition label="Profile image">
                {brand.brandProfileImage?.originalUrl ? (
                  <img alt="brand profile" src={brand.brandProfileImage?.originalUrl} />
                ) : (
                  '—'
                )}
              </Definition>
              <Definition label="Profile cover image">
                {brand.brandProfileCoverImage?.originalUrl ? (
                  <img alt="brand profile cover" src={brand.brandProfileCoverImage?.originalUrl} />
                ) : (
                  '—'
                )}
              </Definition>
              <Definition label="Profile lifestyle image">
                {brand.brandProfileLifestyleImage?.originalUrl ? (
                  <img alt="brand profile lifestyle" src={brand.brandProfileLifestyleImage?.originalUrl} />
                ) : (
                  '—'
                )}
              </Definition>
            </Panel>
          </Grid>

          <Grid item xs={6}>
            <Panel title="Commission Plan">
              <EditableDefinition
                label="Plan Type"
                options={[
                  { label: 'Net 45', value: 'net_45' },
                  { label: 'On Delivery', value: 'on_delivery' },
                  { label: 'Basic (Tier One)', value: 'tier_one' },
                  { label: 'Plus (Tier Two)', value: 'tier_two' },
                  { label: 'Premium (Tier Three)', value: 'tier_three' },
                ]}
                {...fieldsFor('commissionPlanPlanType')}
              />
              <EditableDefinition
                label="First Order Commission Rate"
                {...fieldsFor('commissionPlanFirstOrderCommissionRate')}
              />
              <EditableDefinition label="Recurring Commission Rate" {...fieldsFor('commissionPlanCommissionRate')} />
              <EditableDefinition
                label="Payout Period"
                options={[
                  { label: 'Net 30 On Delivered', value: 'net_30_on_delivered' },
                  { label: 'Net 45 On Delivered', value: 'net_45_on_delivered' },
                  { label: 'On Delivered', value: 'on_delivered' },
                  { label: 'Next Day On Ship', value: 'next_day_on_ship' },
                ]}
                {...fieldsFor('commissionPlanPayoutPeriod')}
              />
              <EditableDefinition label="Search Rank Boost" {...fieldsFor('commissionPlanRankingBoost')} />
              {(brand.stripeAccountId || user?.country.name === 'United States') && (
                <Definition label="Stripe ID">{brand.stripeAccountId}</Definition>
              )}
              {(brand.transferwiseAccountId || user?.country.name === 'United Kingdom') && (
                <Definition label="Transferwise ID">{brand.transferwiseAccountId}</Definition>
              )}
            </Panel>

            <Panel title="Onboarding" collapsible startCollapsed={brand.aasmState !== 'pending_approval'}>
              <EditableDefinition
                label="State"
                options={[brand.aasmState, ...(brand.availableAasmStateTransitions || [])]}
                value={brand.aasmState}
                onSave={handleSetAasmState}
              >
                <BrandStateChip state={brand.aasmState} size="small" />
              </EditableDefinition>
              <Collapse in={brand.aasmState === 'declined'}>
                <>
                  <Definition label="Declined at" format="date">
                    {brand.declinedAt}
                  </Definition>
                  <EditableDefinition
                    label="Decline reason"
                    textFieldProps={{ multiline: true, rows: 4, rowsMax: 12 }}
                    {...fieldsFor('declineReason')}
                    style={{ marginBottom: 24 }}
                  />
                </>
              </Collapse>
              <EditableDefinition label="Entity Country" options={countryOptions} {...fieldsFor('entityCountryId')}>
                {brand.entityCountry?.name}
              </EditableDefinition>
              <EditableDefinition
                label="Shipping country"
                options={countryOptions.map((option) => option.label)}
                {...fieldsFor('shippingCountry')}
              />
              <EditableDefinition label="Number of Stockists" {...fieldsFor('stockists')} />
              {/* TODO: Needs to be modeled on backend */}
              {/* <EditableDefinition
                label="Onboarding notes"
                textFieldProps={{ multiline: true, rows: 6 }}
                {...fieldsFor('onboardingNotes')}
              /> */}
            </Panel>

            <Panel title="Referrals & existing relationships" collapsible startCollapsed>
              {/* TODO: Make editable */}
              <Definition label="Referrals">
                {user?.referrals?.map((referral: any) => (
                  <Link key={referral.id} to={`/retailers/${referral.id}`} className={css.relationshipLink}>
                    {referral.storeName}
                  </Link>
                ))}
              </Definition>
              <Definition label="Existing Relationships">
                {(brand.existingRelationships || []).map((relationship: any) => (
                  <Link
                    key={relationship.id}
                    to={`/retailers/${relationship.retailerProfile?.id}`}
                    className={css.relationshipLink}
                  >
                    {relationship.retailerProfile?.storeName}
                  </Link>
                ))}
              </Definition>
              <Button size="small" onClick={handleAddExistingRelationship} disabled={isDeleted}>
                ＋ Existing Relationship
              </Button>
            </Panel>

            <Panel title="Order fulfillment">
              <EditableDefinition
                label="Order minimum"
                textFieldProps={{
                  InputProps: {
                    inputComponent: MoneyInput,
                  },
                }}
                value={centsToUnit(brand.orderMinimumInCents)}
                validator={yup.string().required("Order minimum can't be blank.")}
                onSave={(value: number) => saveField('orderMinimumInCents', currencyToCents(value))}
              >
                {centsToCurrency(brand.orderMinimumInCents ?? 0, currency)}
              </EditableDefinition>
              <EditableDefinition
                label="Reorder minimum"
                validator={yup.string().required("Reorder minimum can't be blank.")}
                textFieldProps={{
                  InputProps: {
                    inputComponent: MoneyInput,
                  },
                }}
                value={centsToUnit(brand.reorderMinimumInCents)}
                onSave={(value: number) => saveField('reorderMinimumInCents', currencyToCents(value))}
              >
                {centsToCurrency(brand.reorderMinimumInCents ?? 0, currency)}
              </EditableDefinition>
              <EditableDefinition label="Lead time" options={leadTimeOptions} {...fieldsFor('leadTime')} />
              <EditableDefinition
                label="Avg Shipping Percentage"
                options={avgShippingOptions}
                {...fieldsFor('averageShippingPercentage')}
              >
                {brand.averageShippingPercentage}%
              </EditableDefinition>
              <EditableDefinition label="Max Shipping Percentage" {...fieldsFor('maxShippingPercentage')}>
                {brand.maxShippingPercentage}%
              </EditableDefinition>
              <EditableDefinition
                label="Dropshipping shipping percentage"
                {...fieldsFor('dropshippingShippingPercentage')}
              >
                {brand.dropshippingShippingPercentage}%
              </EditableDefinition>
            </Panel>

            <Panel title="Visibility">
              <EditableDefinition
                format="boolean"
                label="Brand Paused"
                {...fieldsFor('paused')}
                options={booleanOptions}
              />
              {brand.paused && <EditableDefinition label="Paused Message" {...fieldsFor('pausedMessage')} />}
              <EditableDefinition
                format="boolean"
                label="Hidden"
                helperText="This will hide the brand and their products from search"
                {...fieldsFor('user.hidden')}
                options={booleanOptions}
              />
            </Panel>
            <Panel data-testid="productImports" title="Product Imports" collapsible startCollapsed>
              {productImports?.length ? (
                <DataGrid
                  density="compact"
                  rows={productImports}
                  columns={productImportColumns}
                  pageSize={pageSize}
                  paginationMode="server"
                  onPageChange={handlePageChange}
                  loading={isLoadingProductImports}
                  pagination
                  rowCount={productImportsCount}
                />
              ) : (
                'No Product Imports Available'
              )}
            </Panel>

            <Panel title="Reviews" collapsible startCollapsed>
              <ReviewsDataGrid for="brand" id={brand.id} hideBrand />
            </Panel>

            <AuditLog type="Brand" id={brand.id} collapsible startCollapsed />

            <Panel gray>
              <Definition format="datetime" label="Profile Created At">
                {brand.createdAt}
              </Definition>

              <Definition format="datetime" label="Brand Approved At">
                {brand.approvedAt}
              </Definition>

              <Definition format="datetime" label="Updated At">
                {brand.updatedAt}
              </Definition>

              <Definition format="datetime" label="Deleted At">
                {brand.deletedAt}
              </Definition>
            </Panel>
          </Grid>

          <Grid item xs={12}>
            <ProductImportStatus brandId={brand.id} collapsible startCollapsed />
          </Grid>
        </Grid>
        <BrandDeclineDialog
          defaultDeclineReasonText={declineReason}
          shown={showDeclineDialog}
          onCancel={onCancel}
          onConfirm={onConfirmDecline}
        />
        <FindRetailerDialog
          title="Add Existing Relationship"
          open={showAddExistingRelationshipModal}
          onSelectUser={(retailer) => {
            setShowAddExistingRelationshipModal(false)
            addExistingRelationship(retailer.id)
          }}
          onCancel={() => setShowAddExistingRelationshipModal(false)}
        />
        <ProductUploadDialog
          open={showProductUploadDialog}
          onConfirm={handleProductUpload}
          onClose={() => setShowProductUploadDialog(false)}
        />
      </EditableContext.Provider>
    </CurrencyProvider>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerBeside: {
      display: 'flex',
      alignItems: 'center',
    },
    headerChip: {
      marginRight: 8,
    },
    companyName: {
      margin: '0px 16px 0 0',
    },
    addrLine: {
      '&:empty': {
        display: 'none',
      },
    },
    categories: {
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    relationshipLink: {
      '&:not(:last-child)::after': {
        content: '", "',
      },
    },
    btnMargin: {
      marginLeft: '.4em',
    },
  })
)

const productImportColumns = [
  {
    headerName: 'id',
    field: 'id',
    flex: 1,
  },
  {
    headerName: '# of Products',
    field: 'rows',
    flex: 1,
  },
  {
    headerName: 'Results',
    field: 'results',
    flex: 2,
    renderCell: ({ row, value }: any) =>
      value.errors?.length > 0 && row.finishedAt
        ? value.errors.map((err: any) => err)
        : row.finishedAt
        ? 'Successful with no errors'
        : 'Request stalled or still processing',
  },
  {
    headerName: 'Uploaded By',
    field: 'uploadedByEmail',
    flex: 1,
  },
  {
    headerName: 'Started_at',
    field: 'startedAt',
    flex: 1,
  },
  {
    headerName: 'Finished_at',
    field: 'finishedAt',
    flex: 1,
  },
]
