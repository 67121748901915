import * as yup from 'yup'
import { useFormik } from 'formik'
import { useCallback, useMemo, useState } from 'react'

import {
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import Button from 'components/Button'
import useCreateOrderReturn from 'hooks/useCreateOrderReturn'
import DebouncedTextField from 'components/DebouncedTextField'
import { LineItemValue } from './interfaces/index.interface'

type Props = {
  orderId: string
  lineItems: LineItemValue[]
  open: boolean
  onClose: () => void
}

export default function ReturnForm({ open, onClose, orderId, lineItems }: Props) {
  const [err, setErr] = useState<null | string>(null)
  const { isLoading, reasons, createOrderReturn } = useCreateOrderReturn()

  const [filteredLineItems, schema] = useMemo(() => {
    const filteredLineItems = lineItems.filter((lineItem) => lineItem.deletedAt === null)
    return [filteredLineItems, generateSchema(filteredLineItems)]
  }, [lineItems])

  const formik = useFormik<{
    aboundFeedback: string
    brandFeedback: string
    numberOfPackages: number
    reasons: string[]
    orderReturnLineItems: (Pick<LineItemValue, 'id' | 'variant' | 'quantity'> & { submittedQuantity: number })[]
  }>({
    initialValues: {
      aboundFeedback: '',
      brandFeedback: '',
      numberOfPackages: 0,
      reasons: [],
      orderReturnLineItems: [],
    },
    validateOnChange: true,
    validationSchema: schema,
    onSubmit: (data) => {
      setErr(null)
      return createOrderReturn({
        ...data,
        orderId: Number.parseInt(orderId),
        orderReturnLineItems: data.orderReturnLineItems.map((item) => ({
          lineItemId: item.id,
          submittedQuantity: item.submittedQuantity,
        })),
      })
        .then(() => {
          formik.resetForm()
          onClose()
        })
        .catch((err) => {
          setErr(err)
        })
    },
  })

  const selectedLineItems = formik.values['orderReturnLineItems']

  const getLineItemState = useCallback(
    (item: LineItemValue): [boolean, number] => {
      const foundItem = selectedLineItems.find((x) => x.id === item.id)
      return foundItem ? [true, foundItem.submittedQuantity] : [false, 0]
    },
    [selectedLineItems]
  )

  const toggleLineItem = useCallback(
    (item: LineItemValue) => {
      const foundItem = selectedLineItems.find((x) => x.id === item.id)
      return foundItem
        ? () => {
            formik.setFieldValue(
              'orderReturnLineItems',
              selectedLineItems.filter((x) => x.id !== item.id)
            )
          }
        : () => {
            formik.setFieldValue('orderReturnLineItems', [...selectedLineItems, { ...item, submittedQuantity: 0 }])
          }
    },
    [formik, selectedLineItems]
  )

  const setSubmittedQuantity = useCallback(
    (item: LineItemValue) => (val: number) => {
      const index = selectedLineItems.findIndex((x) => x.id === item.id)
      if (index === -1) return
      selectedLineItems[index].submittedQuantity = val
      formik.setFieldValue('orderReturnLineItems', selectedLineItems)
    },
    [formik, selectedLineItems]
  )

  const handleClose = useCallback(() => {
    formik.resetForm()
    setErr(null)
    onClose()
  }, [formik, onClose])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>New Return</DialogTitle>
        <Divider />

        <DialogContent>
          {err !== null && <Alert severity="error">{err}</Alert>}

          {filteredLineItems.map((item) => {
            const [checked, submittedQuantity] = getLineItemState(item)
            return (
              <LineItem
                key={item.id}
                item={item}
                checked={checked}
                setChecked={toggleLineItem(item)}
                submittedQuantity={submittedQuantity}
                setSubmittedQuantity={setSubmittedQuantity(item)}
              />
            )
          })}

          <TextField
            fullWidth
            label="Number Of Packages"
            type="number"
            margin="normal"
            {...formik.getFieldProps('numberOfPackages')}
            error={formik.errors.numberOfPackages !== undefined}
            helperText={formik.errors.numberOfPackages}
          />

          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Reasons</InputLabel>
            <Select fullWidth label="Reasons" variant="outlined" multiple {...formik.getFieldProps('reasons')}>
              {reasons.map((reason) => (
                <MenuItem key={reason} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Abound Feedback"
            margin="normal"
            multiline
            {...formik.getFieldProps('aboundFeedback')}
            error={formik.errors.aboundFeedback !== undefined}
            helperText={formik.errors.aboundFeedback}
          />

          <TextField
            fullWidth
            label="Brand Feedback"
            margin="normal"
            multiline
            {...formik.getFieldProps('brandFeedback')}
            error={formik.errors.brandFeedback !== undefined}
            helperText={formik.errors.brandFeedback}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          <Button
            type="submit"
            primary
            autoFocus
            disabled={!formik.isValid}
            busy={isLoading || formik.isValidating || formik.isSubmitting}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

type LineItemProps = {
  item: LineItemValue
  checked: boolean
  setChecked: (val: boolean) => void
  submittedQuantity: number
  setSubmittedQuantity: (val: number) => void
}

function LineItem({ item, checked, setChecked, submittedQuantity, setSubmittedQuantity }: LineItemProps) {
  const css = useStyles()

  return (
    <div className={css.lineItem}>
      <Checkbox
        checked={checked}
        onChange={({ target }) => {
          setChecked(target.checked)
        }}
      />

      <div>
        <Typography>{item.variant.name}</Typography>
        <Typography color="textSecondary" gutterBottom>
          SKU {item.variant.sku}
        </Typography>
      </div>

      <DebouncedTextField
        label="Quantity"
        variant="outlined"
        type="number"
        disabled={!checked}
        required
        value={'' + (submittedQuantity || 0)}
        onChange={(e: any) => {
          setSubmittedQuantity(e.target.value)
        }}
        error={checked && (submittedQuantity < 1 || submittedQuantity > item.quantity)}
        InputLabelProps={{ shrink: true }}
      />
    </div>
  )
}

function generateSchema(items: LineItemValue[]) {
  return yup.object({
    aboundFeedback: yup.string().optional(),
    brandFeedback: yup.string().optional(),
    numberOfPackages: yup.number().positive().required(),
    reasons: yup.array().of(yup.string()).required(),
    orderReturnLineItems: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(),
          submittedQuantity: yup
            .number()
            .required()
            .when('id', (id: number, schema: yup.NumberSchema) =>
              schema.min(1).max(items.find((item) => item.id === id)?.quantity ?? Number.POSITIVE_INFINITY)
            ),
        })
      )
      .min(1)
      .required(),
  })
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      margin: theme.spacing(2, 0),
      '&:last-child': {
        border: 0,
      },
    },

    lineItem: {
      paddingTop: `${theme.spacing(1)}px`,
      paddingBottom: `${theme.spacing(2)}px`,
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
      gridGap: 12,
      alignItems: 'center',
    },
  })
)
