import {
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { useState } from 'react'
import DebouncedTextField from 'components/DebouncedTextField'
import useRetailers from 'hooks/useRetailers'
import Async from 'components/Async'

interface User {
  id: number
  fullName: string
  email: string
  country: {
    id: number
    name: string
  }
}

interface Retailer {
  id: number
  storeName: string
  user: User
  deletedAt: boolean
}

interface Props {
  title: string
  open: boolean
  onCancel: Function
  onSelectUser: (user: Retailer) => void
}

const FindRetailerDialog = ({ title, onSelectUser, onCancel, open }: Props) => {
  const [query, setQuery] = useState('')
  const { retailerProfiles, isLoading } = useRetailers({ query })
  // TODO: API should provide a method for filtering out deleted retailers
  // This method results in result sets of varying lengths which is bad for UX.
  const activeRetailers = retailerProfiles.filter((retailer: Retailer) => !retailer.deletedAt)
  const handleQueryChange = (evt: any) => {
    setQuery(evt.target.value)
  }

  const handleClose = () => {
    onCancel()
    setQuery('')
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <Divider />

      <DialogContent style={{ width: 400 }}>
        <DialogContentText id="alert-dialog-description">
          <DebouncedTextField
            fullWidth
            value={query}
            onChange={handleQueryChange}
            label="Search Retailers"
            margin="normal"
          />
          <Collapse in={query.trim() !== ''}>
            <Async loading={isLoading} overlay>
              <List dense>
                {activeRetailers.map((retailer: Retailer) => (
                  <ListItem key={retailer.id} button onClick={() => onSelectUser(retailer)}>
                    <ListItemText
                      primary={retailer.storeName}
                      primaryTypographyProps={{
                        color: 'textPrimary',
                      }}
                      secondary={retailer.user.email}
                    />
                  </ListItem>
                ))}
              </List>
            </Async>
          </Collapse>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default FindRetailerDialog
