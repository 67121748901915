import { debounce } from 'lodash'
import { useState, useCallback, useEffect } from 'react'

import TextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete'
import useBrands from 'hooks/useBrands'
import { api } from 'services/api.service'

type BrandProfile = {
  id: number
  companyName: string
}

type Props = {
  TextFieldProps?: MuiTextFieldProps
} & Omit<AutocompleteProps<BrandProfile, false, false, false>, 'options' | 'renderInput'>

export default function BrandAutocomplete(props: Props) {
  const { TextFieldProps: textFieldProps, ...otherProps } = props
  const [inputValue, setInputValue] = useState('')
  const [query, setQuery] = useState('')
  const handleSetQuery = useCallback((value: string) => {
    setQuery(value)
  }, [])
  const [isShown, setIsShown] = useState(false)
  const { isLoading, brandProfiles, brandProfilesById } = useBrands({ query })
  const [isLoadingSingle, setIsLoadingSingle] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(debounce(handleSetQuery, 400), [])

  const handleChange = useCallback(
    (_, newValue) => {
      setInputValue(newValue || '')
      debouncedOnChange(newValue || '')
    },
    [debouncedOnChange]
  )

  useEffect(() => {
    if (props.value?.id && !brandProfilesById[props.value?.id] && !isLoading) {
      setIsLoadingSingle(true)
      api.get(`/brands/${props.value?.id}`).then((resp: any) => {
        if (props.value?.id === resp.data.id) {
          setInputValue(resp.data.companyName)
        }
        setIsLoadingSingle(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value?.id])

  return (
    <Autocomplete
      open={isShown}
      onOpen={() => {
        setIsShown(true)
      }}
      onClose={() => {
        setIsShown(false)
      }}
      getOptionSelected={(option: any, value) => option.id === value.id}
      getOptionLabel={(option: BrandProfile) =>
        option.companyName || (brandProfilesById[option.id] ? brandProfilesById[option.id].companyName : '')
      }
      options={brandProfiles}
      loading={isLoading || isLoadingSingle}
      inputValue={inputValue}
      onInputChange={handleChange}
      {...otherProps}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Brand"
          {...props.TextFieldProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading || isLoadingSingle ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
