import * as yup from 'yup'
import { Divider, Dialog, DialogTitle, Grid, Box } from '@material-ui/core'

import MoneyInput from 'components/MoneyInput'
import { currencyToCents } from 'util/currencyToCents'
import { Payment } from 'types/paymentPlan'
import Panel from 'components/Panel'
import EditableDefinition from 'components/EditableDefinition'
import centsToCurrency, { centsToUnit } from 'util/centsToCurrency'
import { useCurrencyContext } from 'context/Country/CountryContext'
import Definition from 'components/Definition'

export const Frequents = ['weekly', 'bi_weekly', 'monthly']

type Props = {
  open: boolean
  onClose: () => void
  payments: Payment[]
  updatePayment: (id: number, field: string, value: any) => any
}

export default function Payments({ open, onClose, payments, updatePayment }: Props) {
  const currency = useCurrencyContext()
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle id="form-dialog-title">Payments</DialogTitle>
      <Divider />
      <Box padding>
        {payments &&
          payments.map((payment) => (
            <Panel>
              <Grid container spacing={2}>
                <Grid item xs={6} lg={4}>
                  <Definition label="State">{payment.state}</Definition>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Definition label="Stripe Fingerprint">{payment.stripeFingerprint}</Definition>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <EditableDefinition
                    label="Total Amount"
                    textFieldProps={{
                      InputProps: {
                        inputComponent: MoneyInput,
                      },
                    }}
                    value={centsToUnit(payment.amountCents)}
                    validator={yup.string().required("Amount can't be blank.")}
                    onSave={(value: any) => updatePayment(payment.id, 'amountCents', currencyToCents(value))}
                  >
                    {centsToCurrency(payment.amountCents, currency)}
                  </EditableDefinition>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <EditableDefinition
                    label="Scheduled At"
                    asDatePicker
                    value={payment.scheduledAt}
                    onSave={(value: any) => updatePayment(payment.id, 'scheduledAt', value)}
                  />
                </Grid>

                <Grid item xs={6} lg={4}>
                  <EditableDefinition
                    label="Stripe Payment Intent"
                    value={payment.stripePaymentIntentId}
                    onSave={(value: any) => updatePayment(payment.id, 'stripePaymentIntentId', value)}
                  />
                </Grid>
              </Grid>
            </Panel>
          ))}
      </Box>
    </Dialog>
  )
}
