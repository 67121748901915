import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Grid } from '@material-ui/core'

import { Category, isParentCategory, isTertiaryCategory, isSecondaryCategory } from 'types/category'

import usePageTitle from 'hooks/usePageTitle'
import useCategory from 'hooks/useCategory'
import useCategories from 'hooks/useCategories'

import Button from 'components/Button'
import PageHeader from 'components/PageHeader'
import Async from 'components/Async'
import Panel from 'components/Panel'

import DeleteDialog from './DeleteDialog'
import CountrySettings from './CountrySettings'
import CategoryAttrPanel from './CategoryAttrPanel'
import CategoriesDataGrid from './CategoriesDataGrid'
import CreateCategoryForm from 'pages/Categories/CreateCategory'

interface Params {
  id: string
}

export default function Categories() {
  const { id: stringId } = useParams<Params>()
  const id = Number.parseInt(stringId)

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showCreateForm, setShowCreateForm] = useState(false)

  const {
    isLoading,
    categories,
    setCategories,
    parentCategories,
    secondaryCategories,
    tertiaryCategories,
  } = useCategories()
  const { category, saveField, deleteCategory } = useCategory({ id, categories, setCategories })
  usePageTitle(category?.displayName || 'Category')

  if (category === null) {
    return null
  }

  let childCategories: Category[] = []

  if (isParentCategory(category)) {
    childCategories = secondaryCategories.filter((c) => c.parentId === id)
  }

  if (isSecondaryCategory(category)) {
    childCategories = tertiaryCategories.filter((c) => c.parentId === id)
  }

  return (
    <div>
      <PageHeader
        title={category.displayName}
        backLink={isSecondaryCategory(category) ? `/categories/${category.parentId}` : '/categories'}
        contentRight={
          <Button
            onClick={() => {
              setShowDeleteDialog(true)
            }}
            primary
            danger
          >
            Delete
          </Button>
        }
      ></PageHeader>

      <Async loading={isLoading}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CategoryAttrPanel category={category} saveField={saveField[0]} parentCategories={parentCategories} />
          </Grid>

          {isParentCategory(category) && (
            <Grid item xs={6}>
              <CountrySettings category={category} setCategories={setCategories} />
            </Grid>
          )}

          {!isTertiaryCategory(category) && (
            <Grid item xs={12}>
              <Panel
                title="Subcategories"
                contentRight={
                  <Button primary onClick={() => setShowCreateForm(true)}>
                    New Subcategory
                  </Button>
                }
              >
                <CategoriesDataGrid isLoading={isLoading} categories={childCategories} />
              </Panel>
            </Grid>
          )}
        </Grid>
      </Async>

      <DeleteDialog
        category={category}
        open={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false)
        }}
        isLoading={deleteCategory[1]}
        deleteCategory={deleteCategory[0]}
      />

      <CreateCategoryForm
        open={showCreateForm}
        onClose={() => setShowCreateForm(false)}
        parentId={category.id}
        taxonomyLevel={category.taxonomyLevel + 1}
        setCategories={setCategories}
      />
    </div>
  )
}
