import { useState, useEffect, useMemo } from 'react'

import { Review } from 'types'

import { api } from 'services/api.service'
import { useAlert } from 'context/Alert/AlertContext'
import useAction from 'hooks/useAction'
import usePagination from 'hooks/usePagination'

export type UseReviewsParams =
  | { for: 'order'; id: number }
  | { for: 'brand'; id: number }
  | { for: 'retailer'; id: number }
  | { for: 'all'; id?: undefined }

export function useReviews(opts: UseReviewsParams) {
  const snackbar = useAlert()
  const [reviews, setReviews] = useState<Review[]>([])
  const { pagination, setPagination, paginationQueryParams } = usePagination()

  const params = useMemo(() => ({ ...paginationQueryParams, ...(opts.for !== 'all' ? { [opts.for]: opts.id } : {}) }), [
    opts.for,
    opts?.id,
    paginationQueryParams,
  ])

  const queryString = new URLSearchParams(params).toString()

  const [fetchReviews, isLoading] = useAction({
    request: () => api.get(`reviews?${queryString}`),
    onSuccess: (resp) => {
      setReviews(resp.data.data)
      setPagination(resp.data.meta)
    },
    onError: () => snackbar.error(`Error fetching reviews`),
  })

  const [toggleFlag] = useAction({
    request: (review: Review) => api.patch(`reviews/${review.id}`, { review: { flagged: !review.flagged } }),
    onSuccess: (resp, [targetReview]) => {
      setReviews((reviews) =>
        reviews.map((review) =>
          review.id !== targetReview.id ? review : { ...targetReview, flagged: resp.data.flagged }
        )
      )
      snackbar.success('Updated review flag')
    },
    onError: (err: unknown) => {
      console.error(err)
      snackbar.error('Failed to toggle review flag')
    },
  })

  useEffect(() => {
    fetchReviews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return { reviews, isLoading, toggleFlag, pagination }
}
