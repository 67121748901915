import React, { createContext, useContext } from 'react'

import { Currency } from 'types/currency'

const CurrencyContext = createContext<Currency | undefined>(undefined)

export function useCurrencyContext() {
  const value = useContext(CurrencyContext)
  if (value === undefined) {
    throw new Error('could not find a currency provider')
  }
  return value
}

export function CurrencyProvider({ currency, children }: { currency: Currency; children: React.ReactNode }) {
  return <CurrencyContext.Provider value={currency}>{children}</CurrencyContext.Provider>
}
