import { api } from 'services/api.service'
import { Category } from 'types/category'
import { useAlert } from 'context/Alert/AlertContext'
import useAction from 'hooks/useAction'

type Params = {
  setCategories: React.Dispatch<React.SetStateAction<Category[]>>
}

export default function useCreateCategory({ setCategories }: Params) {
  const snackbar = useAlert()

  const [createCategory, isCreatingCategory, createCategoryError] = useAction({
    request: (category: Pick<Category, 'name' | 'description' | 'featured' | 'managedByAdmin' | 'parentId'>) =>
      api.post(`categories`, { category }),
    onSuccess: (resp) => {
      setCategories((categories) => [...categories, resp.data])
      snackbar.success('Created new category')
    },
    onError: (err) => {
      console.error(err)
      snackbar.error('Error creating new category')
    },
  })

  return { createCategory, isCreatingCategory, createCategoryError }
}
