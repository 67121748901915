import { Chip, ChipProps } from '@material-ui/core'
import getStateMeta, { BrandState } from 'util/getBrandStateMeta'

interface Props extends ChipProps {
  state: BrandState
}

export default function BrandStateChip({ state, style, ...rest }: Props) {
  const stateMeta = getStateMeta(state)
  return (
    <Chip label={stateMeta.label} style={{ backgroundColor: stateMeta.color, marginLeft: '5px', ...style }} {...rest} />
  )
}
