import { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { DateTime, FixedOffsetZone } from 'luxon'

import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent,
  Pagination,
} from '@material-ui/lab'
import { Typography } from '@material-ui/core'

import Panel from 'components/Panel'
import Async from 'components/Async'
import useAuditLog, { Params as AuditLogParams } from 'hooks/useAuditLog'

type Props = {
  title?: string
  collapsible?: boolean
  startCollapsed?: boolean
} & AuditLogParams

const eventVerbMap: { [key: string]: string } = {
  create: 'created',
  update: 'updated',
  destroy: 'deleted',
}

export default function AuditLog({
  title = 'Audit Log',
  id,
  type,
  collapsible = false,
  startCollapsed = false,
}: Props) {
  const [isOpen, setIsOpen] = useState(!startCollapsed)
  const { fetchData, isLoading, log, pagination } = useAuditLog({ id, type })
  useEffect(() => (isOpen ? fetchData() : undefined), [isOpen, fetchData])

  return (
    <Panel title={title} onToggle={setIsOpen} collapsible={collapsible} startCollapsed={startCollapsed}>
      <Async loading={isLoading} overlay>
        <Timeline>
          {log
            .filter((entry) => Object.keys(entry.changeset).length)
            .map((entry, i) => (
              <TimelineItem key={entry.id}>
                <TimelineOppositeContent>
                  <Typography color="textSecondary">{formatDate(entry.changeset.updatedAt[1])}</Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                  <TimelineDot variant="outlined" />
                  {i < log.length - 1 && <TimelineConnector />}
                </TimelineSeparator>

                <TimelineContent>
                  {_.map(Object.entries(_.omit(entry.changeset, ['updatedAt'])), ([key, [oldVal, newVal]]) => (
                    <Typography key={key} display="block">
                      <Typography color="secondary" display="inline">
                        {_.startCase(key)}{' '}
                      </Typography>
                      <Typography display="inline">
                        {`${eventVerbMap[entry.event]} from `}
                        <code style={{ backgroundColor: '#eee' }}>{(oldVal ?? 'null').toString()}</code>
                        {' to '}
                        <code style={{ backgroundColor: '#eee' }}>{(newVal ?? 'null').toString()}</code>
                        {'.'}
                      </Typography>
                    </Typography>
                  ))}

                  <Typography color="primary" display="block">
                    By {entry.whodunnit?.fullName || 'N/A'}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          {log.length === 0 && <Typography>No changes yet!</Typography>}
        </Timeline>
      </Async>

      {pagination.count > 0 && (
        <Pagination
          color="primary"
          count={pagination.totalPages}
          page={pagination.currentPage}
          onChange={(_, page) => {
            pagination.goToPage(page)
          }}
        />
      )}
    </Panel>
  )
}

function formatDate(date: string): string {
  if (!date) {
    return 'N/A'
  }
  return DateTime.fromFormat(date.slice(0, -4), 'yyyy-MM-dd HH:mm:ss', {
    zone: FixedOffsetZone.utcInstance,
  })
    .setZone('local')
    .toLocaleString(DateTime.DATETIME_MED)
}
