import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormControl, FormHelperText, Box, TextField } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import DebouncedTextField from 'components/DebouncedTextField'
import MoneyInput from 'components/MoneyInput'
import SelectField from 'components/SelectField'
import { Autocomplete } from '@material-ui/lab'
import { centsToUnit } from 'util/centsToCurrency'
import { currencyToCents } from 'util/currencyToCents'

interface OrderShipmentFormFieldsProps {
  formik: any
}

const datePickerProps = {
  fullWidth: true,
  disableToolbar: true,
  variant: 'inline',
  format: 'yyyy-MM-dd',
  margin: 'normal',
}

const methodOptions = [
  { label: 'Ship with abound', value: 'ship_with_abound' },
  { label: 'Ship on your own', value: 'ship_on_your_own' },
]

const carrierOptions = [
  'ups',
  'fedex',
  'usps',
  'dhl_express',
  'stamps_com',
  'Purolator',
  'fedex_uk',
  'DHLExpress',
  'RoyalMail',
  'Parcelforce',
  'Parcel2Go',
  'Yodel',
  'Hermes',
  'DeutschePostUK',
  'DPDUK',
]

const OrderShipmentFormFields: React.FC<OrderShipmentFormFieldsProps> = ({ formik }) => {
  const css = useStyles()
  return (
    <FormControl fullWidth component="fieldset">
      <DebouncedTextField margin="normal" fullWidth label="Tracking ID" {...formik.getFieldProps('trackingId')} />
      <DebouncedTextField
        margin="normal"
        fullWidth
        label="Cost"
        {...formik.getFieldProps('costInCents')}
        InputProps={{
          inputComponent: MoneyInput,
          defaultValue: formik.values.costInCents,
        }}
        value={centsToUnit(formik.values.costInCents)}
        onChange={(evt: any) => formik.setFieldValue('costInCents', currencyToCents(evt.target.value))}
      />
      <Autocomplete
        freeSolo
        options={carrierOptions}
        value={formik.values.carrier}
        onInputChange={(e, newValue: any) => {
          formik.setFieldValue('carrier', newValue)
        }}
        renderInput={(params) => <TextField margin="normal" name="carrier" {...params} label="Carrier" fullWidth />}
      />

      <KeyboardDatePicker
        {...datePickerProps}
        label="Delivered At"
        {...formik.getFieldProps('deliveredAt')}
        onChange={(val: any) => formik.setFieldValue('deliveredAt', val)}
      />
      <KeyboardDatePicker
        {...datePickerProps}
        label="Assumed Delivered At"
        {...formik.getFieldProps('assumedDeliveredAt')}
        onChange={(val: any) => formik.setFieldValue('assumedDeliveredAt', val)}
      />
      <SelectField
        margin="normal"
        fullWidth
        label="Method"
        {...formik.getFieldProps('method')}
        options={methodOptions}
        required={true}
      />
      <Box display="flex" justifyContent="space-between" className={css.row}>
        <DebouncedTextField margin="normal" fullWidth label="Weight" {...formik.getFieldProps('weight.value')} />
        <DebouncedTextField margin="normal" fullWidth disabled label="Unit" {...formik.getFieldProps('weight.unit')} />
      </Box>
      <Box display="flex" justifyContent="space-between" className={css.row}>
        <DebouncedTextField margin="normal" fullWidth label="Length" {...formik.getFieldProps('dimensions.length')} />
        <DebouncedTextField margin="normal" fullWidth label="Width" {...formik.getFieldProps('dimensions.width')} />
        <DebouncedTextField margin="normal" fullWidth label="Height" {...formik.getFieldProps('dimensions.height')} />
        <DebouncedTextField
          margin="normal"
          fullWidth
          disabled
          label="Unit"
          {...formik.getFieldProps('dimensions.unit')}
        />
      </Box>
      {formik.errors.form && <FormHelperText error>{formik.errors.form}</FormHelperText>}
    </FormControl>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      '& > *': {
        flex: '1 1 auto',
        minWidth: 'auto',
      },
      '& > * + *': {
        marginLeft: 16,
      },
    },
  })
)

export default OrderShipmentFormFields
