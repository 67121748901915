import { useState } from 'react'
import * as yup from 'yup'

import { Grid, Typography } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

import Async from 'components/Async'
import AsyncButton from 'components/AsyncButton'
import Button from 'components/Button'
import Definition from 'components/Definition'
import Panel from 'components/Panel'
import Link from 'components/Link'
import EditableDefinition from 'components/EditableDefinition'
import MoneyInput from 'components/MoneyInput'
import centsToCurrency, { centsToUnit } from 'util/centsToCurrency'
import { currencyToCents } from 'util/currencyToCents'
import AddCreditDialog from './CreditForm'

import { useConfirm } from 'context/Confirm/ConfirmContext'

import usePagination from 'hooks/usePagination'
import { useCurrencyContext } from 'context/Country/CountryContext'

interface Props {
  credits: any[]
  pagination: ReturnType<typeof usePagination>['pagination']
  countryName: string
  createCredit: (credit: any) => Promise<any>
  updateCredit: (creditId: string, field: string, value: any) => Promise<any>
  deleteCredit: (credit: any) => Promise<any>
  isCreditsLoading: boolean
  isReferred?: boolean
}

export default function Credits({
  credits,
  pagination,
  countryName,
  createCredit,
  updateCredit,
  deleteCredit,
  isCreditsLoading,
  isReferred = false,
}: Props) {
  const { confirm } = useConfirm()
  const [createCreditDialog, setCreateCreditDialog] = useState(false)
  const [showMoreCredits, setShowMoreCredits] = useState(false)
  const currency = useCurrencyContext()

  const handleCreditDelete = (creditId: string) =>
    confirm({
      title: 'Delete Credit',
      text: 'Are you sure you would like to delete this credit?',
      submitText: 'Delete',
      danger: true,
    })
      .then(() => {
        deleteCredit(creditId)
      })
      .catch((err: any) => {
        console.error(err)
      })

  return (
    <>
      <Panel
        title="Credits"
        contentRight={
          <Button small onClick={() => setCreateCreditDialog(true)}>
            ＋ Create Credit
          </Button>
        }
      >
        <Async loading={isCreditsLoading}>
          {credits &&
            credits.length > 0 &&
            credits
              .filter((_, i) => (i > 0 ? showMoreCredits : true))
              .map((credit: any) => (
                <Panel key={credit.id}>
                  <Grid container spacing={2} key={credit.id}>
                    <Grid item xs={8}>
                      <Typography style={{ fontWeight: 'bold' }} gutterBottom>
                        {credit.type}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <AsyncButton
                        danger
                        small
                        style={{ float: 'right' }}
                        onClick={() => handleCreditDelete(credit.id)}
                      >
                        Delete
                      </AsyncButton>
                    </Grid>

                    <Grid item xs={6} lg={4}>
                      <EditableDefinition
                        label="Amount"
                        textFieldProps={{
                          InputProps: {
                            inputComponent: MoneyInput,
                          },
                        }}
                        value={centsToUnit(credit.amountInCents)}
                        validator={yup.string().required("Amount can't be blank.")}
                        onSave={(value: any) => updateCredit(credit.id, 'amountInCents', currencyToCents(value))}
                      >
                        {centsToCurrency(credit.amountInCents, currency)}
                      </EditableDefinition>
                    </Grid>

                    <Grid item xs={6} lg={4}>
                      <Definition label="Created At" format="date">
                        {credit.createdAt}
                      </Definition>
                    </Grid>

                    <Grid item xs={6} lg={4}>
                      <Definition label="Used At" format="date">
                        {credit.usedAt}
                      </Definition>
                    </Grid>

                    <Grid item xs={6} lg={4}>
                      {credit.orderId && (
                        <Link to={`/orders/${credit.orderId}`}>
                          <Definition label="Order">{credit.orderId}</Definition>
                        </Link>
                      )}
                      {!credit.orderId && <Definition label="Order">{credit.orderId}</Definition>}
                    </Grid>

                    <Grid item xs={6} lg={4}>
                      <Definition label="Charge ID">{credit.chargeId}</Definition>
                    </Grid>

                    <Grid item xs={6} lg={4}>
                      <EditableDefinition
                        asDatePicker
                        label="Expires At"
                        value={credit.expiresAt}
                        format="date"
                        onSave={(value: any) => updateCredit(credit.id, 'expiresAt', value)}
                      />
                    </Grid>
                  </Grid>
                </Panel>
              ))}
        </Async>

        {pagination.totalPages > 1 && showMoreCredits && (
          <Pagination
            style={{ marginTop: 10, marginBottom: 10 }}
            color="primary"
            count={pagination.totalPages}
            page={pagination.currentPage}
            onChange={(_, page) => {
              pagination.goToPage(page)
            }}
          />
        )}

        {credits.length > 1 && !showMoreCredits && (
          <Button
            small
            onClick={() => {
              setShowMoreCredits(true)
            }}
            style={{ marginTop: 10 }}
          >
            Show more
          </Button>
        )}

        {credits.length > 1 && showMoreCredits && (
          <Button
            small
            onClick={() => {
              pagination.goToPage(1)
              setShowMoreCredits(false)
            }}
            style={{ marginTop: 10 }}
          >
            Hide
          </Button>
        )}
      </Panel>

      <AddCreditDialog
        open={createCreditDialog}
        onClose={() => {
          setCreateCreditDialog(false)
        }}
        onSubmit={createCredit}
        isReferred={isReferred}
      />
    </>
  )
}
