import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'

import useToken from 'hooks/useToken'
import { useAbility } from 'hooks/useAbility'

const UserMenu = () => {
  const { removeToken } = useToken()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const ability = useAbility()

  const open = Boolean(anchorEl)

  const handleLogout = () => {
    setAnchorEl(null)
    removeToken()
    ability.update([])
    history.push('/login')
  }

  return (
    <>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        aria-expanded={open ? 'true' : undefined}
        aria-describedby="user-menu"
      >
        <PersonIcon />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MenuItem component={Link} to="/profile">
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  )
}

export default UserMenu
