import { useCallback, useMemo, useState } from 'react'
import { get } from 'lodash'
import { DateTime } from 'luxon'
import { Box, Divider, Grid } from '@material-ui/core'

import Link from 'components/Link'
import EditableDefinition from 'components/EditableDefinition'
import RefreshableDefinition from 'components/RefreshableDefinition'
import RefundFormFields from 'components/RefundFormFields'
import Button from 'components/Button'
import Definition from 'components/Definition'
import Panel from 'components/Panel'
import MiniForm from 'components/MiniForm'
import centsToCurrency from 'util/centsToCurrency'
import normalizeOptions from 'util/normalizeOptions'
import AddTransferDialog from 'pages/Orders/Order/TransferForm'

import { Charge } from 'types'
import { BrandTransfer } from './BrandTransfer'
import { RetailerCharge } from 'pages/Orders/Order/RetailerCharge'
import { useCurrencyContext } from 'context/Country/CountryContext'
import { useConfirm } from 'context/Confirm/ConfirmContext'

interface OverviewProps {
  order: any
  charge: Charge
  saveField: (keyPath: string, value: any) => Promise<any>
  createRefund: (amountInCents: number, reason: string) => Promise<any>
  saveAasmState: (state: any) => Promise<any>
  saveChargeAasmState: (state: any) => Promise<any>
  createTransfer: (transfer: any) => Promise<any>
  processTransfer: (transfer: any) => Promise<any>
  refreshCalculatedTotal: () => Promise<void>
}

const booleanOptions = [
  { label: 'NO', value: false },
  { label: 'YES', value: true },
]

const commissionRateOptions = [
  { label: '0%', value: 0 },
  { label: '10%', value: 10 },
  { label: '15%', value: 15 },
  { label: '20%', value: 20 },
  { label: '25%', value: 25 },
]

const newRefund = {
  amountInCents: 0,
  reason: 'requested_by_customer',
}

const updateOptions = (availableTransitions: string[]) => {
  if (availableTransitions) {
    return normalizeOptions(availableTransitions.filter((el: string) => el !== 'cancel'))
  }
}

export default function Overview({
  order,
  charge,
  saveField,
  createRefund,
  saveAasmState,
  saveChargeAasmState,
  createTransfer,
  processTransfer,
  refreshCalculatedTotal,
}: OverviewProps) {
  const currency = useCurrencyContext()
  const { confirm } = useConfirm()
  const [createTransferDialog, setCreateTransferDialog] = useState(false)

  const fieldsFor = (keyPath: string) => ({
    value: get(order, keyPath),
    onSave: (value: any) => saveField(keyPath, value),
  })

  const handleAddRefund = useCallback(({ amountInCents, reason }) => createRefund(amountInCents, reason), [
    createRefund,
  ])

  const totalRefunds = useMemo(() => charge.refunds.reduce((acc: number, { amount }) => acc + amount, 0), [
    charge.refunds,
  ])

  const handleSetAasmState = (state: any) => {
    return confirm({
      title: 'Update order state',
      text: `Are you sure you want to set the order state to ${state}?`,
      submitText: 'Update State',
    })
      .then(() => {
        saveAasmState(state)
      })
      .catch(() => {
        Promise.resolve()
      })
  }

  const appliedDiscountPercentage = get(order, ['appliedPromotions', 0, 'appliedDiscountPercentage'], 0)

  return (
    <>
      <Panel title="Overview" gray>
        <Definition format="datetime" label="Checked Out At">
          {order.checkedOutAt}
        </Definition>
        <Definition format="datetime" label="Accepted At">
          {order.acceptedAt}
        </Definition>
        <Definition format="datetime" label="Cancelled At">
          {order.canceledAt}
        </Definition>
      </Panel>

      <Panel>
        <EditableDefinition
          readOnly={order.availableTransitions ? false : true}
          label="Order State"
          value={order.aasmState}
          options={updateOptions(order.availableTransitions)}
          onSave={handleSetAasmState}
        >
          {order.aasmState}
        </EditableDefinition>
        <RefreshableDefinition
          label="Calculated Total"
          value={centsToCurrency(order.calculatedTotalInCents, currency)}
          onRefresh={refreshCalculatedTotal}
        />
      </Panel>

      <Panel
        title="Brand Payout"
        contentRight={
          order.transfer === null ? (
            <Button small onClick={() => setCreateTransferDialog(true)}>
              ＋ Create Transfer
            </Button>
          ) : (
            ''
          )
        }
      >
        <Definition fontLarge label="Total Payout">
          {centsToCurrency(order.brandTotalPayoutIncludingVatInCents, currency)}
        </Definition>
        <EditableDefinition
          label={`Commission ${
            order.zeroCommissionRateReason ? `(Zero commission as it's ${order.zeroCommissionRateReason} )` : ''
          }`}
          {...fieldsFor('commissionRate')}
          options={commissionRateOptions}
        >
          {centsToCurrency(order.commissionInCents, currency)}
          {order.commissionRate && ` (${order.commissionRate}%)`}
        </EditableDefinition>

        <Definition label="Shipping Contribution">
          {centsToCurrency(order.brandShippingCostContributionInCents, currency)}
        </Definition>

        <EditableDefinition
          label="Max Reimbursable Shipping Percentage"
          {...fieldsFor('maxReimbursableShippingPercentage')}
        >
          {order.maxReimbursableShippingPercentage}%
        </EditableDefinition>

        <Definition label="Days Before Transfer">{order.daysBeforeTransfer}</Definition>

        <BrandTransfer
          transfer={order.transfer}
          onSave={(val) => saveField('transfer', val)}
          processTransfer={processTransfer}
        />
      </Panel>

      <AddTransferDialog
        open={createTransferDialog}
        onClose={() => {
          setCreateTransferDialog(false)
        }}
        onSubmit={createTransfer}
      />

      <Panel title="Retailer Payment">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Definition fontLarge label="Order Payment Terms">
              {charge.paymentTerms}
            </Definition>
          </Grid>
          <Grid item xs={4}>
            <Definition fontLarge label="Retailer Total">
              {centsToCurrency(order.retailerTotalIncludingVat, currency)}
            </Definition>
          </Grid>
          <Grid item xs={4}>
            <Definition fontLarge label="Subtotal">
              {centsToCurrency(order.subtotalInCents, currency)}
            </Definition>
          </Grid>
          <Grid item xs={4}>
            {charge.shippingCostInCents ? (
              <Definition fontLarge label="Shipping Total">
                {centsToCurrency(charge.shippingCostInCents, currency)}
              </Definition>
            ) : (
              <Definition fontLarge label="Estimated Shipping Total">
                {centsToCurrency(order.estimatedShippingCostInCents, currency)}
              </Definition>
            )}
          </Grid>
          <Grid item xs={4}>
            <Definition fontLarge label="Total Applied Credits">
              {centsToCurrency(order.totalAppliedCreditsInCents, currency)}
            </Definition>
          </Grid>
          <Grid item xs={4}>
            <Definition fontLarge label="Total Applied Promotions">
              {centsToCurrency(order.totalPromotionDiscountAmountInCents, currency)} ({appliedDiscountPercentage}%)
            </Definition>
          </Grid>
          <Grid item xs={4}>
            <Definition fontLarge label="Returns Refunds">
              {centsToCurrency(order.returnsAmountRefundedInCents, currency)}
            </Definition>
          </Grid>
        </Grid>

        <Divider style={{ margin: '20px 0' }} />

        <h3>Charge</h3>
        <RetailerCharge charge={charge} saveField={saveField} saveChargeAasmState={saveChargeAasmState} />

        <Divider style={{ margin: '20px 0' }} />

        {charge.captured && (
          <>
            <Definition label={`refunds (total ${centsToCurrency(totalRefunds, currency)})`}>
              {charge.refunds.map((refund) => (
                <div key={refund.id}>
                  {centsToCurrency(refund.amount, currency)} ({DateTime.fromSeconds(refund.created).toFormat('DD')})
                  Order - <Link to={`/orders/${refund.order.id}`}>#{refund.order.uid}</Link>
                </div>
              ))}
            </Definition>
            <MiniForm
              fields={newRefund}
              onSave={(s) => handleAddRefund(s)}
              style={{ marginBottom: 24 }}
              renderFields={(formik: any) => <RefundFormFields formik={formik} />}
              renderToggleEdit={(onClick) => (
                <Box display="flex" justifyContent="flex-end">
                  <Button onClick={onClick}>Add refund</Button>
                </Box>
              )}
            ></MiniForm>
          </>
        )}
      </Panel>

      <Panel title="Emails">
        <Definition label="OOSW" format="boolean">
          {order.oosw}
        </Definition>
        <Definition label="OOSW Emails Sent">{order.ooswEmailsSent}</Definition>
        <EditableDefinition
          label="OOSW Emails Enabled"
          format="boolean"
          {...fieldsFor('ooswEmailsEnabled')}
          options={booleanOptions}
        />
      </Panel>
    </>
  )
}
