import { api } from 'services/api.service'
import { useEffect, useState } from 'react'
import { useAlert } from 'context/Alert/AlertContext'
import useAction from 'hooks/useAction'

interface OrderReturnParams {
  id: string
}

export default function useOrderReturn({ id }: OrderReturnParams) {
  const [orderReturn, setOrderReturn] = useState<any>({})
  const snackbar = useAlert()

  const [fetchData, isLoading, error] = useAction({
    request: () => api.get(`/order_returns/${id}`),
    onSuccess: (resp: any) => setOrderReturn(resp.data),
    onError: () => snackbar.error('Error fetching order return.'),
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const [changeState, isChangingState] = useAction({
    request: (event) => api.patch(`order_returns/${id}/${event}`),
    onSuccess: (resp) => setOrderReturn(resp.data),
  })

  const [saveLineItems, isSavingLineItems, formError] = useAction({
    request: (fields) => api.patch(`order_returns/${id}/`, { order_return: fields }),
    onSuccess: (resp) => {
      snackbar.success('Order return updated successfully.')
      setOrderReturn(resp.data)
    },
  })

  const [processReturn] = useAction({
    request: (fields) => api.patch(`order_returns/${id}/process_return`, { order_return: fields }),
    onSuccess: (resp) => {
      snackbar.success('Order return processed successfully.')
      setOrderReturn(resp.data)
    },
  })

  return {
    fetchData,
    isLoading,
    error,
    orderReturn,
    changeState,
    isChangingState,
    saveLineItems,
    isSavingLineItems,
    processReturn,
    formError,
  }
}
