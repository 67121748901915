import { DataGridProps, GridCellParams, GridColDef } from '@material-ui/data-grid'

import { Category } from 'types/category'

import Link from 'components/Link'
import DataGrid from 'components/DataGrid'

type CategoryPreferencesDataGridProps = {
  title?: string
  isLoading?: boolean
  categories: Category[]
} & Partial<DataGridProps>

export default function CategoryPreferencesDataGrid({
  isLoading = false,
  categories,
  ...rest
}: CategoryPreferencesDataGridProps) {
  return (
    <DataGrid
      {...rest}
      autoHeight
      disableColumnMenu
      rowsPerPageOptions={[]}
      rows={categories}
      columns={categoryColumns}
      rowCount={categories.length}
      loading={isLoading}
    />
  )
}

const categoryColumns: GridColDef[] = [
  {
    headerName: 'ID',
    field: 'id',
    flex: 0,
  },
  {
    headerName: 'Name',
    field: 'displayName',
    flex: 1,
    renderCell: ({ row, value }: GridCellParams) => <Link to={`/categories/${row.id}`}>{value}</Link>,
  },
]
