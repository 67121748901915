import { useRef, useLayoutEffect, useCallback } from 'react'

interface Dispatch<A> {
  (action: A): void
}

export default function useSafeDispatch<A>(dispatch: Dispatch<A>) {
  const mounted = useRef(false)
  useLayoutEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])
  return useCallback((action: A) => (mounted.current ? dispatch(action) : void 0), [dispatch])
}
