import Dinero from 'dinero.js'

import { Nullable } from 'types'
import { Currencies, Currency } from 'types'

type MaybeCents = Nullable<number | string>
type MaybeCurrency = Nullable<Currency>

export default function centsToCurrency(cents: MaybeCents, currency: MaybeCurrency): string {
  const code = currency?.code ?? Currencies.USD
  return Dinero({ amount: centsToNumber(cents), currency: code as Dinero.Currency }).toFormat('$0,0.00')
}

export function centsToUnit(cents: MaybeCents): string {
  const unit = Dinero({ amount: centsToNumber(cents) }).toUnit()
  return unit === 0 ? '' : unit.toString()
}

function centsToNumber(cents: MaybeCents): number {
  const amount = typeof cents === 'number' ? cents : Number.parseInt(cents ?? '0')
  if (Number.isNaN(amount)) {
    return 0
  }
  if (!Number.isInteger(amount)) {
    return Math.floor(amount)
  }
  return amount
}
