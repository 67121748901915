const overwrite = (item, currentValue) => item

//
// Given an array of objects, returns an object of objects
// indexed by the given field.
//
// eg: indexBy('id', [{id: 'a', value: 'A'}])
// returns { 'a': {id: 'a' value: 'A'} }
//
export default function indexBy(field, array, predicate = overwrite) {
  return array.reduce((acc, item) => {
    acc[item[field]] = predicate(item, acc[item[field]])
    return acc
  }, {})
}
