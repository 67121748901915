import { get } from 'lodash'

import { GridCellParams, GridColDef } from '@material-ui/data-grid'
import { Paper, Typography } from '@material-ui/core'

import DataGrid, { UserCell, MoneyCell, RelativeDateCell } from 'components/DataGrid'
import { Chip } from '@material-ui/core'
import useOrders from 'hooks/useOrders'
import useQueryParam from 'hooks/useQueryParam'
import usePageTitle from 'hooks/usePageTitle'
import useIndexStyle from 'hooks/useIndexStyle'
import Link from 'components/Link'
import DebouncedTextField from 'components/DebouncedTextField'
import SelectField from 'components/SelectField'

import { Currencies, getCountry } from 'types'
import { countryOptions } from 'util/geography'

const pageSize = 20

export default function Orders() {
  const css = useIndexStyle()
  usePageTitle('Orders')

  const [query, setQuery]: any = useQueryParam({
    key: 'query',
    defaultValue: '',
  })

  const handleQueryChange = (evt: any) => {
    setQuery(evt.target.value)
  }

  const [aasmState, setAasmState] = useQueryParam({
    key: 'aasm',
    defaultValue: aasmStateOptions[0],
  })
  const [paymentTerms, setPaymentTerms] = useQueryParam({
    key: 'paymentTerms',
    defaultValue: paymentTermsOptions[0],
  })
  const [chargeState, setChargeState] = useQueryParam({
    key: 'chargeState',
    defaultValue: chargeStateOptions[0],
  })
  const [oosw, setOosw] = useQueryParam({
    key: 'oosw',
    defaultValue: ooswOptions[0],
  })
  const [dropship, setDropship] = useQueryParam({
    key: 'dropship',
    defaultValue: dropshipOptions[0],
  })
  const [selectedCountry, setSelectedCountry] = useQueryParam({
    key: 'country',
    defaultValue: countryOptions[0].value,
    parse: (value: string) => (value ? parseInt(value) : 'all'),
    stringify: (value) => (value !== 'all' ? value : null),
  })

  const { orders, total, isLoading, handlePageChange } = useOrders({
    pageSize,
    query,
    aasmState: aasmState !== 'all' ? aasmState : undefined,
    paymentTerms: paymentTerms !== 'all' ? paymentTerms : undefined,
    chargeState: chargeState !== 'all' ? chargeState : undefined,
    oosw: oosw !== 'all' ? String(oosw === 'yes') : undefined,
    dropship: dropship !== 'all' ? String(dropship === 'yes') : undefined,
    country: selectedCountry !== 'all' ? selectedCountry : undefined,
  })

  return (
    <div>
      <h1 style={{ marginTop: 0 }}>Orders</h1>
      <Paper variant="outlined" style={{ padding: 16, marginBottom: 24, display: 'flex', flexWrap: 'wrap' }}>
        <DebouncedTextField
          className={css.searchField}
          label="Search by brand/retailer email"
          value={query}
          onChange={handleQueryChange}
        />
        <SelectField
          className={css.formControl}
          label="Country"
          value={selectedCountry}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedCountry(event.target.value)}
          options={[{ label: 'All', value: 'all' }, ...countryOptions]}
        />
        <SelectField
          className={css.formControl}
          label="Order state"
          value={aasmState}
          onChange={(event: any) => setAasmState(event?.target.value)}
          options={aasmStateOptions}
        />
        <SelectField
          className={css.formControl}
          label="Payment terms"
          value={paymentTerms}
          onChange={(event) => setPaymentTerms(event?.target.value)}
          options={paymentTermsOptions}
        />
        <SelectField
          className={css.formControl}
          label="Charge state"
          value={chargeState}
          onChange={(event) => setChargeState(event?.target.value)}
          options={chargeStateOptions}
        />
        <SelectField
          className={css.formControl}
          label="OOSW"
          value={oosw}
          onChange={(event) => setOosw(event?.target.value)}
          options={ooswOptions}
        />
        <SelectField
          className={css.formControl}
          label="Dropship"
          value={dropship}
          onChange={(event) => setDropship(event?.target.value)}
          options={dropshipOptions}
        />
      </Paper>
      <Paper elevation={0}>
        <DataGrid
          autoHeight={true}
          rows={orders}
          columns={ordersColumns}
          sortingMode="server"
          paginationMode="server"
          loading={isLoading}
          rowCount={total}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </Paper>
    </div>
  )
}

const aasmStateOptions = [
  'all',
  'pending_retailer_verification',
  'pending_acceptance',
  'accepted',
  'canceled',
  'shipped',
  'hand_delivered',
  'delivered',
  'completed',
  'canceled_retailer_unverified',
  'pending_shipment',
]

const paymentTermsOptions = ['all', 'acceptance', 'net60']

const chargeStateOptions = ['all', 'pending', 'fully_paid', 'past_due', 'defaulted', 'on_paymen_plan', 'canceled']

const ooswOptions = ['all', 'yes', 'no']

const dropshipOptions = ['all', 'yes', 'no']

const ordersColumns = [
  {
    headerName: 'Order #',
    field: 'uid',
    width: 120,
    renderCell: (params: GridCellParams) => <Link to={`/orders/${params.id}`}>{params.row.uid}</Link>,
  },
  {
    headerName: 'Order State',
    field: 'aasmState',
    width: 200,
    type: 'date',
  },
  {
    headerName: 'Retailer',
    field: 'retailerProfile',
    flex: 1,
    renderCell: ({ value }: any) => (
      <UserCell user={value?.user} linkTo={{ href: `/retailers/${value?.id}`, text: value?.user?.fullName }} />
    ),
  },
  {
    headerName: 'Brand',
    field: 'brandProfile',
    flex: 1,
    renderCell: ({ value }: any) => (
      <UserCell user={value?.user} linkTo={{ href: `/brands/${value?.id}`, text: value?.companyName }} />
    ),
  },
  {
    headerName: 'OOSW',
    field: 'oosw',
    width: 100,
    renderCell: ({ value }: any) => (value ? <Chip label="YES" size="small" color="secondary" /> : 'No'),
  },
  {
    headerName: 'Dropship',
    field: 'dropship',
    width: 100,
    renderCell: ({ value }: any) => (value ? <Chip label="YES" size="small" color="secondary" /> : 'No'),
  },
  {
    headerName: 'Created At',
    field: 'createdAt',
    flex: 1,
    type: 'date',
    renderCell: ({ value }: any) => <RelativeDateCell isoDateTime={value} />,
  },
  {
    headerName: 'Payment Terms',
    field: 'charge.paymentTerms',
    width: 120,
  },
  {
    headerName: 'Charge Details',
    field: 'charge',
    flex: 1,
    renderCell: ({ row }: any) => (
      <MoneyCell
        amount={row.subtotalInCents ?? 0}
        currency={getCountry(get(row, 'brandProfile.user.country.name'))?.currency ?? Currencies.USD}
      >
        <Typography variant="caption">{row.charge.aasmState}</Typography>
      </MoneyCell>
    ),
  },
] as GridColDef[]
