import clsx from 'clsx'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import parseDateTime from 'util/parseDateTime'

export interface DefinitionProps {
  label: string
  format?: 'boolean' | 'date' | 'datetime' | null
  fontLarge?: boolean
  className?: string
  children?: any
  style?: object
}

export default function Definition({ label, children, className, format, fontLarge, ...rest }: DefinitionProps) {
  const css = useStyles()
  let content = children

  if (content == null || content === '') {
    content = '—'
  } else if (format === 'boolean') {
    content = children ? 'YES' : 'NO'
  } else if (format === 'date' && parseDateTime(children)) {
    content = parseDateTime(children)?.toFormat('DD')
  } else if (format === 'datetime' && parseDateTime(children)) {
    content = parseDateTime(children)?.toFormat('ff')
  }

  return (
    <div className={clsx(css.definition, className)} {...rest}>
      <div className={css.label}>{label}</div>
      <div className={clsx({ [css.fontLarge]: fontLarge })}>{content}</div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      textTransform: 'uppercase',
      letterSpacing: 1.2,
      color: theme.palette.text.secondary,
      marginBottom: 4,
    },
    definition: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2.5),
      },
    },
    fontLarge: {
      fontSize: 20,
    },
  })
)
