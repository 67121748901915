import { debounce } from 'lodash'
import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'

type DebouncedTextFieldProps = TextFieldProps & {
  /** Debounce delay */
  delay?: number
  value: string
  onChange: (event: React.ChangeEvent, value: any) => void
  /**
    Type of input component to render.
    Must accept `value` and `onChange: (evt, value) => {}` props.
  */
  InputComponent?: any
}

/**
A wrapper component for debouncing `onChange` invocations from any component
that accepts `value` and `onChange ((evt, value) => {})` props.

Use this to prevent lots of renders / DOM-thrashing.
*/
const DebouncedTextField: React.FC<DebouncedTextFieldProps> = ({
  delay = 400,
  value,
  onChange,
  InputComponent = TextField,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const debouncedOnChange = useMemo(() => debounce(onChange, delay), [delay, onChange])

  const handleChange = useCallback(
    (evt) => {
      setInputValue(evt.target.value)
      debouncedOnChange(evt, evt.target.value)
    },
    [debouncedOnChange]
  )

  return <InputComponent value={inputValue} onChange={handleChange} {...rest} />
}

export default DebouncedTextField
