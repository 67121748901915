import { GridCellParams } from '@material-ui/data-grid'
import Link from 'components/Link'

export const productTypesColumns = [
  {
    headerName: 'Name',
    field: 'name',
    flex: 1,
    renderCell: (params: GridCellParams) => <Link to={`/product_types/${params.id}`}>{params.row.name}</Link>,
  },
  {
    headerName: 'Created Timestamp',
    field: 'createdAt',
    flex: 1,
  },
  {
    headerName: 'Deleted Timestamp',
    field: 'deletedAt',
    type: 'date',
    width: 150,
  },
]
