import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@material-ui/core'
import normalizeOptions, { Option } from 'util/normalizeOptions'

export type CheckGroupProps = {
  options: Option[] | string[]
  label?: string
  value: string[]
  onChange: (evt: any) => void
  className?: string
  helperText?: string
}

const CheckGroup: React.FC<CheckGroupProps> = ({ className, helperText, label, onChange, options, value, ...rest }) => {
  const css = useStyles()
  const normalizedOptions = normalizeOptions(options)
  const valueSet = new Set(value)

  const handleAdd = (optionValue: string) => () => {
    onChange({ target: { value: [...value, optionValue] } })
  }

  const handleRemove = (optionValue: string) => () => {
    onChange({ target: { value: value.filter((v) => v !== optionValue) } })
  }

  return (
    <FormControl component="fieldset" className={clsx(css.checkGroup, className)} {...rest}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup>
        {normalizedOptions.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                name={option.value}
                checked={valueSet.has(option.value)}
                onChange={valueSet.has(option.value) ? handleRemove(option.value) : handleAdd(option.value)}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    checkGroup: {},
  })
)

export default CheckGroup
