import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ErrorResponse } from 'types/errorResponse'
import { Grid, Checkbox } from '@material-ui/core'
import { TextField, Typography, FormControlLabel } from '@material-ui/core'
import { TokenResponse } from 'types/tokenResponse'
import { useAbility } from 'hooks/useAbility'
import { useAlert } from 'context/Alert/AlertContext'
import { useFormik } from 'formik'
import * as yup from 'yup'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Button from 'components/Button'
import useInitialPath from 'hooks/useInitialPath'
import useToken from 'hooks/useToken'
import useVerification from 'hooks/useVerification'

type Props = {
  setVerificationMethod: (verificationMethod: string) => void
  verificationMethod: string
}

const formSchema = yup.object({
  verificationCode: yup
    .string()
    .required('')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .length(6, 'Must be exactly 6 digits'),
  remember: yup.boolean().default(true),
})

const CodeInput = ({ verificationMethod, setVerificationMethod }: Props) => {
  const { redirectToInitialPath } = useInitialPath()
  const { setToken } = useToken()
  const ability = useAbility()
  const css = useStyles()
  const snackbar = useAlert()

  const { handleVerification } = useVerification()

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      verificationCode: '',
      remember: true,
    },
    onSubmit: ({ verificationCode, remember }) => {
      return handleVerification({ verificationMethod, verificationCode, remember })
        .then((response: TokenResponse) => {
          setToken(response.data)
          ability.update(response.data.rules || [])
          redirectToInitialPath()
        })
        .catch((error: ErrorResponse) => {
          if (!error.response || error.response.status >= 500) {
            snackbar.error('Something went wrong on the backend. Please contact support.')
          } else {
            snackbar.error('Expired or incorrect code. Please try again.', 8000)
          }
        })
    },
    validationSchema: formSchema,
  })

  return (
    <>
      <Button className={css.backLink} onClick={() => setVerificationMethod('')} disableRipple>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <ArrowBackIcon className={css.backIcon} />
          </Grid>
          <Grid item>Choose a different verification method</Grid>
        </Grid>
      </Button>

      <Typography className={css.introText}>
        {verificationMethod === 'email'
          ? 'Please check your Abound email for a verification code. Note that it may take a few minutes to reach your inbox.'
          : 'Please use your authenticator app of choice to obtain a verification code.'}
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <TextField
          id="verificationCode"
          label="Six digit code"
          className={css.pad}
          fullWidth
          inputProps={{ required: true }}
          placeholder=""
          onChange={(v) => {
            formik.setFieldValue('verificationCode', v.target.value.replace(/[^\d]/, '').slice(0, 6))
          }}
          onBlur={formik.handleBlur}
          value={formik.values.verificationCode}
          error={formik.errors.verificationCode !== undefined && formik.values.verificationCode.length > 5}
        />
        <FormControlLabel
          label="Remember me"
          control={
            <Checkbox
              checked={formik.getFieldProps('remember').value}
              onChange={() => formik.setFieldValue('remember', !formik.getFieldProps('remember').value)}
            />
          }
        />
        <Button
          type="submit"
          size="large"
          fullWidth
          className={css.pad}
          primary
          autoFocus
          disabled={!formik.dirty || !formik.isValid}
          busy={formik.isSubmitting}
        >
          Verify
        </Button>
      </form>
      {verificationMethod === 'authenticator' && (
        <Typography className={css.tip}>
          Tip: If you have not yet set up Administry to work with your authenticator app, use email verification for now
          and then, once authenticated, visit your profile to configure your authenticator app.
        </Typography>
      )}
    </>
  )
}

export default CodeInput

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pad: {
      margin: theme.spacing(1),
      marginLeft: 0,
    },
    backLink: {
      background: 'transparent',
      border: 0,
      filter: 'grayscale(40%)',
      margin: 0,
      minWidth: 0,
      opacity: 0.9,
      padding: 0,
      textTransform: 'none',
      '&:hover, &:focus, &:active': {
        opacity: 1,
        filter: 'grayscale(0%)',
        border: 0,
        background: 'transparent',
      },
    },
    introText: {
      color: '#666',
      fontSize: '0.95rem',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    backIcon: {
      fontSize: '1.2rem',
      marginRight: '3px',
      marginTop: '6px',
      opacity: '0.7',
    },
    tip: {
      color: '#888',
      fontSize: '0.8rem',
    },
  })
)
