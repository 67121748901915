import { Fragment, useState } from 'react'

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  LinearProgress,
} from '@material-ui/core'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import useProductImport from 'hooks/useProductImport'

export type ProductImportTableProps = {
  brandId: number
  importIds: number[]
}

export const ProductImportTable = ({ brandId, importIds }: ProductImportTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ID</TableCell>
            <TableCell align="right">State</TableCell>
            <TableCell align="right">Started at</TableCell>
            <TableCell align="right">Finished at</TableCell>
            <TableCell align="right">Products validated</TableCell>
            <TableCell align="right">Products saved</TableCell>
            <TableCell align="right">Uploaded by</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {importIds.map((id) => (
            <ProductImportRow key={id} brandId={brandId} importId={id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const ProductImportRow = (props: { brandId: number; importId: number }) => {
  const { brandId, importId } = props

  const [open, setOpen] = useState(false)
  const row = useProductImport({ brandId, importId })

  if (!row) return null

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            disabled={!row.validationErrors || row.validationErrors.length === 0}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="right">{row.state ?? '-'}</TableCell>
        <TableCell align="right">{row.startedAt ?? '-'}</TableCell>
        <TableCell align="right">{row.finishedAt ?? '-'}</TableCell>
        <TableCell align="right">{row.productsValidated ?? '-'}</TableCell>
        <TableCell align="right">{row.productsSaved ?? '-'}</TableCell>
        <TableCell align="right">{row.uploadedByEmail ?? '-'}</TableCell>
      </TableRow>

      {row.inProgress && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <LinearProgress />
          </TableCell>
        </TableRow>
      )}

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="subtitle1" gutterBottom component="div">
                Validation Errors
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Rows</TableCell>
                    <TableCell>Row</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Errors</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(row.validationErrors || []).map((errs, i) => {
                    const { startRow, endRow, row, type, ...rest } = errs
                    return (
                      <TableRow key={`${row}_i`}>
                        <TableCell component="th" scope="row">
                          {`${startRow} - ${endRow}`}
                        </TableCell>
                        <TableCell>{row ? row : '-'}</TableCell>
                        <TableCell>{type}</TableCell>
                        <TableCell>{JSON.stringify(rest, null, '\t')}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}
