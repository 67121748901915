import clsx from 'clsx'
import { useState } from 'react'
import { Collapse, IconButton, Paper } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ExpandMore } from '@material-ui/icons'

interface PanelProps {
  title?: string
  className?: string
  gray?: boolean
  collapsible?: boolean
  startCollapsed?: boolean
  onToggle?: (open: boolean) => void
  contentRight?: React.ReactNode
  style?: object
  testName?: string
}

const Panel: React.FC<PanelProps> = ({
  title = null,
  children,
  className = null,
  gray = false,
  collapsible = false,
  startCollapsed = false,
  onToggle,
  contentRight,
  style,
  testName = 'panel',
  ...rest
}) => {
  const css = useStyles()
  const [isOpen, setIsOpen]: any = useState(!startCollapsed)
  const toggleOpen = () => {
    setIsOpen((open: boolean) => {
      if (onToggle) onToggle(!open)
      return !open
    })
  }

  return (
    <Paper
      data-test={testName}
      variant="outlined"
      className={clsx(css.panel, className, {
        [css.gray]: !!gray,
      })}
      style={style}
      {...rest}
    >
      {(title || collapsible) && (
        <header className={css.header}>
          <h2>{title}</h2>
          <div>
            {contentRight}
            {collapsible && (
              <IconButton aria-label="toggle" onClick={toggleOpen} className={css.iconButton}>
                <ExpandMore className={clsx(css.icon, { [css.isOpen]: isOpen })} />
              </IconButton>
            )}
          </div>
        </header>
      )}
      <Collapse in={!collapsible || isOpen} unmountOnExit>
        {children}
      </Collapse>
    </Paper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    panel: {
      padding: 24,
      '& + &': {
        marginTop: 16,
      },
    },
    header: {
      display: 'flex',
      margin: '0 0 16px',
      '& > h2': {
        flex: '1 1 auto',
        margin: '0',
      },
    },
    gray: {
      backgroundColor: '#E5E5E5',
    },
    iconButton: {
      margin: '-12px -12px -12px 4px',
    },
    icon: {
      transition: 'transform 0.15s ease-in-out',
    },
    isOpen: {
      transform: 'scaleY(-1)',
    },
  })
)

export default Panel
