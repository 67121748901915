import { useAlert } from 'context/Alert/AlertContext'
import useAction from 'hooks/useAction'
import { useEffect, useState } from 'react'
import { api } from 'services/api.service'
import { PaymentPlan } from 'types/paymentPlan'

export default function usePaymentPlans({ retailerId }: { retailerId: number }) {
  const snackbar = useAlert()
  const [paymentPlans, setPaymentPlans] = useState<PaymentPlan[]>([])

  const [fetchPaymentPlans, isLoading] = useAction({
    request: () => api.get(`/retailers/${retailerId}/payment_plans`),
    onSuccess: (resp) => {
      setPaymentPlans(resp.data.data)
    },
    onError: () => snackbar.error(`Error fetching payment plans`),
  })

  const [createPaymentPlan] = useAction({
    request: (paymentPlan) =>
      api.post(`/retailers/${retailerId}/payment_plans`, { paymentPlan: { ...paymentPlan, retailerId: retailerId } }),
    onSuccess: () => {
      fetchPaymentPlans()
    },
    onError: () => snackbar.error(`Error creating payment plans`),
  })

  const [updatePaymentPlan] = useAction({
    request: (id, field, value) =>
      api.put(`/retailers/${retailerId}/payment_plans/${id}`, { paymentPlan: { [field]: value } }),
    onSuccess: () => {
      fetchPaymentPlans()
    },
    onError: () => snackbar.error(`Error updating payment plans`),
  })

  const [updatePayment] = useAction({
    request: (id, field, value) => api.put(`/retailers/${retailerId}/payments/${id}`, { payment: { [field]: value } }),
    onSuccess: () => {
      fetchPaymentPlans()
    },
    onError: () => snackbar.error(`Error updating payment plans`),
  })

  useEffect(() => {
    fetchPaymentPlans()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailerId])

  return {
    paymentPlansIsLoading: isLoading,
    paymentPlans,
    createPaymentPlan,
    updatePaymentPlan,
    updatePayment,
  }
}
