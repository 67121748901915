import React, { createContext } from 'react'
import ability from 'services/ability'

const AbilityContext = createContext<typeof ability>((null as unknown) as typeof ability)

const AbilityProvider = ({ children }: { children: React.ReactNode }) => {
  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
}

export { AbilityProvider, AbilityContext }
