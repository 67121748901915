import { useCallback, useEffect, useState } from 'react'
import { set } from 'lodash'

import { api } from 'services/api.service'
import { useAlert } from 'context/Alert/AlertContext'

import { Charge, emptyCharge, Event } from 'types'
import useAction from 'hooks/useAction'
import usePagination from 'hooks/usePagination'

interface OrderParams {
  id: string | number
}

type Credit = {
  type: string
  amountInCents: number
  expiresAt: string
}

export default function useOrder({ id }: OrderParams) {
  const [order, setOrder] = useState<any>({})
  const [lineItems, setLineItems] = useState<any[]>([])
  const [shipments, setShipments] = useState<any[]>([])
  const [brand, setBrand] = useState<any>({})
  const [retailer, setRetailer] = useState<any>({})
  const [charge, setCharge] = useState<Charge>(emptyCharge)
  const [events, setEvents] = useState<Event[]>([])
  const [credits, setCredits]: [any[], Function] = useState([])
  const snackbar = useAlert()

  const handleResponse = useCallback((resp: any) => {
    const { lineItems, brandProfile, retailerProfile, shippingAddress, charge, events, shipments, ...order } = resp.data
    setOrder(order)
    setLineItems(lineItems)
    setBrand(brandProfile)
    setShipments(shipments)
    setRetailer({ ...retailerProfile, shippingAddress })
    setCharge(charge)
    setEvents(events)
  }, [])

  const getErrorHandler = (message: string) => (err: any) => {
    snackbar.error(err.response?.data?.error || err.message || message)
  }

  const [fetchData, isLoading] = useAction({
    request: () => api.get(`/orders/${id}`),
    onSuccess: handleResponse,
    onError: getErrorHandler('Error fetching order'),
  })

  const [saveField] = useAction({
    request: (keyPath: string, value: any) => api.patch(`orders/${id}`, { order: set({}, keyPath, value) }),
    onSuccess: handleResponse,
    onError: getErrorHandler('Error saving order field.'),
  })

  const [reinstateLineItem] = useAction({
    request: (lineItem) => api.patch(`orders/${id}/reinstate_line_item`, { lineItem }),
    onSuccess: handleResponse,
    onError: getErrorHandler('Error reinstating line item.'),
  })

  const [saveFields, isSavingFields] = useAction({
    request: (orderItemsToUpdate) => api.patch(`orders/${id}`, { order: orderItemsToUpdate }),
    onSuccess: handleResponse,
    onError: getErrorHandler('Error saving order fields.'),
  })

  const [createShipment] = useAction({
    request: (shipment: any) => api.post(`orders/${id}/shipments`, { shipment }),
    onSuccess: (resp) => setShipments((shipments: any) => [...shipments, resp.data]),
    onError: getErrorHandler('Error creating shipment.'),
  })

  const [updateShipment] = useAction({
    request: ({ id: shipmentId, ...shipment }: any) => api.patch(`orders/${id}/shipments/${shipmentId}`, { shipment }),
    onSuccess: (resp, reqArgs) => {
      setShipments((shipments: any) =>
        shipments.map((shipment: any) => (shipment.id === reqArgs[0].id ? resp.data : shipment))
      )
    },
    onError: getErrorHandler('Error updating shipment.'),
  })

  const [updateShipmentState] = useAction({
    request: ({ id: shipmentId }: any, state: string) => api.patch(`orders/${id}/shipments/${shipmentId}/${state}`),
    onSuccess: (resp, reqArgs) => {
      setShipments((shipments: any) =>
        shipments.map((shipment: any) => (shipment.id === reqArgs[0].id ? resp.data : shipment))
      )
    },
    onError: getErrorHandler('Error setting shipment state.'),
  })

  const [deleteShipment] = useAction({
    request: ({ id: shipmentId }: any) => api.delete(`orders/${id}/shipments/${shipmentId}`),
    onSuccess: (resp, reqArgs) => {
      setShipments((shipments: any) => shipments.filter((shipment: any) => shipment.id !== reqArgs[0].id))
    },
    onError: getErrorHandler('Error deleting shipment'),
  })

  const [cancelOrder] = useAction({
    request: () => api.patch(`orders/${id}/cancel`, {}),
    onSuccess: handleResponse,
    onError: (err) => snackbar.error('Error cancelling order'),
  })

  const [cancelCharge] = useAction({
    request: () => api.patch(`orders/${id}/cancel_charge`, {}),
    onSuccess: handleResponse,
    onError: getErrorHandler('Error cancelling order charge'),
  })

  const [refreshCalculatedTotal] = useAction({
    request: () => api.patch(`orders/${id}/refresh_calculated_total`, {}),
    onSuccess: handleResponse,
    onError: getErrorHandler('Error cancelling order charge'),
  })

  const [addProductToOrder] = useAction({
    request: (productId, quantity) =>
      api.post(`orders/${id}/add_product`, { product: { product_id: productId, quantity: quantity } }),
    onSuccess: handleResponse,
    onError: getErrorHandler('Error cancelling order charge'),
  })

  const [createRefund] = useAction({
    request: (amountInCents: number, reason: string) =>
      api.post(`orders/${id}/refund`, { order: { refund: { amountInCents, reason } } }),
    onSuccess: handleResponse,
    onError: getErrorHandler('Error creating refund'),
  })

  const [createTransfer] = useAction({
    request: (transfer: any) => api.post(`orders/${id}/transfer`, { scheduledAt: transfer.scheduledAt }),
    onSuccess: handleResponse,
    onError: getErrorHandler('Error creating transfer'),
  })

  const [processTransfer] = useAction({
    request: (transfer: any) => api.patch(`orders/${id}/process_transfer`),
    onSuccess: handleResponse,
    onError: getErrorHandler('Error creating transfer'),
  })

  const [reinstateOrder] = useAction({
    request: (state: 'accepted' | 'pending_acceptance') => api.patch(`orders/${id}/reinstate`, { state }),
    onSuccess: (resp) => {
      snackbar.success('Order Reinstated')
      handleResponse(resp)
    },
    onError: getErrorHandler('Failed to reinstate order'),
  })

  const [disconnectShopify, isDisconnectingShopify] = useAction({
    request: () => api.patch(`orders/${id}/disconnect_shopify`),
    onSuccess: handleResponse,
    onError: getErrorHandler('Failed to disconnect Shopify'),
  })

  const [saveAasmState] = useAction({
    request: (state: any) => api.patch(`orders/${id}/transition`, { state }),
    onSuccess: handleResponse,
    onError: getErrorHandler('Failed to update order state'),
  })

  const [saveChargeAasmState] = useAction({
    request: (state: any) => api.patch(`orders/${id}/transition_charge`, { state }),
    onSuccess: handleResponse,
    onError: getErrorHandler('Failed to update order charge state'),
  })

  const {
    pagination: creditsPagination,
    setPagination: setCreditsPagination,
    paginationQueryParams: creditPaginationQueryParams,
  } = usePagination({ perPage: 3 })

  const [fetchCredits, isCreditsLoading] = useAction({
    request: () =>
      api.get(`/orders/${id}/credits`, { params: { order_by: 'createdAt', ...creditPaginationQueryParams } }),
    onSuccess: (resp) => {
      setCredits(resp.data.data)
      setCreditsPagination(resp.data.meta)
    },
    onError: () => snackbar.error('Error fetching order credits'),
  })

  const [createCredit, isCreatingCredit] = useAction({
    request: (credit: Credit) => api.post(`orders/${id}/credits`, { credit }),
    onSuccess: (resp) => {
      creditsPagination.goToPage(1, { forceReload: true })
      snackbar.success('Credit added')
    },
    onError: () => snackbar.error('Error adding credit'),
  })

  const [updateCredit] = useAction({
    request: (creditId: string, field: string, value: any) =>
      api.patch(`orders/${id}/credits/${creditId}`, { credit: set({}, field, value) }),
    onSuccess: (resp, reqArgs) => {
      const updatedCredits = credits.map((credit) => (credit.id === reqArgs[0] ? resp.data : credit))
      setCredits(updatedCredits)
      snackbar.success('Credit updated')
    },
  })

  const [deleteCredit] = useAction({
    request: (creditId: string) => api.delete(`orders/${id}/credits/${creditId}`),
    onSuccess: (resp, reqArgs) => {
      let updatedCredits: any[] = credits.filter((credit) => credit.id !== reqArgs[0])
      const { currentPage } = creditsPagination
      creditsPagination.goToPage(updatedCredits.length > 0 ? currentPage : currentPage - 1, { forceReload: true })
      snackbar.success('Credit deleted')
    },
  })

  useEffect(() => {
    id && fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, creditPaginationQueryParams])

  useEffect(() => {
    id && fetchCredits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, creditPaginationQueryParams])

  return {
    brand,
    cancelCharge,
    cancelOrder,
    charge,
    createCredit,
    createRefund,
    createShipment,
    createTransfer,
    credits,
    creditsPagination,
    deleteCredit,
    deleteShipment,
    disconnectShopify,
    events,
    fetchCredits,
    isCreatingCredit,
    isCreditsLoading,
    isDisconnectingShopify,
    isLoading,
    isSavingFields,
    lineItems,
    order,
    processTransfer,
    refreshCalculatedTotal,
    addProductToOrder,
    reinstateLineItem,
    reinstateOrder,
    retailer,
    saveAasmState,
    saveChargeAasmState,
    saveField,
    saveFields,
    shipments,
    updateCredit,
    updateShipment,
    updateShipmentState,
  }
}
