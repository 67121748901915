import NumberFormat from 'react-number-format'

import { useCurrencyContext } from 'context/Country/CountryContext'

interface MoneyInputProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const MoneyInput: React.FC<MoneyInputProps> = (props) => {
  const currency = useCurrencyContext()

  const { inputRef, onChange, ...rest } = props

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator={currency.thousand}
      decimalScale={currency.fraction}
      isNumericString
      prefix={currency.grapheme}
    />
  )
}

export default MoneyInput
