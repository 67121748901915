import Timeline from '@material-ui/lab/Timeline'
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@material-ui/lab'
import { Typography } from '@material-ui/core'

import { startCase } from 'lodash'
import parseDateTime from 'util/parseDateTime'

import Panel from 'components/Panel'

import { Event } from 'types'

export default function Returns({ events }: { events: Event[] }) {
  return (
    <>
      <Panel title="Timeline">
        <Timeline>
          {events.map((event: Event, idx: number) => (
            <TimelineItem key={event.id}>
              <TimelineOppositeContent>
                <Typography color="textSecondary">{parseDateTime(event.createdAt)?.toFormat('ff')}</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot variant="outlined" />
                {idx < events.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>{startCase(event.action)}</TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Panel>
    </>
  )
}
