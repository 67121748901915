import useQueryParam from 'hooks/useQueryParam'
import BrandAutocomplete from 'components/BrandAutocomplete'
import useProducts from 'hooks/useProducts'
import DataGrid from 'components/DataGrid'
import { Checkbox } from '@material-ui/core'
import Button from 'components/Button'
import { useState } from 'react'

import { LiveShoppingHighlight } from 'types/live_shopping_highlight'

type FormProps = {
  liveShoppingHighlight: LiveShoppingHighlight
  formik: any
  onRemove: (brandKey: number) => void
  brandKey: number
  productIds: number[]
  presetBrand: any
  updateBrandList: (updatedBrandList: any) => void
}

export default function LiveShoppingHighlightBrand({
  liveShoppingHighlight,
  formik,
  onRemove,
  brandKey,
  productIds,
  presetBrand,
  updateBrandList,
}: FormProps) {
  const [brand, setBrand] = useQueryParam({
    key: 'brand' + brandKey,
    defaultValue: presetBrand || undefined,
    parse: (value: string) => (value ? { id: parseInt(value), companyName: '' } : undefined),
    stringify: (brand: any) => (brand ? '' + brand?.id : ''),
  })

  const [brandProductIds, setBrandProductIds] = useState<number[]>(productIds)

  const setupBrand = (newBrand: any) => {
    if (newBrand) {
      const priorBrand = brand
      setBrand(newBrand)
      const newBrandsList = { [newBrand.id]: [] }
      Object.keys(formik.values.brands).forEach((id: any) => {
        if (parseInt(id) === priorBrand.id) return
        newBrandsList[parseInt(id)] = formik.values.brands[id]
      })
      formik.setFieldValue('brands', newBrandsList)
    }
  }

  const removeBrand = () => {
    let currentBrands = { ...formik.values.brands }
    delete currentBrands[brand.id]
    formik.setFieldValue('brands', currentBrands)

    onRemove(brandKey)
  }

  const pageSize = 10

  const toggleProductEvt = (id: number) => {
    const currentBrands = { ...formik.values.brands }
    let currentBrandProductList = currentBrands[brand.id]

    const index = currentBrandProductList.indexOf(id)

    if (index > -1) {
      currentBrandProductList.splice(index, 1)
    } else {
      currentBrandProductList = [...currentBrandProductList, id]
    }

    currentBrands[brand.id] = currentBrandProductList
    setBrandProductIds(currentBrandProductList)
    formik.setFieldValue('brands', currentBrands)

    updateBrandList(currentBrands)
  }

  const { products, handlePageChange, productsCount } = useProducts({
    pageSize,
    tagging: undefined,
    visibility: true,
    brandId: brand?.id,
    dynamicCancelKey: true,
  })

  const productColumns = [
    {
      headerName: 'ID',
      field: 'id',
      width: 100,
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 500,
    },
    {
      headerName: 'Include',
      width: 200,
      field: '',
      renderCell: ({ row }: any) => (
        <Checkbox checked={brandProductIds.includes(row.id)} onChange={(e: any) => toggleProductEvt(row.id)} />
      ),
    },
  ]

  return (
    <>
      <div>
        Brand:
        <BrandAutocomplete
          style={{ display: 'inline-block', minWidth: 250 }}
          value={brand}
          onChange={(evt: any, brand: any) => setupBrand(brand)}
        />
        <Button onClick={() => removeBrand()}>Remove brand</Button>
        <DataGrid
          rowHeight={100}
          rows={products}
          columns={productColumns}
          sortingMode="server"
          paginationMode="server"
          pageSize={pageSize}
          rowCount={productsCount}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  )
}
