import { useState } from 'react'
import { useFormik } from 'formik'
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Button from 'components/Button'

const DateFormat = 'yyyy-MM-dd'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: (transfer: any) => Promise<Error | null>
}

export default function AddTransferDialog({ open, onClose, onSubmit }: Props) {
  const [err, setErr] = useState<string | null>(null)

  const handleClose = () => {
    formik.resetForm()
    setErr(null)
    onClose()
  }

  const formik = useFormik({
    initialValues: {
      scheduledAt: null,
    },
    onSubmit: (transfer) => {
      onSubmit({
        scheduledAt: transfer.scheduledAt,
      })
        .then(() => {
          formik.resetForm()
          setErr(null)
          onClose()
        })
        .catch((err: any) => {
          setErr(err?.response?.data?.error || err?.message || 'Unable to Create a Credit')
        })
    },
  })

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">Create Transfer</DialogTitle>
      <Divider />
      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth component="fieldset">
          <DialogContent style={{ width: 400 }}>
            {err && <FormHelperText error>{err}</FormHelperText>}

            <KeyboardDatePicker
              fullWidth
              label="Scheduled At:"
              margin="normal"
              disableToolbar
              format={DateFormat}
              {...formik.getFieldProps('scheduledAt')}
              onChange={(val: any) => formik.setFieldValue('scheduledAt', val)}
            />

            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button primary type="submit" busy={formik.isSubmitting}>
                Create
              </Button>
            </DialogActions>
          </DialogContent>
        </FormControl>
      </form>
    </Dialog>
  )
}
