import { useCallback, useEffect, useState } from 'react'
import { GridPageChangeParams } from '@material-ui/data-grid'

import { api, cancelRequest } from 'services/api.service'
import { useAlert } from 'context/Alert/AlertContext'
import useAction from 'hooks/useAction'

interface OrderParamTypes {
  pageSize: number
  aasmState?: string | undefined
  paymentTerms?: string | undefined
  chargeState?: string | undefined
  oosw?: string | undefined
  dropship?: string | undefined
  query?: string | undefined
  country?: string | undefined
}

const cancelKey = 'orders'

export default function useOrders({
  pageSize = 20,
  query,
  aasmState,
  paymentTerms,
  chargeState,
  oosw,
  dropship,
  country,
}: OrderParamTypes) {
  const [orders, setOrders] = useState<any[]>([])
  const [total, setOrdersTotal] = useState<number | undefined>(0)
  const [page, setPage] = useState<number>(0)

  const snackbar = useAlert()

  const handlePageChange = useCallback((params: GridPageChangeParams) => {
    setPage(params.page)
  }, [])

  const qp = new URLSearchParams({
    page: '' + (page + 1),
    per_page: '' + pageSize,
  })
  query && qp.set('search', query)
  aasmState && qp.set('aasmState', aasmState.toString())
  paymentTerms && qp.set('paymentTerms', paymentTerms.toString())
  chargeState && qp.set('chargeState', chargeState.toString())
  oosw && qp.set('oosw', oosw.toString())
  dropship && qp.set('dropship', dropship.toString())
  country && qp.set('country', country.toString())
  const queryString = qp.toString()

  const [fetchData, isLoading] = useAction({
    request: () => api.get(`/orders?${queryString}`, { cancelKey }),
    onSuccess: (resp: any) => {
      setOrdersTotal(resp.data.meta.total)
      setOrders(resp.data.data)
    },
    onError: () => snackbar.error('Error fetching Orders'),
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  useEffect(() => () => cancelRequest(cancelKey), [])

  return {
    fetchData,
    isLoading,
    total,
    orders,
    handlePageChange,
  }
}
