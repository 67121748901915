import { api } from 'services/api.service'
import { useEffect, useState, useCallback } from 'react'
import { useAlert } from 'context/Alert/AlertContext'

import { CreateOrderReturnReq } from 'types'

export default function useCreateOrderReturn() {
  const snackbar = useAlert()
  const [isLoading, setIsLoading] = useState(false)
  const [reasons, setReasons] = useState<string[]>([])

  const getReasons = useCallback(() => {
    setIsLoading(true)
    return api
      .get('constants/order_return_reasons')
      .then((resp) => {
        setReasons(resp.data)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const createOrderReturn = useCallback(
    (data: CreateOrderReturnReq) => {
      return api
        .post(`order_returns`, { orderReturn: data })
        .then(() => {
          snackbar.success('Order Return Created')
        })
        .catch((err) => {
          console.error(err)
          const errMessage = 'Create Order Return Failed'
          snackbar.error(errMessage)
          return Promise.reject(err.response.data?.error || err.message || errMessage)
        })
    },
    [snackbar]
  )

  useEffect(() => {
    getReasons()
  }, [getReasons])

  return { isLoading, reasons, createOrderReturn }
}
