export type Category = {
  id: number
  name: string
  displayName: string
  featured: boolean
  managedByAdmin: boolean
  description?: string
  maxShippingPercentage?: number
  position?: number
  parentId: number | null
  slug: string
  navLevel: string
  dropship?: boolean
  taxonomyLevel: number
  'featuredSubcategory?': boolean
}

export type ParentCategory = Category & {
  countries: {
    id: number
    countryDisplayName: string
    country: { id: number; name: string }
  }[]
  parentId: null
  taxonomyLevel: 1
}

export function isParentCategory(category: Category): category is ParentCategory {
  return category.taxonomyLevel === 1
}

export type SecondaryCategory = Category & { parentId: number; taxonomyLevel: 2 }

export function isSecondaryCategory(category: Category): category is SecondaryCategory {
  return category.taxonomyLevel === 2
}

export type TertiaryCategory = Category & { parentId: number; taxonomyLevel: 3 }

export function isTertiaryCategory(category: Category): category is TertiaryCategory {
  return category.taxonomyLevel === 3
}
