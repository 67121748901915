import React from 'react'
import clsx from 'clsx'
import { useState } from 'react'
import { DateTime } from 'luxon'
import EditIcon from '@material-ui/icons/Edit'
import CopyIcon from '@material-ui/icons/FileCopy'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Collapse, TextField } from '@material-ui/core'
import { DatePicker, DatePickerProps as MuiDatePickerProps } from '@material-ui/pickers'
import { AnySchema } from 'yup'

import Button from 'components/Button'
import Select from 'components/SelectField'
import { Option } from 'util/normalizeOptions'
import { useEditableContext } from 'context/Editable/EditableContext'

export type DatePickerProps = Omit<MuiDatePickerProps, 'value' | 'onChange'>

const defaultDatePickerProps: DatePickerProps = {
  disableToolbar: true,
  variant: 'inline',
  format: 'yyyy-MM-dd',
}

export type EditableProps = {
  label: string
  value: any
  options?: Option[] | string[]
  onSave: (value: any) => Promise<any>
  className?: string
  textFieldProps?: any
  asDatePicker?: boolean
  datePickerProps?: DatePickerProps
  EditorComponent?: any
  helperText?: string
  readOnly?: boolean
  style?: any
  validator?: AnySchema
}

const Editable: React.FC<EditableProps> = ({
  label,
  value,
  onSave,
  children,
  className = null,
  textFieldProps = {},
  datePickerProps,
  asDatePicker = false,
  options,
  helperText,
  EditorComponent = null,
  validator,
  style,
  readOnly,
  ...rest
}) => {
  const css = useStyles()
  const [isEditing, setIsEditing]: any = useState(false)
  const [isBusy, setIsBusy]: any = useState(false)
  const [isValid, setIsValid]: any = useState(true)
  const [error, setError]: any = useState('')
  const [newValue, setNewValue] = useState(value ?? '')
  const { disabled } = useEditableContext()

  readOnly = readOnly || disabled

  const toggleEdit = () => {
    if (readOnly) return
    if (value !== newValue) {
      setNewValue(value)
    }
    if (isEditing) {
      setIsEditing(false)
      setError('')
    } else {
      setIsEditing(true)
    }
  }

  const handleChange = (evt: any) => {
    const eventValue = evt instanceof DateTime ? evt : evt.target.value
    setNewValue(eventValue)
    validator
      ?.validate(eventValue)
      .then((value: any) => {
        setError('')
        setIsValid(true)
      })
      .catch((err: any) => {
        setError(err.errors[0])
        setIsValid(false)
      })
  }

  const handleSubmit = () => {
    setIsBusy(true)
    onSave(newValue)
      .then(() => {
        setIsBusy(false)
        setIsEditing(false)
      })
      .catch((err: any) => {
        setIsBusy(false)
        setError(err.response?.data?.error || err.message || 'Error saving changes.')
      })
  }

  if (EditorComponent == null) {
    const inputEditorComponent = asDatePicker ? DatePicker : TextField
    EditorComponent = options ? Select : inputEditorComponent
  }

  datePickerProps = asDatePicker ? { ...defaultDatePickerProps, ...datePickerProps } : {}

  return (
    <div
      className={clsx(css.root, className)}
      style={style}
      {...rest}
      onDoubleClick={!isEditing ? toggleEdit : () => {}}
    >
      <Collapse unmountOnExit in={isEditing}>
        <div className={css.editWrapper}>
          <EditorComponent
            className={css.editor}
            options={options}
            disabled={isBusy}
            label={label}
            error={error !== ''}
            {...textFieldProps}
            {...datePickerProps}
            helperText={error || helperText}
            value={newValue}
            onChange={handleChange}
          />
          <div className={css.actions}>
            <Button disabled={isBusy} onClick={toggleEdit}>
              Cancel
            </Button>
            <Button primary busy={isBusy} disabled={!isValid || value === newValue} onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      </Collapse>
      <Collapse in={!isEditing} className={clsx({ [css.noSelect]: isEditing })}>
        <div className={css.editLabel}>
          {children ?? value}
          {!readOnly && (
            <div>
              <IconButton
                className={css.iconButtonWrapper}
                onClick={(event) => {
                  event.preventDefault()
                  navigator.clipboard.writeText(newValue)
                }}
              >
                <CopyIcon className={css.iconButton} />
              </IconButton>
              <IconButton
                className={css.iconButtonWrapper}
                onClick={(event) => {
                  event.preventDefault()
                  toggleEdit()
                }}
              >
                <EditIcon className={css.iconButton} />
              </IconButton>
            </div>
          )}
        </div>
      </Collapse>
    </div>
  )
}

export default Editable

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      '&:hover > svg': {
        display: 'block',
        opacity: 1,
        transform: 'scale(1)',
      },
    },
    editWrapper: {
      padding: '6px 0 0',
    },
    actions: {
      display: 'flex',
      marginTop: 8,
      justifyContent: 'flex-end',
      '& > *': {
        minWidth: 80,
      },
      '& > * + *': {
        marginLeft: 8,
      },
    },
    editor: {
      width: '100%',
    },
    editLabel: { display: 'flex', justifyContent: 'space-between' },
    iconButton: {
      fontSize: 18,
      opacity: 0.2,
    },
    iconButtonWrapper: {
      padding: '6px',
    },
    noSelect: {
      userSelect: 'none',
    },
  })
)
