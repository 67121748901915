import { Paper, Typography } from '@material-ui/core'

import ProductImportsQueueDataGrid from 'components/ProductImportsQueueDataGrid'

import usePageTitle from 'hooks/usePageTitle'

export default function ProductImports() {
  const title = 'Product Imports'
  usePageTitle(title)

  return (
    <div>
      <h1 style={{ marginTop: 0 }}>{title}</h1>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4rem' }}>
        <div>
          <Typography variant="h6">Processing</Typography>
          <Paper elevation={0}>
            <ProductImportsQueueDataGrid processing />
          </Paper>
        </div>

        <div>
          <Typography variant="h6">Queued</Typography>
          <Paper elevation={0}>
            <ProductImportsQueueDataGrid queued />
          </Paper>
        </div>

        <div>
          <Typography variant="h6">Rejected/Failed</Typography>
          <Paper elevation={0}>
            <ProductImportsQueueDataGrid rejected />
          </Paper>
        </div>

        <div>
          <Typography variant="h6">Succeeded</Typography>
          <Paper elevation={0}>
            <ProductImportsQueueDataGrid succeeded />
          </Paper>
        </div>

        <div>
          <Typography variant="h6">Canceled</Typography>
          <Paper elevation={0}>
            <ProductImportsQueueDataGrid canceled />
          </Paper>
        </div>
      </div>
    </div>
  )
}
