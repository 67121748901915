import { useField } from 'formik'

import { TextField, TextFieldProps } from '@material-ui/core'

interface Props {
  name: string
  helperText?: string
}

const Input = ({ helperText, ...props }: Props & TextFieldProps) => {
  const [field, meta] = useField({ name: props.name })

  return (
    <TextField
      {...props}
      {...field}
      error={meta.touched && Boolean(meta.error)}
      helperText={helperText || (meta.touched && meta.error)}
    />
  )
}

Input.propTypes = {}

export default Input
