import { api } from './api.service'
import ability from './ability'

const TokenService = {
  set: function (tokens: any) {
    localStorage.setItem('t', JSON.stringify(tokens))
    localStorage.removeItem('refreshingToken')
  },

  remove: function () {
    localStorage.removeItem('t')
  },

  getAccess: function () {
    const data = JSON.parse(localStorage.getItem('t') || '{}')
    return data?.access_token
  },

  setAccess: function (token: any, rules: any, exp: any) {
    const data = JSON.parse(localStorage.getItem('t') || '{}')
    data.access_token = token
    data.rules = rules
    data.exp = exp

    localStorage.setItem('t', JSON.stringify(data))
    localStorage.removeItem('refreshingToken')
  },

  refreshAccess: function () {
    return api
      .post('/auth/refresh_token')
      .then((response) => {
        this.setAccess(response.data.access_token, response.data?.rules, response.data?.exp)
        ability.update(response.data?.rules || [])
        return response.data.access_token
      })
      .catch((err) => {
        throw err
      })
  },
}

export default TokenService
