import { useState } from 'react'
import { useFormik } from 'formik'
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
} from '@material-ui/core'

import Button from 'components/Button'
import MoneyInput from 'components/MoneyInput'
import SelectField from 'components/SelectField'
import DebouncedTextField from 'components/DebouncedTextField'
import { currencyToCents } from 'util/currencyToCents'

const moneyInput: any = MoneyInput
export const Frequents = ['weekly', 'bi_weekly', 'monthly']

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: (plan: any) => Promise<Error | null>
}

export default function PaymentPlanForm({ open, onClose, onSubmit }: Props) {
  const [err, setErr] = useState<string | null>(null)

  const formik = useFormik({
    initialValues: {
      paymentAmountCents: '',
      totalAmountCents: '',
      frequent: '',
    },

    onSubmit: (plan) => {
      onSubmit({
        paymentAmountCents: currencyToCents(plan.paymentAmountCents),
        totalAmountCents: currencyToCents(plan.totalAmountCents),
        frequent: plan.frequent,
      })
        .then(() => {
          formik.resetForm()
          setErr(null)
          onClose()
        })
        .catch((err: any) => {
          setErr(err?.response?.data?.error || err?.message || 'Unable to Create a PaymentPlan')
        })
    },
  })

  const handleClose = () => {
    formik.resetForm()
    setErr(null)
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">Create Payment Plan</DialogTitle>
      <Divider />

      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth component="fieldset">
          <DialogContent style={{ width: 400 }}>
            {err && <FormHelperText error>{err}</FormHelperText>}

            <DebouncedTextField
              fullWidth
              label="Total Amount"
              margin="normal"
              {...formik.getFieldProps('totalAmountCents')}
              InputProps={{
                inputComponent: moneyInput,
                defaultValue: formik.values.totalAmountCents,
              }}
            />

            <DebouncedTextField
              fullWidth
              label="Single payment amount"
              margin="normal"
              {...formik.getFieldProps('paymentAmountCents')}
              InputProps={{
                inputComponent: moneyInput,
                defaultValue: formik.values.paymentAmountCents,
              }}
            />

            <SelectField
              fullWidth
              label="Frequent"
              margin="normal"
              {...formik.getFieldProps('frequent')}
              options={Frequents}
            />

            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button primary type="submit" busy={formik.isSubmitting}>
                Create
              </Button>
            </DialogActions>
          </DialogContent>
        </FormControl>
      </form>
    </Dialog>
  )
}
