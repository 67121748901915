import { Container } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useAlert } from 'context/Alert/AlertContext'
import { useEffect, useState } from 'react'
import CodeInput from 'pages/Verify/CodeInput'
import MethodSelector from 'pages/Verify/MethodSelector'
import usePageTitle from 'hooks/usePageTitle'
import useVerification from 'hooks/useVerification'

const Verify = () => {
  usePageTitle('Verify')

  const [verificationMethod, setVerificationMethod] = useState<string>('')
  const { sendEmailVerificationCode } = useVerification()
  const css = useStyles()
  const snackbar = useAlert()

  useEffect(() => {
    if (verificationMethod === 'email') {
      sendEmailVerificationCode()
        .then(() => {
          snackbar.info('Verification code sent. Please check your email.')
        })
        .catch(() => {
          snackbar.error('Something went wrong on the backend. Please contact support.')
        })
    }
  }, [verificationMethod, sendEmailVerificationCode, snackbar])

  return (
    <Container maxWidth="xs" className={css.container}>
      <h1 className={css.title}>Verify your account</h1>

      {verificationMethod ? (
        <CodeInput verificationMethod={verificationMethod} setVerificationMethod={setVerificationMethod} />
      ) : (
        <MethodSelector setVerificationMethod={setVerificationMethod} />
      )}
    </Container>
  )
}

export default Verify

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(15),
    },
    pad: {
      margin: theme.spacing(1),
      marginLeft: 0,
    },
    title: {
      margin: 0,
    },
  })
)
