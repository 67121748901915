import { api } from 'services/api.service'
import { useCallback, useEffect, useState } from 'react'
import useAction from 'hooks/useAction'
import { useAlert } from 'context/Alert/AlertContext'
import { Profile } from 'types/profile'

export default function useProfile() {
  const [profile, setProfile] = useState({} as Profile)
  const snackbar = useAlert()

  const handleSuccess = useCallback((resp) => setProfile(resp.data), [])

  const [fetchData, isLoading] = useAction({
    request: () => api.get(`/users/profile`),
    onSuccess: handleSuccess,
    onError: () => snackbar.error(`Error fetching profile`),
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    fetchData,
    isLoading,
    profile,
  }
}
