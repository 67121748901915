import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  TextField,
} from '@material-ui/core'

import { Category } from 'types/category'
import useCreateCategory from 'hooks/useCreateCategory'

import Button from 'components/Button'

const formSchema = yup.object({
  name: yup.string().required(),
  description: yup.string().default(''),
  featured: yup.boolean().default(false),
  managedByAdmin: yup.boolean().default(false),
  parentId: yup.number().integer().positive().nullable().default(null),
})

type Props = {
  open: boolean
  onClose: () => void
  setCategories: React.Dispatch<React.SetStateAction<Category[]>>
  parentId: number | null
  taxonomyLevel: number
}

type CreateCategoryFields = Pick<Category, 'name' | 'description' | 'featured' | 'managedByAdmin'>

export default function CreateCategoryForm({ open, onClose, setCategories, parentId, taxonomyLevel }: Props) {
  const { createCategory } = useCreateCategory({ setCategories })

  const formik = useFormik<CreateCategoryFields>({
    initialValues: {
      name: '',
      description: '',
      featured: false,
      managedByAdmin: false,
    },
    onSubmit: async (val) => {
      const response = await createCategory({ ...val, taxonomyLevel, parentId })
        .catch()
        .finally(onClose)
      formik.resetForm()

      return response
    },
    validationSchema: formSchema,
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  if (!open) {
    return null
  }

  return (
    <Dialog open={true} onClose={handleClose} fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>New Category</DialogTitle>
        <Divider />

        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            margin="normal"
            {...formik.getFieldProps('name')}
            error={formik.errors.name !== undefined}
            helperText={formik.errors.name}
          />

          <TextField
            fullWidth
            label="Description"
            margin="normal"
            {...formik.getFieldProps('description')}
            error={formik.errors.description !== undefined}
            helperText={formik.errors.description}
          />

          <div>
            <FormControlLabel
              label="Featured"
              control={
                <Checkbox
                  checked={formik.getFieldProps('featured').value}
                  onChange={() => formik.setFieldValue('featured', !formik.getFieldProps('featured').value)}
                />
              }
            />
          </div>

          <div>
            <FormControlLabel
              label="Managed By Admin"
              control={
                <Checkbox
                  checked={formik.getFieldProps('managedByAdmin').value}
                  onChange={() => formik.setFieldValue('managedByAdmin', !formik.getFieldProps('managedByAdmin').value)}
                />
              }
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          <Button type="submit" primary autoFocus disabled={!formik.isValid} busy={formik.isSubmitting}>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
