import React from 'react'
import { useFormik } from 'formik'
import { Box, FormControl } from '@material-ui/core'

import { Category, ParentCategory } from 'types/category'
import useCategoryCountry from 'hooks/useCategoryCountry'
import Button from 'components/Button'
import Panel from 'components/Panel'
import EditableDefinition from 'components/EditableDefinition'
import MiniForm from 'components/MiniForm'
import SelectField from 'components/SelectField'
import DebouncedTextField from 'components/DebouncedTextField'
import { countryOptions } from 'util/geography'

type CountrySettingsProps = {
  category: ParentCategory
  setCategories: React.Dispatch<React.SetStateAction<Category[]>>
}

export default function CountrySettings({ category, setCategories }: CountrySettingsProps) {
  const { createCountry, updateCountry, deleteCountry } = useCategoryCountry({ category, setCategories })

  const availableCountryOptions = countryOptions.filter((option) =>
    category.countries.every((c) => c.country.id !== option.value)
  )

  return (
    <>
      {category.countries.map((categoryCountry) => (
        <Panel
          key={categoryCountry.id}
          title={categoryCountry.country.name}
          contentRight={
            <Button danger small onClick={() => deleteCountry[0](categoryCountry.id)}>
              Delete
            </Button>
          }
        >
          <EditableDefinition
            label="Display Name"
            value={categoryCountry.countryDisplayName}
            onSave={(val) => updateCountry[0](categoryCountry.id, { countryDisplayName: val })}
          />
        </Panel>
      ))}

      {availableCountryOptions.length > 0 && (
        <MiniForm
          fields={{ countryId: availableCountryOptions[0].value, countryDisplayName: '' }}
          onSave={({ countryId, countryDisplayName }) =>
            createCountry[0]({ countryId, categoryId: category.id, countryDisplayName })
          }
          renderFields={(formik: ReturnType<typeof useFormik>) => (
            <FormControl fullWidth component="fieldset">
              <SelectField
                fullWidth
                margin="normal"
                label="Country"
                {...formik.getFieldProps('countryId')}
                options={availableCountryOptions}
              />
              <DebouncedTextField
                fullWidth
                margin="normal"
                label="Country Display Name"
                {...formik.getFieldProps('countryDisplayName')}
              />
            </FormControl>
          )}
          renderToggleEdit={(onClick) => (
            <Box display="flex" justifyContent="flex-end" style={{ marginTop: 20 }}>
              <Button onClick={onClick} aria-describedby="add-country" disableElevation>
                Add country
              </Button>
            </Box>
          )}
        />
      )}
    </>
  )
}
