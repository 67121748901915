import { GridPageChangeParams } from '@material-ui/data-grid'
import { useAlert } from 'context/Alert/AlertContext'
import useAction from 'hooks/useAction'
import { useCallback, useEffect, useState } from 'react'
import { api, cancelRequest } from 'services/api.service'
import { ProductType } from 'types/product_type'

interface ProductTypesParams {
  productTypeId?: string
  pageSize?: number
}

const cancelKey = 'brands'

export default function useProductTypeCategories({ productTypeId, pageSize = 25 }: ProductTypesParams) {
  const [productTypeCategories, setProductTypeCategories] = useState<ProductType[]>([])
  const [productTypeCategoriesCount, setProductTypeCategoriesCount] = useState(0)
  const snackbar = useAlert()

  const [page, setPage] = useState(0)
  const handlePageChange = useCallback((params: GridPageChangeParams) => {
    setPage(params.page)
  }, [])

  const qp = new URLSearchParams({
    page: '' + (page + 1),
    per_page: '' + pageSize,
  })
  if (productTypeId) qp.set('productTypeId', productTypeId)

  const [fetchData, isLoading] = useAction({
    request: () => api.get(`/product_type_categories?${qp.toString()}`, { cancelKey }),
    onSuccess: (resp: any) => {
      setProductTypeCategoriesCount(resp.data.meta.total)
      setProductTypeCategories(resp.data.data)
    },
    onError: () => snackbar.error('Error fetching product types'),
  })

  const refetch = () => fetchData()

  useEffect(() => {
    fetchData()
    return () => cancelRequest(cancelKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, productTypeId])

  return {
    fetchData,
    isLoading,
    refetch,
    productTypeCategories,
    productTypeCategoriesCount,
    handlePageChange,
    pageSize,
  }
}
