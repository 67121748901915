import { Currency, Currencies } from 'types/currency'

export type Country = {
  name: string
  currency: Currency
}

const createCountries = <T extends Record<string, Country>>(countries: T) => countries

export const Countries = createCountries({
  'United States': {
    name: 'United States',
    currency: Currencies['USD'],
  },
  'United Kingdom': {
    name: 'United Kingdom',
    currency: Currencies['GBP'],
  },
})

const supportedCountry = (name: string): name is keyof typeof Countries => {
  if (name in Countries) {
    return true
  }
  return false
}

export const getCountry = (name: string = ''): Country | null => {
  return supportedCountry(name) ? Countries[name] : null
}
