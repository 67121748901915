import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import MuiLink, { LinkProps } from '@material-ui/core/Link'
import React from 'react'

const LinkBehavior = React.forwardRef<RouterLinkProps, any>((props, ref) => <RouterLink ref={ref} {...props} />)

const Link = React.forwardRef<LinkProps & RouterLinkProps, any>((props, ref) => (
  <MuiLink ref={ref} component={LinkBehavior} {...props} />
))

export default Link
