import { useState, useCallback } from 'react'

import Button, { ButtonProps } from 'components/Button'

import { useEditableContext } from 'context/Editable/EditableContext'

type AsyncButtonProps = ButtonProps & {
  onClick: (event: any) => Promise<any>
  children?: any
}
const AsyncButton = ({ onClick, children, disabled, ...rest }: AsyncButtonProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const { disabled: disabledByContext } = useEditableContext()

  const handleClick = useCallback(
    (event) => {
      setIsLoading(true)
      onClick(event).finally(() => {
        setIsLoading(false)
      })
    },
    [onClick]
  )

  return (
    <Button onClick={handleClick} busy={isLoading} disabled={disabled || disabledByContext} {...rest}>
      {children}
    </Button>
  )
}

export default AsyncButton
