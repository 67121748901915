import { useCallback, useState } from 'react'
import { GridCellParams, GridEditRowModelParams } from '@material-ui/data-grid'
import { Paper, Typography, IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Switch } from '@material-ui/core'
import { useConfirm } from 'context/Confirm/ConfirmContext'
import { useAlert } from 'context/Alert/AlertContext'
import useProducts from 'hooks/useProducts'
import useQueryParam from 'hooks/useQueryParam'
import useCategories from 'hooks/useCategories'
import usePageTitle from 'hooks/usePageTitle'
import ProductCategoryAutocomplete from 'components/ProductCategoryAutocomplete'
import BrandAutocomplete from 'components/BrandAutocomplete'
import DataGrid from 'components/DataGrid'
import SelectField from 'components/SelectField'
import Link from 'components/Link'
import ExternalLink from 'components/ExternalLink'
import { Option } from 'util/normalizeOptions'
import DebouncedTextField from 'components/DebouncedTextField'
import Async from 'components/Async'
import { SecondaryCategory } from 'types/category'
import { Product } from 'types/product'

const pageSize = 25

export default function Products() {
  const css = useStyles()
  usePageTitle('Products')

  const [brand, setBrand] = useQueryParam({
    key: 'brand',
    defaultValue: undefined,
    parse: (value: string) => (value ? { id: parseInt(value), companyName: '' } : undefined),
    stringify: (brand: any) => (brand ? '' + brand?.id : ''),
  })

  const [sku, setSku] = useState<string>('')
  const handleSetSku = (evt: any) => {
    setSku(evt.target.value)
  }

  const [productName, setProductName] = useState<string>('')
  const handleSetProductName = (evt: any) => {
    setProductName(evt.target.value)
  }

  const [tagging, setTagging] = useQueryParam({
    key: 'tagging',
    defaultValue: taggingOptions[0].value,
    parse: (value: string) => (value ? parseInt(value) : undefined),
  })
  const handleTaggingChange = (evt: any) => setTagging(evt.target.value)

  const [visibility, setVisibility] = useQueryParam({
    key: 'visibility',
    defaultValue: visibilityOptions[0].value,
    parse: (value: string) => (value ? parseInt(value) : undefined),
  })
  const handleVisibilityChange = (evt: any) => setVisibility(evt.target.value)

  const handleDeleteProduct = (row: any) => {
    confirm({
      title: 'Delete Product',
      text: `Are you sure you would like to delete ${row.name}? This action can't be undone.`,
      submitText: 'Delete',
      danger: true,
    }).then(() => {
      return deleteProduct(row as Product)
    })
  }

  const { confirm } = useConfirm()

  const [editRowsModel, setEditRowsModel] = useState({})
  const handleEditRowModelChange = useCallback((params: GridEditRowModelParams) => {
    setEditRowsModel(params.model)
  }, [])

  const {
    products,
    isLoading,
    handlePageChange,
    productsCount,
    untaggedProductsCount,
    saveProductCategories,
    toggleExcluded,
    updateProductField,
    deleteProduct,
  } = useProducts({
    pageSize,
    tagging: tagging !== 'all' ? !!tagging : undefined,
    visibility: visibility !== 'all' ? !!visibility : undefined,
    brandId: brand?.id,
    sku: sku,
    productName: productName,
  })

  const { addAlert } = useAlert()

  const { secondaryCategories } = useCategories()
  const renderCategory = ({ value, row }: GridCellParams) => (
    <ProductCategoryAutocomplete
      TextFieldProps={{ margin: 'dense', label: '', variant: 'standard' }}
      value={value as SecondaryCategory[]}
      onChange={(evt: any, value: SecondaryCategory[]) => {
        if (value.filter((cat) => !cat.managedByAdmin && !cat['featuredSubcategory?']).length <= 3) {
          saveProductCategories(row.id, value)
        } else {
          addAlert("Can't add more than 3 categories to a product.")
        }
      }}
      subCategories={secondaryCategories}
    />
  )

  const productColumns = [
    {
      headerName: 'ID',
      field: 'id',
      width: 100,
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 200,
      renderCell: ({ row, value }: any) => (
        <ExternalLink
          href={`${process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000'}/products/${row.id}`}
          className={css.threeLines}
        >
          {value}
        </ExternalLink>
      ),
    },
    {
      headerName: 'Brand',
      field: 'brandProfile',
      width: 200,
      renderCell: ({ value }: any) => <Link to={`/brands/${value.id}`}>{value.companyName}</Link>,
    },
    {
      headerName: 'Internal SKU',
      field: 'internalSku',
      flex: 1,
      renderCell: ({ value }: GridCellParams) => <div className={css.threeLines}>{value}</div>,
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 1,
      renderCell: ({ value }: GridCellParams) => <div className={css.threeLines}>{value}</div>,
    },
    {
      headerName: 'Categories',
      field: 'categories',
      width: 300,
      renderCell: renderCategory,
    },
    {
      headerName: 'Excluded',
      field: 'excluded',
      renderCell: ({ row, value }: any) => (
        <Switch
          size="small"
          color="secondary"
          checked={value === true}
          onChange={() => toggleExcluded(row as Product)}
        />
      ),
      width: 120,
    },
    {
      headerName: 'Dropship',
      field: 'dropship',
      renderCell: ({ row, value }: any) => (
        <Switch
          size="small"
          color="secondary"
          checked={value === true}
          onChange={() => updateProductField(row as Product, 'dropship', !row.dropship)}
        />
      ),
      width: 120,
    },
    {
      headerName: 'Delete',
      field: 'deleted',
      hide: process.env.NODE_ENV === 'production',
      renderCell: ({ row }: any) => (
        <IconButton onClick={() => handleDeleteProduct(row)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ]

  return (
    <div>
      <h1 style={{ marginTop: 0 }}>Products</h1>
      <Paper variant="outlined" style={{ padding: 16, marginBottom: 24, display: 'flex', alignItems: 'center' }}>
        <BrandAutocomplete
          style={{ display: 'inline-block', minWidth: 250 }}
          className={css.formControl}
          value={brand}
          onChange={(evt: any, brand: any) => setBrand(brand)}
        />
        <SelectField
          className={css.formControl}
          label="Tagging"
          value={tagging}
          onChange={handleTaggingChange}
          options={taggingOptions}
        />
        <SelectField
          className={css.formControl}
          label="Visibility"
          value={visibility}
          onChange={handleVisibilityChange}
          options={visibilityOptions}
        />
        <DebouncedTextField className={css.formControl} onChange={handleSetSku} label="sku" value={sku} />

        <DebouncedTextField
          className={css.formControl}
          onChange={handleSetProductName}
          label="product name"
          value={productName}
        />

        <Typography style={{ marginLeft: 'auto' }}>{untaggedProductsCount} untagged</Typography>
      </Paper>

      <Async loading={isLoading} overlay>
        <Paper elevation={0}>
          <DataGrid
            rowHeight={100}
            rows={products}
            columns={productColumns}
            sortingMode="server"
            paginationMode="server"
            pageSize={pageSize}
            rowCount={productsCount}
            onPageChange={handlePageChange}
            editRowsModel={editRowsModel}
            onEditRowModelChange={handleEditRowModelChange}
          />
        </Paper>
      </Async>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 140,
    },
    threeLines: {
      whiteSpace: 'normal',
      lineHeight: 1.25,
      '&:not(:hover)': {
        lineClamp: 3,
        display: '-webkit-box',
        boxOrient: 'vertical',
      },
    },
  })
)

const taggingOptions: Option[] = [
  { label: 'All', value: 'all' },
  { label: 'Tagged', value: 1 },
  { label: 'Untagged', value: 0 },
]

const visibilityOptions: Option[] = [
  { label: 'All', value: 'all' },
  { label: 'Visible', value: 1 },
  { label: 'Hidden', value: 0 },
]
