import { Index, Hits, connectStateResults } from 'react-instantsearch-dom'

import { Box, Divider } from '@material-ui/core'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'

import Link from 'components/Link'

interface OrderHitProps {
  hit: {
    objectID: number
    uid: string
  }
}

const OrderHit = ({ hit }: OrderHitProps) => (
  <Link to={`/orders/${hit.objectID}`}>
    <Box display="flex" alignContent="center">
      <ShoppingBasketIcon style={{ marginRight: 10 }} /> #{hit.uid}
    </Box>
  </Link>
)

const OrderIndexResult = connectStateResults<any>(
  ({ searchResults, children }) =>
    searchResults &&
    searchResults.nbHits !== 0 && (
      <div>
        {children}
        <Divider />
      </div>
    )
)

const OrderIndex = () => {
  return (
    <Index indexName={`admin_${process.env.REACT_APP_ENV}_orders`}>
      <OrderIndexResult />
      <Hits hitComponent={OrderHit} />
    </Index>
  )
}

export default OrderIndex
