import clsx from 'clsx'
import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import DebouncedTextField from 'components/DebouncedTextField'
import SelectField from 'components/SelectField'
import { usaStateAbbreviations } from 'util/geography'
import PhoneInput from 'components/PhoneInput'
import { FormControl, FormHelperText, FormLabel, InputAdornment } from '@material-ui/core'
import { Phone } from '@material-ui/icons'
import { Country, Countries } from 'types'

type Margin = 'normal' | 'dense' | 'none'

interface AddressFormFieldsProps {
  formik: any
  fieldPrefix?: string
  fieldsetLabel?: React.ReactNode
  margin?: Margin
  fieldMargin?: Margin
  country: Country
}

// Notes on UK Address Forms here:
// https://alliescomputing.com/knowledge-base/how-to-build-a-uk-address-form

const AddressFormFields: React.FC<AddressFormFieldsProps> = ({
  formik,
  country,
  fieldPrefix,
  fieldsetLabel = 'Address',
  margin,
  fieldMargin = 'dense',
}) => {
  const css = useStyles()
  const getFieldProps = (fieldName: string) =>
    fieldPrefix ? formik.getFieldProps(`${fieldPrefix}.${fieldName}`) : formik.getFieldProps(fieldName)

  return (
    <FormControl fullWidth component="fieldset" margin={margin}>
      {fieldsetLabel && <FormLabel component="legend">{fieldsetLabel}</FormLabel>}
      <DebouncedTextField margin={fieldMargin} fullWidth label="Name" {...getFieldProps('name')} />
      <DebouncedTextField margin={fieldMargin} fullWidth label="Business Name" {...getFieldProps('businessName')} />
      <DebouncedTextField margin={fieldMargin} fullWidth label="Address Line 1" {...getFieldProps('address1')} />
      <DebouncedTextField margin={fieldMargin} fullWidth label="Address Line 2" {...getFieldProps('address2')} />
      <div
        className={clsx({
          [css.cityStateZip]: country === Countries['United States'],
        })}
      >
        <DebouncedTextField margin={fieldMargin} fullWidth label="City" {...getFieldProps('city')} />
        {country === Countries['United States'] && (
          <SelectField margin={fieldMargin} options={usaStateAbbreviations} label="State" {...getFieldProps('state')} />
        )}
        {country === Countries['United Kingdom'] && (
          <DebouncedTextField margin={fieldMargin} fullWidth label="State/County" {...getFieldProps('state')} />
        )}
        <DebouncedTextField
          margin={fieldMargin}
          fullWidth
          label={country === Countries['United States'] ? 'Zipcode' : 'Postcode'}
          {...getFieldProps('zipcode')}
        />
      </div>
      <DebouncedTextField margin={fieldMargin} fullWidth label="Country" {...getFieldProps('country')} />
      <DebouncedTextField
        margin={fieldMargin}
        fullWidth
        label="Phone"
        InputProps={{
          inputComponent: PhoneInput,
          inputProps: { country },
          startAdornment: (
            <InputAdornment position="start">
              <Phone fontSize="small" />
            </InputAdornment>
          ),
        }}
        {...getFieldProps('phone')}
      />
      {formik.errors.form && <FormHelperText error>{formik.errors.form}</FormHelperText>}
    </FormControl>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cityStateZip: {
      display: 'flex',
      '& > *': {
        flex: '1 1 auto',
        minWidth: 'auto',
      },
      '& > * + *': {
        marginLeft: 16,
      },
    },
  })
)

export default AddressFormFields
