import clsx from 'clsx'

import { Drawer, List, IconButton } from '@material-ui/core'
import { ChevronRight, ChevronLeft } from '@material-ui/icons'

import useLocalStorage from 'hooks/useLocalStorage'
import NavLinks from './NavLinks'
import { CSS } from './styles'

const NavDrawer = () => {
  const classes = CSS()
  const [open, setOpen] = useLocalStorage({
    key: 'navDrawerOpen',
    initialValue: false,
    parse: (v) => v === 'true',
  })

  const handleDrawer = () => {
    setOpen(!open)
  }

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton
          onClick={handleDrawer}
          className={clsx(classes.icon, {
            [classes.drawerOpenIcon]: open,
            [classes.drawerClosedIcon]: !open,
          })}
        >
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </div>
      <List>
        <NavLinks />
      </List>
    </Drawer>
  )
}

export default NavDrawer
