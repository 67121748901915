import { Link, useLocation } from 'react-router-dom'
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import LabelIcon from '@material-ui/icons/Label'
import StoreIcon from '@material-ui/icons/Store'
import OndemandVideo from '@material-ui/icons/OndemandVideo'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'
import SettingsIcon from '@material-ui/icons/Settings'
import Category from '@material-ui/icons/Category'
import StarsIcon from '@material-ui/icons/Stars'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'

import { CSS } from './styles'

import { Can } from '../Can'

const NavItem = ({ path, label, IconComponent }: any) => {
  const classes = CSS()
  const location = useLocation()
  return (
    <ListItem className={classes.listItem} selected={location.pathname.startsWith(path)}>
      <Link to={path} role="button" className={classes.link}>
        <ListItemIcon>
          <IconComponent />
        </ListItemIcon>
        <ListItemText secondary={label} />
      </Link>
    </ListItem>
  )
}

const NavLinks = () => {
  const routesWithAbilities = [
    { ability: { i: 'index', a: 'BrandProfile' }, route: { path: '/brands', label: 'Brands', icon: LabelIcon } },
    {
      ability: { i: 'index', a: 'RetailerProfile' },
      route: { path: '/retailers', label: 'Retailers', icon: StoreIcon },
    },
    { ability: { i: 'index', a: 'Product' }, route: { path: '/products', label: 'Products', icon: LocalOfferIcon } },
    { ability: { i: 'index', a: 'Order' }, route: { path: '/orders', label: 'Orders', icon: ShoppingBasketIcon } },
    {
      ability: { i: 'index', a: 'OrderReturn' },
      route: { path: '/returns', label: 'Returns', icon: KeyboardReturnIcon },
    },
    { ability: { i: 'index', a: 'Review' }, route: { path: '/reviews', label: 'Reviews', icon: StarsIcon } },
    { ability: { i: 'index', a: 'Category' }, route: { path: '/categories', label: 'Categories', icon: Category } },
    {
      ability: { i: 'index', a: 'ProductType' },
      route: { path: '/product_types', label: 'Product Types', icon: SettingsIcon },
    },
    {
      ability: { i: 'index', a: 'ProductImports' },
      route: { path: '/product_imports', label: 'Product Imports', icon: OpenInBrowserIcon },
    },
    {
      ability: { i: 'index', a: 'LiveShoppingHighlight' },
      route: { path: '/live_shopping_highlights', label: 'Live Shopping Highlights', icon: OndemandVideo },
    },
    {
      ability: { i: 'index', a: 'ProductSetting' },
      route: { path: '/product_settings', label: 'ProductSettings', icon: SettingsIcon },
    },
  ]

  return (
    <>
      {routesWithAbilities.map((route) => {
        return (
          <Can I={route.ability.i} a={route.ability.a} key={route.route.path}>
            <NavItem path={route.route.path} label={route.route.label} IconComponent={route.route.icon} />
          </Can>
        )
      })}
    </>
  )
}

export default NavLinks
