import { Paper } from '@material-ui/core'
import DataGrid from 'components/DataGrid'
import PageHeader from 'components/PageHeader'
import useProductTypes from 'hooks/useProductTypes'
import { productTypesColumns } from './productTypeColumns'

export default function ProductTypes() {
  const { productTypes, pageSize, isLoading, handlePageChange, productTypesCount } = useProductTypes({})

  return (
    <div>
      <PageHeader title="Product Types" />

      <Paper elevation={0}>
        <DataGrid
          rows={productTypes}
          columns={productTypesColumns}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={handlePageChange}
          loading={isLoading}
          pagination
          rowCount={productTypesCount}
        />
      </Paper>
    </div>
  )
}
