import React, { useEffect } from 'react'
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import BrandDetail from 'pages/Brands/Brand'
import Brands from 'pages/Brands'
import Categories from 'pages/Categories'
import Category from 'pages/Categories/Category'
import LiveShoppingHighlights from 'pages/LiveShoppingHighlights'
import LiveShoppingHighlightsNew from 'pages/LiveShoppingHighlights/New'
import LiveShoppingHighlightsEdit from 'pages/LiveShoppingHighlights/Edit'
import Login from 'pages/Login'
import Order from 'pages/Orders/Order'
import OrderEdit from 'pages/Orders/Order/Edit'
import OrderReturn from 'pages/OrderReturns/OrderReturn'
import OrderReturns from 'pages/OrderReturns'
import Orders from 'pages/Orders'
import ProductImports from 'pages/ProductImports'
import Products from 'pages/Products'
import ProductSettings from 'pages/ProductSettings'
import Profile from 'pages/Profile'
import Retailer from 'pages/Retailers/Retailer'
import Retailers from 'pages/Retailers'
import Reviews from 'pages/Reviews'
import Verify from 'pages/Verify'

import AuthGate from 'components/AuthGate'
import ConnectionNotice from 'components/ConnectionNotice'
import MainLayout from './MainLayout'
import { useAbility } from './hooks/useAbility'
import TokenService from 'services/token.service'
import useToken from 'hooks/useToken'
import ProductTypes from 'pages/ProductTypes'
import ProductType from 'pages/ProductTypes/ProductType'

const InvalidRoute: React.FC<RouteComponentProps> = () => <h1>This page does not exist.</h1>

function App() {
  const { token } = useToken()
  const ability = useAbility()
  const homePage = () => {
    const resource = ['BrandProfile', 'OrderReturn', 'Product'].find((page) => {
      return ability.can('index', page)
    })

    switch (resource) {
      case 'BrandProfile':
        return '/brands'
      case 'OrderReturn':
        return '/returns'
      case 'Product':
        return '/products'
      default:
        return '/brands'
    }
  }

  useEffect(() => {
    if (token) {
      TokenService.refreshAccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={'An error has occurred'}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/verify" component={Verify} />
        <AuthGate>
          <Switch>
            <Route
              path="/product-categories"
              render={() => (
                <div style={{ margin: 24 }}>
                  <Products />
                </div>
              )}
            />
            <MainLayout>
              <Switch>
                <Redirect from="/" exact to={homePage()} />
                <Route exact path="/brands" component={Brands} />
                <Route exact path="/brands/:id" component={BrandDetail} />
                <Route exact path="/retailers" component={Retailers} />
                <Route exact path="/retailers/:id" component={Retailer} />
                <Route exact path="/products" component={Products} />
                <Route exact path="/orders" component={Orders} />
                <Route exact path="/orders/:id/edit" component={OrderEdit} />
                <Route exact path="/orders/:id/:tab" component={Order} />
                <Redirect from="/orders/:id" to="/orders/:id/overview" />
                <Route exact path="/returns" component={OrderReturns} />
                <Route exact path="/returns/:id" component={OrderReturn} />
                <Route exact path="/categories" component={Categories} />
                <Route exact path="/categories/:id" component={Category} />
                <Route exact path="/product_types" component={ProductTypes} />
                <Route exact path="/product_types/:id" component={ProductType} />
                <Route exact path="/reviews" component={Reviews} />
                <Route exact path="/product_imports" component={ProductImports} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/live_shopping_highlights" component={LiveShoppingHighlights} />
                <Route exact path="/live_shopping_highlights/new" component={LiveShoppingHighlightsNew} />
                <Route exact path="/live_shopping_highlights/:id/edit" component={LiveShoppingHighlightsEdit} />
                <Route exact path="/product_settings" component={ProductSettings} />
                <Route component={InvalidRoute} />
              </Switch>
            </MainLayout>
          </Switch>
        </AuthGate>
      </Switch>
      <ConnectionNotice />
    </Sentry.ErrorBoundary>
  )
}

export default App
