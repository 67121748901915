import { api, cancelRequest } from 'services/api.service'
import { useCallback, useEffect, useState } from 'react'
import { useAlert } from 'context/Alert/AlertContext'
import { GridPageChangeParams } from '@material-ui/data-grid'
import useAction from 'hooks/useAction'

interface ProductImportParams {
  brandId: string
  pageSize?: number
  cancelKey?: string
}

const cancelKey = 'product_imports'

export default function useProductImports({ brandId, pageSize = 10 }: ProductImportParams) {
  const [productImportsCount, setProductImportsCount] = useState<number>(0)
  const [productImports, setProductImports] = useState<any[]>([])

  const [page, setPage] = useState<number>(0)
  const handlePageChange = useCallback((params: GridPageChangeParams) => {
    setPage(params.page)
  }, [])
  const snackbar = useAlert()

  const productImportsParams = new URLSearchParams({
    page: '' + (page + 1),
    per_page: '' + pageSize,
  }).toString()

  const [fetchData, isLoading] = useAction({
    request: () => api.get(`brands/${brandId}/product_imports?${productImportsParams}`, { cancelKey }),
    onSuccess: (resp: any) => {
      setProductImportsCount(resp.data.meta.total)
      setProductImports(resp.data.data)
    },
    onError: () => snackbar.error(`Error fetching product imports for brand: ${brandId}`),
  })

  useEffect(() => {
    fetchData()
    return () => cancelRequest(cancelKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, brandId])

  return {
    isLoading,
    productImports,
    productImportsCount,
    pageSize,
    handlePageChange,
  }
}
