import { DateTime } from 'luxon'

/**
 * Parses a date or dateTime string from our API into a luxon DateTime object
 */
const parseDateTime = (value?: string): DateTime | null => {
  if (!value) {
    return null
  } else {
    return DateTime.fromISO(value)
  }
}

export default parseDateTime
