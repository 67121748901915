import React, { useMemo, useState } from 'react'
import { TextField } from '@material-ui/core'

import centsToCurrency from 'util/centsToCurrency'

import useStyles from './styles'
import LineItemDialog from './LineItemDialog'
import ConditionValuePanel from './ConditionValuePanel'

import { OrderReturnLineItem } from 'types'
import { useCurrencyContext } from 'context/Country/CountryContext'

type Props = {
  lineItem: OrderReturnLineItem
  countryName: string
  itemIndex: number
  appliedDiscountPercentages: number[]
  returnState: string
  formik: any
}

const LineItemFields: React.FC<Props> = ({
  formik,
  itemIndex,
  lineItem,
  countryName,
  appliedDiscountPercentages,
  returnState,
}) => {
  const css = useStyles()
  const currency = useCurrencyContext()
  const itemPrefix = `orderReturnLineItems[${itemIndex}]`
  const [dialogOpen, setDialogOpen] = useState(false)
  const productOptionValues = lineItem.data.variant.productOptionValues

  const itemPrice = useMemo(() => {
    if (lineItem.receivedQuantity === null) return '-'

    const amountInCents = appliedDiscountPercentages.reduce(
      (acc, appliedDiscountPercentage) => acc - lineItem.priceInCents * (appliedDiscountPercentage / 100),
      lineItem.priceInCents
    )

    const totalAmount = formik.getFieldMeta(`${itemPrefix}.receivedQuantity`).value * lineItem.priceInCents
    const actualAmount = Math.round(formik.getFieldMeta(`${itemPrefix}.receivedQuantity`).value * amountInCents)

    const actualAmountInCurrency = centsToCurrency(actualAmount, currency)
    const diffAmountInCurrency = centsToCurrency(totalAmount - actualAmount, currency)

    return actualAmount === totalAmount
      ? actualAmountInCurrency
      : `${actualAmountInCurrency} (${diffAmountInCurrency} promotions discount)`
  }, [lineItem, appliedDiscountPercentages, currency, formik, itemPrefix])

  const formattedConditionValues = useMemo(() => {
    const conditions = formik.getFieldMeta(`${itemPrefix}.orderReturnConditionsAttributes`).value

    if (!conditions || conditions.length === 0) {
      return ['--']
    } else {
      return conditions.map((condition: any) => {
        if (condition.lineItemQuantity && condition.conditionGrade) {
          return `${condition.conditionGrade[0]} (${condition.lineItemQuantity})`
        } else {
          return ['--']
        }
      })
    }
  }, [formik, itemPrefix])

  return (
    <>
      <div className={css.lineItem}>
        <div>
          <img
            src={lineItem.imageUrl}
            alt={lineItem.data.product.name}
            className={css.lineItemImage}
            onClick={() => setDialogOpen(true)}
            onError={(e: any) => {
              e.target.onerror = null
              e.target.src = lineItem.data.product.mainLargeImage?.processedUrl
            }}
          />
          <LineItemDialog
            lineItem={lineItem}
            countryName={countryName}
            open={dialogOpen}
            close={() => setDialogOpen(false)}
          />
        </div>
        <div>
          <h4>{lineItem.data.product.name}</h4>
          {productOptionValues.map((productOptionValue: any) => (
            <div key={productOptionValue.id}>
              {productOptionValue.productOption.name}: <strong>{productOptionValue.value}</strong>
            </div>
          ))}
          <div>{lineItem.data.variant.sku}</div>
          <div>{centsToCurrency(lineItem.priceInCents, currency)} each</div>
        </div>
        <div>
          <TextField
            margin="dense"
            fullWidth
            {...formik.getFieldProps(`${itemPrefix}.submittedQuantity`)}
            inputProps={{ type: 'number', min: 0, disabled: true }}
          />
        </div>
        <div>
          <TextField
            margin="dense"
            fullWidth
            {...formik.getFieldProps(`${itemPrefix}.receivedQuantity`)}
            inputProps={{ type: 'number', min: 0 }}
          />
        </div>
        <div>{itemPrice}</div>
        <div>
          {formattedConditionValues.map((val: string, idx: number) => (
            <div key={idx}>
              {val} <br />
            </div>
          ))}
        </div>
      </div>
      <div className={css.conditionValue}>
        <div></div>
        <ConditionValuePanel formik={formik} itemIndex={itemIndex} />
      </div>
    </>
  )
}

export default LineItemFields
