import { Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ButtonGroup, Button as MuiButton } from '@material-ui/core'

type Props = {
  setVerificationMethod: (method: string) => void
}

const MethodSelector = ({ setVerificationMethod }: Props) => {
  const css = useStyles()

  const methods = [
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'authenticator',
      label: 'Authenticator App',
    },
  ]
  return (
    <>
      <Typography className={css.introText}>
        For security reasons, we need to verify your account. Please select a method below.
      </Typography>
      <ButtonGroup className={css.buttonGroup} orientation="vertical" aria-label="vertical button group">
        {methods.map((method) => (
          <MuiButton key={method.key} onClick={() => setVerificationMethod(method.key)}>
            {method.label}
          </MuiButton>
        ))}
      </ButtonGroup>
    </>
  )
}

export default MethodSelector

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonGroup: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    introText: {
      color: '#666',
      fontSize: '0.95rem',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  })
)
