import React from 'react'
import { Grid } from '@material-ui/core'
import { TextField, MenuItem } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'

import CheckGroup from '../../components/CheckGroup/index'

type Props = {
  formik: any
  conditions: string[]
  itemIndex: number
  lineItemIndex: number
}

export const reasons = [
  'Perfect',
  'Incomplete pack',
  'Product label defects',
  'Product packaging defects',
  'Retailer tags',
]

const ConditionValueFields: React.FC<Props> = ({ formik, conditions, lineItemIndex, itemIndex }) => {
  const itemPrefix = `orderReturnLineItems[${lineItemIndex}].orderReturnConditionsAttributes[${itemIndex}]`

  const handleRemoveCondition = () => {
    const currentValue = formik.getFieldMeta(`orderReturnLineItems[${lineItemIndex}].orderReturnConditionsAttributes`)
      .value
    const newValue = [...currentValue.slice(0, itemIndex), ...currentValue.slice(itemIndex + 1)]
    formik.setFieldValue(`orderReturnLineItems[${lineItemIndex}].orderReturnConditionsAttributes`, newValue)
  }

  return (
    <>
      <Grid item xs={4}>
        <TextField
          margin="dense"
          label="Quantity"
          name={`${itemPrefix}.lineItemQuantity`}
          required
          fullWidth
          inputProps={{ type: 'number', min: 0 }}
          value={formik.getFieldMeta(`${itemPrefix}.lineItemQuantity`).value}
          onChange={(evt: any) => formik.setFieldValue(`${itemPrefix}.lineItemQuantity`, evt.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          select
          margin="dense"
          label="Item condition"
          required
          fullWidth
          name={`${itemPrefix}.conditionGrade`}
          value={formik.getFieldMeta(`${itemPrefix}.conditionGrade`).value || ''}
          onChange={(evt: any) => formik.setFieldValue(`${itemPrefix}.conditionGrade`, evt.target.value)}
        >
          {conditions.map((condition) => (
            <MenuItem key={condition} value={condition}>
              {condition}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={4}>
        {itemIndex !== 0 && <DeleteIcon onClick={() => handleRemoveCondition()} />}
      </Grid>
      <div>
        <h4>Reason for condition</h4>
      </div>
      <Grid item xs={12}>
        <CheckGroup
          options={reasons}
          value={formik.getFieldMeta(`${itemPrefix}.reasons`).value || []}
          onChange={(evt: any) => formik.setFieldValue(`${itemPrefix}.reasons`, evt.target.value)}
        />
      </Grid>
    </>
  )
}

export default ConditionValueFields
