import Button from 'components/Button'
import useProductImportsQueueCancel from 'hooks/useProductImportsQueueCancel'
import { useCallback } from 'react'

export function CancelButton({
  id,
  disabled,
  triggerRefresh,
}: {
  id: number
  disabled: boolean
  triggerRefresh?: () => void
}) {
  const { cancel, isLoading } = useProductImportsQueueCancel(id)

  const cb = useCallback(async () => {
    await cancel()
    triggerRefresh?.()
  }, [cancel, triggerRefresh])

  return (
    <Button danger small busy={isLoading} disabled={disabled} onClick={cb}>
      Cancel
    </Button>
  )
}
