import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const CSS = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'fixed',
      bottom: '24px',
      left: '24px',
      right: 'auto',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1400,
    },
    snackbar: {
      position: 'static',
      marginTop: '10px',
      minWidth: '200px',
      cursor: 'pointer',
    },
  })
)
