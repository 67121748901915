import { set } from 'lodash'
import { api } from 'services/api.service'
import { useEffect, useState } from 'react'
import { useAlert } from 'context/Alert/AlertContext'
import { BrandState } from 'util/getBrandStateMeta'
import useAction from 'hooks/useAction'

import { Brand } from 'types'

interface BrandParams {
  id: string
}

export default function useBrand({ id }: BrandParams) {
  const [brandProfile, setBrandProfile] = useState<Brand>()
  const snackbar = useAlert()

  const [fetchData, isLoading] = useAction({
    request: () => api.get(`/brands/${id}`),
    onSuccess: (resp) => setBrandProfile(resp.data),
    onError: () => snackbar.error(`Error fetching brand ${id}`),
  })

  const [saveField, saveFieldStatus] = useAction({
    request: (keyPath: string, value: any) => api.patch(`brands/${id}`, { brand_profile: set({}, keyPath, value) }),
    onSuccess: (resp) => setBrandProfile(resp.data),
  })

  const [saveAasmState] = useAction({
    request: ({ state, declineReason }: { state: BrandState; declineReason?: Brand['declineReason'] }) =>
      api.patch(`brands/${id}/state`, {
        ...(declineReason ? { brand_profile: declineReason } : {}),
        state: state,
      }),
    onSuccess: (resp) => setBrandProfile(resp.data),
    onError: () => snackbar.error('Failed to transition state. Please contact support'),
  })

  const [launchDropship] = useAction({
    request: () => api.patch(`brands/${id}/launch_dropship`),
    onSuccess: (resp) => setBrandProfile(resp.data),
    onError: () => snackbar.error('Failed launch brand dropship store. Please contact support'),
  })

  const [unlaunchDropship] = useAction({
    request: () => api.patch(`brands/${id}/unlaunch_dropship`),
    onSuccess: (resp) => setBrandProfile(resp.data),
    onError: () => snackbar.error('Failed unlaunch brand dropship store. Please contact support'),
  })

  const [waitlistDropship] = useAction({
    request: () => api.patch(`brands/${id}/waitlist_dropship`),
    onSuccess: (resp) => setBrandProfile(resp.data),
    onError: () => snackbar.error('Failed waitlist brand dropship store. Please contact support'),
  })

  const [approveDropship] = useAction({
    request: () => api.patch(`brands/${id}/approve_dropship`),
    onSuccess: (resp) => setBrandProfile(resp.data),
    onError: () => snackbar.error('Failed approve brand dropship store. Please contact support'),
  })

  const [declineDropship] = useAction({
    request: () => api.patch(`brands/${id}/decline_dropship`),
    onSuccess: (resp) => setBrandProfile(resp.data),
    onError: () => snackbar.error('Failed decline brand dropship store. Please contact support'),
  })

  const [saveFields, saveFieldsStatus] = useAction({
    request: (brandProfile) => api.patch(`brands/${id}`, { brandProfile }),
    onSuccess: (resp) => setBrandProfile(resp.data),
  })

  const [addExistingRelationship] = useAction({
    request: (retailerProfileId) => api.post(`brands/${id}/existing_relationship`, { retailerProfileId }),
    onSuccess: (resp: any) => setBrandProfile(resp.data),
  })

  const [deleteAllProducts] = useAction({
    request: () => api.post(`brands/${id}/remove_all_products`),
    onSuccess: () =>
      snackbar.success('Product deletion has started. It may take a few minutes for changes to reflect.'),
    onError: () => snackbar.error('Failed to delete products for this brand. Please contact support.'),
  })

  const [exportProducts] = useAction({
    request: () => api.post(`brands/${id}/export_products`),
    onSuccess: () =>
      snackbar.success('Product Export Job has started, you will receive an email with the product file shortly.'),
    onError: () => snackbar.error('Failed to export products for this brand. Please contact support.'),
  })

  const [uploadProducts] = useAction({
    request: (file, csvType, fileName) =>
      api.post(`brands/${id}/product_imports`, {
        product_import: { product_csv_file_data: file, csvType: csvType, brandId: id, fileName },
      }),
    onSuccess: () =>
      snackbar.success('Product CSVs successfully uploaded. It may take a few minutes for changes to reflect.'),
    onError: (err: any) => snackbar.error('Error uploading CSVs. Please contact support.'),
  })

  const [deleteBrand] = useAction({
    request: () => api.delete(`brands/${id}`),
    onSuccess: () => snackbar.success('Brand deleted'),
    onError: () => snackbar.error('Error deleting Brand. Please contact support.'),
  })

  const [resendApprovalEmail] = useAction({
    request: () => api.post(`brands/${id}/resend_approval_email`),
    onSuccess: () => snackbar.success('Email sent!'),
    onError: () => snackbar.error('Error sending email. Please contact support.'),
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return {
    fetchData,
    isLoading,
    brandProfile,
    saveField,
    saveFieldStatus,
    saveFields,
    saveFieldsStatus,
    saveAasmState,
    addExistingRelationship,
    deleteBrand,
    deleteAllProducts,
    launchDropship,
    unlaunchDropship,
    uploadProducts,
    exportProducts,
    resendApprovalEmail,
    waitlistDropship,
    approveDropship,
    declineDropship,
  }
}
