import clsx from 'clsx'
import { Link } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CallMade } from '@material-ui/icons'

export default function ExternalLink({ href, children, className = null, ...props }) {
  const css = useStyles()
  return children ? (
    <Link
      href={href}
      target="_blank"
      className={clsx(css.externalLink, className)}
      rel="noreferrer noopener"
      {...props}
    >
      {children}
      <CallMade />
    </Link>
  ) : null
}

const useStyles = makeStyles((theme) =>
  createStyles({
    externalLink: {
      '& svg': {
        fontSize: 'inherit',
      },
    },
  })
)
