import Panel from 'components/Panel'
import { HelloworksWorkflowInstance } from 'types/helloworksWorkflowInstance'
import HelloworksWorkflowInstanceItem from 'pages/Retailers/Retailer/HelloworksWorkflowInstanceItem'

export default function HelloworksWorkflowInstances({
  workflowInstances,
  startCollapsed,
  refreshWorkflowInstance,
}: {
  workflowInstances: HelloworksWorkflowInstance[]
  startCollapsed: boolean
  refreshWorkflowInstance: (workflowInstanceId: number) => Promise<void>
}) {
  return (
    <Panel title="Reseller Certification" startCollapsed={startCollapsed} collapsible>
      {workflowInstances
        .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
        .map((workflowInstance) => (
          <HelloworksWorkflowInstanceItem
            key={workflowInstance.id}
            workflowInstance={workflowInstance}
            refreshWorkflowInstance={() => refreshWorkflowInstance(workflowInstance.id)}
          />
        ))}
    </Panel>
  )
}
