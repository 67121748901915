import * as _ from 'lodash'
import { useMemo } from 'react'
import Button from 'components/Button'
import Definition from 'components/Definition'
import EditableDefinition from 'components/EditableDefinition'

import { EditableContext } from 'context/Editable/EditableContext'
import { useConfirm } from 'context/Confirm/ConfirmContext'

type TransferObject = {
  id: string
  scheduledAt: string
}

type Props = {
  transfer?: TransferObject
  onSave: (value: { id: string; scheduledAt: string } | { id: string | undefined; _destroy: true }) => Promise<any>
  processTransfer: (transfer: any) => Promise<any>
}

export function BrandTransfer({ transfer, onSave, processTransfer }: Props) {
  const { confirm } = useConfirm()
  const attrs = useMemo(() => extractTransferAttributes(transfer), [transfer])

  const handleCancelTransfer = () => {
    confirm({
      title: 'Cancel Transfer',
      text: 'Are you sure you would like to cancel the brand payment transfer for this order?',
      submitText: 'Submit',
      danger: true,
    }).then(() => {
      onSave({ id: transfer?.id, _destroy: true })
    })
  }

  return transfer ? WithTransfer(transfer) : WithOutTransfer()

  function WithOutTransfer() {
    return (
      <Definition label="Scheduled At">
        No Transfer Scheduled
        <br />
        <br />
      </Definition>
    )
  }

  function WithTransfer(transfer: TransferObject) {
    const transferCompleted = attrs.length > 0

    const processableTransfer = () => {
      if (
        !transferCompleted &&
        new Date().toLocaleDateString('en-ZA').slice(0, 10) === transfer.scheduledAt.slice(0, 10).replaceAll('-', '/')
      ) {
        return (
          <Button text onClick={processTransfer}>
            Process Transfer
          </Button>
        )
      }
    }

    return (
      <EditableContext.Provider value={{ disabled: transferCompleted }}>
        <EditableDefinition
          readOnly={false}
          label="Scheduled At"
          value={transfer.scheduledAt}
          format="datetime"
          asDatePicker
          onSave={(val: string) => onSave({ id: transfer.id, scheduledAt: val })}
        >
          {transfer.scheduledAt}
        </EditableDefinition>

        {attrs.map(([k, v]) => (
          <Definition key={k} label={k}>
            {v}
          </Definition>
        ))}

        {!transferCompleted && (
          <Button text onClick={handleCancelTransfer}>
            Cancel Transfer
          </Button>
        )}
        {processableTransfer()}
      </EditableContext.Provider>
    )
  }
}

// Show payment integration transfer ids if available
function extractTransferAttributes(transfer?: TransferObject) {
  if (!transfer) {
    return []
  }

  return Object.entries(_.omit(transfer, ['id', 'scheduledAt', 'createdAt', 'deletedAt', 'updatedAt']))
    .filter(([, v]) => v ?? false)
    .map(([k, v]): [string, unknown] => [_.startCase(k), v])
}
