import { api } from 'services/api.service'
import { useCallback, useEffect, useState } from 'react'
import { useAlert } from 'context/Alert/AlertContext'

export default function useBrand() {
  const [declineReason, setDeclineReason] = useState<string>('')
  const snackbar = useAlert()

  const getBrandDeclineReason = useCallback(() => {
    api
      .get(`/constants/brand_decline_reason`)
      .then((resp) => {
        setDeclineReason(resp.data)
      })
      .catch((err) => {
        snackbar.error('Error fetching brand decline reason.')
      })
  }, [snackbar])

  useEffect(() => {
    getBrandDeclineReason()
  }, [getBrandDeclineReason])

  return { declineReason }
}
