import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    searchField: {
      margin: theme.spacing(1),
      minWidth: 400,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 140,
    },
  })
)
