import { useEffect, useMemo, useState } from 'react'

import { api, cancelRequest } from 'services/api.service'
import { useAlert } from 'context/Alert/AlertContext'
import { Category, isParentCategory, isTertiaryCategory, isSecondaryCategory } from 'types/category'
import useAction from 'hooks/useAction'

const cancelKey = 'categories/index'

type Params = {
  filterFor?: 'all' | 'parentsOnly' | number
}

export default function useCategories({ filterFor = 'all' }: Params = {}) {
  const snackbar = useAlert()
  const [categories, setCategories] = useState<Category[]>([])

  const [fetchData, isLoading] = useAction({
    request: () => {
      const params =
        filterFor === 'all'
          ? {}
          : filterFor === 'parentsOnly'
          ? { parent_categories: 'true' }
          : { parent_category_id: filterFor }

      return api.get(`categories`, { params: { per_page: 100000, ...params }, cancelKey })
    },
    onSuccess: (resp) => {
      setCategories(resp.data.data)
    },
    onError: () => {
      snackbar.error('Error fetching categories')
    },
  })

  useEffect(() => {
    fetchData()
    return () => cancelRequest(cancelKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFor])

  const parentCategories = useMemo(() => categories.filter(isParentCategory), [categories])

  const secondaryCategories = useMemo(() => categories.filter(isSecondaryCategory), [categories])

  const tertiaryCategories = useMemo(() => categories.filter(isTertiaryCategory), [categories])

  return {
    isLoading,
    categories,
    setCategories,
    parentCategories,
    secondaryCategories,
    tertiaryCategories,
  }
}
