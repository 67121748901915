import { useCallback, useState } from 'react'

import { useAlert } from 'context/Alert/AlertContext'

import { api } from 'services/api.service'

export default function useProductImportsQueueCancel(id: number) {
  const snackbar = useAlert()
  const [isLoading, setIsLoading] = useState<boolean>()

  const cancel = useCallback(() => {
    setIsLoading(true)
    return api
      .patch(`/product_imports_queue/${id}/cancel`)
      .catch((err) => {
        console.error(err)
        snackbar.error('Failed to cancel import')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [id, snackbar])

  return { cancel, isLoading }
}
