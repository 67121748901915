import { Dialog } from '@material-ui/core'

import centsToCurrency from 'util/centsToCurrency'
import Definition from 'components/Definition'
import useStyles from './styles'

import { OrderReturnLineItem } from 'types'
import { useCurrencyContext } from 'context/Country/CountryContext'

type Props = {
  lineItem: OrderReturnLineItem
  countryName: string
  open: boolean
  close: any
}

const LineItemDialog: React.FC<Props> = ({ lineItem, countryName, open, close }) => {
  const css = useStyles()
  const currency = useCurrencyContext()

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        style: {
          maxWidth: 1000,
        },
      }}
    >
      <div className={css.modal}>
        <img
          src={lineItem.imageUrl}
          alt={lineItem.data.product.name}
          onError={(e: any) => {
            e.target.onerror = null
            e.target.src = lineItem.data.product.mainLargeImage?.processedUrl
          }}
        />
        <div>
          <h2>Variant</h2>
          <div className={css.modalContent}>
            <Definition label="Sku">{lineItem.data.variant.sku}</Definition>
            <Definition label="Weights"> {JSON.stringify(lineItem.data.variant.weights)}</Definition>
            <Definition label="Dimensions">{JSON.stringify(lineItem.data.variant.dimensions)}</Definition>
            <Definition label="Case Quantity">{lineItem.data.variant.caseQuantity}</Definition>
            <Definition label="MSRP">{centsToCurrency(lineItem.data.variant.msrpInCents, currency)}</Definition>
            <Definition label="Price">{centsToCurrency(lineItem.data.variant.priceInCents, currency)}</Definition>
          </div>
          <h2>Product</h2>
          <div className={css.modalContent}>
            <Definition label="Name">{lineItem.data.product.name}</Definition>
            <Definition label="Description">{lineItem.data.product.description}</Definition>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default LineItemDialog
