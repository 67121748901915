import { useParams } from 'react-router-dom'
import usePageTitle from 'hooks/usePageTitle'
import useLiveShoppingHighlight from 'hooks/useLiveShoppingHighlight'
import LiveShoppingHighlightForm from './LiveShoppingHighlightsForm'

interface Params {
  id: string
}

export default function LiveShoppingEdit() {
  const { id } = useParams<Params>()

  usePageTitle(`Edit Live Shopping Highlight ${id}`)

  const { liveShoppingHighlight, isLoading } = useLiveShoppingHighlight({ id })

  return (
    <div>
      {!isLoading && liveShoppingHighlight.brands && (
        <LiveShoppingHighlightForm
          action="update"
          currentLiveShoppingHighlight={liveShoppingHighlight}
          formTitle="Edit Live Shopping Highlight"
        />
      )}
    </div>
  )
}
