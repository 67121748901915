import { Grid } from '@material-ui/core'
import Panel from 'components/Panel'
import Button from 'components/Button'
import { HelloworksWorkflowInstance } from 'types/helloworksWorkflowInstance'
import Definition from 'components/Definition'
import parseDateTime from 'util/parseDateTime'

export default function HelloworksWorkflowInstanceItem({
  workflowInstance,
  refreshWorkflowInstance,
}: {
  workflowInstance: HelloworksWorkflowInstance
  refreshWorkflowInstance: () => void
}) {
  const linkToInstance = ['completed', 'submitted', 'active'].includes(workflowInstance.status)
  return (
    <Panel>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={10}>
          <Definition label="Status">{workflowInstance.status}</Definition>
        </Grid>
        <Grid item xs={12} lg={2}>
          <Button onClick={() => refreshWorkflowInstance()}>Refresh</Button>
        </Grid>
        <Grid item xs={6} lg={10}>
          <Definition label="Created">{parseDateTime(workflowInstance.createdAt)?.toFormat('ff')}</Definition>
        </Grid>
        <Grid item xs={12}>
          <Definition label="Instance ID">
            {linkToInstance && (
              <a
                href={`https://portal.helloworks.com/workflow/transactions/download/${workflowInstance.workflowInstanceId}`}
              >
                {workflowInstance.workflowInstanceId}
              </a>
            )}
            {!linkToInstance && <>{workflowInstance.workflowInstanceId}</>}
          </Definition>
        </Grid>
        <Grid item xs={12}>
          <Definition label="Workflow ID">{workflowInstance.workflowId}</Definition>
        </Grid>

        <hr />

        {workflowInstance.formData &&
          Object.entries(workflowInstance.formData).map(([key, value]) => (
            <Grid item xs={12} key={key}>
              <Definition label={key}>{value}</Definition>
            </Grid>
          ))}
      </Grid>
    </Panel>
  )
}
