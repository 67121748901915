import { useEffect, useState } from 'react'

import { sortBy } from 'lodash'

import { api } from 'services/api.service'

export type UseInprogressImportsParams = {
  brandId?: number
  disable?: boolean
}

export default function useInprogressImports({ brandId, disable = false }: UseInprogressImportsParams) {
  const POLL_INTERVAL_MS = 1000
  const [importIds, setImportIds] = useState<number[]>([])

  useEffect(() => {
    if (!brandId || disable) return

    const intervalId = setInterval(() => {
      api.get(`brands/${brandId}/product_imports/in_progress_recently`).then(({ data }) => {
        setImportIds(sortBy(data.in_progress, (id) => -id))
      })
    }, POLL_INTERVAL_MS)

    return () => {
      clearInterval(intervalId)
    }
  }, [brandId, disable])

  return importIds
}
