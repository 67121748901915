import NumberFormat from 'react-number-format'
import { Country, Countries } from 'types'

interface PhoneInputProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  country: Country
}

export default function PhoneInput(props: PhoneInputProps) {
  const { inputRef, onChange, country, ...rest } = props

  const format = {
    [Countries['United States'].name]: '+1 (###) ###-####',
    [Countries['United Kingdom'].name]: '+44 #### ######',
  }[country.name]

  return (
    <NumberFormat
      format={format}
      mask="_"
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
    />
  )
}
