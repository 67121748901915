import EditableDefinition from 'components/EditableDefinition'
import Panel from 'components/Panel'

export default function Returns({ order, saveField }: any) {
  return (
    <Panel title="Returns">
      <EditableDefinition
        label="Returnable Until"
        asDatePicker
        value={order.returnableUntil}
        format="datetime"
        onSave={(value: any) => saveField('returnableUntil', value)}
      >
        {order.returnableUntil}
      </EditableDefinition>
    </Panel>
  )
}
