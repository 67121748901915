import { useState } from 'react'
import { Paper } from '@material-ui/core'

import useCategories from 'hooks/useCategories'
import usePageTitle from 'hooks/usePageTitle'

import CategoriesDataGrid from './CategoriesDataGrid'
import CreateCategoryForm from 'pages/Categories/CreateCategory'

import Button from 'components/Button'
import PageHeader from 'components/PageHeader'

export default function Categories() {
  usePageTitle('Categories')
  const [isCreatingCategory, setIsCreatingCategory] = useState(false)
  const { isLoading, parentCategories, setCategories } = useCategories()

  return (
    <div>
      <h1 style={{ marginTop: 0 }}>Categories</h1>

      <PageHeader
        title=""
        contentRight={
          <Button primary onClick={() => setIsCreatingCategory(true)}>
            New Category
          </Button>
        }
      />

      <Paper elevation={0}>
        <CategoriesDataGrid isLoading={isLoading} categories={parentCategories} />
      </Paper>

      <CreateCategoryForm
        open={isCreatingCategory}
        onClose={() => setIsCreatingCategory(false)}
        parentId={null}
        taxonomyLevel={1}
        setCategories={setCategories}
      />
    </div>
  )
}
