import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'

import Panel from 'components/Panel'
import Button from 'components/Button'

import ConditionValueFields from 'pages/OrderReturns/ConditionValueFields'

type Props = {
  formik: any
  itemIndex: number
}

export const itemConditions = ['A - Sellable condition', 'B - Eligible for donation', 'C - Explicitly damaged']

const initialValues = {
  quantity: 0,
  grade: '',
  reasons: '',
}

const ConditionValuePanel: React.FC<Props> = ({ formik, itemIndex }) => {
  const currentConditions = useMemo(() => {
    return formik.getFieldMeta(`orderReturnLineItems[${itemIndex}].orderReturnConditionsAttributes`).value || []
  }, [itemIndex, formik])

  const handleAddCondition = () => {
    const newValue = [...currentConditions, initialValues]

    formik.setFieldValue(`orderReturnLineItems[${itemIndex}].orderReturnConditionsAttributes`, newValue)
  }

  const disableButton = () => {
    return currentConditions.length === 0 || currentConditions.length === 3
  }

  return (
    <Panel collapsible startCollapsed={currentConditions.length < 1}>
      <Grid container spacing={2}>
        <ConditionValueFields formik={formik} conditions={itemConditions} itemIndex={0} lineItemIndex={itemIndex} />
        {currentConditions.length > 1 &&
          currentConditions
            .slice(1)
            .map((_condition: object, idx: number) => (
              <ConditionValueFields
                formik={formik}
                conditions={itemConditions}
                itemIndex={idx + 1}
                lineItemIndex={itemIndex}
                key={idx}
              />
            ))}
        <Button onClick={() => handleAddCondition()} disabled={disableButton()}>
          {' '}
          Add Condition
        </Button>
      </Grid>
    </Panel>
  )
}

export default ConditionValuePanel
