import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'

import { Divider, Grid, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import useOrder from 'hooks/useOrder'
import usePageTitle from 'hooks/usePageTitle'
import { useAlert } from 'context/Alert/AlertContext'
import { useConfirm } from 'context/Confirm/ConfirmContext'

import Async from 'components/Async'
import Button from 'components/Button'
import PageHeader from 'components/PageHeader'
import Panel from 'components/Panel'
import TextFieldDialog from 'components/TextFieldDialog'
import LineItem from './LineItem'

import { LineItemValue } from './interfaces/index.interface'
import centsToCurrency from 'util/centsToCurrency'
import { CurrencyProvider } from 'context/Country/CountryContext'
import { getCountry } from 'types/country'
import { Currencies } from 'types/currency'

interface Params {
  id: string
}

export default function OrderEdit() {
  const css = useStyles()
  const snackbar = useAlert()
  const { confirm } = useConfirm()
  const { id } = useParams<Params>()
  const { isLoading, order, lineItems, saveFields, isSavingFields } = useOrder({ id })
  const [validationError, setValidationError] = useState('')
  const [showEditQtyDialog, setShowEditQtyDialog] = useState(false)
  const [showEditPriceDialog, setShowEditPriceDialog] = useState(false)
  const [editedLineItems, setEditedLineItems] = useState<any[]>([])
  const [editItem, setEditItem] = useState<any>({
    quantity: 0,
    originalQty: 0,
    priceInCents: 0,
    originalPriceInCents: 0,
    variant: {
      name: '',
    },
  })
  const appliedDiscountPercentage = get(order, ['appliedPromotions', 0, 'appliedDiscountPercentage'], 0)

  usePageTitle(`Edit Order ${id}`)

  useEffect(() => {
    setEditedLineItems(
      lineItems.map((item) => ({ ...item, originalQty: item.quantity, originalPriceInCents: item.priceInCents }))
    )
  }, [lineItems])

  const handleItemRemoval = (itemToRemove: LineItemValue) => {
    confirm({
      title: 'Remove Item',
      text: `Are you sure you would like to remove ${itemToRemove.variant.name} from this order?`,
      submitText: 'Remove',
      danger: true,
    })
      .then(() => {
        setEditedLineItems(
          editedLineItems.map((item) => (item.id === itemToRemove.id ? { ...item, _destroy: true, quantity: 0 } : item))
        )
      })
      .catch(() => Promise.resolve())
  }

  const confirmEditQty = (item: LineItemValue) => {
    setEditItem(item)
    setShowEditQtyDialog(true)
  }

  const confirmEditPrice = (item: LineItemValue) => {
    setEditItem(item)
    setShowEditPriceDialog(true)
  }

  const handleEditQty = (qty: string) => {
    const quantity = parseInt(qty)
    if (quantity < 0) {
      setValidationError('Line Item quantity must be greater than or equal to 0')
    } else {
      setValidationError('')
      setEditedLineItems(
        editedLineItems.map((item) =>
          item.id === editItem.id ? { ...item, quantity, edited: quantity !== item.originalQty } : item
        )
      )
      setShowEditQtyDialog(false)
    }
  }

  const handleEditPrice = (price: string) => {
    const priceInCents = parseInt(price)
    if (priceInCents < 0) {
      setValidationError('Line Item price must be greater than or equal to 0')
    } else {
      setValidationError('')
      setEditedLineItems(
        editedLineItems.map((item) =>
          item.id === editItem.id ? { ...item, priceInCents, edited: priceInCents !== item.originalPriceInCents } : item
        )
      )
      setShowEditPriceDialog(false)
    }
  }

  const calcItemsTotal = (items: LineItemValue[]) =>
    items.map((item) => item.priceInCents * item.quantity).reduce((acc, curr) => acc + curr, 0)

  const handleOrderUpdate = () => {
    const editableFields = editedLineItems
      .filter((item: LineItemValue) => item.deletedAt === null)
      .map((item: LineItemValue) =>
        item._destroy
          ? { id: item.id, _destroy: item._destroy }
          : { id: item.id, quantity: +item.quantity, priceInCents: +item.priceInCents }
      )

    saveFields({ line_items_attributes: editableFields })
      .then(() => {
        snackbar.success('Order successfully updated.')
      })
      .catch(() => {})
  }

  const currency = getCountry(get(order, 'brandProfile.user.country.name'))?.currency ?? Currencies.USD
  const editedLineItemsTotal = calcItemsTotal(editedLineItems)
  const lineItemsTotal = calcItemsTotal(lineItems)
  const hasEditedItems = editedLineItems.some((item) => item._destroy || item.edited)
  const priceChange = lineItemsTotal - editedLineItemsTotal

  return (
    <CurrencyProvider currency={currency}>
      <PageHeader backLink={`/orders/${id}/items`} title="Edit Order">
        Order #{order.uid}
      </PageHeader>

      <Async loading={isLoading}>
        <TextFieldDialog
          title="Edit Quantity"
          message={`Adjust the quantity for ${editItem.variant.name}`}
          inputLabel="Quantity"
          shown={showEditQtyDialog}
          onCancel={() => setShowEditQtyDialog(false)}
          onConfirm={handleEditQty}
          inputProps={{ type: 'number', min: 0 }}
          textFieldProps={{ error: !!validationError, helperText: validationError }}
          initialValue={'' + editItem.quantity}
        />
        <TextFieldDialog
          title="Edit Price in Cents"
          message={`Adjust the price in cents for ${editItem.variant.name}`}
          inputLabel="Price in Cents"
          shown={showEditPriceDialog}
          onCancel={() => setShowEditPriceDialog(false)}
          onConfirm={handleEditPrice}
          inputProps={{
            type: 'number',
            min: 0,
          }}
          textFieldProps={{ error: !!validationError, helperText: validationError }}
          initialValue={'' + editItem.priceInCents}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} lg={9}>
            <Panel title="Edit Products">
              {editedLineItems
                .filter((item: LineItemValue) => item.deletedAt === null)
                .map((item: LineItemValue, idx: number) =>
                  item._destroy ? (
                    <s className={css.deleted} key={idx}>
                      <Typography color="textSecondary">{item.variant.name}</Typography>
                    </s>
                  ) : (
                    <div className={css.item} key={idx}>
                      <LineItem item={item} appliedDiscountPercentage={appliedDiscountPercentage} />
                      {item.edited && <div className={css.updated}>original quantity {item.originalQty}</div>}
                      {item.edited && (
                        <div className={css.updated}>
                          original price {centsToCurrency(item.originalPriceInCents, currency)}
                        </div>
                      )}
                      <Button text className={css.itemAction} onClick={() => confirmEditQty(item)}>
                        Edit quantity
                      </Button>
                      <Button text className={css.itemAction} onClick={() => confirmEditPrice(item)}>
                        Edit price
                      </Button>
                      <Button text className={css.itemAction} onClick={() => handleItemRemoval(item)}>
                        Remove Item
                      </Button>
                    </div>
                  )
                )}
            </Panel>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Panel title="Summary" className={css.summary}>
              {!hasEditedItems ? (
                <Typography gutterBottom variant="body2">
                  No changes have been made
                </Typography>
              ) : (
                <>
                  <Typography className={css.pricing}>
                    Total:
                    <span>{centsToCurrency(lineItemsTotal, currency)}</span>
                  </Typography>
                  <Typography className={css.pricing}>
                    Updated Total:
                    <span>{centsToCurrency(editedLineItemsTotal, currency)}</span>
                  </Typography>
                  <Divider className={css.spacing} />
                  {priceChange > 0 && (
                    <Typography className={css.pricing}>
                      Amount to Refund:
                      <span>{centsToCurrency(priceChange, currency)}</span>
                    </Typography>
                  )}
                  {priceChange < 0 && (
                    <Typography className={css.pricing}>
                      Amount to Charge:
                      <span>{centsToCurrency(priceChange * -1, currency)}</span>
                    </Typography>
                  )}
                  <Divider className={css.spacing} />
                  <Typography>
                    NOTE: Changes here will <span className={css.warning}>NOT</span> update charges or notify customers.
                  </Typography>
                </>
              )}
              <Button
                primary
                fullWidth
                disabled={!hasEditedItems}
                busy={isSavingFields}
                onClick={handleOrderUpdate}
                className={css.spacing}
              >
                Update Order
              </Button>
            </Panel>
          </Grid>
        </Grid>
      </Async>
    </CurrencyProvider>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    summary: {
      position: 'sticky',
      top: theme.spacing(10),
    },
    item: {
      padding: theme.spacing(2, 0),
      borderBottom: '1px solid rgba(0,0,0,.1)',
      '&:last-child': {
        border: 0,
      },
    },
    itemAction: {
      marginRight: theme.spacing(1),
    },
    updated: {
      display: 'list-item',
      listStyleType: 'disc',
      listStylePosition: 'inside',
      margin: theme.spacing(-1, 0, 1, 1),
      color: '#578194',
    },
    deleted: {
      display: 'block',
      padding: theme.spacing(1, 0),
      borderBottom: '1px solid rgba(0,0,0,.1)',
      '&:last-child': {
        border: 0,
      },
    },
    pricing: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
    spacing: {
      margin: theme.spacing(3, 0),
    },
    warning: {
      color: theme.palette.error.main,
    },
  })
)
