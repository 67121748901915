import { capitalize, get } from 'lodash'
import { RouteComponentProps } from 'react-router-dom'
import { Chip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import { useConfirm } from 'context/Confirm/ConfirmContext'
import useOrderReturn from 'hooks/useOrderReturn'
import usePageTitle from 'hooks/usePageTitle'
import useReturnsShippingAddress from 'hooks/useReturnsShippingAddress'
import Definition from 'components/Definition'
import Panel from 'components/Panel'
import PageHeader from 'components/PageHeader'
import Async from 'components/Async'
import Link from 'components/Link'
import PageActionsMenu from 'components/PageActionsMenu'
import ShippingLabel from './ShippingLabels'

import LineItemsForm from './LineItemsForm'
import useStyles from './styles'
import { CurrencyProvider } from 'context/Country/CountryContext'
import { Countries, getCountry } from 'types/country'
import { useCallback, useMemo, useState } from 'react'
import ShippingLabelForm from 'pages/OrderReturns/ShippingLabelForm'
import Button from 'components/Button'
import { useAbility } from '../../hooks/useAbility'
import centsToCurrency from 'util/centsToCurrency'

type TParams = { id: string }

const ship = 'ship'
const cancel = 'cancel'
const complete = 'complete'
const deliver = 'deliver'
const initiate = 'initiate'

const transitionsWithConfirmation = [initiate]

export default function OrderReturn({ match }: RouteComponentProps<TParams>) {
  const css = useStyles()
  const { id } = match.params
  const {
    isLoading,
    error,
    orderReturn,
    changeState,
    isChangingState,
    saveLineItems,
    processReturn,
    formError,
    fetchData,
  } = useOrderReturn({
    id,
  })
  const [showShippingLabelForm, setShowShippingLabelForm] = useState(false)
  const stateTransitions = new Set(orderReturn.availableAasmStateTransitions)
  const ability = useAbility()

  const { confirm } = useConfirm()
  const handleSubmitLineItems = (fields: any) => {
    return confirm({
      title: 'Save Line Items',
      text: 'Are you sure you want to save changes to line items for this order return?',
      submitText: 'Save changes',
    }).then(() => {
      saveLineItems(fields).catch(Promise.reject)
    })
  }

  const processOrderReturn = (fields: any) => {
    return confirm({
      title: 'Process Return',
      text: 'Are you sure you want to process this return with these updates?',
      submitText: 'Process Return',
    }).then(() => {
      processReturn(fields).catch(Promise.reject)
    })
  }

  const handleTransition = useCallback(
    (transition) => () => {
      if (transitionsWithConfirmation.includes(transition))
        return confirm({
          title: `${capitalize(transition)} return?`,
          text: `Are you sure you want to ${transition} the return?`,
          submitText: 'Confirm',
        }).then(() => changeState(transition))
      return changeState(transition)
    },
    [changeState, confirm]
  )
  const handleShippingFormClose = () => {
    setShowShippingLabelForm(false)
    fetchData()
  }

  usePageTitle(`Order Return ${id}`)

  const [country, currency] = useMemo(() => {
    const country = getCountry(get(orderReturn, 'brandProfile.user.country.name')) ?? Countries['United States']
    const currency = country.currency
    return [country, currency]
  }, [orderReturn])

  const { returnsShippingAddress, returnAddressLoading } = useReturnsShippingAddress()

  return (
    <CurrencyProvider currency={currency}>
      <Async loading={isLoading} error={error}>
        <PageHeader
          title={`Return ${orderReturn.uid || '...'} for Order ${orderReturn.order?.uid || '...'}`}
          backLink="/returns"
          beside={<Chip label={capitalize(orderReturn.aasmState)} />}
          contentRight={
            <PageActionsMenu
              items={[
                {
                  label: 'Set Shipped',
                  callback: handleTransition(ship),
                  disabled: !stateTransitions.has(ship) || isChangingState,
                },
                {
                  label: 'Set Canceled',
                  callback: handleTransition(cancel),
                  disabled: !stateTransitions.has(cancel) || isChangingState,
                },
                {
                  label: 'Set Completed',
                  callback: handleTransition(complete),
                  disabled: !stateTransitions.has(complete) || isChangingState,
                },
                {
                  label: `Set Delivered`,
                  callback: handleTransition(deliver),
                  disabled: !stateTransitions.has(deliver) || isChangingState,
                },
                {
                  label: 'Set Initiated',
                  callback: handleTransition(initiate),
                  disabled: !stateTransitions.has(initiate) || isChangingState,
                },
              ]}
            />
          }
        />
        <Panel>
          <div className={css.flex}>
            <Definition label="Return">{orderReturn.uid}</Definition>
            <Definition label="Order">
              {orderReturn.order &&
                (ability.can('show', 'Order') ? (
                  <Link to={`/orders/${orderReturn.order?.id}`}>{orderReturn.order?.uid}</Link>
                ) : (
                  `${orderReturn.order.uid}`
                ))}
            </Definition>
            <Definition label="Submitted at" format="datetime">
              {orderReturn.submittedAt}
            </Definition>
            <Definition label="Packages">{orderReturn.numberOfPackages}</Definition>
            <Definition label="Return Status">{capitalize(orderReturn.aasmState)}</Definition>
            {orderReturn.shippedAt && (
              <Definition label="Shipped on" format="datetime">
                {orderReturn.shippedAt}
              </Definition>
            )}
            {orderReturn.deliveredAt && (
              <Definition label="Delivered on" format="datetime">
                {orderReturn.deliveredAt}
              </Definition>
            )}
            {orderReturn.processedBy?.fullName && (
              <Definition label="Processed by">{orderReturn.processedBy.fullName}</Definition>
            )}
            {orderReturn.processedAt && (
              <Definition label="Processed on" format="datetime">
                {orderReturn.processedAt}
              </Definition>
            )}
          </div>
        </Panel>

        <div className={css.twoCols}>
          <Panel title="Details">
            <div className={css.threeCols}>
              <Definition label="Ordered Item Qty">{orderReturn?.order?.totalItemQuantity}</Definition>
              <Definition label="Submitted Qty">{orderReturn?.submittedItemsQuantity}</Definition>
              <Definition label="Received Qty">{orderReturn?.receivedItemsQuantity}</Definition>
              <Definition label="Original Order Total">
                {centsToCurrency(orderReturn?.order?.retailerTotalInCents, currency)}
              </Definition>
              <Definition label="Submitted Return Value">
                {centsToCurrency(orderReturn?.itemsSubmittedTotalInCents, currency)}
              </Definition>
              <Definition label="Received Return Value">
                {centsToCurrency(orderReturn?.itemsReceivedTotalInCents, currency)}
              </Definition>
            </div>
          </Panel>

          <Panel title="Charges">
            <div className={css.threeCols}>
              <Definition label="State">{orderReturn.order?.charge.aasmState}</Definition>
              <Definition label="Payment Terms">{orderReturn.order?.charge.paymentTerms}</Definition>
              <Definition label="Scheduled At" format="datetime">
                {orderReturn.order?.charge.scheduledAt}
              </Definition>
              <Definition label="Payment ID">
                <a
                  href={'https://dashboard.stripe.com/payments/' + orderReturn.order?.charge.stripePaymentIntentId}
                  target="_blank"
                  rel="noreferrer"
                >
                  {orderReturn.order?.charge.stripePaymentIntentId}
                </a>
              </Definition>
              <Definition label="Capture Failed At" format="datetime">
                {orderReturn.order?.charge.captureFailedAt}
              </Definition>
              <Definition label="Original Charge">
                {centsToCurrency(orderReturn.order?.charge.amountToCapture, currency)}
              </Definition>
              <Definition label="Refund Total">
                {centsToCurrency(orderReturn.itemsReceivedTotalInCents, currency)}
              </Definition>
            </div>
          </Panel>
        </div>

        <Panel title="Feedback">
          <Definition label="Reasons">
            {(orderReturn?.reasons || []).map((reason: string) => (
              <li key={reason}>{reason}</li>
            ))}
          </Definition>
          <Definition label="Brand feedback">{orderReturn.brandFeedback}</Definition>
          <Definition label="Abound feedback">{orderReturn.aboundFeedback}</Definition>
        </Panel>

        <Panel title="Line Items">
          {orderReturn.orderReturnLineItems && (
            <LineItemsForm
              orderReturn={orderReturn}
              onSubmit={orderReturn.processedBy ? handleSubmitLineItems : processOrderReturn}
              formError={formError}
            />
          )}
        </Panel>

        <Panel
          title="Shipping Labels"
          contentRight={
            <Button
              busy={returnAddressLoading}
              onClick={() => setShowShippingLabelForm(true)}
              startIcon={<AddIcon />}
              disabled={!orderReturn.canCreateShippingLabel}
            >
              Create Shipping Label
            </Button>
          }
        >
          {orderReturn.shippingLabels &&
            orderReturn.shippingLabels.map((label: any) => <ShippingLabel key={label.id} shippingLabel={label} />)}
          {returnsShippingAddress && (
            <ShippingLabelForm
              orderReturnId={orderReturn.id}
              country={country}
              shipFrom={orderReturn.orderShippingAddress}
              shipTo={returnsShippingAddress}
              open={showShippingLabelForm}
              onClose={handleShippingFormClose}
            />
          )}
        </Panel>
      </Async>
    </CurrencyProvider>
  )
}
