import React, { useEffect } from 'react'
import { Backdrop, LinearProgress, Typography, Card } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import useConnectionStatus from 'hooks/useConnectionStatus'

export default function ConnectionCheck(): JSX.Element | null {
  const { connected } = useConnectionStatus()
  const css = useStyles()

  useEffect(() => {
    document.body.style.overflow = connected ? '' : 'hidden'
  }, [connected])

  if (connected) {
    return null
  }

  return (
    <Backdrop open className={css.backdrop}>
      <Card className={css.card}>
        <Typography variant="h6">Reconnecting to server</Typography>
        <br />
        <LinearProgress color="primary" />
      </Card>
    </Backdrop>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    backdrop: {
      zIndex: 9999,
    },
    card: {
      backgroundColor: 'whitesmoke',
      textAlign: 'center',
      opacity: 0.85,
      padding: '4em',
    },
  })
)
