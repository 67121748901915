import { Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'

interface Props {
  backLink?: string
  title?: JSX.Element | string
  beside?: JSX.Element | string
  contentRight?: any
  children?: any
}

const PageHeader: React.FC<Props> = ({ backLink, title, beside, contentRight, children }) => {
  const css = useStyles()
  const history = useHistory()

  const goBack = () => {
    if (document.referrer.includes(window.location.host)) {
      history.goBack()
    } else {
      history.push(`${backLink}`)
    }
  }

  return (
    <header className={css.root}>
      {backLink && (
        <Button className={css.link} onClick={goBack} disableRipple>
          <ArrowBackIcon />
        </Button>
      )}
      {title && (
        <Typography variant="h5" component="h1" className={css.title}>
          {title}
        </Typography>
      )}
      <Typography component="div">{beside}</Typography>
      <div className={css.contentRight}>{contentRight}</div>
      <aside className={css.children}>{children}</aside>
    </header>
  )
}

export default PageHeader

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    link: {
      marginRight: theme.spacing(2),
      border: 0,
      background: 'transparent',
      padding: 0,
      margin: 0,
      minWidth: 0,
      '&:hover, &:focus, &:active': {
        border: 0,
        background: 'transparent',
      },
    },
    title: {
      marginRight: theme.spacing(2),
    },
    contentRight: {
      marginLeft: 'auto',
    },
    children: {
      width: '100%',
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(5),
    },
  })
)
