import { PropsWithChildren } from 'react'
import { Typography } from '@material-ui/core'

import { Currency } from 'types/currency'
import centsToCurrency from 'util/centsToCurrency'

export function MoneyCell(props: PropsWithChildren<{ amount: number; currency: Currency }>) {
  const { amount, currency, children } = props
  return (
    <>
      <Typography variant="body2">{centsToCurrency(amount, currency)}</Typography>
      {children}
    </>
  )
}
