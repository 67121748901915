/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const identity = (v: any) => v
const stringy = (value: any) => '' + value

interface Params {
  key: string
  defaultValue: any
  parse?: (stringValue: string) => any
  stringify?: (value: any) => string | null
}

/**
 * Encodes a state variable in the url query params.
 * Each call to setValue will push a new location onto
 * the react-router history.
 */
export default function useQueryParam({
  key,
  defaultValue,
  parse = identity,
  stringify = stringy,
}: Params): [value: any, setValue: (value: any) => void] {
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  function getParsedOrDefaultValue(value: any) {
    try {
      return parse(value) ?? defaultValue
    } catch {
      return defaultValue
    }
  }

  // Use the url as the source of truth for the initial state
  // otherwise fall back to defaultValue
  const [storedValue, setStoredValue] = useState(() => getParsedOrDefaultValue(searchParams.get(key)))

  const setValue = (value: any) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value
    setStoredValue(valueToStore)
    if (valueToStore !== undefined && stringify(valueToStore) !== null) {
      searchParams.set(key, stringify(valueToStore) as string)
    } else {
      searchParams.delete(key)
    }

    const [url] = location.pathname.split('?')
    history.push(`${url}?${searchParams.toString()}`)
  }

  useEffect(() => {
    const parsedValue = getParsedOrDefaultValue(searchParams.get(key))
    if (parsedValue !== storedValue) {
      setStoredValue(parsedValue || defaultValue)
    }
    return () => {
      searchParams.delete(key)
    }
  }, [location.search])

  return [storedValue, setValue]
}
