import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@material-ui/core'
import Button from 'components/Button'

interface Props {
  title?: string
  text?: string
  open: boolean
  onCancel: Function
  onSubmit: Function
  submitText?: string
  danger?: boolean
}

const Confirmation = ({ title, text, submitText = 'OK', onSubmit, onCancel, open, danger }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={() => onCancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <Divider />
      <DialogContent style={{ marginTop: '10px' }}>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>Cancel</Button>
        <Button onClick={() => onSubmit()} primary danger={danger} autoFocus>
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Confirmation
