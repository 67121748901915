import { DataGridProps, GridCellParams, GridColDef } from '@material-ui/data-grid'

import { Category } from 'types/category'

import Link from 'components/Link'
import DataGrid, { booleanCell } from 'components/DataGrid'

type CategoriesDataGridProps = {
  title?: string
  isLoading?: boolean
  categories: Category[]
} & Partial<DataGridProps>

const pageSize = 50

export default function CategoriesDataGrid({ isLoading = false, categories, ...rest }: CategoriesDataGridProps) {
  return (
    <DataGrid
      {...rest}
      autoHeight
      disableColumnMenu
      rowsPerPageOptions={[]}
      rows={categories}
      sortingMode="client"
      paginationMode="client"
      columns={categoryColumns}
      rowCount={categories.length}
      pageSize={pageSize}
      loading={isLoading}
    />
  )
}

const categoryColumns: GridColDef[] = [
  {
    headerName: 'ID',
    field: 'id',
    flex: 0,
  },
  {
    headerName: 'Name',
    field: 'displayName',
    flex: 1,
    renderCell: ({ row, value }: GridCellParams) => <Link to={`/categories/${row.id}`}>{value}</Link>,
  },
  {
    headerName: 'Description',
    field: 'description',
    flex: 2,
  },
  {
    headerName: 'Featured',
    field: 'featured',
    ...booleanCell,
    flex: 0,
  },
  {
    headerName: 'Managed By Admin',
    field: 'managedByAdmin',
    ...booleanCell,
    flex: 0,
  },
  {
    headerName: 'Dropship',
    field: 'dropship',
    ...booleanCell,
    flex: 0,
  },
]
