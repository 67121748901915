import { useCallback, useMemo, useState } from 'react'
import { Paper, IconButton } from '@material-ui/core'
import { GridCellParams, GridColDef } from '@material-ui/data-grid'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import useProductSettings from 'hooks/useProductSettings'
import usePageTitle from 'hooks/usePageTitle'
import useProductSettingActions from 'hooks/useProductSettingActions'

import DataGrid, { dateCell } from 'components/DataGrid'
import Button from 'components/Button'
import PageHeader from 'components/PageHeader'

import { useConfirm } from 'context/Confirm/ConfirmContext'

import ProductSettingForm from './ProductSettingForm'

import { ProductSetting } from 'types/product_setting'

const newProductSettings: ProductSetting = {
  key: '',
  value: '',
}

export default function ProductSettings() {
  usePageTitle('ProductSettings')

  const {
    isLoading,
    productSettings,
    productSettingsCount,
    handlePageChange,
    fetchData,
    pageSize,
  } = useProductSettings({})
  const {
    delete: { submit },
  } = useProductSettingActions()
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false)
  const [isUpdateFormOpen, setIsUpdateFormOpen] = useState(false)

  const [productSettingToUpdate, setProductSettingToUpdate] = useState<ProductSetting | null>(null)

  const { confirm } = useConfirm()

  const handleRefreshProductSetting = useCallback(() => {
    fetchData().catch((err) => console.error(err))
  }, [fetchData])

  const handleOpenProductSettings = useCallback((productSetting: ProductSetting) => {
    setProductSettingToUpdate(productSetting)
    setIsUpdateFormOpen(true)
  }, [])

  const handleOpenDeleteProductSettings = useCallback(
    (productSetting: ProductSetting) => {
      confirm({
        title: 'Delete Product Setting',
        text: `Are you sure you want to delete ${productSetting.key} ?`,
        submitText: 'Delete',
      })
        .then(() => {
          submit(productSetting).then(() => {
            handleRefreshProductSetting()
          })
        })
        .catch(() => Promise.resolve())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirm, handleRefreshProductSetting]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => productSettingsColumns(handleOpenProductSettings, handleOpenDeleteProductSettings), [
    handleOpenProductSettings,
  ])

  return (
    <div>
      <PageHeader
        title="Product Settings"
        contentRight={
          <Button primary onClick={() => setIsCreateFormOpen(true)}>
            Add
          </Button>
        }
      />

      <ProductSettingForm
        open={isCreateFormOpen}
        action="create"
        productSettings={newProductSettings}
        formTitle="Create new Product Setting"
        onClose={() => setIsCreateFormOpen(false)}
        onSuccess={handleRefreshProductSetting}
      />

      {productSettingToUpdate && (
        <ProductSettingForm
          open={isUpdateFormOpen}
          action="update"
          productSettings={productSettingToUpdate}
          formTitle="Edit Product Setting"
          onClose={() => {
            setIsUpdateFormOpen(false)
            setProductSettingToUpdate(null)
          }}
          onSuccess={handleRefreshProductSetting}
        />
      )}

      <Paper elevation={0}>
        <DataGrid
          autoHeight
          disableColumnMenu
          rowsPerPageOptions={[]}
          rows={productSettings}
          columns={columns}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={handlePageChange}
          loading={isLoading}
          pagination
          rowCount={productSettingsCount}
        />
      </Paper>
    </div>
  )
}

const productSettingsColumns: (
  updateProduct: (p: ProductSetting) => void,
  deleteProduct: (p: ProductSetting) => void
) => GridColDef[] = (updateProduct, deleteProduct) => [
  {
    headerName: 'Key',
    field: 'key',
    sortable: false,
    width: 250,
  },
  {
    headerName: 'Value',
    field: 'value',
    sortable: false,
    flex: 2,
  },
  {
    headerName: 'Created At',
    field: 'createdAt',
    sortable: false,
    flex: 1,
    ...dateCell,
  },
  {
    headerName: 'Updated At',
    sortable: false,
    field: 'updatedAt',
    flex: 1,
    ...dateCell,
  },
  {
    headerName: 'Actions',
    sortable: false,
    field: '',
    flex: 1,
    renderCell: ({ row }: GridCellParams) => (
      <>
        <IconButton
          onClick={() => {
            updateProduct(row as ProductSetting)
          }}
        >
          <EditIcon />
        </IconButton>

        <IconButton
          onClick={() => {
            deleteProduct(row as ProductSetting)
          }}
        >
          <DeleteIcon />
        </IconButton>
      </>
    ),
  },
]
