import Definition from 'components/Definition'
import Link from 'components/Link'
import Panel from 'components/Panel'
import Address from 'components/Address'

import { getCountry, Countries } from 'types'

const RetailerPanel = ({ retailer }: any) => {
  if (!Object.keys(retailer).length) return null
  return (
    <Panel title="Retailer">
      <Definition label="Full Name">
        <Link to={`/retailers/${retailer.id}`}>{retailer.user?.fullName}</Link>
      </Definition>
      <Definition label="Email">{retailer.user?.email}</Definition>
      <Definition label="Store Name">{retailer.storeName}</Definition>
      <Definition label={`Shipping Address`}>
        {retailer.shippingAddress && (
          <Address
            address={retailer.shippingAddress}
            country={getCountry(retailer?.user?.country.name) ?? Countries['United States']}
          />
        )}
      </Definition>
      <Definition label="Country">{retailer?.user?.country.name}</Definition>
    </Panel>
  )
}

export default RetailerPanel
