import { Paper } from '@material-ui/core'
import { GridCellParams } from '@material-ui/data-grid'

import useBrands from 'hooks/useBrands'
import useQueryParam from 'hooks/useQueryParam'
import usePageTitle from 'hooks/usePageTitle'
import useCategories from 'hooks/useCategories'
import useIndexStyle from 'hooks/useIndexStyle'
import DataGrid from 'components/DataGrid'
import DebouncedTextField from 'components/DebouncedTextField'
import BrandStateChip from 'components/BrandStateChip'
import Link from 'components/Link'
import SelectField from 'components/SelectField'
import getBrandStateMeta, { brandStates } from 'util/getBrandStateMeta'

export default function Brands() {
  const css = useIndexStyle()
  usePageTitle('Brands')
  const [query, setQuery]: any = useQueryParam({
    key: 'query',
    defaultValue: '',
  })
  const handleQueryChange = (evt: any) => {
    setQuery(evt.target.value)
  }

  const [brandState, setBrandState]: any = useQueryParam({
    key: 'state',
    defaultValue: null,
    parse: (value) => (value ? value : null),
    stringify: (value) => (value ? '' + value : ''),
  })

  const [dropshipStatus, setDropshipStatus]: any = useQueryParam({
    key: 'dropship_status',
    defaultValue: null,
    parse: (value) => (value ? value : null),
    stringify: (value) => (value ? '' + value : ''),
  })

  const handleChangeBrandState = (evt: any) => {
    setBrandState(evt.target.value)
  }

  const [brandCategoryId, setBrandCategoryId]: any = useQueryParam({
    key: 'category',
    defaultValue: null,
    parse: (value) => (value ? parseInt(value) : null),
    stringify: (value) => (value ? '' + value : ''),
  })

  const handleChangeBrandCategory = (evt: any) => {
    setBrandCategoryId(evt.target.value)
  }

  const handleChangeDropshipStatus = (evt: any) => {
    setDropshipStatus(evt.target.value)
  }

  const { isLoading, brandProfiles, brandProfilesCount, handlePageChange, pageSize } = useBrands({
    query,
    brandState,
    brandCategoryId,
    dropshipStatus,
  })

  const { parentCategories } = useCategories()
  const dropshipStatusOptions = [{ label: 'Enabled', value: 'enabled' }]

  return (
    <div>
      <h1 style={{ marginTop: 0 }}>Brand Profiles</h1>
      <Paper variant="outlined" style={{ padding: 16, marginBottom: 24 }}>
        <DebouncedTextField
          className={css.searchField}
          label="Search by brand name"
          value={query}
          onChange={handleQueryChange}
        />
        <SelectField
          nullable
          className={css.formControl}
          label="Brand state"
          value={brandState}
          options={brandStates.map((state) => ({
            label: getBrandStateMeta(state).label,
            value: state,
          }))}
          onChange={handleChangeBrandState}
        />
        <SelectField
          nullable
          className={css.formControl}
          label="Brand category"
          value={brandCategoryId}
          options={parentCategories.map((category) => ({
            label: category.name,
            value: category.id,
          }))}
          onChange={handleChangeBrandCategory}
        />
        <SelectField
          nullable
          className={css.formControl}
          label="Dropship status"
          value={dropshipStatus}
          options={dropshipStatusOptions}
          onChange={handleChangeDropshipStatus}
        />
      </Paper>
      <Paper elevation={0}>
        <DataGrid
          rows={brandProfiles}
          columns={brandProfilesColumns}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={handlePageChange}
          loading={isLoading}
          pagination
          rowCount={brandProfilesCount}
        />
      </Paper>
    </div>
  )
}

const brandProfilesColumns = [
  {
    headerName: 'Brand Name',
    field: 'companyName',
    flex: 1,
    renderCell: ({ row, value }: GridCellParams) => (
      <Link to={`/brands/${row.id}`} style={{ display: 'block' }}>
        {value}
      </Link>
    ),
  },
  {
    headerName: 'Full Name',
    field: 'user.fullName',
    flex: 1,
  },
  {
    headerName: 'Email',
    field: 'user.email',
    flex: 1,
  },
  {
    headerName: 'State',
    field: 'aasmState',
    flex: 1,
    renderCell: ({ value }: any) => <BrandStateChip state={value} size="small" />,
  },
  {
    headerName: 'Created',
    field: 'createdAt',
    flex: 1,
  },
  {
    headerName: 'Country',
    field: 'user.country.name',
    width: 150,
  },
  {
    headerName: 'Deleted_at',
    field: 'deletedAt',
    type: 'date',
    width: 150,
  },
]
