import { useFormik } from 'formik'
import * as yup from 'yup'
import { Dialog, DialogTitle, DialogActions, DialogContent, Divider, TextField } from '@material-ui/core'

import Button from 'components/Button'
import useCreateResource from 'hooks/useCreateResource'
import { Autocomplete } from '@material-ui/lab'
import useCategories from 'hooks/useCategories'

const formSchema = yup.object({
  category: yup
    .object({
      id: yup.string().required(),
      label: yup.string(),
    })
    .required(),
})

interface Props {
  open: boolean
  onClose: () => void
  onComplete: () => void
  productTypeId: string
}

interface CreateMappingFields {
  category: { label: string; id: string } | null
}

export default function CreateMappingForm({ open, onClose, onComplete, productTypeId }: Props) {
  const { tertiaryCategories, isLoading } = useCategories()
  const { createResource } = useCreateResource({
    url: 'product_type_categories',
    resourceName: 'product type category',
  })

  const formik = useFormik<CreateMappingFields>({
    initialValues: {
      category: null,
    },
    onSubmit: async (values) => {
      try {
        const response = await createResource({ categoryId: values.category?.id, productTypeId })
        onComplete()
        return response
      } finally {
        onClose()
        formik.resetForm()
      }
    },
    validationSchema: formSchema,
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  if (!open) {
    return null
  }

  return (
    <Dialog open={true} onClose={handleClose} fullWidth>
      <form onSubmit={formik.handleSubmit} style={{ minHeight: 400 }}>
        <DialogTitle>New Product Type Mapping</DialogTitle>
        <Divider />

        <DialogContent>
          <Autocomplete
            disablePortal
            options={tertiaryCategories.map((category) => ({ label: category.displayName, id: category.id }))}
            renderInput={(params) => <TextField {...params} label="Category" />}
            value={formik.getFieldProps('category').value}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(_, newValue) => {
              formik.setFieldValue('category', newValue)
            }}
            loading={isLoading}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          <Button type="submit" primary autoFocus disabled={!formik.isValid} busy={formik.isSubmitting}>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
