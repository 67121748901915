import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  editLabel: { display: 'flex', justifyContent: 'space-between' },
  iconButton: {
    fontSize: 18,
    opacity: 0.2,
  },
  iconButtonWrapper: {
    padding: '6px',
  },
})

export default useStyles
