import axios from 'axios'
import { useState } from 'react'
import useSafeDispatch from 'hooks/useSafeDispatch'
import { useAlert } from 'context/Alert/AlertContext'
import { useHistory } from 'react-router-dom'

interface Params {
  request: (...args: any[]) => Promise<any>
  onSuccess?: (resp: any, requestArgs: any[]) => void
  onError?: (error: any) => void
}

type ReturnType = [
  /** Your request wrapped in a promise so we can handle loading & error state */
  request: (...args: any[]) => Promise<any>,
  /** Request Loading/Submitting state */
  isPending: boolean,
  /** Any error returned by the request promise */
  error: any
]

export default function useAction({ request, onSuccess, onError }: Params): ReturnType {
  const [pending, _setPending] = useState<boolean>(false)
  const [error, _setError] = useState<any>()

  const safeSetPending = useSafeDispatch(_setPending)
  const safeSetError = useSafeDispatch(_setError)
  const snackbar = useAlert()
  const history = useHistory()

  const action = (...args: any[]) => {
    safeSetPending(true)
    return new Promise((resolve, reject) => {
      request(...args).then(
        (resp: any) => {
          safeSetPending(false)
          if (onSuccess) onSuccess(resp, args)
          resolve(resp)
        },
        (error: any) => {
          if (!axios.isCancel(error)) {
            safeSetError(error.response?.data?.error || error.message || error)
            if (error.response?.status === 403) {
              if (error.response?.data?.error_code === 'DEVICE_VERIFICATION_REQUIRED') {
                history.push('/verify')
                return
              }
              snackbar.error(`You don't have permission`)
              return
            }
            if (onError) onError(error)
            reject(error)
          }
          safeSetPending(false)
        }
      )
    })
  }

  return [action, pending, error]
}
