import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import Panel from 'components/Panel'
import Definition from 'components/Definition'
import usePageTitle from 'hooks/usePageTitle'
import useProfile from 'hooks/useProfile'
import { QRCodeSVG } from 'qrcode.react'
import ExternalLink from 'components/ExternalLink'

export default function Profile() {
  const title = 'Profile'
  usePageTitle(title)

  const { profile } = useProfile()
  const css = useStyles()

  return (
    <>
      <h1 style={{ marginTop: 0 }}>{title}</h1>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Panel title="General">
            <Definition label="Full Name">{profile.fullName}</Definition>
            <Definition label="Email">{profile.email}</Definition>
            <Definition label="Role">{profile.role}</Definition>
            <Definition label="IP Address">{profile.ipAddress}</Definition>
          </Panel>
        </Grid>
        <Grid item xs={4}>
          <Panel title="MFA">
            <Typography className={css.description}>
              <p>
                Use the QR code or secret key below to configure an MFA app that will allow you to verify your account
                on login – an alternative to receiving a verification code via email.
              </p>
              <p>
                <ExternalLink
                  href={`https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US`}
                >
                  Google Authenticator
                </ExternalLink>{' '}
                and
                <ExternalLink href={`https://authy.com/guides/twilio/`}>Twilio Authy</ExternalLink> are examples of
                supported apps.
              </p>
            </Typography>
            <Definition label="QR Code">
              <QRCodeSVG value={profile.provisioningUri} />
            </Definition>
            <Definition label="Secret Key">{profile.otpSecretKey}</Definition>
          </Panel>
        </Grid>
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      fontSize: '0.85rem',
      '& p': {
        marginTop: theme.spacing(0),
      },
    },
  })
)
