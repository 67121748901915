import { useCallback, useState } from 'react'

import algoliasearch from 'algoliasearch'
import { InstantSearch, Configure } from 'react-instantsearch-dom'

import SearchBox from './SearchBox'
import ResultsBox from './ResultsBox'

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID || '',
  process.env.REACT_APP_ALGOLIA_API_KEY || ''
)

const Search = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClose = useCallback(() => setAnchorEl(null), [])
  const handleOpen = useCallback((event: any) => {
    setAnchorEl((el) => (el ? null : event.currentTarget))
  }, [])

  return (
    <InstantSearch indexName={`admin_${process.env.REACT_APP_ENV}_brand_profiles`} searchClient={searchClient}>
      <Configure hitsPerPage={3} />
      <SearchBox onOpen={handleOpen} onClose={handleClose} />
      <ResultsBox open={open} anchorEl={anchorEl} close={handleClose} />
    </InstantSearch>
  )
}

export default Search
