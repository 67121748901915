import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import Definition from 'components/Definition'
import ExternalLink from 'components/ExternalLink'
import usePickRenameMap from 'hooks/usePickRenameMap'

type Props = {
  shippingLabel: {
    aasmState: string
    id: number
    carrierCode: string
    cost: string
    createdAt: string
    fileUrl: string
    trackingId: string
  }
}

export default function ShippingLabel({ shippingLabel }: Props) {
  const css = useStyles()

  const entries = usePickRenameMap(
    {
      keys: ['id', 'carrierCode', 'cost', 'fileUrl', 'aasmState', 'trackingId', 'createdAt'],
      map: {
        fileUrl: (val) => <ExternalLink href={val}>label</ExternalLink>,
      },
    },
    shippingLabel
  )

  return (
    <div className={css.shippingLabel}>
      {entries.map(([key, val]) => (
        <Definition key={key} label={key}>
          {val}
        </Definition>
      ))}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shippingLabel: {
      padding: 5,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      '&:not(:last-child)': {
        borderBottom: '1px solid rgba(0,0,0,.1)',
        marginBottom: 5,
      },
    },
  })
)
