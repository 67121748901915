import * as yup from 'yup'
import { useMemo } from 'react'
import { useFormik } from 'formik'
import { DateTime } from 'luxon'
import SelectField from 'components/SelectField'
import ReactPlayer from 'react-player'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

import { InputAdornment, TextField, Checkbox } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Button from 'components/Button'
import { useAlert } from 'context/Alert/AlertContext'

import { LiveShoppingHighlight } from 'types/live_shopping_highlight'
import useLiveShoppingHighlightActions from 'hooks/useLiveShoppingHighlightActions'
import LiveShoppingHighlightBrand from './LiveShoppingHighlightBrand'

import BrandAutocomplete from 'components/BrandAutocomplete'

const escapeRegExp = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping

const dateFormat: string = 'yyyy-MM-dd'
const videoIdPrefixes = ['https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/', 'https://youtu.be/']
const videoIdPrefix = videoIdPrefixes.map((prefix) => escapeRegExp(prefix)).join('|')
const videoIdChars = '[a-zA-Z0-9_-]+'
const videoIdSuffix = '[&?#].*'
const videoIdPattern = new RegExp(`^(?:${videoIdPrefix})?(?<videoId>${videoIdChars})?(?:${videoIdSuffix})?$`, 'i')

type FormProps = {
  currentLiveShoppingHighlight: LiveShoppingHighlight
  formTitle: string
  action: 'create' | 'update'
}

export default function LiveShoppingHighlightForm({ currentLiveShoppingHighlight, formTitle, action }: FormProps) {
  const actions = useLiveShoppingHighlightActions()

  const { submit, isLoading } = useMemo<typeof actions.create>(() => actions[action], [actions, action])

  const { error } = useAlert()

  const schema = yup
    .object({
      name: yup.string().defined(),
      eventDate: yup
        .string()
        .defined()
        .test('valid-date', '', (value) => value !== undefined && DateTime.fromFormat(value, dateFormat).isValid),
      videoId: yup.string().defined(),
      description: yup.string().defined(),
      videoType: yup.string().defined(),
    })
    .defined()

  const formik = useFormik({
    initialValues: currentLiveShoppingHighlight,
    validationSchema: schema,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (data: LiveShoppingHighlight) => {
      return submit({
        ...data,
      })
        .then(() => {
          formik.resetForm()
          // redirect back to index
          history.push(`/live_shopping_highlights`)
        })
        .catch((err) => {
          error(err)
        })
    },
  })

  let history = useHistory()

  const videoTypeOptions = [
    { label: 'Live Shopping', value: 'live_shopping' },
    { label: 'New Arrivals', value: 'new_arrivals' },
    { label: 'Maker Spotlights', value: 'maker_spotlights' },
    { label: 'Unboxings', value: 'unboxings' },
    { label: 'Retail 101', value: 'retail_101' },
  ]

  const initialBrands = currentLiveShoppingHighlight && currentLiveShoppingHighlight.brands
  const emptyBrands = {}
  const [selectedBrandList, setSelectedBrandList] = useState<{ [key: number]: [] }>(initialBrands ?? emptyBrands)

  const onRemoveBrandEvt = (brandKey: number) => {
    if (selectedBrandList[brandKey]) {
      const newSelected = { ...selectedBrandList }
      delete newSelected[brandKey]
      setSelectedBrandList(newSelected)
    }
  }

  const startBrand = (brand: any) => {
    let newBrandList = { ...selectedBrandList }

    if (brand && brand.id) {
      newBrandList[brand.id] = []
      setSelectedBrandList({ ...newBrandList })

      let currentBrands = formik.values.brands || {}

      if (currentBrands instanceof Map) {
        currentBrands.set(brand.id, [])
        formik.setFieldValue('brands', Object.fromEntries(currentBrands))
      } else {
        currentBrands = { ...currentBrands, [brand.id]: [] }
        formik.setFieldValue('brands', currentBrands)
      }
    }
  }

  const updateSelectedBrandList = (updatedBrandList: any) => {
    setSelectedBrandList(updatedBrandList)
  }

  const handleThumbnailUpload = (e: any) => {
    let reader = new FileReader()
    let file = e.target.files[0]
    let filename = e.target.value.split('\\').pop()

    reader.onloadend = () => {
      if (reader.readyState === 2) {
        let thumbnailData = { data: reader.result, filename: filename }
        formik.setFieldValue('thumbnailData', thumbnailData)
      }
    }
    reader.readAsDataURL(file)
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          size="small"
          label="Name"
          margin="normal"
          {...formik.getFieldProps('name')}
          error={formik.touched.name && formik.errors.name !== undefined}
        />
        <KeyboardDatePicker
          fullWidth
          disableToolbar
          format={dateFormat}
          label="Date"
          margin="dense"
          {...formik.getFieldProps('eventDate')}
          value={formik.values.eventDate ? DateTime.fromFormat(formik.values.eventDate, dateFormat) : null}
          onChange={(luxonDate?: any) => {
            formik.setFieldValue('eventDate', luxonDate && luxonDate.isValid ? luxonDate.toFormat(dateFormat) : null)
            formik.setFieldTouched('eventDate')
            formik.validateForm()
          }}
          placeholder={DateTime.now().toFormat(dateFormat)}
          error={formik.touched.eventDate && formik.errors.eventDate !== undefined}
          invalidDateMessage={null}
        />

        <h2>Description</h2>
        <textarea id="description" {...formik.getFieldProps('description')} rows={20} cols={100}></textarea>

        <SelectField
          fullWidth
          label="Video Type"
          margin="normal"
          options={videoTypeOptions}
          {...formik.getFieldProps('videoType')}
          error={formik.errors.videoType !== undefined}
          helperText={formik.errors.videoType}
        />

        <label>
          <Checkbox checked={formik.values.horizontalVideo} {...formik.getFieldProps('horizontalVideo')} />
          Horizontal video?
        </label>

        <h2>Thumbnail image</h2>

        {currentLiveShoppingHighlight.thumbnailData?.filename && (
          <div>Current thumbnail: {currentLiveShoppingHighlight.thumbnailData.filename}</div>
        )}

        <div>
          <input
            type="file"
            name={formik.getFieldProps('thumbnailData').name}
            onChange={handleThumbnailUpload}
            onBlur={formik.getFieldProps('thumbnailData').onBlur}
          />
        </div>

        <TextField
          fullWidth
          size="small"
          label="Video"
          margin="normal"
          {...formik.getFieldProps('videoId')}
          onChange={(event: any) => {
            const match = event.target.value.match(videoIdPattern)
            if (match) {
              const { videoId } = match.groups
              formik.setFieldValue('videoId', videoId || '')
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" disablePointerEvents style={{ marginRight: 0 }}>
                {videoIdPrefixes[0]}
              </InputAdornment>
            ),
          }}
          error={formik.touched.videoId && formik.errors.videoId !== undefined}
          helperText="You may either paste the video's full URL (multiple formats supported) or enter just the ID"
        />
        <ReactPlayer width="100%" height="100%" url={`${videoIdPrefixes[0]}${formik.values.videoId || '0'}`} />

        <div id="highlight-brands">
          {Object.entries(selectedBrandList).map(([key, value]) => {
            return (
              <>
                <LiveShoppingHighlightBrand
                  liveShoppingHighlight={currentLiveShoppingHighlight}
                  formik={formik}
                  onRemove={onRemoveBrandEvt}
                  brandKey={parseInt(key)}
                  key={key}
                  presetBrand={{ id: parseInt(key), companyName: '' }}
                  productIds={value}
                  updateBrandList={updateSelectedBrandList}
                />
              </>
            )
          })}
          <BrandAutocomplete
            style={{ display: 'inline-block', minWidth: 250 }}
            onChange={(evt: any, brand: any) => startBrand(brand)}
          />
        </div>

        <Button onClick={() => history.goBack()}>Cancel</Button>

        <Button
          type="submit"
          primary
          disabled={!formik.isValid}
          busy={isLoading || formik.isValidating || formik.isSubmitting}
        >
          Submit
        </Button>
      </form>
    </>
  )
}
