import { api } from 'services/api.service'
import { useCallback, useState } from 'react'
import { useAlert } from 'context/Alert/AlertContext'

interface BecomeUserParams {
  id?: number
}

export default function useBecomeUser({ id }: BecomeUserParams) {
  const [isLoading, setIsLoading] = useState(false)
  const { addAlert } = useAlert()

  const becomeUser = useCallback(
    () =>
      api
        .post(`users/${id}/become`)
        .then(() => {
          setIsLoading(false)
          window.open(process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000', '_blank')
        })
        .catch((err: any) => {
          console.error(err)
          setIsLoading(false)
          addAlert('Error becoming a user', 'error')
        }),
    [id, addAlert]
  )

  return {
    becomeUser,
    isLoading,
  }
}
