import { api } from 'services/api.service'

import useAction from './useAction'
import { useAlert } from 'context/Alert/AlertContext'

import { LiveShoppingHighlight } from 'types/live_shopping_highlight'

export default function useLiveShoppingHighlightActions() {
  const { success, error } = useAlert()

  const [createLiveShoppingHighlight, isCreatingLiveShoppingHighlight] = useAction({
    request: ({ brandProfile, ...liveShoppingHighlight }: LiveShoppingHighlight) =>
      api.post('live_shopping_highlights', { ...liveShoppingHighlight }),
    onSuccess: () => success('Live Shopping Highlight Created'),
    onError: (_err) => error('Error Creating Live Shopping Highlight'),
  })

  const [updateLiveShoppingHighlight, isUpdatingLiveShoppingHighlight] = useAction({
    request: ({ id, brandProfile, ...liveShoppingHighlight }: LiveShoppingHighlight) =>
      api.patch(`live_shopping_highlights/${id}`, { ...liveShoppingHighlight }),
    onSuccess: () => success('Live Shopping Highlight Updated'),
    onError: (_err) => error('Error Updating Live Shopping Highlight'),
  })

  const [deleteLiveShoppingHighlight, isDeleteLiveShoppingHighlight] = useAction({
    request: ({ id }: LiveShoppingHighlight) => api.delete(`live_shopping_highlights/${id}`),
    onSuccess: () => success('Live Shopping Highlight Deleted'),
    onError: (_err) => error('Error Deleting Live Shopping Highlight'),
  })

  return {
    create: {
      submit: createLiveShoppingHighlight,
      isLoading: isCreatingLiveShoppingHighlight,
    },
    update: {
      submit: updateLiveShoppingHighlight,
      isLoading: isUpdatingLiveShoppingHighlight,
    },
    delete: {
      submit: deleteLiveShoppingHighlight,
      isLoading: isDeleteLiveShoppingHighlight,
    },
  }
}
