import React, { useState, useCallback, useMemo } from 'react'

import { Snackbar, Slide } from '@material-ui/core'
import { SnackbarCloseReason, SnackbarOrigin } from '@material-ui/core/Snackbar'
import { Alert, Color } from '@material-ui/lab'

import { AlertItem } from './interface'
import { CSS } from './styles'

type AlertActions = {
  addAlert: (message: string, type?: Color, duration?: number) => void
  success: (message: string, duration?: number) => void
  error: (message: string, duration?: number) => void
  info: (message: string, duration?: number) => void
  warning: (message: string, duration?: number) => void
}

const noop: AlertActions = {
  addAlert: () => {},
  success: () => {},
  error: () => {},
  info: () => {},
  warning: () => {},
}

const AlertContext = React.createContext<AlertActions>(noop)

export const useAlert = () => React.useContext(AlertContext)

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [alerts, setAlerts] = useState<AlertItem[]>([])
  const position: SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  }
  const classes = CSS()

  const addAlert: AlertActions['addAlert'] = useCallback((message, type = 'error', duration) => {
    setAlerts((current) => [
      ...current,
      {
        id: new Date().getTime(),
        message: message,
        severity: type,
        duration: duration ?? (type === 'error' ? 20000 : 3000),
        open: true,
      },
    ])
  }, [])

  const handleClose = (event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason, item: AlertItem) => {
    if (reason === 'clickaway') {
      return
    }
    setAlerts((current) => current.map((alert: any) => (alert.id !== item.id ? alert : { ...alert, open: false })))
    setAlerts((current) => current.filter((alert) => alert.id !== item.id))
  }

  const value = useMemo(
    () => ({
      addAlert,
      success: (message: string, duration?: number) => addAlert(message, 'success', duration),
      error: (message: string, duration?: number) => addAlert(message, 'error', duration),
      info: (message: string, duration?: number) => addAlert(message, 'info', duration),
      warning: (message: string, duration?: number) => addAlert(message, 'warning', duration),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <AlertContext.Provider value={value}>
      {children}

      <div className={classes.container}>
        {alerts.map((item: AlertItem) => (
          <Snackbar
            className={classes.snackbar}
            key={item.id}
            open={item.open}
            anchorOrigin={position}
            TransitionComponent={Slide}
            autoHideDuration={item.duration}
            onClose={(event, reason) => handleClose(event, reason, item)}
          >
            <Alert severity={item.severity}>{item.message}</Alert>
          </Snackbar>
        ))}
      </div>
    </AlertContext.Provider>
  )
}
