import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Card, Typography, Tooltip } from '@material-ui/core'

import { LineItemValue } from './interfaces/index.interface'
import centsToCurrency from 'util/centsToCurrency'
import { useCurrencyContext } from 'context/Country/CountryContext'

interface LineItemProps {
  item: LineItemValue
  appliedDiscountPercentage: number
}

export default function LineItem({ item, appliedDiscountPercentage }: LineItemProps) {
  const css = useStyles()
  const currency = useCurrencyContext()

  const unitPrice = centsToCurrency(item.priceInCents, currency)
  const subtotalPrice = centsToCurrency(item.quantity * item.priceInCents, currency)
  const totalDiscountedPrice = centsToCurrency(
    item.quantity * item.priceInCents * (1 - appliedDiscountPercentage / 100.0),
    currency
  )

  return (
    <Card className={css.root} variant="outlined">
      <div>
        <Typography>{item.variant.name}</Typography>
        {item.variant.productOptionValues.map((variant) => (
          <Typography color="textSecondary" gutterBottom>
            {variant.productOption.name} : {variant.value}
          </Typography>
        ))}
        <Typography color="textSecondary" gutterBottom>
          SKU {item.variant.sku}
        </Typography>
        <Typography className={css.pricing}>
          {item.quantity} x {unitPrice}
        </Typography>
      </div>
      <div>
        <Typography className={css.pricing}>{subtotalPrice}</Typography>
        {totalDiscountedPrice !== subtotalPrice && (
          <Tooltip title={`Price after ${appliedDiscountPercentage}% discount`} arrow>
            <Typography color="textSecondary" gutterBottom>
              {totalDiscountedPrice}
            </Typography>
          </Tooltip>
        )}
      </div>
    </Card>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      margin: theme.spacing(0, 1, 2, 1),
      border: 0,
      borderRadius: 0,
      '&:last-child': {
        border: 0,
      },
    },
    pricing: {
      fontWeight: 'bold',
    },
  })
)
