import { CircularProgress, Button as MuiButton, ButtonProps as MuiButtonProps, Fade } from '@material-ui/core'
import useStyles from './styles'

export interface ButtonProps extends Omit<MuiButtonProps, 'component'> {
  /** If true a loading indicator will be rendered and the button will be disabled */
  busy?: boolean
  /** Render a red button, use for destructive actions. */
  danger?: boolean
  /** Render a plain text button (no outline or background). */
  text?: boolean
  /** Use for the primary action on the page/surface. Renders a solid button. */
  primary?: boolean
  /** Render a small button */
  small?: boolean
  /** Test name for automation */
  testName?: string
  /** Component to render as (eg: `Link`) */
  component?: string | Function
  /**
   * We whitelist this prop as a convenience for rendering as a ReactRouter Link.
   * Use with the `component` prop.
   * eg: <Button component={Link} to='path'>Go</Button>
   */
  to?: string
}

type Variant = 'contained' | 'outlined' | 'text'

const Button: React.FC<ButtonProps> = ({
  busy,
  children,
  className,
  danger,
  disabled,
  text,
  primary,
  small,
  style = {},
  testName,
  ...rest
}) => {
  const css = useStyles()
  let variant: Variant = 'outlined'
  if (primary) variant = 'contained'
  if (text) variant = 'text'

  return (
    <MuiButton
      variant={variant}
      color={danger ? 'secondary' : 'primary'}
      disabled={disabled || busy}
      disableElevation
      style={style}
      className={className}
      data-test-name={testName}
      size={small ? 'small' : undefined}
      {...rest}
    >
      <span style={{ opacity: busy ? 0.5 : 1 }}>{children}</span>
      <Fade in={busy} unmountOnExit>
        <CircularProgress size={16} className={css.loader} />
      </Fade>
    </MuiButton>
  )
}

export default Button
