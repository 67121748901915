import DataGrid from 'components/DataGrid'
import useProductTypeCategories from 'hooks/useProductTypeCategories'
import { getProductTypeCategoryColumns } from './productTypeCategoryColumns'
import { Grid } from '@material-ui/core'
import Panel from 'components/Panel'
import Button from 'components/Button'
import { useState } from 'react'
import CreateMappingForm from './components/CreateMappingForm'
import useAction from 'hooks/useAction'
import { api } from 'services/api.service'
import { useAlert } from 'context/Alert/AlertContext'

interface CategoryMappingsParams {
  productTypeId: string
}

export default function CategoryMappings({ productTypeId }: CategoryMappingsParams) {
  const [showCreateForm, setShowCreateForm] = useState(false)
  const snackbar = useAlert()

  const [deleteProductTypeCategory] = useAction({
    request: (id) => api.delete(`product_type_categories/${id}`),
    onSuccess: () => {
      snackbar.success('Category deleted')
    },
    onError: (err) => {
      console.error(err)
      snackbar.error('Failed to delete category')
    },
  })

  const {
    productTypeCategories,
    pageSize,
    productTypeCategoriesCount,
    handlePageChange,
    isLoading,
    refetch,
  } = useProductTypeCategories({ productTypeId })

  const handleProductTypeCategoryDelete = async (id: string) => {
    await deleteProductTypeCategory(id)
    refetch()
  }

  return (
    <>
      <Grid item xs={12}>
        <Panel
          title="Category Mappings"
          contentRight={<Button onClick={() => setShowCreateForm(true)}>New Mapping</Button>}
        >
          <DataGrid
            rows={productTypeCategories}
            columns={getProductTypeCategoryColumns({ onDelete: handleProductTypeCategoryDelete })}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={handlePageChange}
            loading={isLoading}
            pagination
            rowCount={productTypeCategoriesCount}
          />
        </Panel>
      </Grid>
      <CreateMappingForm
        open={showCreateForm}
        onClose={() => setShowCreateForm(false)}
        onComplete={() => refetch()}
        productTypeId={productTypeId}
      />
    </>
  )
}
