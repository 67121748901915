import { api } from 'services/api.service'

import useAction from './useAction'
import { ProductSetting } from 'types/product_setting'
import { useAlert } from 'context/Alert/AlertContext'

export default function useProductSettingActions() {
  const { success, error } = useAlert()

  const [createProductSetting, isCreatingProductSetting] = useAction({
    request: (productSetting: ProductSetting) => api.post('product_settings', { ...productSetting }),
    onSuccess: () => success('Product Setting Created'),
    onError: (_err) => error('Error Creating Product Settings'),
  })

  const [updateProductSetting, isUpdatingProductSetting] = useAction({
    request: ({ id, ...productSetting }: ProductSetting) => api.patch(`product_settings/${id}`, { ...productSetting }),
    onSuccess: () => success('Product Setting Updated'),
    onError: (_err) => error('Error Updating Product Settings'),
  })

  const [deleteProductSetting, isDeleteProductSetting] = useAction({
    request: ({ id }: ProductSetting) => api.delete(`product_settings/${id}`),
    onSuccess: () => success('Product Setting Deleted'),
    onError: (_err) => error('Error Deleting Product Settings'),
  })

  return {
    create: {
      submit: createProductSetting,
      isLoading: isCreatingProductSetting,
    },
    update: {
      submit: updateProductSetting,
      isLoading: isUpdatingProductSetting,
    },
    delete: {
      submit: deleteProductSetting,
      isLoading: isDeleteProductSetting,
    },
  }
}
