import { connectSearchBox } from 'react-instantsearch-dom'

import { TextField, InputAdornment } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import useStyles from './styles'
import { useCallback } from 'react'

interface Props {
  refine: (...args: any[]) => any
  currentRefinement: string
  isSearchStalled: boolean
  onOpen: (evt: any) => void
  onClose: (evt: any) => void
}

const SearchBox = connectSearchBox<Props>(({ currentRefinement, refine, onOpen, onClose }) => {
  const css = useStyles()
  const handleOnBlur = useCallback(
    (evt: any) => {
      setTimeout(() => onClose(evt), 200)
    },
    [onClose]
  )

  return (
    <form noValidate action="" role="search" onFocus={onOpen} onBlur={handleOnBlur}>
      <TextField
        className={css.root}
        placeholder="Search"
        type="search"
        value={currentRefinement}
        onChange={(event) => refine(event.currentTarget.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          className: css.input,
        }}
      />
    </form>
  )
})

export default SearchBox
