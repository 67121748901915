import { Divider } from '@material-ui/core'
import Definition from 'components/Definition'
import Link from 'components/Link'
import Panel from 'components/Panel'

const BrandPanel = ({ brand }: any) => {
  if (!Object.keys(brand).length) return null
  return (
    <Panel title="Brand">
      <Definition label="Brand Name">
        <Link to={`/brands/${brand.id}`}>{brand.companyName}</Link>
      </Definition>
      <Definition label="Full Name">{brand.user?.fullName}</Definition>
      <Definition label="Email">{brand.user?.email}</Definition>
      <Divider />
      <br />
      {/* TODO: add number of orders here */}
      <Definition label="Country">{brand.user?.country.name}</Definition>
    </Panel>
  )
}
export default BrandPanel
