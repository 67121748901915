import React from 'react'
import clsx from 'clsx'
import { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { Collapse } from '@material-ui/core'
import { useFormik } from 'formik'

import Button from 'components/Button'
import { useStyles } from 'components/Editable'
import { useEditableContext } from 'context/Editable/EditableContext'

type Props = {
  fields: any
  onSave: (value: any) => Promise<any>
  className?: string
  style?: any
  formikParams?: any
  readOnly?: boolean
  renderFields: (formik: any) => JSX.Element
  renderToggleEdit?: (onClick: () => void) => JSX.Element
}

const MiniForm: React.FC<Props> = ({
  renderFields,
  fields,
  onSave,
  children,
  className = null,
  style,
  formikParams = {},
  readOnly,
  renderToggleEdit,
  ...rest
}) => {
  const css = useStyles()
  const [isEditing, setIsEditing]: any = useState(false)

  const handleToggleEdit = () => {
    formik.resetForm({ values: fields })
    if (isEditing) {
      setIsEditing(false)
      formik.setErrors({})
    } else {
      setIsEditing(true)
    }
  }

  const handleSubmit = (fields: any) => {
    onSave(fields)
      .then(() => {
        formik.setSubmitting(false)
        setIsEditing(false)
      })
      .catch((err: any) => {
        formik.setSubmitting(false)
        formik.setErrors({
          form: err.response?.data?.error || err.message || 'Error saving changes.',
        })
      })
  }

  const formik = useFormik({
    initialValues: fields,
    onSubmit: handleSubmit,
    ...formikParams,
  })

  const { disabled } = useEditableContext()
  readOnly = readOnly || disabled

  const toggleEdit = readOnly ? null : renderToggleEdit ? (
    !isEditing && renderToggleEdit(handleToggleEdit)
  ) : (
    <div>
      <IconButton className={css.iconButtonWrapper} onClick={handleToggleEdit}>
        <EditIcon className={css.iconButton} />
      </IconButton>
    </div>
  )

  return (
    <div
      className={clsx(css.root, className)}
      style={style}
      {...rest}
      onDoubleClick={!isEditing ? handleToggleEdit : () => {}}
    >
      <Collapse unmountOnExit in={isEditing}>
        <form onSubmit={formik.handleSubmit} className={css.editWrapper}>
          <div>{renderFields(formik)}</div>
          <div className={css.actions}>
            <Button disabled={formik.isSubmitting} onClick={handleToggleEdit}>
              Cancel
            </Button>
            <Button primary type="submit" busy={formik.isSubmitting} disabled={!formik.isValid || !formik.dirty}>
              Save
            </Button>
          </div>
        </form>
      </Collapse>

      <Collapse unmountOnExit in={!isEditing} className={clsx({ [css.noSelect]: isEditing })}>
        <div className={css.editLabel}>
          {children}
          {toggleEdit}
        </div>
      </Collapse>
    </div>
  )
}

export default MiniForm
