import { pick } from 'lodash'
import { useMemo } from 'react'
import { useFormik } from 'formik'
import { Grid } from '@material-ui/core'

import Button from 'components/Button'
import LineItemFields from './LineItemFields'
import { OrderReturnLineItem } from 'types'

import useStyles from './styles'
import Alert from '@material-ui/lab/Alert'

import { reasons } from './ConditionValueFields'
import { itemConditions } from './ConditionValuePanel'

type LineItemsFormProps = {
  orderReturn: any
  onSubmit: (fields: any) => Promise<any>
  formError?: string
}

const lineItemFields = ['id', 'receivedQuantity', 'submittedQuantity', 'condition', 'orderReturnConditionsAttributes']

export default function LineItemsForm({ orderReturn, onSubmit, formError }: LineItemsFormProps) {
  const css = useStyles()

  const initialValues = useMemo(
    () => ({
      orderReturnLineItems: orderReturn.orderReturnLineItems
        ?.map((item: any) => pick(item, lineItemFields))
        .map((item: any, index: any) => ({
          ...item,
          receivedQuantity: orderReturn.orderReturnLineItems[index].data.updated
            ? item.receivedQuantity
            : item.submittedQuantity,
          orderReturnConditionsAttributes:
            orderReturn.orderReturnLineItems[index].data.updated && item.orderReturnConditionsAttributes.length
              ? item.orderReturnConditionsAttributes.map((attr: any) => ({
                  ...attr,
                  reasons: attr.reasons,
                  conditionGrade: attr.conditionGrade,
                  lineItemQuantity: attr.lineItemQuantity,
                }))
              : [
                  {
                    reasons: new Array(reasons[0]),
                    conditionGrade: itemConditions[0],
                    lineItemQuantity: item.submittedQuantity,
                  },
                ],
        })),
    }),
    [orderReturn]
  )

  const appliedDiscountPercentages = useMemo(() => {
    return orderReturn.order.appliedPromotions.map(
      ({ appliedDiscountPercentage }: { appliedDiscountPercentage: number }) => appliedDiscountPercentage
    )
  }, [orderReturn.order.appliedPromotions])

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={css.lineItem}>
        <div>Product image</div>
        <div>Product info</div>
        <div>Submitted quantity</div>
        <div>Received quantity</div>
        <div>Received value</div>
        <div>Condition</div>
      </div>

      {(orderReturn.orderReturnLineItems || []).map((lineItem: OrderReturnLineItem, index: number) => (
        <LineItemFields
          formik={formik}
          itemIndex={index}
          lineItem={lineItem}
          key={index}
          appliedDiscountPercentages={appliedDiscountPercentages}
          countryName={orderReturn?.brandProfile.user.country.name}
          returnState={orderReturn.aasmState}
        />
      ))}
      <Grid container alignItems="flex-start" wrap="nowrap" spacing={3}>
        <Grid item style={{ flex: '1 1 auto' }}>
          {formError && <Alert severity="error">{formError}</Alert>}
        </Grid>
        <Grid item>
          <Button primary type="submit" style={{ minWidth: 120 }} busy={formik.isSubmitting} disabled={!formik.isValid}>
            Process Return
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
