import { createTheme } from '@material-ui/core/styles'
import COLORS from './colors'

export default createTheme({
  palette: {
    type: 'light',
    primary: {
      main: COLORS.midBlue2,
      light: COLORS.powderBlue,
      dark: '#3e5c6a',
    },
    secondary: {
      main: COLORS.pink,
      contrastText: COLORS.white,
    },
    text: {
      primary: COLORS.darkGray,
      secondary: COLORS.mediumDarkGray,
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        '& legend': {
          textTransform: 'uppercase',
          letterSpacing: 1.2,
          marginBottom: 4,
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        img: {
          maxWidth: '100%',
        },
        video: {
          maxWidth: '100%',
        },
      },
    },
  },
  props: {
    MuiSwitch: {
      color: 'primary',
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiTextField: {
      variant: 'outlined',
    },
    MuiPaper: {
      elevation: 0,
      // variant: 'outlined', // TODO: deal with double outlines around DataGrid
    },
    MuiSelect: {
      variant: 'outlined',
    },
    MuiButton: {
      variant: 'outlined',
    },
  },
})
