import React from 'react'

import { Category, ParentCategory } from 'types/category'

import { api } from 'services/api.service'
import { useAlert } from 'context/Alert/AlertContext'
import useAction from 'hooks/useAction'

type CategoryCountryHookParams = {
  category: ParentCategory
  setCategories: React.Dispatch<React.SetStateAction<Category[]>>
}

export default function useCategoryCountry({ category, setCategories }: CategoryCountryHookParams) {
  const snackbar = useAlert()

  const createCountry = useAction({
    request: (params: { countryId: number; categoryId: number; countryDisplayName: string }) =>
      api.post('country_categories', { countryCategory: params }),
    onSuccess: (resp) => {
      category.countries = [...category.countries, resp.data]
      setCategories((xs) => xs.map((x) => (x.id !== category.id ? x : category)))
      snackbar.success('Category country created')
    },
    onError: (err) => {
      console.error(err)
      snackbar.error('Failed to add category country')
    },
  })

  const updateCountry = useAction({
    request: (id: number, params: { countryDisplayName: string }) =>
      api.patch(`country_categories/${id}`, { countryCategory: params }),
    onSuccess: (resp, [id]) => {
      category.countries = category.countries.map((x) => (x.id !== id ? x : resp.data))
      setCategories((xs) => xs.map((x) => (x.id !== category.id ? x : category)))
      snackbar.success('Category country updated')
    },
    onError: (err) => {
      console.error(err)
      snackbar.error('Failed to update category country')
    },
  })

  const deleteCountry = useAction({
    request: (id: number) => api.delete(`country_categories/${id}`),
    onSuccess: (_, [id]) => {
      category.countries = category.countries.filter((x) => x.id !== id)
      setCategories((xs) => xs.map((x) => (x.id !== category.id ? x : category)))
      snackbar.success('Category country deleted')
    },
    onError: (err) => {
      console.error(err)
      snackbar.error('Failed to delete category country')
    },
  })

  return { createCountry, updateCountry, deleteCountry }
}
