import { Country, Countries } from 'types'

import NumberFormat from 'react-number-format'

const getPhoneFormatForCountry = (country: Country) =>
  ({
    [Countries['United States'].name]: '+1 (###) ###-####',
    [Countries['United Kingdom'].name]: '+44 #### ######',
  }[country.name])

interface Props {
  phone?: string
  country: Country
}

const PhoneNumber: React.FC<Props> = ({ phone, country }) => {
  if (!phone) return null
  return <NumberFormat format={getPhoneFormatForCountry(country)} mask="_" value={phone} displayType={'text'} />
}

export default PhoneNumber
