import { api } from 'services/api.service'
import { useAlert } from 'context/Alert/AlertContext'
import useAction from 'hooks/useAction'

export default function useCreateOrderShippingLabel() {
  const snackbar = useAlert()

  // Eventually want to be able to pass in packages and new address info
  const [createOrderShippingLabel, isCreatingOrderShippingLabel, error] = useAction({
    request: (shippingAddressInfo, orderId) =>
      api.post(`orders/${orderId}/create_shipping_label`, {
        shippingLabel: shippingAddressInfo,
      }),
    onSuccess: () => snackbar.success('Succesfully created new label'),
  })

  return { createOrderShippingLabel, isCreatingOrderShippingLabel, error }
}
