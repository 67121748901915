import { useFormik } from 'formik'
import * as Yup from 'yup'
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@material-ui/core'

import Button from 'components/Button'
import { Brand } from 'types/brand'

interface Props {
  shown: boolean
  onCancel: () => void
  onConfirm: (value: Brand['declineReason']) => void
  defaultDeclineReasonText?: string
}

export default function BrandDeclineDialog({ shown, onCancel, onConfirm, defaultDeclineReasonText = '' }: Props) {
  const handleSubmit = (declineReason: Brand['declineReason']) => onConfirm(declineReason)

  const initialValues = {
    internalNotes: '',
    declineReason: defaultDeclineReasonText,
  }

  const declineInfoSchema = Yup.object().shape({
    internalNotes: Yup.string(),
    declineReason: Yup.string().required('Decline Reason required'),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: declineInfoSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  })

  return (
    <Dialog open={shown} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="form-dialog-title">Decline Brand</DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            {...formik.getFieldProps('internalNotes')}
            label="Internal Notes"
            fullWidth
            autoFocus
            multiline
            helperText={formik.errors.internalNotes}
            error={Boolean(formik.touched.internalNotes && formik.errors.internalNotes)}
          />
          <TextField
            {...formik.getFieldProps('declineReason')}
            label="Decline Reason"
            fullWidth
            autoFocus
            multiline
            margin="dense"
            helperText={formik.errors.declineReason}
            error={Boolean(formik.touched.declineReason && formik.errors.declineReason)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button primary danger busy={formik.isSubmitting} type="submit">
            Decline Brand
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
