import Button from 'components/Button'
import useProductImportsQueuePrioritize from 'hooks/useProductImportsQueuePrioritize'
import { useCallback } from 'react'

export function PrioritizeButton({
  id,
  disabled,
  triggerRefresh,
}: {
  id: number
  disabled: boolean
  triggerRefresh?: () => void
}) {
  const { prioritize, isLoading } = useProductImportsQueuePrioritize(id)

  const cb = useCallback(async () => {
    await prioritize()
    triggerRefresh?.()
  }, [prioritize, triggerRefresh])

  return (
    <Button small busy={isLoading} disabled={disabled} onClick={cb}>
      Prioritize
    </Button>
  )
}
