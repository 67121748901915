import * as luxon from 'luxon'
import { useState } from 'react'
import { useFormik } from 'formik'
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'

import Button from 'components/Button'
import MoneyInput from 'components/MoneyInput'
import SelectField from 'components/SelectField'
import DebouncedTextField from 'components/DebouncedTextField'
import { currencyToCents } from 'util/currencyToCents'

const DateFormat = 'yyyy-MM-dd'
const moneyInput: any = MoneyInput

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: (credit: any) => Promise<Error | null>
  isReferred?: boolean
}

export default function AddCreditDialog({ open, onClose, onSubmit, isReferred = false }: Props) {
  const [err, setErr] = useState<string | null>(null)

  const creditTypes = [
    { label: 'Shipping', value: 'ShippingCredit' },
    { label: 'Net Terms', value: 'NetTermsCredit' },
    { label: 'Signup', value: 'SignupCredit' },
    { label: 'Promotional', value: 'PromotionalCredit' },
    isReferred
      ? { label: 'Referral', value: 'ReferralCredit' }
      : {
          label: 'Referral (retailer must be referred)',
          value: 'ReferralCredit',
          disabled: true,
        },
  ]

  const formik = useFormik({
    initialValues: {
      amountInCents: '',
      type: creditTypes[0].value,
      expiresAt: luxon.DateTime.now().plus({ days: 1 }),
    },

    onSubmit: (credit) => {
      onSubmit({
        type: credit.type,
        amountInCents: currencyToCents(credit.amountInCents),
        expiresAt: credit.expiresAt.toFormat(DateFormat),
      })
        .then(() => {
          formik.resetForm()
          setErr(null)
          onClose()
        })
        .catch((err: any) => {
          setErr(err?.response?.data?.error || err?.message || 'Unable to Create a Credit')
        })
    },
  })

  const handleClose = () => {
    formik.resetForm()
    setErr(null)
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">Create Credit</DialogTitle>
      <Divider />

      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth component="fieldset">
          <DialogContent style={{ width: 400 }}>
            {err && <FormHelperText error>{err}</FormHelperText>}

            <DebouncedTextField
              fullWidth
              label="Amount"
              margin="normal"
              {...formik.getFieldProps('amountInCents')}
              InputProps={{
                inputComponent: moneyInput,
                defaultValue: formik.values.amountInCents,
              }}
            />

            <SelectField
              fullWidth
              label="Type"
              margin="normal"
              {...formik.getFieldProps('type')}
              options={creditTypes}
            />

            <KeyboardDatePicker
              fullWidth
              label="Expires At"
              margin="normal"
              disableToolbar
              format={DateFormat}
              {...formik.getFieldProps('expiresAt')}
              onChange={(val: any) => formik.setFieldValue('expiresAt', val)}
            />

            {formik.values.type === 'NetTermsCredit' && (
              <FormHelperText>
                Notice: If the retailer doesn't already have a net-terms application, this action will create and
                auto-approve a net terms application for this retailer.
              </FormHelperText>
            )}

            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button primary type="submit" busy={formik.isSubmitting}>
                Create
              </Button>
            </DialogActions>
          </DialogContent>
        </FormControl>
      </form>
    </Dialog>
  )
}
