import { get } from 'lodash'
import { useState, useEffect, useMemo } from 'react'
import { useLocation, useParams, Link } from 'react-router-dom'
import { Chip, Paper, Tab, Tabs } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { useConfirm } from 'context/Confirm/ConfirmContext'
import useOrder from 'hooks/useOrder'
import usePageTitle from 'hooks/usePageTitle'

import Async from 'components/Async'
import Button from 'components/Button'
import TabPanel from 'components/TabPanel'
import PageHeader from 'components/PageHeader'
import PageActionsMenu from 'components/PageActionsMenu'
import AuditLog from 'components/AuditLog'
import ReviewsDataGrid from 'components/ReviewsDataGrid'

import Overview from './Overview'
import LineItems from './LineItems'
import RetailerPanel from './RetailerPanel'
import BrandPanel from './BrandPanel'
import Shipments from './Shipments'
import Returns from './Returns'
import Timeline from './Timeline'
import ReinstateDialog from './ReinstateDialog'
import ReturnForm from './ReturnForm'
import { CurrencyProvider } from 'context/Country/CountryContext'
import { getCountry } from 'types/country'
import { Currencies } from 'types/currency'
import { LineItemValue } from 'pages/Orders/Order/interfaces/index.interface'
import Credits from '../../Retailers/Retailer/Credits'

interface Params {
  id: string
}

const tabRoutes = ['overview', 'items', 'shipments', 'returns', 'timeline']

export default function Order() {
  const css = useStyles()
  const location = useLocation()
  const { confirm } = useConfirm()

  const { id } = useParams<Params>()
  const {
    isLoading,
    order,
    lineItems,
    shipments,
    events,
    brand,
    retailer,
    charge,
    saveField,
    saveAasmState,
    saveChargeAasmState,
    createShipment,
    updateShipment,
    updateShipmentState,
    deleteShipment,
    cancelOrder,
    cancelCharge,
    createRefund,
    reinstateOrder,
    disconnectShopify,
    isDisconnectingShopify,
    isCreditsLoading,
    credits,
    creditsPagination,
    createCredit,
    updateCredit,
    deleteCredit,
    createTransfer,
    processTransfer,
    reinstateLineItem,
    refreshCalculatedTotal,
    addProductToOrder,
  } = useOrder({
    id,
  })

  const countryName = retailer?.user?.country?.name

  const [tabValue, setTabValue] = useState(0)
  const [openOrderReturnForm, setOpenOrderReturnForm] = useState(false)
  usePageTitle(`Order ${id} ${tabRoutes[tabValue]}`)

  const [showReinstate, setShowReinstate] = useState(false)

  useEffect(() => {
    let tabPath = location.pathname.split('/').pop()
    let index = tabRoutes.findIndex((tab) => tab === tabPath)

    index > 0 && setTabValue(index)
  }, [location.pathname])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const cancelOrderText = () => {
    const defaultText = 'Are you sure you would like to cancel this order?'
    const paidText = 'NOTE: The order has been fully paid and a refund will need to be manually issued.'
    const transferText = 'NOTE: The brand will still be paid unless the transfer is also deleted.'

    if (charge.aasmState === 'fully_paid') {
      return `${defaultText} ${paidText}`
    } else if (order.transfer !== null) {
      return `${defaultText} ${transferText}`
    } else {
      return defaultText
    }
  }

  const handleOrderCancel = () => {
    confirm({
      title: 'Cancel Order',
      text: cancelOrderText(),
      submitText: 'Submit',
      danger: true,
    })
      .then(() => {
        cancelOrder()
      })
      .catch((err: any) => {
        if (err !== undefined) {
          console.error(err)
        }
      })
  }

  const currency = getCountry(get(brand, 'user.country.name'))?.currency ?? Currencies.USD

  const canCreateReturn = ['delivered', 'hand_delivered', 'completed'].includes(order.aasmState)
  const hasShopifySyncedProducts = useMemo(
    () => lineItems.some((lineItem: LineItemValue) => lineItem.shopifyProductId && lineItem.shopifySyncTimestamp),
    [lineItems]
  )

  return (
    <CurrencyProvider currency={currency}>
      <PageHeader
        backLink="/orders"
        title={`Order #${order.uid}`}
        contentRight={
          <>
            <PageActionsMenu
              items={[
                { label: 'Create Return', callback: () => setOpenOrderReturnForm(true), disabled: !canCreateReturn },
                {
                  label: 'Disconnect Shopify',
                  callback: disconnectShopify,
                  disabled: !hasShopifySyncedProducts || isDisconnectingShopify,
                },
                {
                  label: 'Cancel Charge',
                  callback: cancelCharge,
                  disabled: charge.aasmState !== 'pending',
                },
              ]}
            />

            {order.aasmState !== 'canceled' ? (
              <Button primary danger className={css.btn} onClick={handleOrderCancel}>
                Cancel Order
              </Button>
            ) : (
              <Button
                primary
                className={css.btn}
                onClick={() => {
                  setShowReinstate(true)
                }}
              >
                Reinstate Order
              </Button>
            )}
          </>
        }
      >
        <Chip label={`order: ${order.aasmState}`} style={{ marginRight: '8px' }} />
        <Chip label={`charge: ${charge.aasmState}`} style={{ marginRight: '8px' }} />
        {order.dropship && <Chip label="Dropshipping" style={{ marginRight: '8px' }} />}
      </PageHeader>

      <Async loading={isLoading}>
        <Grid container className={css.root} spacing={2}>
          <Grid item xs={12} lg={8}>
            <Paper>
              <Tabs value={tabValue} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
                <Tab className={css.tab} component={Link} to={`/orders/${id}/overview`} replace label="Order" />
                <Tab className={css.tab} component={Link} to={`/orders/${id}/items`} replace label="Line Items" />
                <Tab className={css.tab} component={Link} to={`/orders/${id}/shipments`} replace label="Shipments" />
                <Tab className={css.tab} component={Link} to={`/orders/${id}/returns`} replace label="Returns" />
                <Tab className={css.tab} component={Link} to={`/orders/${id}/timeline`} replace label="Timeline" />
                <Tab className={css.tab} component={Link} to={`/orders/${id}/reviews`} replace label="Reviews" />
                <Tab className={css.tab} component={Link} to={`/orders/${id}/auditlog`} replace label="Audit Log" />
              </Tabs>
            </Paper>
            <TabPanel value={tabValue} index={0}>
              <Overview
                order={order}
                charge={charge}
                saveField={saveField}
                saveAasmState={saveAasmState}
                saveChargeAasmState={saveChargeAasmState}
                createRefund={createRefund}
                createTransfer={createTransfer}
                processTransfer={processTransfer}
                refreshCalculatedTotal={refreshCalculatedTotal}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <LineItems
                addProductToOrder={addProductToOrder}
                reinstateLineItem={reinstateLineItem}
                lineItems={lineItems || []}
                order={order}
                charge={charge}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Shipments
                order={order}
                shippingLabels={order.shippingLabels}
                shipments={shipments}
                createShipment={createShipment}
                updateShipment={updateShipment}
                updateShipmentState={updateShipmentState}
                deleteShipment={deleteShipment}
                shipToAddress={retailer.shippingAddress}
                shipFromAddress={brand.shipFromAddress}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <Returns order={order} saveField={saveField} />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <Timeline events={events} />
            </TabPanel>

            <TabPanel value={tabValue} index={5}>
              <ReviewsDataGrid for="order" id={order.id} />
            </TabPanel>

            <TabPanel value={tabValue} index={6}>
              <AuditLog type="Order" id={order.id} />
            </TabPanel>
          </Grid>
          <Grid item xs={12} lg={4}>
            <RetailerPanel retailer={retailer} />
            <BrandPanel brand={brand} />

            <Credits
              credits={credits}
              countryName={countryName}
              createCredit={createCredit}
              updateCredit={updateCredit}
              deleteCredit={deleteCredit}
              isCreditsLoading={isCreditsLoading}
              pagination={creditsPagination}
            />
          </Grid>
        </Grid>
      </Async>

      <ReinstateDialog
        open={showReinstate}
        onClose={() => {
          setShowReinstate(false)
        }}
        chargedOnAcceptance={(charge?.paymentTerms ?? 'acceptance') === 'acceptance'}
        reinstateOrder={reinstateOrder}
      />

      <ReturnForm
        orderId={id}
        open={openOrderReturnForm}
        onClose={() => {
          setOpenOrderReturnForm(false)
        }}
        lineItems={lineItems}
      />
    </CurrencyProvider>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    btn: {
      marginLeft: theme.spacing(1),
    },
    tab: {
      flex: 1,
      minWidth: 'unset',
    },
  })
)
