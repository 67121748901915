import { PropsWithChildren } from 'react'
import { Typography } from '@material-ui/core'

import { Nullable } from 'types'
import Link from 'components/Link'

export type UserCellProps = {
  user: Nullable<{ email: Nullable<string> }>
  linkTo: { href: Nullable<string>; text: Nullable<string> }
}

export function UserCell(props: PropsWithChildren<UserCellProps>) {
  const { user, linkTo, children } = props

  return (
    <>
      {linkTo.href && linkTo.text && (
        <Typography variant="body2">
          <Link to={linkTo.href}>{linkTo.text}</Link>
        </Typography>
      )}
      {!linkTo.href && linkTo.text && <Typography variant="body2">{linkTo.text}</Typography>}
      {user?.email && <Typography variant="caption">{user.email}</Typography>}
      {children}
    </>
  )
}
