import React, { useCallback } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Dialog, DialogTitle, DialogContent, Divider, TextField, DialogActions } from '@material-ui/core'
import BusinessIcon from '@material-ui/icons/Business'
import StoreIcon from '@material-ui/icons/Store'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import Button from 'components/Button'
import AddressFormFields from 'components/AddressFormFields'
import useCreateOrderShippingLabel from 'hooks/useCreateOrderShippingLabel'
import SelectField from 'components/SelectField'
import Alert from '@material-ui/lab/Alert'
import { Address, addressToShipEngineAddress, Countries } from 'types'

type Props = {
  orderId: number
  shipFrom: Address
  shipTo: Address
  open: boolean
  onClose: () => void
}

const OrderShippingLabelForm = ({ orderId, onClose, open, shipFrom, shipTo }: Props) => {
  const css = useStyles()
  const { createOrderShippingLabel, isCreatingOrderShippingLabel, error } = useCreateOrderShippingLabel()

  const initialValues = {
    shipTo: shipTo,
    shipFrom: shipFrom,
    weight: '',
    carrierCode: '',
  }
  const validationSchema = Yup.object({
    weight: Yup.string().required('Required Field'),
    height: Yup.string().required('Required Field'),
    width: Yup.string().required('Required Field'),
    length: Yup.string().required('Required Field'),
    carrierCode: Yup.string().required('Required Field'),
  })

  const onSubmit = (values: { shipFrom: {}; shipTo: {} }) => {
    const remappedValues = {
      ...values,
      shipFrom: addressToShipEngineAddress(values.shipFrom),
      shipTo: addressToShipEngineAddress(values.shipTo),
    }
    createOrderShippingLabel(remappedValues, orderId).then(() => handleClose())
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  })

  const handleClose = useCallback(() => {
    formik.resetForm()
    onClose()
  }, [formik, onClose])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Create Shipping Label</DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            margin="dense"
            label="Weight in Ounces"
            type="number"
            fullWidth
            InputProps={{ inputProps: { min: 0 }, endAdornment: 'oz' }}
            {...formik.getFieldProps('weight')}
            helperText={formik.errors.weight}
            error={Boolean(formik.touched.weight && formik.errors.weight)}
          />
          <TextField
            margin="dense"
            label="Height in inches"
            type="number"
            fullWidth
            InputProps={{ inputProps: { min: 0 }, endAdornment: 'in' }}
            {...formik.getFieldProps('height')}
            helperText={formik.errors.weight}
            error={Boolean(formik.touched.weight && formik.errors.weight)}
          />
          <TextField
            margin="dense"
            label="Width in inches"
            type="number"
            fullWidth
            InputProps={{ inputProps: { min: 0 }, endAdornment: 'in' }}
            {...formik.getFieldProps('width')}
            helperText={formik.errors.weight}
            error={Boolean(formik.touched.weight && formik.errors.weight)}
          />
          <TextField
            margin="dense"
            label="Length in inches"
            type="number"
            fullWidth
            InputProps={{ inputProps: { min: 0 }, endAdornment: 'in' }}
            {...formik.getFieldProps('length')}
            helperText={formik.errors.weight}
            error={Boolean(formik.touched.weight && formik.errors.weight)}
          />
          <SelectField
            margin="dense"
            {...formik.getFieldProps('carrierCode')}
            fullWidth
            label="Service Code"
            options={[
              { label: 'UPS Ground', value: 'ups_ground' },
              { label: 'Fedex Ground', value: 'fedex' },
              { label: 'USPS priority mail', value: 'stamps_com' },
            ]}
            helperText={formik.errors.carrierCode}
            error={Boolean(formik.touched.carrierCode && formik.errors.carrierCode)}
          />
          <AddressFormFields
            fieldPrefix="shipFrom"
            formik={formik}
            country={Countries['United States']}
            margin="normal"
            fieldsetLabel={
              <div className={css.fieldsetLabel}>
                <StoreIcon />
                <div>Ship From</div>
              </div>
            }
          />
          <AddressFormFields
            fieldPrefix="shipTo"
            formik={formik}
            country={Countries['United States']}
            margin="normal"
            fieldsetLabel={
              <div className={css.fieldsetLabel}>
                <BusinessIcon />
                <div>Ship To</div>
              </div>
            }
          />

          {error && <Alert severity="error">{error}</Alert>}

          {Object.keys(formik.errors).length > 1 && (
            <Alert severity="error">Please fill in all the required fields.</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button primary type="submit" busy={isCreatingOrderShippingLabel}>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default OrderShippingLabelForm

const useStyles = makeStyles(() =>
  createStyles({
    fieldsetLabel: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 4,
      '& > svg': {
        position: 'relative',
        top: '-1px',
        marginRight: 8,
      },
    },
  })
)
