import { DateTime } from 'luxon'
import { useState } from 'react'
import { Collapse } from '@material-ui/core'

import Button from 'components/Button'
import Link from 'components/Link'
import Panel from 'components/Panel'
import Definition from 'components/Definition'
import centsToCurrency from 'util/centsToCurrency'

import { Charge, ChargeAASMState, ChargeAttempt as IChargeAttempt } from 'types'
import EditableDefinition from 'components/EditableDefinition'
import { useConfirm } from 'context/Confirm/ConfirmContext'
import { useCurrencyContext } from 'context/Country/CountryContext'

interface Props {
  charge: Charge
  saveField: (fieldName: string, value: any) => Promise<any>
  saveChargeAasmState: (state: ChargeAASMState) => Promise<any>
}
export function RetailerCharge({ charge, saveField, saveChargeAasmState }: Props) {
  const { orders, chargeAttempts } = charge

  const [open, setOpen] = useState(false)
  const { confirm } = useConfirm()

  const allowEditScheduledAt =
    charge.paymentTerms === 'net60' && ['pending', 'past_due', 'defaulted'].includes(charge.aasmState)

  const handleSetAasmState = (state: ChargeAASMState) => {
    return confirm({
      title: 'Update charge state',
      text: `Are you sure you want to set this brand state to: ${state}?`,
      submitText: 'Update State',
    })
      .then(() => saveChargeAasmState(state))
      .catch((err: any) => console.error(err))
  }

  return (
    <>
      <EditableDefinition
        readOnly={!allowEditScheduledAt}
        label="Scheduled At"
        value={charge.scheduledAt}
        format="datetime"
        asDatePicker
        datePickerProps={{ disablePast: true }}
        onSave={(value: DateTime) => {
          const payload: any = { id: charge.id, scheduledAt: value.toISO() }
          if (['past_due', 'defaulted'].includes(charge.aasmState)) {
            return confirm({
              title: 'Update charge scheduled at?',
              text: (
                <>
                  Order is <strong>{charge.aasmState}</strong>. This will reset the dunning process for the charge.
                </>
              ),
              submitText: 'Confirm',
            })
              .then(() => saveField('charge_attributes', payload))
              .catch(() => Promise.resolve())
          } else {
            return saveField('charge_attributes', payload)
          }
        }}
      />

      <EditableDefinition
        label="State"
        options={[charge.aasmState, ...(charge.availableAasmStateTransitions || [])]}
        value={charge.aasmState}
        onSave={handleSetAasmState}
      ></EditableDefinition>
      <EditableDefinition
        label="Stripe Payment Intent Id"
        value={charge.stripePaymentIntentId}
        onSave={(value: string) => {
          const payload = { id: charge.id, stripePaymentIntentId: value || null }
          return saveField('charge_attributes', payload)
        }}
      />
      <EditableDefinition
        label="Balance Transaction Id"
        value={charge.balanceTransactionId}
        onSave={(value: string) => {
          const payload = { id: charge.id, balanceTransactionId: value || null }
          return saveField('charge_attributes', payload)
        }}
      />
      <EditableDefinition
        label="Balance Charge Id"
        value={charge.balanceChargeId}
        onSave={(value: string) => {
          const payload = { id: charge.id, balanceChargeId: value || null }
          return saveField('charge_attributes', payload)
        }}
      />
      <Definition label="Capture Failed At" format="datetime">
        {charge.captureFailedAt}
      </Definition>
      <Definition label="Created At" format="datetime">
        {charge.createdAt}
      </Definition>

      <Definition label="All Orders Associated With This Charge">
        {orders?.map((order) => (
          <div key={order.id}>
            <Link to={`/orders/${order.id}`}>#{order.uid}</Link>
          </div>
        ))}
      </Definition>

      {chargeAttempts && chargeAttempts.length > 0 && (
        <Button text onClick={() => setOpen(!open)} style={{ marginBottom: 5 }}>
          {`${open ? 'Hide' : 'Show'}`} Charge Attempts
        </Button>
      )}

      <Collapse in={open} unmountOnExit>
        {chargeAttempts?.map((attempt) => (
          <ChargeAttempt key={attempt.id} attempt={attempt} />
        ))}
      </Collapse>
    </>
  )
}

function ChargeAttempt({ attempt }: { attempt: IChargeAttempt }) {
  const currency = useCurrencyContext()

  return (
    <Panel>
      <Definition label="ID">{attempt.id}</Definition>
      <Definition label="Amount">{centsToCurrency(attempt.amountInCents, currency)}</Definition>
      <Definition label="Decline Code">{attempt.declineCode}</Definition>
      <Definition label="Decline Message">{attempt.declineMessage}</Definition>
      <Definition label="Status">{attempt.status}</Definition>
      <Definition label="Created At" format="datetime">
        {attempt.createdAt}
      </Definition>
    </Panel>
  )
}
