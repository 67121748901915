import _ from 'lodash'
import { useMemo } from 'react'

export default function usePickRenameMap(
  opts: {
    keys: string[]
    rename?: Record<string, string>
    map?: Record<string, (val: any) => any>
  },
  target: Record<string, any>
): [string, unknown][] {
  return useMemo(
    () =>
      Object.entries(_.pick(target, opts.keys)).map(([k, v]) => [
        _.startCase(opts.rename?.[k] ?? k),
        opts.map?.[k]?.(v) ?? v,
      ]),
    [opts, target]
  )
}
