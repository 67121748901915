import { Paper } from '@material-ui/core'

import usePageTitle from 'hooks/usePageTitle'
import useOrderReturns from 'hooks/useOrderReturns'
import useQueryParam from 'hooks/useQueryParam'
import useIndexStyle from 'hooks/useIndexStyle'
import DataGrid, { UserCell } from 'components/DataGrid'
import Link from 'components/Link'
import SelectField from 'components/SelectField'
import DebouncedTextField from 'components/DebouncedTextField'
import { useAbility } from '../../hooks/useAbility'

const pageSize = 25

const orderReturnStateOptions = [
  { label: 'Initiated', value: 'initiated' },
  { label: 'Shipped', value: 'shipped' },
  { label: 'Delivered', value: 'delivered' },
  { label: 'Canceled', value: 'canceled' },
  { label: 'Completed', value: 'completed' },
]
const chargeStateOptions = [
  { label: 'Pending', value: 'pending' },
  { label: 'Fully Paid', value: 'fully_paid' },
  { label: 'Past Due', value: 'past_due' },
  { label: 'Defaulted', value: 'defaulted' },
  { label: 'Canceled', value: 'canceled' },
]
const paymentTermsOptions = [
  { label: 'Net 60', value: 'net60' },
  { label: 'Net 90', value: 'net90' },
  { label: 'Acceptance', value: 'acceptance' },
]

export default function Returns() {
  const css = useIndexStyle()

  const [query, setQuery] = useQueryParam({
    key: 'query',
    defaultValue: '',
  })
  const handleQueryChange = (evt: any) => {
    setQuery(evt.target.value)
  }

  const [aasmState, setAasmState]: any = useQueryParam({
    key: 'state',
    defaultValue: null,
    parse: (value) => value ?? null,
    stringify: (value) => (value ? String(value) : ''),
  })
  const handleChangeState = (evt: any) => {
    setAasmState(evt.target.value)
  }

  const [chargeState, setChargeState]: any = useQueryParam({
    key: 'chargeState',
    defaultValue: null,
    parse: (value) => value ?? null,
    stringify: (value) => (value ? String(value) : ''),
  })
  const handleChangeChargeState = (evt: any) => {
    setChargeState(evt.target.value)
  }

  const [paymentTerms, setPaymentTerms]: any = useQueryParam({
    key: 'paymentTerms',
    defaultValue: null,
    parse: (value) => value ?? null,
    stringify: (value) => (value ? String(value) : ''),
  })
  const handleChangePaymentTerms = (evt: any) => {
    setPaymentTerms(evt.target.value)
  }

  const { orderReturns, isLoading, handlePageChange, orderReturnsCount } = useOrderReturns({
    query,
    aasmState,
    chargeState,
    paymentTerms,
    pageSize,
  })

  const ability = useAbility()

  const showBrand = () => {
    return ability.can('show', 'BrandProfile')
  }

  const showRetailer = () => {
    return ability.can('show', 'RetailerProfile')
  }

  const orderColumn = () => {
    if (ability.can('show', 'Order')) {
      return {
        headerName: 'Order #',
        field: 'order.id',
        width: 120,
        renderCell: ({ value, row }: any) => <Link to={`orders/${value}`}>{row.order.uid || value}</Link>,
      }
    } else {
      return {
        headerName: 'Order #',
        field: 'order.uid',
        width: 120,
      }
    }
  }

  usePageTitle('Order Returns')

  return (
    <div>
      <h1 style={{ marginTop: 0 }}>Order Returns</h1>

      <Paper variant="outlined" style={{ padding: 16, marginBottom: 24 }}>
        <DebouncedTextField
          className={css.searchField}
          label="Search by Retailer Email"
          placeholder="Search by Retailer Email"
          value={query}
          onChange={handleQueryChange}
        />
        <SelectField
          nullable
          className={css.formControl}
          label="State"
          value={aasmState}
          options={orderReturnStateOptions}
          onChange={handleChangeState}
        />
        <SelectField
          nullable
          className={css.formControl}
          label="Charge State"
          value={chargeState}
          options={chargeStateOptions}
          onChange={handleChangeChargeState}
        />
        <SelectField
          nullable
          className={css.formControl}
          label="Payment Terms"
          value={paymentTerms}
          options={paymentTermsOptions}
          onChange={handleChangePaymentTerms}
        />
      </Paper>

      <Paper elevation={0}>
        <DataGrid
          rowHeight={70}
          rows={orderReturns}
          columns={returnsColumns(showBrand(), showRetailer(), orderColumn())}
          sortingMode="server"
          paginationMode="server"
          pageSize={pageSize}
          rowCount={orderReturnsCount}
          onPageChange={handlePageChange}
          loading={isLoading}
        />
      </Paper>
    </div>
  )
}

const returnsColumns = (showBrand: boolean, showRetailer: boolean, orderColumn: any) => {
  return [
    {
      headerName: 'Return #',
      field: 'id',
      width: 120,
      renderCell: ({ value, row }: any) => <Link to={`returns/${value}`}>{row.uid || value}</Link>,
    },
    orderColumn,
    {
      headerName: 'Created',
      field: 'createdAt',
      type: 'date',
      width: 120,
    },
    {
      headerName: 'Delivered',
      field: 'deliveredAt',
      type: 'date',
      width: 120,
    },
    {
      headerName: 'Submitted',
      field: 'submittedAt',
      type: 'date',
      width: 120,
    },
    {
      headerName: 'State',
      field: 'aasmState',
      width: 100,
    },
    {
      headerName: 'Payment Terms',
      field: 'order.charge.paymentTerms',
      width: 120,
    },
    {
      headerName: 'Charge state',
      field: 'order.charge.aasmState',
      width: 120,
    },
    {
      headerName: 'Retailer',
      field: 'retailerProfile',
      flex: 1,
      renderCell: ({ value }: any) => (
        <UserCell
          user={value?.user}
          linkTo={{ href: showRetailer ? `/retailers/${value?.id}` : null, text: value?.user?.fullName }}
        />
      ),
    },
    {
      headerName: 'Brand',
      field: 'brandProfile',
      flex: 1,
      renderCell: ({ value }: any) => (
        <UserCell
          user={value?.user}
          linkTo={{ href: showBrand ? `/brands/${value?.id}` : null, text: value?.companyName }}
        />
      ),
    },
  ]
}
