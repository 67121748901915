import { useHistory } from 'react-router-dom'

export default function useInitialPath() {
  const history = useHistory()

  const redirectToInitialPath = () => {
    const initialPath = localStorage.getItem('initialPath')
    if (initialPath) {
      localStorage.removeItem('initialPath')
      history.replace(initialPath)
    } else {
      history.replace('/')
    }
  }

  return {
    redirectToInitialPath,
  }
}
