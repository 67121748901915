import { get } from 'lodash'
import { ReactNode } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  DataGrid as MuiDataGrid,
  DataGridProps,
  GridCellParams,
  GridColTypeDef,
  GridValueGetterParams,
} from '@material-ui/data-grid'
import { Chip } from '@material-ui/core'
import { renderGridCellPopout } from 'components/GridCellPopout'
import GridPagination from './GridPagination'
import parseDateTime from 'util/parseDateTime'

export { UserCell } from './UserCell'
export { MoneyCell } from './MoneyCell'
export { RelativeDateCell } from './RelativeDateCell'

const withPopout = (customRenderer: (params: GridCellParams) => ReactNode) => (params: GridCellParams) =>
  renderGridCellPopout({
    ...params,
    value: customRenderer(params),
  })

export default function DataGrid({ columns, ...rest }: DataGridProps) {
  const css = useStyles()
  const columnsWithPopouts = columns.map((col) => ({
    valueGetter: ({ row, field }: GridValueGetterParams): any => get(row, field),
    ...col,
    sortable: false,
    renderCell: col.renderCell ? withPopout(col.renderCell) : renderGridCellPopout,
  }))

  return (
    <MuiDataGrid
      className={css.root}
      autoHeight
      disableColumnMenu
      rowsPerPageOptions={[]}
      columns={columnsWithPopouts}
      components={{
        Pagination: GridPagination,
      }}
      {...rest}
    />
  )
}

export const booleanCell: GridColTypeDef = {
  renderCell: ({ value }: GridCellParams) =>
    value ? <Chip label="YES" size="small" color="primary" /> : <Chip label="NO" size="small" color="secondary" />,
}
export const dateCell: GridColTypeDef = {
  renderCell: ({ value }: GridCellParams) => <span>{value ? parseDateTime(value as string)?.toFormat('ff') : ''}</span>,
}
const useStyles = makeStyles((_: Theme) =>
  createStyles({
    root: {
      '& .MuiDataGrid-overlay': {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        zIndex: '100',
      },
    },
  })
)
