import { useCallback, useEffect, useState } from 'react'
import { api, cancelRequest } from 'services/api.service'

import { GridPageChangeParams } from '@material-ui/data-grid'

import useAction from './useAction'
import { useAlert } from 'context/Alert/AlertContext'

import { LiveShoppingHighlight } from 'types/live_shopping_highlight'

const cancelKey = 'live_shopping_highlights'

export default function useLiveShoppingHighlights({ pageSize = 20 }) {
  const [liveShoppingHighlights, setLiveShoppingHighlights] = useState<LiveShoppingHighlight[]>([])
  const [liveShoppingHighlightsCount, setLiveShoppingHighlightsCount] = useState<number>(0)
  const [page, setPage] = useState(0)
  const handlePageChange = useCallback((params: GridPageChangeParams) => {
    setPage(params.page)
  }, [])

  const { error } = useAlert()

  const qp = new URLSearchParams({
    page: '' + (page + 1),
    per_page: '' + pageSize,
    order_by: 'event_date',
    order_direction: 'desc',
  })

  const queryString = qp.toString()

  const [fetchData, isLoading] = useAction({
    request: () => api.get(`live_shopping_highlights?${queryString}`, { cancelKey }),
    onSuccess: ({ data }) => {
      setLiveShoppingHighlightsCount(data.meta.total)
      setLiveShoppingHighlights(data.data)
    },
    onError: () => error('Error fetching live shopping highlights'),
  })

  useEffect(() => {
    fetchData().catch((err) => {
      console.error(err)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  useEffect(() => () => cancelRequest(cancelKey), [])

  return {
    liveShoppingHighlights,
    isLoading,
    handlePageChange,
    liveShoppingHighlightsCount,
    fetchData,
    pageSize,
  }
}
