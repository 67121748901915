import { useState } from 'react'
import TokenService from 'services/token.service'

export default function useToken() {
  const setSessionTokens = (token: any) => {
    TokenService.set(token)
    setToken(token)
  }

  const removeSessionTokens = () => {
    TokenService.remove()
    setToken(null)
  }

  const [token, setToken] = useState(TokenService.getAccess)

  return {
    setToken: setSessionTokens,
    removeToken: removeSessionTokens,
    token,
  }
}
