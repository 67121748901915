import { Category, ParentCategory, isSecondaryCategory } from 'types/category'

import Panel from 'components/Panel'
import EditableDefinition from 'components/EditableDefinition'

type CategoryAttrPanelProps = {
  category: Category
  saveField: any
  parentCategories: ParentCategory[]
}

export default function CategoryAttrPanel({ category, saveField, parentCategories }: CategoryAttrPanelProps) {
  const parent = isSecondaryCategory(category) ? parentCategories.find((c) => c.id === category.parentId) : null

  return (
    <Panel>
      <EditableDefinition label="Name" value={category.name} onSave={(v) => saveField('name', v)} />

      <EditableDefinition
        label="Description"
        value={category.description}
        onSave={(v) => saveField('description', v)}
      />

      <EditableDefinition
        label="Featured"
        format="boolean"
        options={[
          { label: 'NO', value: false },
          { label: 'YES', value: true },
        ]}
        value={category.featured}
        onSave={(v) => saveField('featured', v)}
      />

      <EditableDefinition
        label="Max Shipping Percentage"
        value={category.maxShippingPercentage}
        onSave={(v) => saveField('maxShippingPercentage', v)}
      />

      <EditableDefinition
        label="Dropship"
        format="boolean"
        options={[
          { label: 'NO', value: false },
          { label: 'YES', value: true },
        ]}
        value={category.dropship}
        onSave={(v) => saveField('dropship', v)}
      />

      <EditableDefinition label="Position" value={category.position} onSave={(v) => saveField('position', v)} />

      <EditableDefinition
        label="Managed by admin"
        format="boolean"
        options={[
          { label: 'NO', value: false },
          { label: 'YES', value: true },
        ]}
        value={category.managedByAdmin}
        onSave={(v) => saveField('managedByAdmin', v)}
      />

      <EditableDefinition label="Slug" value={category.slug} onSave={(v) => saveField('slug', v)} />

      <EditableDefinition
        label="Navigation Level"
        value={category.navLevel}
        onSave={(v) => saveField('nav_level', v)}
      />

      {isSecondaryCategory(category) && (
        <EditableDefinition
          label="Parent"
          options={parentCategories.map((x) => ({ label: x.displayName, value: x.id }))}
          value={parent?.id}
          onSave={(v) => saveField('parentId', v)}
        >
          {parent?.displayName}
        </EditableDefinition>
      )}
    </Panel>
  )
}
