import { useCallback, useState } from 'react'

import { Menu, MenuItem } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Button from 'components/Button'

interface MenuItemConfig {
  label: string
  callback?: () => any
  disabled?: boolean
  hidden?: boolean
}

interface Props {
  disabled?: boolean
  items: MenuItemConfig[]
}

const ActionsMenu: React.FC<Props> = ({ items, disabled = false }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const getClickHandler = useCallback(
    (callback: any) => () => {
      setAnchorEl(null)
      return callback()
    },
    []
  )

  return (
    <>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        aria-expanded={open ? 'true' : undefined}
        aria-describedby="page-actions-menu"
        disabled={disabled}
        endIcon={<ArrowDropDownIcon />}
      >
        Actions
      </Button>
      <Menu
        id="page-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {items
          .filter((item) => !item.hidden)
          .map(({ label, disabled, callback }) => (
            <MenuItem key={label} disabled={disabled} onClick={getClickHandler(callback)}>
              {label}
            </MenuItem>
          ))}
      </Menu>
    </>
  )
}

export default ActionsMenu
