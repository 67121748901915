import Definition, { DefinitionProps } from 'components/Definition'
import { FC, useState } from 'react'
import RefreshIcon from '@material-ui/icons/Refresh'
import { IconButton } from '@material-ui/core'
import useStyles from './styles'

type Props = {
  value: string | number
  onRefresh: (value: void) => Promise<void>
} & DefinitionProps

const RefreshableDefinition: FC<Props> = ({ value, onRefresh, ...rest }) => {
  const [refreshing, setRefreshing] = useState(false)
  const classes = useStyles()

  const handleRefresh = async () => {
    setRefreshing(true)
    try {
      await onRefresh()
    } catch {
      setRefreshing(false)
    }

    setRefreshing(false)
  }

  return (
    <div className={classes.editLabel}>
      {<Definition {...rest}>{refreshing ? 'refreshing...' : value}</Definition>}
      <div>
        <IconButton
          className={classes.iconButtonWrapper}
          onClick={(event) => {
            event.preventDefault()
            handleRefresh()
          }}
        >
          <RefreshIcon className={classes.iconButton} />
        </IconButton>
      </div>
    </div>
  )
}

export default RefreshableDefinition
