import React from 'react'

import { FormControl, FormHelperText } from '@material-ui/core'

import DebouncedTextField from 'components/DebouncedTextField'
import MoneyInput from 'components/MoneyInput'
import SelectField from 'components/SelectField'
import { centsToUnit } from 'util/centsToCurrency'
import { currencyToCents } from 'util/currencyToCents'

interface RefundFormFieldsProps {
  formik: any
}

const reasonOptions = [
  { label: 'Requested by customer', value: 'requested_by_customer' },
  { label: 'Duplicate', value: 'duplicate' },
  { label: 'Fraudulent', value: 'fraudulent' },
]

const RefundFormFields: React.FC<RefundFormFieldsProps> = ({ formik }) => {
  return (
    <FormControl fullWidth component="fieldset">
      <DebouncedTextField
        margin="normal"
        fullWidth
        label="Amount"
        {...formik.getFieldProps('amountInCents')}
        InputProps={{
          inputComponent: MoneyInput,
          defaultValue: formik.values.amountInCents,
        }}
        value={centsToUnit(formik.values.amountInCents)}
        onChange={(evt: any) => formik.setFieldValue('amountInCents', currencyToCents(evt.target.value))}
      />
      <SelectField
        margin="normal"
        fullWidth
        label="Reason"
        {...formik.getFieldProps('reason')}
        options={reasonOptions}
      />
      {formik.errors.form && <FormHelperText error>{formik.errors.form}</FormHelperText>}
    </FormControl>
  )
}

export default RefundFormFields
