import { Chip, Grid } from '@material-ui/core'
import EditableDefinition from 'components/EditableDefinition'
import PageHeader from 'components/PageHeader'
import Panel from 'components/Panel'
import { useConfirm } from 'context/Confirm/ConfirmContext'
import useProductType from 'hooks/useProductType'
import { get } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { ProductType } from 'types/product_type'
import Button from 'components/Button'
import COLORS from 'colors'
import CategoryMappings from 'pages/ProductTypes/components/CategoryMappings'

interface Params {
  id: string
}

export default function ProductTypeDetails() {
  const { id } = useParams<Params>()
  const { productType, isLoading, saveField, deleteProductType } = useProductType({ id })
  const { confirm } = useConfirm()
  const history = useHistory()

  const fieldsFor = (keyPath: keyof ProductType) => ({
    value: get(productType, keyPath),
    onSave: (value: any) => saveField(keyPath, value),
  })

  const handleDeleteProductType = () => {
    confirm({
      title: 'Delete Product Type',
      text: `Are you sure you would like to delete the product type ${productType?.name}? This action can't be undone.`,
      submitText: 'Delete',
      danger: true,
    })
      .then(() => {
        return deleteProductType()
      })
      .then(() => {
        history.push('/product_type')
      })
  }

  if (!productType || isLoading) {
    return null
  }

  const isDeleted = Boolean(productType.deletedAt)

  return (
    <>
      <PageHeader
        title={productType.name}
        backLink="/product_types"
        contentRight={
          <Button onClick={handleDeleteProductType} disabled={isDeleted} primary danger>
            Delete
          </Button>
        }
      >
        <div>{isDeleted && <Chip label="Deleted" style={{ backgroundColor: COLORS.red }} />}</div>
      </PageHeader>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Panel title="General">
            <EditableDefinition label="Name" {...fieldsFor('name')} />
          </Panel>
        </Grid>

        <CategoryMappings productTypeId={id} />
      </Grid>
    </>
  )
}
