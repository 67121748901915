import { Paper } from '@material-ui/core'
import { GridCellParams, GridColDef } from '@material-ui/data-grid'

import useRetailers from 'hooks/useRetailers'
import useQueryParam from 'hooks/useQueryParam'
import usePageTitle from 'hooks/usePageTitle'
import useIndexStyle from 'hooks/useIndexStyle'
import DebouncedTextField from 'components/DebouncedTextField'
import Link from 'components/Link'
import DataGrid, { booleanCell } from 'components/DataGrid'
import SelectField from 'components/SelectField'
import { countryOptions } from 'util/geography'

const pageSize = 20

export default function Retailers() {
  const css = useIndexStyle()
  usePageTitle(`Retailers`)
  const [query, setQuery] = useQueryParam({
    key: 'query',
    defaultValue: '',
  })
  const handleQueryChange = (evt: any) => {
    setQuery(evt.target.value)
  }
  const [selectedCountry, setSelectedCountry] = useQueryParam({
    key: 'country',
    defaultValue: countryOptions[0].value,
    parse: (value: string) => (value ? parseInt(value) : 'all'),
    stringify: (value) => (value !== 'all' ? value : null),
  })
  const [verified, setVerified] = useQueryParam({
    key: 'verified',
    defaultValue: verifiedOptions[0].value,
    parse: (value: string) => (value ? parseInt(value) : 'all'),
    stringify: (value) => (value !== 'all' ? value : null),
  })
  const [deleted, setDeleted] = useQueryParam({
    key: 'deleted',
    defaultValue: displayOptions[0].value,
    parse: (value: string) => (value ? parseInt(value) : 'all'),
    stringify: (value) => (value !== 'all' ? value : null),
  })
  const [dropshipStatus, setDropshipStatus] = useQueryParam({
    key: 'dropshipStatus',
    defaultValue: dropshipStatusOptions[0].value,
    parse: (value: string) => (value ? value : 'all'),
    stringify: (value) => (value !== 'all' ? value : null),
  })
  const { isLoading, retailerProfiles, retailerProfilesCount, handlePageChange } = useRetailers({
    pageSize,
    query,
    selectedCountry: selectedCountry !== 'all' ? selectedCountry : undefined,
    verified: verified !== 'all' ? verified : undefined,
    deleted: deleted !== 'all' ? deleted : undefined,
    dropshipStatus: dropshipStatus !== 'all' ? dropshipStatus : undefined,
  })

  return (
    <div>
      <h1 style={{ marginTop: 0 }}>Retailer Profiles</h1>
      <Paper variant="outlined" style={{ padding: 16, marginBottom: 24 }}>
        <DebouncedTextField
          className={css.searchField}
          label="Search by Email or Store Name"
          placeholder="Search by Email or Store Name"
          value={query}
          onChange={handleQueryChange}
        />

        <SelectField
          className={css.formControl}
          label="Country"
          value={selectedCountry}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedCountry(event.target.value)}
          options={[{ label: 'All', value: 'all' }, ...countryOptions]}
        />

        <SelectField
          className={css.formControl}
          label="Verified"
          value={verified}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setVerified(event.target.value)}
          options={verifiedOptions}
        />

        <SelectField
          className={css.formControl}
          label="Display"
          value={deleted}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDeleted(event.target.value)}
          options={displayOptions}
        />

        <SelectField
          className={css.formControl}
          label="Dropship Status"
          value={dropshipStatus}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDropshipStatus(event.target.value)}
          options={dropshipStatusOptions}
        />
      </Paper>

      <Paper elevation={0}>
        <DataGrid
          autoHeight
          disableColumnMenu
          rowsPerPageOptions={[]}
          rows={retailerProfiles}
          columns={reatailerColumns}
          pageSize={pageSize}
          rowCount={retailerProfilesCount}
          loading={isLoading}
          paginationMode="server"
          onPageChange={handlePageChange}
          sortingMode="server"
        />
      </Paper>
    </div>
  )
}

const reatailerColumns: GridColDef[] = [
  {
    headerName: 'Store Name',
    field: 'storeName',
    flex: 1,
    renderCell: ({ row, value }: GridCellParams) => <Link to={`/retailers/${row.id}`}>{value}</Link>,
  },
  {
    headerName: 'Full Name',
    field: 'user.fullName',
    flex: 1,
  },
  {
    headerName: 'Email',
    field: 'user.email',
    flex: 1,
  },
  {
    headerName: 'Verified',
    field: 'verifiedAt',
    width: 90,
    ...booleanCell,
  },
  {
    headerName: 'Country',
    field: 'user.country.name',
    width: 150,
  },
  {
    headerName: 'Deleted At',
    field: 'deletedAt',
    type: 'date',
    width: 150,
  },
]

const displayOptions = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 0 },
  { label: 'Deleted', value: 1 },
]

const dropshipStatusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'pending' },
  { label: 'Approved', value: 'approved' },
  { label: 'Live', value: 'live' },
  { label: 'Waitlisted', value: 'waitlisted' },
  { label: 'Declined', value: 'declined' },
]

const verifiedOptions = [
  { label: 'All', value: 'all' },
  { label: 'Verified', value: 1 },
  { label: 'Unverified', value: 0 },
]
