import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Paper, IconButton } from '@material-ui/core'
import { GridCellParams, GridColDef } from '@material-ui/data-grid'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import DataGrid from 'components/DataGrid'
import Button from 'components/Button'
import PageHeader from 'components/PageHeader'
import ExternalLink from 'components/ExternalLink'

import { useConfirm } from 'context/Confirm/ConfirmContext'

import { LiveShoppingHighlight } from 'types/live_shopping_highlight'
import useLiveShoppingHighlights from 'hooks/useLiveShoppingHighlights'
import usePageTitle from 'hooks/usePageTitle'
import useLiveShoppingHighlightActions from 'hooks/useLiveShoppingHighlightActions'

export default function LiveShoppingHighlights() {
  usePageTitle('Live Shopping Highlights')

  const {
    isLoading,
    liveShoppingHighlights,
    liveShoppingHighlightsCount,
    handlePageChange,
    fetchData,
    pageSize,
  } = useLiveShoppingHighlights({})
  const {
    delete: { submit },
  } = useLiveShoppingHighlightActions()

  const { confirm } = useConfirm()

  const handleRefreshLiveShoppingHighlight = useCallback(() => {
    fetchData().catch((err) => console.error(err))
  }, [fetchData])

  const handleOpenDeleteLiveShoppingHighlights = useCallback(
    (liveShoppingHighlight: LiveShoppingHighlight) => {
      confirm({
        title: 'Delete Live Shopping Highlight',
        text: `Are you sure you want to delete ${liveShoppingHighlight.name} ?`,
        submitText: 'Delete',
      })
        .then(() => {
          submit(liveShoppingHighlight).then(() => {
            handleRefreshLiveShoppingHighlight()
          })
        })
        .catch(() => Promise.resolve())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirm, handleRefreshLiveShoppingHighlight]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => liveShoppingHighlightsColumns(handleOpenDeleteLiveShoppingHighlights), [
    handleOpenDeleteLiveShoppingHighlights,
  ])

  return (
    <div>
      <PageHeader
        title="Live Shopping Highlights"
        contentRight={
          <Link to="/live_shopping_highlights/new" role="button">
            <Button primary>Add</Button>
          </Link>
        }
      />

      <Paper elevation={0}>
        <DataGrid
          autoHeight
          disableColumnMenu
          rowsPerPageOptions={[]}
          rows={liveShoppingHighlights}
          columns={columns}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={handlePageChange}
          loading={isLoading}
          pagination
          rowCount={liveShoppingHighlightsCount}
        />
      </Paper>
    </div>
  )
}

const liveShoppingHighlightsColumns: (deleteProduct: (p: LiveShoppingHighlight) => void) => GridColDef[] = (
  deleteProduct
) => [
  {
    headerName: 'Date',
    field: 'eventDate',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Name',
    field: 'name',
    sortable: false,
    flex: 3,
  },
  {
    headerName: 'Video',
    field: 'videoId',
    sortable: false,
    flex: 1,
    renderCell: ({ value }: GridCellParams) => (
      <ExternalLink href={`https://www.youtube.com/watch?v=${value}`}>{value} </ExternalLink>
    ),
  },
  {
    headerName: 'Edit',
    sortable: false,
    field: 'edit',
    flex: 1,
    renderCell: ({ row }: GridCellParams) => (
      <>
        {/* NB (eunji): /live_shopping_highlights/${row.id}/edit */}
        <Link to={`/live_shopping_highlights/${row.id}/edit`} role="button">
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>
      </>
    ),
  },
  {
    headerName: 'Delete',
    sortable: false,
    field: 'delete',
    flex: 1,
    renderCell: ({ row }: GridCellParams) => (
      <>
        <IconButton
          onClick={() => {
            deleteProduct(row as LiveShoppingHighlight)
          }}
        >
          <DeleteIcon />
        </IconButton>
      </>
    ),
  },
]
