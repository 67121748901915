import { useState, useMemo, useCallback } from 'react'

type UsePaginationParams = {
  count: number
  perPage: number
  currentPage: number
  totalPages: number
}

export default function usePagination({ count, perPage, currentPage, totalPages }: Partial<UsePaginationParams> = {}) {
  const [pagination, setPagination] = useState(() => ({
    count: count ?? 0,
    perPage: perPage ?? 10,
    totalPages: totalPages ?? 1,
    currentPage: currentPage ?? 1,

    _forceReload: Symbol(),

    setPerPage: (perPage: number) => {
      if (perPage < 1) return
      setPagination((pagination) => ({ ...pagination, perPage }))
    },

    goToPage: (page: number, { forceReload = false }: { forceReload?: boolean } = {}) => {
      setPagination((pagination) => {
        let currentPage = page
        if (page > pagination.totalPages) {
          currentPage = pagination.totalPages
        }
        if (page < 1) {
          currentPage = 1
        }
        if (!forceReload && currentPage === pagination.currentPage) {
          return pagination
        }
        return { ...pagination, currentPage, _forceReload: forceReload ? Symbol() : pagination._forceReload }
      })
    },
  }))

  const paginationQueryParams = useMemo(
    () => ({ page: pagination.currentPage.toString(), per_page: pagination.perPage.toString() }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination._forceReload, pagination.currentPage, pagination.perPage]
  )

  const updatePagination = useCallback((opts: { current_page: number; total_pages: number; total: number }) => {
    setPagination((pagination) => ({
      ...pagination,
      count: opts.total,
      totalPages: opts.total_pages,
      currentPage: opts.current_page,
    }))
  }, [])

  return { pagination, setPagination: updatePagination, paginationQueryParams }
}
