import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { AlertProvider } from 'context/Alert/AlertContext'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ConfirmProvider } from 'context/Confirm/ConfirmContext'
import { AbilityProvider } from 'context/Ability/AbilityContext'
import DateLuxonUtils from '@date-io/luxon'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import theme from './theme'
import App from './App'

if (process.env.REACT_APP_ENV === 'test') {
  const { worker } = require('test/browser')
  worker.start()
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  release: 'administry@' + process.env.npm_package_version,
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.0,
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AbilityProvider>
      <AlertProvider>
        <MuiPickersUtilsProvider utils={DateLuxonUtils}>
          <ConfirmProvider>
            <HashRouter>
              <App />
            </HashRouter>
          </ConfirmProvider>
        </MuiPickersUtilsProvider>
      </AlertProvider>
    </AbilityProvider>
  </ThemeProvider>,
  document.getElementById('root')
)
