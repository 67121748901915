import { useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import DescriptionIcon from '@material-ui/icons/Description'
import DeleteIcon from '@material-ui/icons/Delete'

import SelectField from 'components/SelectField'
import Button from 'components/Button'
import COLORS from 'colors'

interface Props {
  open: boolean
  onClose: () => void
  onConfirm: (files: File[], csvType: string) => Promise<any>
}

const supportedFileTypes = [
  { label: 'Abound (New Upload)', value: 'Abound' },
  { label: 'Faire (New Upload)', value: 'Faire' },
  { label: 'Abound (Bulk Update)', value: 'Abound Bulk Update' },
  { label: 'Shopify (New Upload)', value: 'Shopify' },
]

const maxFiles = 5

export default function ProductUploadDialog({ open, onClose, onConfirm }: Props) {
  const css = useStyles()

  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [files, setFiles] = useState<File[]>([])
  const [csvFileType, setCsvFileType] = useState<string>(supportedFileTypes[0].value)

  const handleDrop = (acceptedFiles: File[]) => {
    setFiles(([...files, ...acceptedFiles] as File[]).slice(0, 5))
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: '.csv',
    maxFiles,
  })

  const handleChangeCSVFileType = (evt: any) => {
    setCsvFileType(evt.target.value)
  }

  const handleUpload = () => {
    setIsUploading(true)
    onConfirm(files, csvFileType)
      .then(() => {
        onClose()
        setFiles([])
        setError('')
      })
      .catch((err) => setError(err.message))
      .finally(() => setIsUploading(false))
  }

  const handleRemoveFile = (evt: any, index: number) => {
    evt.stopPropagation()
    setFiles([...files.slice(0, index), ...files.slice(index + 1, files.length)])
  }

  const handleCancel = () => {
    setFiles([])
    setError('')
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      fullWidth
      maxWidth="md"
      className={css.dialogPaper}
    >
      <DialogTitle id="dialog-title">Brand Product CSV Upload</DialogTitle>
      <DialogContent>
        <form noValidate>
          <SelectField
            label="CSV Type"
            fullWidth
            value={csvFileType}
            onChange={handleChangeCSVFileType}
            options={supportedFileTypes}
          />
        </form>
        <div {...getRootProps()} className={css.dropZone}>
          <input {...getInputProps()} />
          {isDragActive && files.length < 5 ? (
            <p>Drop CSVs here...</p>
          ) : !files.length ? (
            <p>Drag & drop up to {maxFiles} files here, or click to select files</p>
          ) : (
            <List dense className={css.fileList}>
              {files.map((file: any, index: number) => (
                <ListItem key={file.path}>
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary={file.path} secondary={file.size + ' bytes'} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={(evt) => handleRemoveFile(evt, index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </div>
        {error && (
          <Alert className={css.error} severity="error">
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} size="large">
          Cancel
        </Button>
        <Button onClick={handleUpload} primary size="large" busy={isUploading} disabled={files.length === 0}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      marginTop: '16px',
    },
    fileList: {
      width: '70%',
      minWidth: '300px',
      maxWidth: '450px',
    },
    dialogPaper: {
      minHeight: '50vh',
      maxHeight: '80vh',
      fontSize: '1.3em',
    },
    dropZone: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: COLORS.lightestGray,
      minHeight: '20vh',
      maxHeight: '80vh',
      transition: 'all 0.1s ease-in',
      border: `1px dashed ${COLORS.lightGray}`,
      marginTop: '16px',
    },
  })
)
