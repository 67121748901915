import { GridCellParams } from '@material-ui/data-grid'
import Button from 'components/Button'

interface Props {
  onDelete: (id: string) => void
}

export const getProductTypeCategoryColumns = ({ onDelete }: Props) => {
  return [
    {
      headerName: 'Id',
      field: 'id',
      flex: 1,
    },
    {
      headerName: 'Category Name',
      field: 'category.name',
      flex: 1,
      renderCell: (params: GridCellParams) => params.row.category.name,
    },
    {
      headerName: 'Created Timestamp',
      field: 'createdAt',
      flex: 1,
    },
    {
      headerName: 'Deleted Timestamp',
      field: 'deletedAt',
      type: 'date',
      width: 150,
      renderCell: (params: GridCellParams) => {
        return (
          <Button danger onClick={() => onDelete(params.row.id)}>
            delete
          </Button>
        )
      },
    },
  ]
}
