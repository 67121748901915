import { useState } from 'react'
import { withErrorBoundary } from '@sentry/react'

import Panel from 'components/Panel'
import { ProductImportTable } from 'components/ProductImportStatus/ProductImportsTable'
import useInprogressImports from 'hooks/useInprogressImports'

export type ProductImportStatusProps = {
  brandId: number
  collapsible?: boolean
  startCollapsed?: boolean
}

const _ProductImportStatus = ({ brandId, collapsible = false, startCollapsed = false }: ProductImportStatusProps) => {
  const [isOpen, setOpen] = useState(!startCollapsed)

  const importsInProgress = useInprogressImports({ brandId, disable: !isOpen })

  return (
    <Panel title="Recent Product Uploads" collapsible={collapsible} startCollapsed={startCollapsed} onToggle={setOpen}>
      <ProductImportTable brandId={brandId} importIds={importsInProgress} />
    </Panel>
  )
}

export const ProductImportStatus = withErrorBoundary(_ProductImportStatus, { fallback: () => null })
