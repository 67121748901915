import { set } from 'lodash'
import { api } from 'services/api.service'
import { useCallback, useEffect, useState } from 'react'
import useAction from 'hooks/useAction'
import { useAlert } from 'context/Alert/AlertContext'
import { ProductType } from 'types/product_type'

interface ProductTypeParams {
  id: string
}

export default function useProductType({ id }: ProductTypeParams) {
  const [productType, setProductType] = useState<ProductType>()
  const snackbar = useAlert()

  const handleSuccess = useCallback((resp) => setProductType(resp.data), [])

  const [fetchData, isLoading] = useAction({
    request: () => api.get(`/product_types/${id}`),
    onSuccess: handleSuccess,
    onError: () => snackbar.error(`Error fetching product type ${id}`),
  })

  const [saveField] = useAction({
    request: (keyPath: string, value: any) =>
      api.patch(`product_types/${id}`, { product_type: set({}, keyPath, value) }),
    onSuccess: handleSuccess,
  })

  const [deleteProductType] = useAction({
    request: () => api.delete(`product_types/${id}`),
    onSuccess: () => {
      snackbar.success('Product type deleted')
    },
    onError: (err) => {
      console.error(err)
      snackbar.error('Failed to delete product type')
    },
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return {
    fetchData,
    isLoading,
    productType,
    saveField,
    deleteProductType,
  }
}
