import { Index, Hits, connectStateResults } from 'react-instantsearch-dom'

import { Box, Divider } from '@material-ui/core'
import StoreIcon from '@material-ui/icons/Store'

import Link from 'components/Link'

interface RetailerHitProps {
  hit: {
    objectID: number
    store_name: string
    email: string
  }
}

const RetailerHit = ({ hit }: RetailerHitProps) => (
  <Link to={`/retailers/${hit.objectID}`}>
    <Box display="flex" alignContent="center">
      <StoreIcon style={{ marginRight: 10 }} /> {hit.store_name} ({hit.email})
    </Box>
  </Link>
)

const RetailerIndexResult = connectStateResults<any>(
  ({ searchResults, children }) =>
    searchResults &&
    searchResults.nbHits !== 0 && (
      <div>
        {children}
        <Divider />
      </div>
    )
)

const RetailerIndex = () => {
  return (
    <Index indexName={`admin_${process.env.REACT_APP_ENV}_retailer_profiles`}>
      <RetailerIndexResult />
      <Hits hitComponent={RetailerHit} />
    </Index>
  )
}

export default RetailerIndex
