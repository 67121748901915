import { PropsWithChildren } from 'react'
import { DateTime } from 'luxon'
import { Typography } from '@material-ui/core'

export function RelativeDateCell(props: PropsWithChildren<{ isoDateTime: string }>) {
  const { isoDateTime, children } = props
  const dt = DateTime.fromISO(isoDateTime).setLocale(navigator.language)

  if (!dt.isValid) return <Typography variant="body2">-</Typography>

  return (
    <>
      <Typography variant="body2">{dt.toRelative()}</Typography>
      <Typography variant="caption">
        {dt.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)} {dt.offsetNameShort}
      </Typography>
      {children}
    </>
  )
}
