import * as yup from 'yup'
import { useMemo } from 'react'
import { useFormik } from 'formik'

import { ProductSetting } from 'types/product_setting'
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from '@material-ui/core'
import Button from 'components/Button'
import { useAlert } from 'context/Alert/AlertContext'
import useProductSettingActions from 'hooks/useProductSettingActions'

type ActionProp = 'create' | 'update'

type FormProps = {
  productSettings: ProductSetting
  onClose: () => void
  open: boolean
  formTitle: string
  action: ActionProp
  onSuccess: () => void
}

export default function ProductSettingForm({
  productSettings,
  onClose,
  open,
  formTitle,
  action,
  onSuccess,
}: FormProps) {
  const actions = useProductSettingActions()
  const { submit, isLoading } = useMemo<typeof actions.create>(() => actions[action], [actions, action])

  const { error } = useAlert()
  const schema = yup
    .object({
      key: yup.string().defined(),
      value: yup.string().defined(),
    })
    .defined()

  const formik = useFormik({
    initialValues: productSettings,
    validationSchema: schema,
    validateOnChange: true,
    onSubmit: (data: ProductSetting) => {
      return submit({
        ...data,
      })
        .then(() => {
          formik.resetForm()
          onSuccess()
          onClose()
        })
        .catch((err) => {
          error(err)
        })
    },
  })

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{formTitle}</DialogTitle>
        <Divider />

        <DialogContent style={{ marginTop: '10px' }}>
          <TextField
            fullWidth
            size="small"
            label="Key"
            margin="normal"
            autoFocus
            {...formik.getFieldProps('key')}
            error={formik.errors.key !== undefined}
            helperText={
              formik.errors.key ||
              'Key must be lowercase, and contain no punctuation except underscores, eg social_media_handle'
            }
          />
          <TextField
            fullWidth
            multiline
            size="small"
            label="Value"
            margin="normal"
            {...formik.getFieldProps('value')}
            error={formik.errors.value !== undefined}
            helperText={
              formik.errors.value ||
              'Use double quotes for String values, eg "abc". ' +
                'Integer value => 123. ' +
                'Floating Point value => 6.8. ' +
                'Array of Integers => [1,2,3]. ' +
                'Array of Strings => ["abc", "def", "ghi"]'
            }
          />
        </DialogContent>
        <Divider />

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>

          <Button
            type="submit"
            primary
            disabled={!formik.isValid}
            busy={isLoading || formik.isValidating || formik.isSubmitting}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
