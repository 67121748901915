import { useCallback, useEffect, useState } from 'react'
import { api, cancelRequest } from 'services/api.service'

import { GridPageChangeParams } from '@material-ui/data-grid'

import useAction from './useAction'
import { ProductSetting } from 'types/product_setting'
import { useAlert } from 'context/Alert/AlertContext'

const cancelKey = 'product_settings'

export default function useProductSettings({ pageSize = 20 }) {
  const [productSettings, setProductSettings] = useState<ProductSetting[]>([])
  const [productSettingsCount, setProductSettingsCount] = useState<number>(0)
  const [page, setPage] = useState(0)
  const handlePageChange = useCallback((params: GridPageChangeParams) => {
    setPage(params.page)
  }, [])

  const { error } = useAlert()

  const qp = new URLSearchParams({
    page: '' + (page + 1),
    per_page: '' + pageSize,
  })

  const queryString = qp.toString()

  const [fetchData, isLoading] = useAction({
    request: () => api.get(`product_settings?${queryString}`, { cancelKey }),
    onSuccess: ({ data }) => {
      setProductSettingsCount(data.meta.total)
      setProductSettings(data.data)
    },
    onError: () => error('Error fetching product settings'),
  })

  useEffect(() => {
    fetchData().catch((err) => {
      console.error(err)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  useEffect(() => () => cancelRequest(cancelKey), [])

  return {
    productSettings,
    isLoading,
    handlePageChange,
    productSettingsCount,
    fetchData,
    pageSize,
  }
}
