import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { get } from 'lodash'
import { Typography } from '@material-ui/core'

import { useAlert } from 'context/Alert/AlertContext'
import { useConfirm } from 'context/Confirm/ConfirmContext'
import { LineItemValue } from './interfaces/index.interface'

import Button from 'components/Button'
import Panel from 'components/Panel'
import Link from 'components/Link'
import Definition from 'components/Definition'
import LineItem from './LineItem'

import centsToCurrency from 'util/centsToCurrency'
import { Charge } from 'types'
import { useCurrencyContext } from 'context/Country/CountryContext'
import AddLineItemForm, { ProductValues } from './components/AddLineItemForm'

interface Params {
  id: string
}

interface Props {
  lineItems: LineItemValue[]
  order: any
  charge: Charge
  reinstateLineItem: any
  addProductToOrder: (productId: number | string, quantity: number | string) => Promise<void>
}

export default function LineItems({ lineItems, order, charge, reinstateLineItem, addProductToOrder }: Props) {
  const css = useStyles()
  const { id } = useParams<Params>()
  const currency = useCurrencyContext()
  const snackbar = useAlert()
  const { confirm } = useConfirm()

  const handleAddLineItem = (values: ProductValues) => {
    addProductToOrder(values.productId, values.quantity)
  }

  const handleReinstateLineItem = (itemToReinstate: LineItemValue) => {
    const confirmText = (
      <Typography>
        Are you sure you would like to reinstate {itemToReinstate.variant.name} from this order? <br /> <br />
        <span className={css.warning}>NOTE: </span>Changes here will <span className={css.warning}>NOT</span> update
        charges or notify customers. If the brand has already been paid you will need to manually administer an
        additional payment. If the retailer has already paid you will need to manually administer an additional charge.
      </Typography>
    )
    confirm({
      title: 'Reinstate Item',
      text: confirmText,
      submitText: 'Reinstate',
      danger: true,
    })
      .then(() => {
        reinstateLineItem({ id: itemToReinstate.id }).then(() => {
          snackbar.success('Order successfully updated.')
        })
      })
      .catch(() => {})
  }

  const appliedDiscountPercentage = get(order, ['appliedPromotions', 0, 'appliedDiscountPercentage'], 0)

  return (
    <>
      <Panel>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Definition fontLarge label="Total">
              {centsToCurrency(order.retailerTotalInCents, currency)}
            </Definition>
          </Grid>
          <Grid item xs={4}>
            <Definition fontLarge label="Subtotal">
              {centsToCurrency(order.subtotalInCents, currency)}
            </Definition>
          </Grid>
          <Grid item xs={4}>
            {charge.shippingCostInCents ? (
              <Definition fontLarge label="Shipping Total">
                {centsToCurrency(charge.shippingCostInCents, currency)}
              </Definition>
            ) : (
              <Definition fontLarge label="Estimated Shipping Total">
                {centsToCurrency(order.estimatedShippingCostInCents, currency)}
              </Definition>
            )}
          </Grid>
        </Grid>
      </Panel>
      <Panel title="Add Line Item">
        <AddLineItemForm onSubmit={handleAddLineItem} />
      </Panel>

      <Panel
        title="Line Items"
        contentRight={
          <Button primary component={Link} to={`/orders/${id}/edit`}>
            Edit Line Items
          </Button>
        }
      >
        {lineItems
          .filter((item: LineItemValue) => item.deletedAt === null)
          .map((item: LineItemValue) => (
            <div className={css.root} key={item.id}>
              <LineItem item={item} appliedDiscountPercentage={appliedDiscountPercentage} />
            </div>
          ))}
      </Panel>
      {lineItems.filter((item: LineItemValue) => item.deletedAt !== null).length > 0 && (
        <Panel title="Deleted Items">
          {lineItems
            .filter((item: LineItemValue) => item.deletedAt !== null)
            .map((item: LineItemValue) => (
              <s className={css.root} key={item.id}>
                <LineItem item={item} appliedDiscountPercentage={appliedDiscountPercentage} />
                <Button text className={css.btn} onClick={() => handleReinstateLineItem(item)}>
                  Reinstate Item
                </Button>
              </s>
            ))}
        </Panel>
      )}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1, 0),
      borderBottom: '1px solid rgba(0,0,0,.1)',
      '&:last-child': {
        border: 0,
        margin: 0,
      },
    },
    btn: {
      display: 'inline-block',
    },
    warning: {
      color: theme.palette.error.main,
    },
  })
)
