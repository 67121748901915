import React from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

import NavDrawer from 'components/NavDrawer'
import Search from 'components/Search'
import UserMenu from 'components/UserMenu'

const MainLayout: React.FC = ({ children }) => {
  const css = useStyles()

  return (
    <div className={css.root}>
      <AppBar position="fixed" className={css.appBar} variant="outlined">
        <Toolbar className={css.toolbar}>
          <div className={css.perspectiveContainer}>
            <Typography variant="h6" noWrap className={css.logotype}>
              Administry
            </Typography>
          </div>
          <Search />
          <UserMenu />
        </Toolbar>
      </AppBar>
      <NavDrawer />
      <main className={css.content}>
        <Toolbar />
        {children}
      </main>
    </div>
  )
}

export default MainLayout

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      animation: '1s $fadeIn',
    },
    perspectiveContainer: {
      perspective: '5cm',
      perspectiveOrigin: '100% 0%',
      marginRight: '50px',
    },
    logotype: {
      color: 'black',
      overflow: 'visible',
      transform: 'rotateY(25deg) scaleY(2.1) scaleX(1.5)',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      transformOrigin: '0% 100%',
      position: 'relative',
      top: '1.1rem',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.primary.light,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    content: {
      flexGrow: 1,
      // 200px is width of the NavDrawer.
      width: 'calc(100vw - 200px)',
      padding: theme.spacing(5),
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(5, 10),
      },
    },
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0,
      },
      '30%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  })
)
