import { ChargeAttempt } from 'types/charge_attempt'
import { CompactOrder } from 'types/order'
import { Refund } from 'types/refund'

export interface Charge {
  aasmState: string
  availableAasmStateTransitions: ChargeAASMState[]
  canceledAt?: string
  captured: boolean
  captureFailedAt?: string
  chargeAttempts: ChargeAttempt[]
  createdAt: string
  id: number
  orders?: CompactOrder[]
  paymentTerms: 'acceptance' | 'net_terms' | 'net60' | 'net90'
  refunds: Refund[]
  scheduledAt: string
  shippingCostInCents?: number
  stripeFingerprint?: string
  stripePaymentIntentId?: string
  balanceTransactionId?: string
  balanceChargeId?: string
}

export const emptyCharge: Charge = {
  aasmState: '',
  availableAasmStateTransitions: [],
  id: 0,
  captured: false,
  chargeAttempts: [],
  refunds: [],
  paymentTerms: 'acceptance',
  createdAt: '',
  scheduledAt: '',
}

export type ChargeAASMState = 'pending' | 'fully_paid' | 'past_due' | 'defaulted' | 'on_payment_plan' | 'canceled'
