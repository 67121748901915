import axios, { CancelTokenSource } from 'axios'

import TokenService from './token.service'

export const HOSTNAME = process.env.REACT_APP_API_BASE_URL || ''

export const api = axios.create({
  baseURL: `${HOSTNAME}/api/admin`,
  withCredentials: true,
})

const requestCancelSourcesByKey: { [cancelKey: string]: CancelTokenSource } = {}

export const cancelRequest = (cancelKey: string) => {
  const cancelSource = requestCancelSourcesByKey[cancelKey]
  if (cancelSource) {
    cancelSource.cancel()
    delete requestCancelSourcesByKey[cancelKey]
  }
}

export const triggerTokenRefreshOnExpiration = (config: any) => {
  const token = localStorage.getItem('t')
  const refreshingToken = localStorage.getItem('refreshingToken') === 'true'
  // Refresh token if its less than 10 minutes from expiring
  if (
    token &&
    !refreshingToken &&
    Math.abs(Date.parse(JSON.parse(token).exp) - Date.now()) < 600000 &&
    config.url !== '/auth/refresh_token'
  ) {
    localStorage.setItem('refreshingToken', 'true')
    TokenService.refreshAccess()
  }
}

api.interceptors.request.use(
  (config: any) => {
    if (config.cancelKey) {
      cancelRequest(config.cancelKey)
      const newCancelSource = axios.CancelToken.source()
      requestCancelSourcesByKey[config.cancelKey] = newCancelSource
      config.cancelToken = newCancelSource.token
    }
    const refreshTokenifExpired = new Promise<void>(function (resolve) {
      triggerTokenRefreshOnExpiration(config)
      resolve()
    })
    return refreshTokenifExpired.then(function () {
      localStorage.setItem('refreshingToken', 'false')
      config.headers.Authorization = `Bearer ${TokenService.getAccess()}`
      return config
    })
  },
  (error: Error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalReq = error.config
    // invalid login
    // do not want to make a request for refresh token
    if (error.response?.status === 401 && originalReq.url === '/auth/sign_in') {
      TokenService.remove()
      return Promise.reject(error)
    }

    if (error.response?.status === 401 && originalReq.url !== '/auth/refresh_token' && !originalReq.isRetry) {
      try {
        originalReq.isRetry = true
        await TokenService.refreshAccess()
        return api.request(originalReq)
      } catch (error: any) {
        if (error.response?.status === 401) {
          // clear tokens, force logout
          TokenService.remove()
          window.location.reload()
        }

        throw error
      }
    } else {
      return Promise.reject(error)
    }
  }
)
