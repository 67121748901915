import { useEffect, useState } from 'react'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'components/Button'

interface Props {
  shown: boolean
  onCancel: any
  onConfirm: (value: string) => void
  title: string
  message?: string
  inputLabel: string
  submitText?: string
  inputProps?: any
  initialValue?: string
  textFieldProps?: TextFieldProps
}

export default function TextFieldDialog({
  title,
  message,
  inputLabel,
  shown,
  onCancel,
  onConfirm,
  submitText = 'Submit',
  inputProps = { type: 'text' },
  initialValue = '',
  textFieldProps,
}: Props) {
  const [value, setValue] = useState<string>(initialValue)
  const handleChange = (evt: any) => {
    setValue(evt.target.value)
  }

  useEffect(() => {
    if (shown) {
      setValue(initialValue)
    }
  }, [initialValue, shown])

  return (
    <Dialog open={shown} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title">
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onConfirm(value)
        }}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {message && <DialogContentText>{message}</DialogContentText>}
          <TextField
            fullWidth
            autoFocus
            label={inputLabel}
            value={value}
            onChange={handleChange}
            inputProps={inputProps}
            {...textFieldProps}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button primary type="submit">
            {submitText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
