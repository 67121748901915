import { api, cancelRequest } from 'services/api.service'
import { useCallback, useEffect, useState } from 'react'
import { GridPageChangeParams } from '@material-ui/data-grid'

import { useAlert } from 'context/Alert/AlertContext'
import useAction from 'hooks/useAction'

interface Params {
  query?: string
  aasmState?: string
  chargeState?: string
  paymentTerms?: string
  pageSize: number
}

const cancelKey = 'orderReturns'

export default function useOrderReturns({ query, aasmState, chargeState, paymentTerms, pageSize = 10 }: Params) {
  const [data, setData] = useState<any>({})
  const [page, setPage] = useState(0)
  const snackbar = useAlert()

  const handlePageChange = useCallback((params: GridPageChangeParams) => {
    setPage(params.page)
  }, [])

  const qp = new URLSearchParams({
    page: '' + (page + 1),
    per_page: '' + pageSize,
  })
  aasmState && qp.set('aasmState', aasmState)
  chargeState && qp.set('chargeState', chargeState)
  paymentTerms && qp.set('paymentTerms', paymentTerms)
  query && qp.set('query', query)

  const [fetchData, isLoading] = useAction({
    request: () => api.get(`/order_returns?${qp.toString()}`, { cancelKey }),
    onSuccess: (resp: any) => setData(resp.data),
    onError: () => snackbar.error('Error fetching order returns'),
  })

  useEffect(() => {
    fetchData()
    return () => cancelRequest(cancelKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, query, aasmState, chargeState, paymentTerms])

  return {
    fetchData,
    isLoading,
    orderReturns: data.data || [],
    orderReturnsCount: data.meta?.total,
    page,
    handlePageChange,
  }
}
