import { useState } from 'react'

interface Params {
  key: string
  initialValue: any
  parse?: (stringValue: string) => any
  stringify?: (value: any) => string
  isValid?: (value: any) => boolean
}

const defaultParse = (v: any) => v
const defaultStringify = (v: any) => '' + v
const defaultIsValid = () => true

export default function useLocalStorage({
  key,
  initialValue,
  parse = defaultParse,
  stringify = defaultStringify,
  isValid = defaultIsValid,
}: Params) {
  // TODO: when we have a user model
  const currentUser: any = null

  const userKey = currentUser ? `${currentUser.username}:${key}` : key

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(userKey) ?? window.localStorage.getItem(key)
      if (item) {
        const parsedValue = parse(item)
        if (isValid(parsedValue)) {
          return parsedValue
        }
      }
      return initialValue
    } catch (error) {
      console.log(error)
      return initialValue
    }
  })

  const setValue = (value: any) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.localStorage.setItem(userKey, stringify(valueToStore))
      if (currentUser) {
        localStorage.removeItem(key)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return [storedValue, setValue]
}
