import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import COLORS from 'colors'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up(760)]: {
        width: 400,
      },
      [theme.breakpoints.up(1024)]: {
        width: 600,
      },
    },
    input: {
      backgroundColor: theme.palette.secondary.contrastText,
    },
    results: {
      '& ul': {
        paddingInlineStart: 0,
        listStyleType: 'none',
        '& li': {
          padding: 6,
          paddingLeft: 15,
          '&:hover': {
            backgroundColor: COLORS.lightestGrayOpaque,
          },
        },
      },
    },
  })
)
