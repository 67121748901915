import usePageTitle from 'hooks/usePageTitle'
import LiveShoppingHighlightForm from './LiveShoppingHighlightsForm'

export default function LiveShoppingNew() {
  usePageTitle(`New Live Shopping Highlight`)

  return (
    <LiveShoppingHighlightForm
      action="create"
      currentLiveShoppingHighlight={{
        name: '',
        eventDate: '',
        videoId: '',
        brands: new Map<number, number[]>(),
      }}
      formTitle="Create Live Shopping Highlight"
    />
  )
}
